import React, { } from 'react';
import { Select, Button } from 'antd';

export default function SiderSelectView(props) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }} >
            <Button
                shape="circle"
                type="primary"
                icon={props.icon ? props.icon : false}
                style={{
                    width: 50,
                    height: 50,
                    backgroundColor: props.iconColor ? props.iconColor : '',
                    borderColor: props.iconColor ? props.iconColor : '',
                }}
            />
            <div style={{ width: '70%' }}>
                <p style={{ color: 'rgb(140,140,140)' }}>{props.title || ''}</p>
                <Select
                    size="large"
                    style={{ borderBottom: '1px solid rgb(217,217,217)', width: '100%' }}
                    bordered={false}
                    value={props.value || ''}
                >
                    {/* <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option> */}
                </Select>
            </div>
        </div>
    )
}
