import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Typography, Space, Tag, Button, Tooltip, Radio, Popover, Input } from 'antd';
import { RightOutlined, PlusCircleOutlined, EyeOutlined, CaretUpOutlined, CaretDownOutlined, ForkOutlined, AccountBookOutlined, SearchOutlined } from '@ant-design/icons';
import { simpleColumns, RateText } from '@/utils/utils2';
import { autoCol, colWidth, isEmptyStringV2 } from '@/utils/utils';
import { useReactive } from 'ahooks';
import RulesModal from './Rules';
import BreakModal from './BreakModal';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Search } = Input;

const PAGE_SIZE = 10;
const CHECK_COLOR = "#2db7f5";
const FILTER_ARRAY = [{ text: '买入', value: 'BUY' }, { text: '卖出', value: 'SELL' }];
const REVERT_TYPE = { 'left': 'right', 'right': 'left' };
// 格式化平台的值；表格不同=>所属平台 显示
const FORMAT_DIR = {
  'bt': 'BT', 'bt_rest': 'BT', 'bt_testing': 'BT',
  'simx': 'SIMX',
  'tamp': 'TAMP', 'tamp_fc': 'TAMP反采'
};
// 渲染差异字段; [显示差异为 0]，disable代表右侧是否无对称数据
const checkDiffs = (text, hasRightData) => {
  const getIsDiff = _.get(text, 'isDiff', false);
  const getRate = _.get(text, 'diffRate', 0);
  const newText = getRate !== undefined && getRate !== null && hasRightData ? getRate + '%' : '';
  return getIsDiff ? <Text strong style={{ color: _.get(text, 'color', 'black') }}>{newText}</Text> : newText;
};
//差异字段 isDiff 为 true，显示红色
const renderDiffText = (isDiff, text) => {
  let textProps = {};
  if (isDiff) {
    textProps.style = { color: '#2774b8' };
    textProps.strong = true;
  }
  return <Text {...textProps}>{text}</Text>
};
const renderSide = (type) => type === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>;
const renderTime = (record, time_key) => {
  const sizeRecord = _.size(_.get(record, 'otherRecord'));
  return sizeRecord > 1 ? `${sizeRecord}笔订单` : moment(_.get(record, time_key)).format('HH:mm:ss.SSS');
}
/**
 * 对比表格：左侧为基准对比右侧数据；左侧为操作栏，操作任务安排到左侧；右侧为纯展示；
 */
const CompareTable = ({
  fullParams = {},
  cDate = '',
  tableDatas = {},
  avgsValue = {},
  unTrading = {},

  upCount = 0,
  loading = false,
  onAddDiffence,
}) => {
  const pageState = useReactive({ pageLeft: 1, pageRight: 1, totalLeft: 0, totalRight: 0 });
  const checkState = useReactive({
    leftCheck: null, rightCheck: null, sameCheck: null, // 选中股票相关
    searchInfo: { list: [], size: 0, index: 0, txt: '' }, // 搜索股票相关state
  }); // 选中相关state
  const [noneType, setNoneType] = useState('normal'); // 右侧table数据的类型
  const [noneList, setNoneList] = useState([]); // 右侧不匹配数据
  const modalState = useReactive({ show: false, value: {} });
  const modalBreakState = useReactive({ show: false, datas: [], title: '' });
  const getBaseType = _.get(fullParams, 'baseType', '');
  const getRightType = _.get(fullParams, 'rightType', '');
  const getCtype = _.get(fullParams, 'ctype', '');

  useEffect(() => {
    // 归零分页；情况选择项，赋值新的不同的list；
    pageState.totalLeft = _.size(_.get(tableDatas, 'left', 0));
    pageState.totalRight = _.size(_.get(tableDatas, 'right', 0));
    checkState.leftCheck = null;
    checkState.rightCheck = null;
    setNoneType('normal');
  }, [upCount]);
  //根据方向筛选未交易数据
  function findUntradingList(dir) {
    const getList = _.chain(_.get(unTrading, dir, [])).filter(o => o.dateIn === cDate).value();
    return getList;
  }
  // 自定义选中点击选中div及文字
  const renderCheckDiv = (record, txt, type) => {
    const isCheck = _.get(record, 'dindex') === checkState.leftCheck;
    // 显示时，有hasSame字段，并且otherRecord并不是多笔数据的，一般是两笔买卖不同的数据
    const isSame = _.get(record, 'hasSame', null) && _.size(_.get(record, 'otherRecord')) < 2;
    const isNone = !('hasDiff' in record);
    const textCheckProps = checkState.sameCheck === _.get(record, 'dindex') ? { style: { color: 'blue' } } : {};
    const newTxt = isNone && type === 'right' ? <Text {...textCheckProps}> {`*${txt}`}</Text> : <Text {...textCheckProps}>{txt}</Text>; // 右侧没有与左侧对应数据加星号
    return <Space size='small'>
      {isCheck ? <div style={{ padding: 3, backgroundColor: CHECK_COLOR, borderRadius: 4, color: 'white' }}>
        {txt}
      </div> : newTxt}
      {isSame && <div style={{ marginLeft: 2, cursor: 'pointer' }} onClick={() => {
        // 相同则可以点击查到到另外一条记录，相同股票代码，不同方向数据
        let findex = _.findIndex(_.get(tableDatas, type), o => o.stockCode === _.get(record, 'stockCode') && o.side !== _.get(record, 'side'));
        if (findex > -1) {
          pageState[type === 'left' ? 'pageLeft' : 'pageRight'] = _.ceil((findex + 1) / PAGE_SIZE);
          checkState.sameCheck = tableDatas[type][findex]['dindex'];
        }
      }}>
        <RightOutlined style={{ fontSize: 10, color: 'grey' }} />
      </div>}
      {type === 'left' && <div style={{ cursor: 'pointer' }} onClick={() => onAddDiffence(record)}>
        <PlusCircleOutlined style={{ fontSize: 11, color: 'grey' }} />
      </div>}
    </Space>
  };
  // 配置所有表格的columns
  const columnsLeft = [
    {
      ...simpleColumns(['股票', 'stockCode', 150], { fixed: 'left' }),
      plateform: 'both',
      sorter: (a, b) => a.orders - b.orders,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')}  ${text}`
        // 点击编号可关联左右相同条件的数据; 【只现在符合条件的第一位】
        return _.get(record, 'dindex') === checkState.leftCheck ? renderCheckDiv(record, conbindName, 'left') : <Button size='small' type='link'
          disabled={!('hasDiff' in record) ? true : false}
          onClick={() => checkChange(record, 'checkRight')}>
          {conbindName}
        </Button>
      }
    },
    {
      ...simpleColumns(['规则', 'rules', 50]),
      plateform: 'simx',
      render: (text, record) => <Button size='small' icon={<EyeOutlined />} type='text' onClick={() => {
        modalState.show = true;
        modalState.value = record;
      }} />
    },
    {
      ...simpleColumns(['成交均价', 'avgPrice', 80]),
      plateform: 'both',
      render: (text, record) => _.get(record, 'dindex') === checkState.leftCheck ? <Text strong>{text}</Text> : text
    },
    {
      ...simpleColumns(['成交数量', 'tQuant', 80]),
      plateform: 'both',
      render: (text, record) => _.get(record, 'dindex') === checkState.leftCheck ? <Text strong>{text}</Text> : text
    },
    {
      ...simpleColumns(['方向', 'side', 80]),
      plateform: 'both',
      filters: FILTER_ARRAY,
      onFilter: (value, record) => record.side.indexOf(value) === 0,
      render: (text) => renderSide(text)
    },
    { // 只有一笔是显示委托事情，更多需要展开显示更多信息
      ...simpleColumns(['委托', 'ctime', 120]),
      plateform: 'both',
      sorter: (a, b) => a.ctime - b.ctime,
      render: (text, record) => renderTime(record, 'commissionTime')
    },
    {
      ...simpleColumns(['成交', 'trtime', 120]),
      plateform: 'tamp',
      sorter: (a, b) => a.trtime - b.trtime,
      render: (text, record) => renderTime(record, 'transactTime')
    },
    {
      ...simpleColumns(['贡献度', 'accountContributionRate', 85]),
      fixed: 'right',
      plateform: 'both',
      sorter: (a, b) => a.accountContributionRate - b.accountContributionRate,
      render: (text) => <RateText rate={text} carry={4} />
    },
    {
      ...simpleColumns(['价格差', 'pricef', 80]),
      fixed: 'right',
      plateform: 'both',
      sorter: (a, b) => a?.pricef?.diffRate - b?.pricef?.diffRate,
      render: (text, record) => checkDiffs(text, 'hasDiff' in record)
    },
  ];
  const columnsRight = [
    {
      ...simpleColumns(['代码', 'stockCode', 190], { fixed: 'left' }),
      idx: 0,
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')}  ${text}`;
        const isBreak = _.get(record, 'isBreak', null) && getRightType === 'tamp' ? true : false; // ctype是simx的时候，右侧是tamp，tamp才有拆单数据
        // 与左侧符合条件的关联数据展示tag
        return <Space size='small'>
          {_.get(record, 'dindex') === checkState.rightCheck ? renderCheckDiv(record, conbindName, 'right') : conbindName}
          {isBreak && <Tooltip title='拆单'>
            <Button type='text' size='small' icon={<ForkOutlined />} onClick={() => {
              modalBreakState.show = true;
              modalBreakState.datas = _.get(record, 'breakList', []);
              modalBreakState.title = _.get(record, 'stockName', '');
            }} />
          </Tooltip>}
        </Space>
      }
    },
    {
      ...simpleColumns(['成交均价', 'avgPrice', 80]),
      idx: 2,
      render: (text, record) => renderDiffText(_.get(record, 'pricef.isDiff', false), text)
    },
    {
      ...simpleColumns(['贡献度', 'accountContributionRate', 85]),
      idx: 7,
      render: (text, record) => _.round(text, 4)
    },
    {
      ...simpleColumns(['成交数量', 'tQuant', 80]),
      idx: 1,
      render: (text, record) => renderDiffText(_.get(record, 'qtf.isDiff', false), text)
    },
    {
      ...simpleColumns(['拆单差', 'breakDiff', 80]),
      idx: 3,
      render: (text) => <RateText rate={_.get(text, 'diffRate', 0)} carry={4} size='normal' />
    },
    {
      ...simpleColumns(['方向', 'side', 80]),
      idx: 4,
      render: (text) => renderSide(text)
    },
    {
      ...simpleColumns(['委托', 'ctime', 120]),
      idx: 5,
      render: (text, record) => renderTime(record, 'commissionTime')
    },
    {
      ...simpleColumns(['成交', 'trtime', 120]),
      idx: 6,
      sorter: (a, b) => a.trtime - b.trtime,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => renderTime(record, 'transactTime')
    },
  ];
  const untampRightCol = _.filter(columnsRight, o => _.includes([0, 2, 7, 1, 4, 5], o.idx));
  const columnsUntrade = [
    simpleColumns(['代码', 'stockCode']),
    simpleColumns(['名称', 'stockName']),
    simpleColumns(['贡献度', 'accountContributionRate']),
    simpleColumns(['所属平台', 'platform'])
  ];
  const columnsNone = [
    {
      ...simpleColumns(['代码', 'stockCode', 130], { fixed: 'left' }),
      idx: 0,
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')}  ${text}`;
        //const isBreak = _.get(record, 'isBreak', null) && getRightType === 'tamp' ? true : false; // ctype是simx的时候，右侧是tamp，tamp才有拆单数据
        // 与左侧符合条件的关联数据展示tag
        return <Space size='small'>
          {_.get(record, 'dindex') === checkState.rightCheck ? renderCheckDiv(record, conbindName, 'right') : conbindName}
          <div style={{ cursor: 'pointer' }} onClick={() => onAddDiffence(record)}>
            <PlusCircleOutlined style={{ fontSize: 11, color: 'grey' }} />
          </div>
        </Space>
      }
    },
    ..._.filter(columnsRight, o => _.includes([2, 1, 4], o.idx)),
    {
      ...simpleColumns(['所属平台', 'dir', 110]),
      render: (text) => FORMAT_DIR[_.get(fullParams, `${text === 'left' ? 'leftType_org' : 'rightType_org'}`, '')] ?? ''
    }
  ];
  const newColLeft = _.filter(columnsLeft, o => o.plateform === 'both' || o.plateform === getBaseType);
  // 操作选中股票 
  function checkChange(record = {}, dir = '') {
    if (noneType === 'normal') {
      if (dir === 'checkRight' || dir === 'both') { // 点击左侧查找右侧，或搜索时，对右侧进行操作
        let findex = _.findIndex(_.get(tableDatas, 'right'), o => o.stockCode === _.get(record, 'stockCode') && o.side === _.get(record, 'side'));
        if (findex > -1) {
          pageState.pageRight = _.ceil((findex + 1) / PAGE_SIZE); // 查找并修改右侧分页
          checkState.rightCheck = tableDatas['right'][findex]['dindex']; // 通过dindex进行联动显示
          checkState.leftCheck = _.get(record, 'dindex');
        }
      }
      if (dir === 'both') { //搜索时左侧进行联动
        let findex = _.findIndex(_.get(tableDatas, 'left'), o => o.stockCode === _.get(record, 'stockCode') && o.side === _.get(record, 'side'));
        if (findex > -1) {
          pageState.pageLeft = _.ceil((findex + 1) / PAGE_SIZE);
          checkState.leftCheck = tableDatas['left'][findex]['dindex'];
        }
      }
    }
  }
  // 拓展表格内是多笔买卖合并的所有记录
  const expandCol = [
    {
      ...simpleColumns(['委托时间', 'commissionTime']),
      render: (text) => moment(text).format('HH:mm:ss')
    },
    simpleColumns(['数量', 'cumQty']),
    simpleColumns(['均价', 'avgPrice']),
    {
      ...simpleColumns(['方向', 'side']),
      render: (text) => renderSide(text)
    },
  ];
  const expandedRowRender = (fatRecord) => {
    return (
      <Table
        rowKey="clientOrderId"
        dataSource={_.get(fatRecord, 'otherRecord', [])}
        columns={expandCol}
        showHeader={false}
        pagination={false}
        size='small'
        border={false}
      />
    )
  }
  // 根据股票名称或代码查找对应股票，并操作跳转
  const onLeftSearch = (value) => {
    let filters = value
      ? _.filter(_.get(tableDatas, 'left'), o => _.includes(o.stockName, value) || _.includes(o.stockCode, value))
      : [];
    checkState.searchInfo.size = _.size(filters);
    checkState.searchInfo.list = filters;
    if (_.size(filters) > 0) {
      checkChange(_.head(filters), 'both');
      checkState.searchInfo.index = 0;
    }
    if (!value) {
      checkState.searchInfo.txt = '';
    }
  }
  // 查找上一个或者下一个搜索项 down 是往下找 +1，up则相反
  const goNextSearch = (type) => {
    checkState.searchInfo.index = type === 'down' ? checkState.searchInfo.index + 1 : checkState.searchInfo.index - 1;
    checkChange(checkState.searchInfo.list[checkState.searchInfo.index], 'both');
  }
  // 表格footer渲染
  const isNoneList = noneType !== 'normal' ? true : false;
  const renderFooter = (type) => {
    const footerObj = {
      'left': {
        'keys': ['total', 'validCount', 'diffs', 'avg'],
        'calKey': 'leftCal',
        'rText': '总价格差: ',
      },
      'right': {
        'keys': ['total', 'validCount', 'bdiff', 'bavg'],
        'calKey': 'rightCal',
        'rText': '总拆单价格差: ',
      },
    }
    const finalKeys = footerObj[type].keys;
    const calKey = footerObj[type].calKey;
    const rightCol = <Space>
      <Text>{footerObj[type].rText}</Text>
      <RateText rate={_.get(tableDatas, `${calKey}.${finalKeys[2]}`, 0)} carry={4} size='normal' />
      <Text>平均: </Text>
      <RateText rate={_.get(tableDatas, `${calKey}.${finalKeys[3]}`, 0)} carry={4} size='normal' />
    </Space>
    return <div style={{ position: 'absolute', bottom: 6, width: '96%' }}>
      <Row>
        <Col span={12}>
          {isNoneList && type === 'right' ? <Text>数量:{_.size(noneList)}</Text>
            : <Space>
              <Text>股票总数: {_.get(tableDatas, `${calKey}.${finalKeys[0]}`, 0)}</Text>
              <Text>有效股票数: {_.get(tableDatas, `${calKey}.${finalKeys[1]}`, 0)}</Text>
            </Space>
          }
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {rightCol}
        </Col>
      </Row>
    </div>
  };
  // 表格相关常量
  const radioOptions = [{ label: '常规', value: 'normal' }, { label: '未交易', value: 'untrade' }, { label: '不同', value: 'none' }];
  const tableRights = _.get(tableDatas, 'right', []);
  const colRightObj = {
    'normal': getRightType !== 'tamp' ? untampRightCol : columnsRight,
    'untrade': columnsUntrade,
    'none': columnsNone
  };
  const renderTableTitle = (type = '') => {
    const dir_obj = { 'left': ['baseType', 'accLeftName'], 'right': ['rightType', 'accRightName'] };
    const getDirName = !isEmptyStringV2(type) ? _.get(fullParams, dir_obj[type][1]) : '';
    let finalString = '';
    if (!isEmptyStringV2(getDirName)) {
      finalString = _.toUpper(_.get(fullParams, dir_obj[type][0], '')) + '-' + _.get(fullParams, dir_obj[type][1])
    }
    if (_.size(finalString) > 23) { // 最大显示长度24个字符; 拼接前20个+省略号+最后两个字符
      let stringArray = _.split(finalString, '');
      let s_arr = _.size(stringArray);
      let newStringArray = _.concat(_.slice(stringArray, 0, 21), ['...'], stringArray[s_arr - 2], stringArray[s_arr - 1]);
      finalString = newStringArray;
    }
    return finalString;
  }
  // 搜索股票功能相关
  const searchUpDownProps = { size: 'small', type: 'text' };
  const searchInputProps = { value: checkState.searchInfo.txt, size: 'small', placeholder: '搜索股票名称或代码', style: { width: 200 } }
  const searchContent = (<Space direction='vertical' size='small'>
    <Space size='small'>
      <Search {...searchInputProps} onChange={(e) => checkState.searchInfo.txt = e.target.value} onSearch={onLeftSearch} />
      <Button {...searchUpDownProps} onClick={() => onLeftSearch()}>清除</Button>
    </Space>
    {checkState.searchInfo.size > 0 && <Row style={{ height: 22 }}>
      <Col span={12}><Text>结果{String(checkState.searchInfo.size)}条</Text></Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <Button
          {...searchUpDownProps}
          disabled={checkState.searchInfo.size < 2 || checkState.searchInfo.index === 0}
          icon={<CaretUpOutlined />}
          onClick={() => goNextSearch('up')}
        />
        <Button
          {...searchUpDownProps}
          disabled={checkState.searchInfo.size < 2 || checkState.searchInfo.index === checkState.searchInfo.size - 1}
          icon={<CaretDownOutlined />}
          onClick={() => goNextSearch('down')}
        />
      </Col>
    </Row>}
  </Space>);
  const cardProps = { bodyStyle: { padding: 8 } };
  // console.log('left', _.get(tableDatas, 'left', []))
  return (
    <Row gutter={[8, 16]}>
      <Col {...autoCol([12])}>
        <Card {...cardProps} style={{ borderRadius: 8, marginLeft: 8, padding: 8, minHeight: 628 }}>
          <Row style={{ marginBottom: 8 }}>
            <Col span={12} style={{ minHeight: 24 }}>
              <Space size='small'><AccountBookOutlined />
                <Text strong>{renderTableTitle(getCtype)}</Text>
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Popover content={searchContent} trigger="click">
                <SearchOutlined />
              </Popover>
            </Col>
          </Row>
          <Table
            rowKey="key"
            loading={loading}
            dataSource={_.get(tableDatas, 'left', [])}
            columns={newColLeft}
            scroll={{ x: colWidth(newColLeft) }}
            bordered
            size='small'
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => _.size(record.otherRecord) > 1, // 只有一条时不用显示扩展
              onExpand: (expanded, record) => {
                if (expanded) {
                  checkChange(record, 'checkRight');
                }
              },
            }}
            pagination={{
              current: pageState.pageLeft,
              total: pageState.totalLeft,
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              onChange: (page) => {
                pageState.pageLeft = page;
              }
            }}
          />
          {renderFooter('left')}
        </Card>
      </Col>
      <Col {...autoCol([12])}>
        <Card {...cardProps} style={{ borderRadius: 8, marginRight: 8, minHeight: 628 }}>
          <Row style={{ marginBottom: 8 }}>
            <Col span={12}>
              <Space size='small'><AccountBookOutlined />
                <Text strong>{renderTableTitle(REVERT_TYPE[getCtype])}</Text>
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Radio.Group
                size='small'
                options={radioOptions}
                onChange={({ target: { value } }) => {
                  // 修改右侧表格的数据，实现切换
                  setNoneType(value);
                  const fdata = value === 'untrade' ? findUntradingList(getCtype) : _.concat(_.get(tableDatas, 'leftDiffList', []), _.get(tableDatas, 'rightDiffList', []));
                  // 【bug-fix】 key值在不同的情况下，会出现相同key，故修改key值不重复，否则表会乱掉..
                  setNoneList(fdata.map((n, i) => { return 'key' in n ? { ...n, 'key': `${n.key}${i}` } : n }));
                  pageState.pageRight = 1;
                  if (value === 'normal') {
                    pageState.totalRight = _.size(tableRights);
                  } else {
                    pageState.totalRight = _.size(fdata);
                  }
                }}
                value={noneType}
                optionType="button"
              />
            </Col>
          </Row>
          <Table
            rowKey={noneType === 'untrade' ? 'id' : "key"}
            dataSource={noneType !== 'normal' ? noneList : tableRights}
            columns={colRightObj[noneType]}
            scroll={{ x: colWidth(colRightObj[noneType]) }}
            bordered
            size='small'
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => _.size(record.otherRecord) > 1,
            }}
            pagination={{
              current: pageState.pageRight,
              total: pageState.totalRight,
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              onChange: (page) => {
                pageState.pageRight = page;
              }
            }}
          />
          {renderFooter('right')}
        </Card>
      </Col>

      <RulesModal
        show={modalState.show}
        onCancel={() => modalState.show = false}
        values={modalState.value}
        avgss={avgsValue}
      />

      <BreakModal
        show={modalBreakState.show}
        onCancel={() => modalBreakState.show = false}
        datas={modalBreakState.datas}
        title={modalBreakState.title}
      />
    </Row>
  )
}

export default CompareTable;