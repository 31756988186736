import React, { useState } from 'react';
import { Row, Col, Space, Cascader, Typography, Button, message, Divider, DatePicker, Spin } from 'antd';
import { CheckCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { saveStorage, getStorage, isValidArray } from '@/utils/utils2';
import { isEmptyStringV2, autoCol } from '@/utils/utils';
import { NewRangePicker } from '@/view/common/widgets';
import { useReactive, useUpdateEffect } from 'ahooks';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const STORAGE_KEY = 'cmp';
const REVERT_SWITCH = { 'left': 'right', 'right': 'left' }; // 切换选择，切换相反的key
//split存储的账户string，后两位是productid和accountid，并进行parseInt处理
const parseLastTowItem = (string) => {
  const splitArray = _.split(string, '_');
  let final = [];
  if (isValidArray(splitArray) && _.size(splitArray) === 3) {
    final = [splitArray[0], parseInt(splitArray[1]), parseInt(splitArray[2])];
  }
  return final;
};
/**
 * 设置栏，选择对比账户，时间周期，已经单日日期操作
 */
const SettingBars = ({
  type = '',
  getPms = {}, // 完成获取\配置参数
  accounts = {},
  defaultDay = [],// 日期范围format数组，常量
  todayFormat = '', // 日期format字符串，常量
  requestKeyObj = {}, // 请求字段对象，常量
  typeFormat = {}, // baseType的formate对象，常量
  validDate = {},
  upCount = 0,
  upCount2 = 0,
  initCount = 0,
  accLoading = false,

  onFinish,
  onBaseChange,
  onSingleDateChange,
}) => {
  const [baseType, setBaseType] = useState('left');
  const pmsState = useReactive({
    left: [], right: [],
    date: defaultDay, sDate: todayFormat
  });
  // 初始加载获取账户完成后,upcount更新，读取storage赋值对比的字段，并触发onFinish自动获取数据；
  useUpdateEffect(() => {
    const accountString = getStorage(STORAGE_KEY);
    if (!isEmptyStringV2(accountString)) {
      let splitAccount = _.split(accountString, '#'); // # 拆分账户1和2
      pmsState.left = parseLastTowItem(splitAccount[0]); // 解析后可直接让Cascader使用
      pmsState.right = parseLastTowItem(splitAccount[1]);
      setBaseType(type);
      handleFinish('init'); // 此处传入type,避免缓存type与初始值不同，upcount首次更新时拿到上层type，去重新更新数据;
    }
  }, [upCount]);
  // 执行缓存时直接更新选项的value
  useUpdateEffect(() => {
    // console.log(getPms)
    pmsState.left = _.get(getPms, 'left_select', []);
    pmsState.right = _.get(getPms, 'right_select', []);
  }, [initCount]);
  // 更新时picker的时间不等于当前selecteDate的时间，更新pikcer时间
  useUpdateEffect(() => {
    if (_.get(getPms, 'selectDate') !== pmsState.sDate) {
      pmsState.sDate = _.get(getPms, 'selectDate');
    };
  }, [upCount2]);

  // 该params包含获取及页面需要的全局参数，该组件是唯一修改该参数的地方
  function handleFinish(ftype = '') {
    if (_.size(pmsState.left) !== 3 || _.size(pmsState.right) !== 3 || _.size(pmsState.date) !== 2) {
      return
    }
    const leftId = _.last(pmsState.left); const rightId = _.last(pmsState.right);
    const leftPlatform = _.head(pmsState.left); const rightPlatform = _.head(pmsState.right);
    let finishParams = {
      'beginTime': pmsState.date[0],
      'endTime': pmsState.date[1],
      'init': ftype === 'init' ? true : false,
      'selectDate': pmsState.sDate,
      'type': type, // 对比基准； 区分left/right数据对比项
      'baseType': typeFormat[leftPlatform], // 账户基准
      'rightType': typeFormat[rightPlatform], // 格式化后，bt_rest 和bt 都等于bt，可执行同平台逻辑
      'leftType_org': leftPlatform, // 反采账户需要调用fc接口，增加标识
      'rightType_org': rightPlatform,
      'accLeftName': accounts?.accoutMap?.get(leftId) ?? '', // 赋值左右测的账户名称
      'accRightName': accounts?.accoutMap?.get(rightId) ?? '',
      'left_select': pmsState.left, //当前 Cascader 选中的value
      'right_select': pmsState.right,
    }
    // 根据选择平台赋值对应参数keyname
    if (finishParams.baseType === finishParams.rightType) { // 对比相同平台，用特殊字段表示
      _.set(finishParams, 'lid', leftId); // 左侧id
      _.set(finishParams, 'rid', rightId); // 右侧id
      _.set(finishParams, 'plate_form', requestKeyObj[leftPlatform]);
    } else {
      _.set(finishParams, requestKeyObj[leftPlatform], leftId);
      _.set(finishParams, requestKeyObj[rightPlatform], rightId);
    }
    // 初始获取默认select选项为结束日期
    if (ftype === 'init') {
      finishParams.selectDate = finishParams.endTime;
      pmsState.sDate = finishParams.endTime;
    }
    if (ftype === 'switch') {
      const nextType = REVERT_SWITCH[type];
      setBaseType(nextType);
      finishParams.type = nextType
      finishParams.baseType = nextType === 'left' ? leftPlatform : rightPlatform;
      finishParams.rightType = nextType === 'left' ? rightPlatform : leftPlatform;
      onBaseChange(finishParams)
    } else {
      onFinish(finishParams);
    }
  }

  const disabledDate = (current) => {
    const tooLate = current.diff(moment(defaultDay[1]), 'days') > 0;
    return !!tooLate;
  };
  const renderPickerDate = pmsState.sDate !== '' ? moment(pmsState.sDate) : null;
  const cascaderProps = { options: _.get(accounts, 'platform', []), style: { width: 185 } };
  const disDefaultBtn = _.size(pmsState.left) !== 3 || _.size(pmsState.right) !== 3 ? true : false;
  const valid_date_array = validDate[type] || [];
  return <>
    {/* <Divider style={{ margin: '12px 0' }} /> */}
    <Row align='middle' style={{ marginTop: 10 }}>
      <Col {...autoCol([12])}>
        <Space>
          <Text type='secondary'>对比账户:</Text>
          {accLoading ? <Spin spinning size='small' /> : <div style={{ width: 12, height: 15 }}></div>}
          <Cascader
            {...cascaderProps}
            value={pmsState.left}
            placeholder='账户1'
            onChange={(v) => pmsState.left = v}
          />
          <Cascader
            {...cascaderProps}
            value={pmsState.right}
            placeholder='账户2'
            onChange={(v) => pmsState.right = v}
          />
          <Button size='small' type='text' disabled={disDefaultBtn} onClick={() => {
            //localstorage存储默认值，下次进入默认获取  ; 存储格式 simx_01_01#tamp_01_01
            saveStorage(STORAGE_KEY, `${_.join(pmsState.left, '_')}#${_.join(pmsState.right, '_')}`);
            message.success('设置成功！')
          }}>设为默认</Button>
        </Space>
      </Col>
      <Col {...autoCol([12])} style={{ textAlign: 'right' }}>
        <Space>
          <NewRangePicker
            dates={pmsState.date}
            onSelect={(dateStrings) => pmsState.date = dateStrings}
          />
          <Button icon={<CheckCircleOutlined />} type='primary' onClick={() => handleFinish()} >
            确定
          </Button>
        </Space>
      </Col>

      <Divider style={{ margin: '12px 0' }} />
    </Row>

    <Row align='middle' style={{ marginBottom: 10 }}>
      <Col {...autoCol([8])}>
        <Space>
          <Text type='secondary'>当前选择:</Text>
          <DatePicker
            format={'YYYY-MM-DD'}
            size='small'
            value={renderPickerDate}
            onChange={(date, dateStrings) => {
              pmsState.sDate = dateStrings;
              onSingleDateChange(dateStrings, baseType);
            }}
            disabledDate={disabledDate}
            dateRender={(current) => { // 有数据的日期画圈显示
              const style = {};
              if (valid_date_array.indexOf(current.format('YYYY-MM-DD')) !== -1) {
                style.border = '1px solid #1890ff';
                style.borderRadius = '50%';
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />
          <Button icon={<SwapOutlined />} size='small' onClick={() => handleFinish('switch')}>
            切换
          </Button>
        </Space>
      </Col>
      <Col {...autoCol([16])} style={{ textAlign: 'right' }}>

      </Col>
    </Row>
  </>
};

export default SettingBars;