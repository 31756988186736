import React, { useState } from 'react';
import { Button, Table, Card, Tag, Space, Row, Col, message, Typography, Spin, Badge, Modal } from 'antd';
import { LineChartOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import { showPositionReturn, getAllPosition } from '@/api/workbench'; // exportPositionReturn
import { batchSellProportion, showList, showCurrentIndexSpeed } from '@/api/stock';
import { useMount, useUpdateEffect, useReactive, useUnmount } from 'ahooks';
import { autoCol, colWidth, singleColumns, validateResponse } from '@/utils/utils';
import { RateText, isValidArray, isValidString, createDatasTimeKey } from '@/utils/utils2';
import { cryptoDatas } from '@/utils/cryptoUtils';
import { KlineDrawer } from '@/view/common/widgets';
import { handlePositions } from '../list_utils';
import PosBars from '../SubComps/PosBars';
import PlateModal from './PlateModal';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { confirm } = Modal;
const DAY_FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(DAY_FORMAT);
const TO_YES_PRE_DAY = [TODAY, moment(TODAY).subtract(1, 'd').format(DAY_FORMAT), moment(TODAY).subtract(2, 'd').format(DAY_FORMAT)]; // 今、昨、前
/**
 *  持仓列表组件，产品&子账户通用模块
 */
const PositionTable = ({
  pageKey = '',
  role = '',
  productId = 0,
  subAccountInfo = {},
  validDateRange = [],
  rises = [],
  rlist = [], // 反采的持仓数据
  mkMap = new Map(),
  newProps = {},
  upCount = 0,
  isReverse = false,
  isTrading = false,
}) => {
  const mobxStore = useLocalObservable(() => store);
  const [fundList, setfundList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [exLoading, setExLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [segVal, setSegVal] = useState('all');
  const [allPlate, setAllPlate] = useState([]);
  const [posMk, setPosMk] = useState(new Map());
  const modalState = useReactive({ show: false, count: 1, dshow: { show: false, record: {} } });
  const isProductPage = pageKey === 'products' ? true : false;
  const isHosting = pageKey === 'hosting' ? true : false; // 反采
  let isUnmont = false;

  const fundColumns = [
    {
      ...singleColumns(['序号', 'order', 50]), //0
      render: (text, record, index) => index + 1,
    },
    {
      ...singleColumns(['股票', 'stock', 200]), //1
      render: (text, record) =>
        <div>
          <Badge dot={_.includes(rises, _.get(record, 'stockName'))}>
            <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>
          </Badge>
          <LineChartOutlined style={{ fontSize: 12, color: '#003e85', marginLeft: 4, cursor: 'pointer' }}
            onClick={() => {
              modalState.dshow = { show: true, record: record };
            }}
          />
        </div>,
    },
    {
      ...singleColumns(['板块', 'plate', 195]), //2
      sorter: (a, b) => { // 涨速排序优先，相同时用板块进行排序；
        return a.plateSpeed === b.plateSpeed ? a.plateKey - b.plateKey : a.plateSpeed - b.plateSpeed;
      },
      render: (text, record) => <Space size='small'>
        <Text keyboard>{_.get(record, 'one_plate')}</Text>
        <Text type='secondary'>{posMk.get(_.get(record, 'one_plate_code'))}%</Text>
        {isTrading && <RateText rate={_.get(record, 'plateSpeed')} size='normal' />}
      </Space>
    },
    {
      ...singleColumns(['总市值', 'totalMarketValue', 110, 'left']), //3
      sorter: (a, b) => a.totalMarketValue - b.totalMarketValue,
    },
    { //4
      ...singleColumns(['今日涨跌幅', 'isHighLimit', 115, 'left', ['descend', 'pctChg']]),
      render: (text, record) => {
        const pctVal = _.get(record, 'pctChg', 0);
        return <Space>
          <RateText rate={pctVal} />
          {text && role !== 'visitor' ? <Tag color={'red'}>{'涨停'}</Tag> : <></>}
        </Space>
      }
    },
    {
      ...singleColumns(['涨速', 'speedIncrease', 95, 'left']),//5
      sorter: (a, b) => a.speedIncrease - b.speedIncrease,
      render: (text) => <RateText rate={text} size='normal' />
    },
    singleColumns(['总收益', 'totalProfit', 110], 'updown'),//6
    singleColumns(['总收益率', 'totalRevenue', 100, 'left', ['descend', 'totalRevenue'], 'rate'], 'updown'),//7
    singleColumns(['股票数量', 'stockAmount', 95]),//8
    singleColumns(['可交易数量', 'availableStockAmount', 110]),//9
    singleColumns(['成本价', 'costPrice', 92]),//10
    {
      ...singleColumns(['买入时间', 'formatDate', 138, 'left', ['descend', 'orderKey']]),//11
      fixed: 'right',
    },
    {
      ...singleColumns(['操作', 'option', 200, 'center']),//12
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Button type="primary" danger onClick={() => goTrading(record, '1')}>买入</Button>
          <Button type="primary" onClick={() => goTrading(record, '2')}>卖出</Button>
        </Space>
      )
    },
    {
      ...singleColumns(['持仓比', 'totalMarketRate', 80]), // 13
      sorter: (a, b) => a.totalMarketRate - b.totalMarketRate,
      render: (text) => _.round(text, 2) + '%'
    },
  ];
  const pro_col = [0, 1, 2, 13, 3, 4, 5, 7, 8, 9, 10, 11];
  const acc_col = _.concat([0, 1, 2, 13, 3, 4, 6, 7, 8, 9, 10, 11], role === 'trader' ? [12] : []);
  const newColIndex = (isProductPage || isHosting) ? pro_col : acc_col;
  let newCol = newColIndex.map(idx => fundColumns[idx]);
  if (!isTrading) { // 盘后不显示涨速
    newCol = _.without(newColIndex, 5).map(idx => fundColumns[idx]);
  };

  useMount(() => {
    // 反采无需获取数据，从上层拿到，加载&重载更新filterList即可
    if (isHosting) {
      setFilterList(rlist);
      setfundList(rlist);
      setPosMk(mkMap);
    } else {
      getPositionDatas();
    }
  });

  useUnmount(() => {
    isUnmont = true;
  });
  // 上层更新重载
  useUpdateEffect(() => {
    if (isHosting) {
      setFilterList(rlist);
      setfundList(rlist);
      setPosMk(mkMap);
    } else {
      getPositionDatas();
    }
  }, [upCount]);

  // 获取持仓数据
  async function getPositionDatas() {
    setLoading(true);
    const res = isProductPage
      ? await getAllPosition({ productId }, isReverse ? 'FC' : 'NOR')
      : await showPositionReturn({ 'subAccountId': subAccountInfo.subAccountId }, isReverse ? 'FC' : 'NOR');
    const res2 = isTrading ? await showCurrentIndexSpeed() : {};
    // const res2 = await showCurrentIndexSpeed()
    if (validateResponse(res, isUnmont)) {
      const getPosData = _.get(res, 'data', [])
      const getSpeed = _.get(res2, 'data', {});
      const newPosition = handlePositions(getPosData, getSpeed);
      setfundList(_.get(newPosition, 'newData'));
      setFilterList(_.get(newPosition, 'newData'));
      mobxStore.savePositionList(_.get(newPosition, 'newData'));
      setSegVal('all');
      setAllPlate(_.get(newPosition, 'optionsPlate'));
      setPosMk(newPosition.marketMap);
    }
    setLoading(false);
  }
  // 批量卖出
  async function _batchSell(vobj) {
    const getId = _.get(subAccountInfo, 'subAccountId', null);
    if (getId) {
      let params = {
        subAccountId: getId,
        sellList: vobj ? _.get(vobj, 'newList') : selectRowKey,
        proportion: vobj ? _.get(vobj, 'proportion') : 1,
      }
      // console.log(params)
      const res = await batchSellProportion(cryptoDatas(params), isReverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        message.success(_.get(res, 'message', '卖出成功！'))
      } else {
        message.error(_.get(res, 'message', '卖出失败！'))
      }
      setSelectRowKey([]);
      getPositionDatas();
    }
  }
  // 导出所有持仓：调用交易记录接口，获取当天的交易记录，showList接口
  async function exportExcelToday() {
    const res = await showList({
      subAccountId: _.get(subAccountInfo, 'subAccountId'),
      beginTime: TODAY,
      endTime: moment().add(1, 'd').format(DAY_FORMAT),
      state: true
    }, isReverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') {
      const getList = createDatasTimeKey(_.get(res, 'data.result', []), 'commissionTime', 'fms');
      exportExcel(
        'today',
        _.filter(getList, o => parseFloat(_.get(o, 'quantity', 0)) > 0 && _.get(o, 'side', '') === 'BUY')
      )
    }
  }
  // 导出全部持仓
  function exportExcel(type, list) {
    let option = {}; let datas = [];

    const todayDescList = type === 'today' ? _.uniqBy(list, 'stockCode') : _.orderBy(fundList, ['orderKey'], ['desc']);
    if (_.size(todayDescList) === 0) {
      message.info('当前无持仓！');
      setExLoading(false);
      return
    }
    todayDescList.map(n => {
      datas.push({ '代码': _.get(n, 'stockCode', ''), '名称': _.get(n, 'stockName', ''), '时间': _.get(n, 'formatDate', '') })
    });
    // console.log('todayDescList', todayDescList)
    const fileNameObj = { 'today': `${TODAY} TAMP 持仓`, 'all': `${TODAY} TAMP 全部持仓` }
    option.fileName = fileNameObj[type] || 'file';
    option.datas = [
      {
        sheetData: datas,
        sheetName: 'sheet',
        sheetFilter: ['代码', '名称', '时间'],
        sheetHeader: ['代码', '名称', '时间'],
      }
    ]
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
    setExLoading(false);
  }
  // 跳转交易员
  function goTrading(record, akey) {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    newProps?.history?.push({
      pathname: '/home/Trading',
      state: { //【tips】 直接使用Reactive的数据无法跳转，需要cloneDeep处理才可以
        'subAccountInfo': _.cloneDeep(subAccountInfo),
        'active': akey,
        'record': record
      }
    });
  }
  // 搜索股票
  const onSearchStock = (value) => {
    let filters = isValidString(value)
      ? _.filter(fundList, o => _.includes(o.stockName, value) || _.includes(o.stockCode, value))
      : [];
    if (_.size(filters) > 0) {
      setFilterList(filters);
    }
    if (!isValidString(value)) {
      setFilterList(fundList);
    }
  }
  // 板块卖出；可直接卖出，也可以添加至多选
  const handlePlateSell = ({ newList, type, proportion }) => {
    if (type === 'add') {
      setSelectRowKey(newList);
    } else {
      _batchSell({ newList, proportion });
    }
    modalState.show = false;
  }
  // 筛选不同持仓数据
  function segChange(v) {
    setSegVal(v);
    if (v === 'all') {
      setFilterList(fundList);
    } else if (v === 'else') {
      setFilterList(_.filter(fundList, o => !_.includes(new_pre_days, o.formatDate_day)));
    } else {
      setFilterList(_.filter(fundList, o => o.formatDate_day === v));
    }
  }

  const rowSelection = {
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys) => {
      setSelectRowKey(selectedRowKeys)
    },
  };

  // 用效日期常量
  const totalMkRate = isValidArray(filterList) ? _.chain(filterList).map(n => n.totalMarketRate).sum().round(2).value() : '0';
  const new_pre_days = isValidArray(validDateRange) && _.size(validDateRange) > 2 ? _.reverse(_.takeRight(validDateRange, 3)) : TO_YES_PRE_DAY;
  const barsProps = {
    'roles': role, 'preDays': new_pre_days, 'isProducts': isProductPage,
    'noSelect': _.size(selectRowKey) === 0, 'noPlate': _.size(allPlate) === 0, 'noExport': _.size(fundList) === 0,
    'sval': segVal, 'eloading': exLoading
  };
  return (
    <Card bordered={true} size='small'>
      <Row justify='space-between' className='sellHeader' align='middle'>
        <Col {...autoCol([6])}>
          <Space>
            <Text style={{ fontSize: 15 }} strong>当前持仓</Text>
            <Spin spinning={loading} size='small' />
          </Space>
        </Col>
        <Col {...autoCol(isHosting ? [0] : [14])} style={{ textAlign: 'right' }}>
          <PosBars
            {...barsProps}
            openPlateModal={() => {
              modalState.show = true;
              modalState.count++;
            }}
            onSearch={(v) => onSearchStock(v)}
            onExport={(e) => {
              setExLoading(true);
              if (e.key === 'today') {
                exportExcelToday();
              } else if (e.key === 'all') {
                exportExcel('all');
              }
            }}
            onSync={getPositionDatas}
            onSvalChange={(v) => segChange(v)}
            onBatchSell={() => {
              confirm({
                title: '是否批量卖出？',
                icon: <ExclamationCircleOutlined />,
                onOk: () => _batchSell(),
                onCancel() { console.log('Cancel') },
              });
            }}
          />
        </Col>
      </Row>

      <Table
        rowKey="stockCode"
        dataSource={filterList}
        columns={newCol}
        rowSelection={role === 'trader' ? rowSelection : null}
        scroll={{ x: colWidth(newCol), y: 620 }}
        bordered
        size='small'
        pagination={false}
        footer={() => <Row>
          <Col span={12}><Text strong>仓位比例：{totalMkRate}%</Text></Col>
        </Row>}
      />

      {role === 'trader' ? <PlateModal
        show={modalState.show}
        upcount={modalState.count}
        plates={allPlate}
        lists={filterList}
        rowChecks={selectRowKey}
        onCancel={() => modalState.show = false}
        onFinish={handlePlateSell}
      /> : <></>}

      <KlineDrawer
        pageKey='position'
        show={modalState.dshow.show}
        value={modalState.dshow.record}
        lists={fundList}
        newProps={newProps}
        onClose={() => modalState.dshow = { show: false, record: {} }}
      />

    </Card>
  )
}

export default PositionTable;