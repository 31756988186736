import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spin, Select } from 'antd';
import { showProfitAndLoss, showProductProfitAndLoss } from '@/api/workbench';
import { showSubAccountHistory, showProductHistoryInterval } from '@/api/fc';
import { useMount } from 'ahooks';
import { validateResponse } from '@/utils/utils';
import { isValidArray, isValidNumber } from '@/utils/utils2';
import { NewRangePicker } from '@/view/common/widgets';
import { CONTRIBUTION_OPTIONS } from '../list_utils';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;
const TODAY = moment().format('YYYY-MM-DD');
const GET_TYPE = { 'account': 'subAccountId', 'product': 'productId' };
const GET_TYPE_2 = { 'account': 'subAccountId', 'product': 'productId' };
function roundData(array) {
  return isValidArray(array) ? array.map(n => _.round(parseFloat(n), 4)) : []
}
/**
 *  子账户盈亏图表；tamp实盘功能具有一定差异化；
 *  240409拆分为两个图表
 */
export default function ContributionCharts(props) {
  const [rangeVal, setRangeVal] = useState([TODAY, TODAY]);
  const [profitDatas, setProfitDatas] = useState({});
  const [update, setUpdate] = useState(0);
  const [updateCount2, setUpdateCount2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [topSymbol, setTopSymbol] = useState([]);
  const [symOption, setSymOption] = useState([]);
  const [rates, setRates] = useState([]);
  const [option, setoption] = useState(CONTRIBUTION_OPTIONS);
  const [pms, setPms] = useState({});

  useMount(() => {
    _showProfitAndLoss();
  });

  async function _showProfitAndLoss(date) {
    setLoading(true);
    const page_key = _.get(props, 'pageKey', '');
    const is_reverse = _.get(props, 'isReverse', false);
    const getId = _.get(props, GET_TYPE[page_key], 0);
    let params = {
      [GET_TYPE[page_key]]: getId,
      startTime: date ? date[0] : rangeVal[0],
      endTime: date ? date[1] : rangeVal[1],
      type: 'byDay'
    };
    let paramsRate = {
      [GET_TYPE_2[page_key]]: getId,
      'beginTime': params.startTime,
      'endTime': params.endTime,
    };
    setPms(params);
    let res = {}; let res2 = {};
    if (page_key === 'account') {
      res = await showProfitAndLoss(params, is_reverse ? 'FC' : 'NOR');
      res2 = await showSubAccountHistory(paramsRate, is_reverse ? 'FC' : 'NOR');
    } else {
      res = await showProductProfitAndLoss(params, is_reverse ? 'FC' : 'NOR');
      res2 = await showProductHistoryInterval(paramsRate, is_reverse ? 'FC' : 'NOR');
    };
    if (validateResponse(res)) {
      setProfitDatas(_.get(res, 'data', {}));
      const getPoints = _.get(res2, 'data.pointList', []);
      setRates(roundData(getPoints));
      setUpdate(update + 1)
    };
    setLoading(false);
  }

  function handleSymbolData(type) {
    const page_key = _.get(props, 'pageKey', '');
    const dataObj = _.get(profitDatas, 'profitPint', {});
    const nameObj = _.get(profitDatas, 'stockNames', {})
    let seriesValue = [];
    Object.keys(dataObj).map(keyname => {
      let stockData = _.get(dataObj, keyname, []);
      if (keyname in nameObj) { // 修复如stockNames里没有名称，name会等于undefined
        let itm = {
          name: _.get(nameObj, keyname),
          type: 'line',
          symbol: 'none',
          data: stockData.map(n => _.round(parseFloat(n) * 100, 4)),
        };
        itm.lastPoint = _.last(itm.data);
        seriesValue.push(itm);
      }
    })
    const rateLine = createBaseLine('#923e93', { 'name': '收益率', 'data': rates });
    const getLimitRate = _.get(profitDatas, 'limitRate', []);
    const isSameDay = _.get(pms, 'startTime') === _.get(pms, 'endTime') ? true : false;
    const limitBuyLine = createBaseLine('#f85a40', {
      'name': '买入涨停',
      'data': isValidArray(getLimitRate) ? getLimitRate.map((n, i) => {
        const isValid = isValidNumber(n);
        let final = 0;
        if (i > 0) { // 每个点是前几个点的累加
          final = _.sum(_.slice(getLimitRate, 0, i + 1));
        } else {
          final = n;
        }
        return isValid ? _.round(final * (isSameDay ? 1 : 100), 4) : '-';
      }) : []
    });
    let sliceFinal = [];
    const optionDatas = _.orderBy(seriesValue, ['lastPoint'], ['desc']);
    if (type === 'init') {
      sliceFinal = _.concat(
        _.slice(optionDatas, 0, 5),
        _.slice(optionDatas,
          _.size(optionDatas) - 5, _.size(optionDatas)
        ).map(s => _.assign(s, { lineStyle: { type: 'dashed', width: 2 } })),
      );
      let tops = sliceFinal.map(n => n.name);
      setTopSymbol(tops);
      setSymOption(optionDatas);
    } else {
      sliceFinal = _.filter(symOption, o => topSymbol.indexOf(o.name) !== -1);
    }
    return _.concat(rateLine, page_key === 'account' ? [] : limitBuyLine, sliceFinal);
  }

  // 个股，初次渲染时加载~
  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('contribution_chart'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });
    /**
     * 选取当天复选账户内，绝对值波动最大的数值（data数组内的数据），选取所有复选账户内波动最大前20个复选账户
     */
    let newOption = _.cloneDeep(option);
    if (_.size(profitDatas) > 0) {
      newOption.xAxis.data = _.get(profitDatas, 'time', []);
      newOption.series = handleSymbolData('init');
    }

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

  }, [update]);
  // tab个股渲染
  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('contribution_chart'));

    let newOption = option;
    if (_.size(profitDatas) > 0) {
      newOption.xAxis.data = _.get(profitDatas, 'time', []);
      newOption.series = handleSymbolData('tab1');
    }

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [updateCount2])

  const onSymbolChange = (value) => {
    setTopSymbol(value);
    setUpdateCount2(updateCount2 + 1)
  };

  const topChildren = _.size(symOption) > 0 ? symOption.map((n, i) => <Option key={n.name + i} value={n.name}>{n.name}</Option>) : null;
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, paddingLeft: 10, paddingRight: 10 };
  return (
    <div style={{ backgroundColor: '#fff', padding: 6 }}>
      <Card bordered={false}>
        <Row justify='space-between' gutter={[16, 16]} style={{ marginBottom: 12 }}>
          <Col span={8}>
            <NewRangePicker dates={rangeVal} onSelect={(dateString) => {
              setRangeVal(dateString);
              _showProfitAndLoss(dateString);
            }} />
          </Col>
        </Row>
        <Row style={controlBarStyle}>
          <Col span={24}>
            <Select
              mode="multiple"
              allowClear
              bordered={false}
              style={{
                width: '100%',
              }}
              value={topSymbol}
              onChange={onSymbolChange}
            >
              {topChildren}
            </Select>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <div
            id="contribution_chart"
            style={{ width: '100%', height: 475 }}
          />
        </Spin>
      </Card>
    </div >
  )
}