import React from 'react';
import { Col, Row, Space } from 'antd';
import { autoCol } from '@/utils/utils';
import { isValidString } from '@/utils/utils2';
import StatisticHeader from '@/utils/StatisticHeader';
import _ from 'lodash';
/**
 *  产品资产信息，后端返回为 String 类型，所以包括 % 也都是后端返回
 * 240305 增加页面的特殊显示
 */
const HOST_SUB_FIRST = [
  ['账户总资产', 'totalAssets'],
  ['当前市值', 'positionsAssets'],
  ['可用资金', 'totalBalance'],
  ['冻结资金', 'totalFrozenBalance'],
];
const AssetsHeader = ({ assets = {}, typeName = '产品' }) => {
  const isProduct = typeName === '产品' ? true : false;

  const HostSubFirst = <Row gutter={[16, 8]}>
    {HOST_SUB_FIRST.map(itm => <Col span={12} key={itm[1]}>
      <StatisticHeader title={itm[0]} value={isValidString(_.get(assets, itm[1])) ? _.get(assets, itm[1]) : '0'} />
    </Col>)}
  </Row>
  const NetComp = <StatisticHeader title={`净值`} value={_.round(_.get(assets, 'netValue'), 4)} isColor />;

  return <Row gutter={[8, 8]}>
    <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        {isProduct ? <Space direction='vertical'>
          <StatisticHeader title={`${typeName}总资产`} value={_.get(assets, 'totalAssets', '')} isColor />
          {NetComp}
        </Space> : HostSubFirst}
      </div>
    </Col>
    <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        <Space direction='vertical'>
          <StatisticHeader title={'总收益'} value={_.get(assets, 'totalRevenue', '')} isColor />
          {
            isProduct
              ? <StatisticHeader title={'总收益率'} value={_.get(assets, 'totalRevenueRate', '')} isColor isPrefix />
              : NetComp
          }
        </Space>
      </div>
    </Col>
    <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        <Space direction='vertical'>
          <StatisticHeader title={'日收益'} value={_.get(assets, 'dailyRevenue', '')} isColor />
          <StatisticHeader title={'日收益率'} value={_.get(assets, 'dailyRevenueRate', '')} isColor isPrefix />
        </Space>
      </div>
    </Col>
  </Row>
}

export default AssetsHeader;