import React, { useState } from 'react';
import { Form, Input, Select, Modal, message, InputNumber, Radio } from 'antd';
import { isRepeatTradeUnit, checkTradeAccount } from '@/api/details';
import { useUpdateEffect } from 'ahooks';
import { isValidArray } from '@/utils/utils2';
import _ from 'lodash';

const { Option } = Select;

const BindModal = ({ bindType, brokType, brokDatas, showCount, secAccout, onSecAccChange, onPhoneChange }) => {
  const [visible, setvisible] = useState(false);
  const [modalForm] = Form.useForm();

  useUpdateEffect(() => {
    if (!visible) {
      setvisible(true);
    }
  }, [showCount])

  // 确认线上绑定或线下绑定
  async function modalOnFinish() {
    const values = await modalForm.validateFields();
    const centralize = values.centralizedLogin;
    //console.log(values)
    if (bindType === 1) {          // 线上绑定
      let brokerage = JSON.parse(values.brokerage);
      let params = {
        accountType: brokType
      };
      if (brokType === '1') {
        params = {
          ...params,
          brokerageId: String(brokerage.id),
          securityType: brokerage.securityType,
          customerNumber: values.customerNumber,
          normalTradeUnit: String(values.normalTradeUnit),
          creditTradeUnit: values.creditTradeUnit,
          password: values.password,
          tradeAccount: String(values.tradeAccount),
          ip: values.ip,
          mac: values.mac,
          centralizedLogin: centralize,
        }
        // 国金新增提交字段
        if (_.get(brokerage, 'securityType') === 'guoj_security') {
          params = {
            accountType: brokType,
            brokerageId: String(brokerage.id),
            securityType: brokerage.securityType,
            customerNumber: values.customerNumber,
            password: values.password,
            tradeAccount: String(values.tradeAccount),
            appId: _.get(values, 'appId', ''),
            authCode: _.get(values, 'authCode', ''),
            loginUrl: _.get(values, 'loginUrl', ''),
            centralizedLogin: centralize,
          }
        }
        // 湘财新增提交字段
        if (_.get(brokerage, 'securityType') === 'xczq_oes_security') {
          params = {
            accountType: brokType,
            brokerageId: String(brokerage.id),
            securityType: brokerage.securityType,
            customerNumber: values.customerNumber,
            tradeAccount: String(values.tradeAccount),
            password: values.password,
            centralizedLogin: centralize,
            fundAccountNumber: values.fundAccountNumber,
          }
        }
        //console.log(params);
        const data = await checkTradeAccount(params, centralize === '2' ? 'FC' : 'NOR');
        if (data.code === '200') {
          // 校验成功 退出弹窗 保存数据
          message.success('校验成功');
          let arr = JSON.parse(JSON.stringify(secAccout));
          arr.push(params);
          onSecAccChange(arr);
          handleCancel();
        } else {
          // 如果校验失败 
          message.error(data.message);
        }
      } else if (brokType === '2') {
        params = {
          ...params,
          brokerageId: String(brokerage.id),
          securityType: brokerage.securityType,
          customerNumber: values.customerNumber,
          password: values.password,
          tradeAccount: String(values.tradeAccount),
          appId: _.get(values, 'appId', ''),
          authCode: _.get(values, 'authCode', ''),
          brokerId: _.get(values, 'brokerId', ''),
          loginUrl: _.get(values, 'loginUrl', ''),
          ip: values.ip,
          mac: values.mac,
          centralizedLogin: values.centralizedLogin,
        }

        let arr = JSON.parse(JSON.stringify(secAccout));
        arr.push(params);
        onSecAccChange(arr);
        handleCancel();
      }

    } else if (bindType === 2) {    // 线下绑定
      onPhoneChange(values.phone);
      handleCancel();
      Modal.confirm({
        title: '信息已提交，请等待工作人员和您联系',
        content: '',
        okText: '确认',
        cancelText: '取消',
      });
    }
  }

  function handleCancel() {
    setvisible(false);
    modalForm.resetFields();
  }
  const BROK_ITEM = [
    { name: 'loginUrl', txt: '登录地址' },
    { name: 'appId', txt: 'appId' },
    { name: 'authCode', txt: '认证码' },
    { name: 'brokerId', txt: 'brokerId' },
  ];
  const ACC_ITEM = [
    { name: 'tradeAccount', txt: '交易账户' },
    { name: 'password', txt: '交易密码' },
  ];
  // console.log('brokDatas', brokDatas)
  return (
    <Modal
      title={`线${bindType === 1 ? '上' : '下'}添加`}
      visible={visible}
      onCancel={handleCancel}
      onOk={modalOnFinish}
      maskClosable={false}
      okText='添加'
    >
      <Form form={modalForm} size='small' initialValues={{}}>
        {
          bindType === 1 ?
            <>
              <Form.Item name="brokerage" rules={[{ required: true, message: '请选择股票/期货账户', },]} >
                <Select placeholder={`请选择${brokType === '1' ? '股票' : '期货'}账户`} >
                  {isValidArray(brokDatas) && brokDatas.map(item => (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label=""
                name='customerNumber'
                rules={[{ required: true, message: '请输入股票/期货账户名称', },]}
              >
                <Input placeholder={`请输入${brokType === '1' ? '股票' : '期货'}账户名称`} />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.brokerage !== currentValues.brokerage}
              >
                {({ getFieldValue }) => {
                  const brokVal = getFieldValue('brokerage');
                  const parseBrok = brokVal ? JSON.parse(brokVal) : {};
                  return !_.includes(['xczq_oes_security', 'guoj_security'], _.get(parseBrok, 'securityType')) ? (
                    <>
                      <Form.Item
                        label=""
                        name='normalTradeUnit'
                        validateTrigger='onBlur'
                        rules={[{
                          required: true,
                          validator: async (_, value) => {
                            if (value) {
                              const data = await isRepeatTradeUnit({ tradeUnit: value });
                              if (data.code === '200') {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(data.message));
                            } else {
                              return Promise.reject(new Error('请输入普通交易单元'));
                            }
                          }
                        },]}
                      >
                        <InputNumber placeholder="请输入普通交易单元" style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item
                        label=""
                        name='creditTradeUnit'
                      // rules={[{ required: true, message: '请输入信用交易单元', },]}
                      >
                        <InputNumber placeholder="请输入信用交易单元" style={{ width: '100%' }} />
                      </Form.Item>
                    </>
                  ) : null
                }}
              </Form.Item>
              {ACC_ITEM.map(b => <Form.Item
                label=""
                key={b.name}
                name={b.name}
                rules={[{ required: true, message: `请输入${b.txt}`, },]}
              >
                <Input placeholder={b.txt} style={{ width: '100%' }} />
              </Form.Item>)}
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.brokerage !== currentValues.brokerage}
              >
                {({ getFieldValue }) => {
                  const brokVal = getFieldValue('brokerage');
                  const parseBrok = brokVal ? JSON.parse(brokVal) : {};
                  return _.get(parseBrok, 'securityType') === 'xczq_oes_security' ? (
                    <Form.Item label=""
                      name={'fundAccountNumber'}
                      rules={[{ required: true, message: `请输入资金账户`, },]}
                    >
                      <Input placeholder={'请输入资金账户'} style={{ width: '100%' }} />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.brokerage !== currentValues.brokerage}
              >
                {({ getFieldValue }) => {
                  const brokVal = getFieldValue('brokerage');
                  const parseBrok = brokVal ? JSON.parse(brokVal) : {};
                  return _.get(parseBrok, 'securityType') === 'guoj_security' || _.get(parseBrok, 'brokType') === 2 ? (
                    <>
                      {BROK_ITEM.map(b => <Form.Item
                        label=""
                        key={b.name}
                        name={b.name}
                        rules={[{ required: true, message: `请输入${b.txt}`, },]}
                      >
                        <Input placeholder={b.txt} style={{ width: '100%' }} />
                      </Form.Item>)}
                    </>
                  ) : null
                }}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.brokerage !== currentValues.brokerage}
              >
                {({ getFieldValue }) => {
                  const brokVal = getFieldValue('brokerage');
                  const parseBrok = brokVal ? JSON.parse(brokVal) : {};
                  // 特殊产品类型处理 【国金和湘财 不需要ip和mac】
                  return _.includes(['xczq_oes_security', 'guoj_security'], _.get(parseBrok, 'securityType')) ? null : (
                    <>
                      <Form.Item
                        label=""
                        name="ip"
                        validateTrigger='onBlur'
                        rules={[{ required: true }, {
                          validator: async (_, value) => {
                            if (value) {
                              if ((/^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))).){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/.test(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('请输入正确的IP地址'));
                            }
                          }
                        }]}
                      >
                        <Input placeholder="请输入IP地址" />
                      </Form.Item>
                      <Form.Item
                        label=""
                        name="mac"
                        validateTrigger='onBlur'
                        rules={[{ required: true }, {
                          validator: async (_, value) => {
                            if (value) {
                              if ((/^([0-9a-fA-F]{2})(([/\s:-][0-9a-fA-F]{2}){5})$/.test(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('请输入正确MAC地址'));
                            }
                          }
                        }]}
                      >
                        <Input placeholder="请输入MAC地址" />
                      </Form.Item>
                    </>
                  )
                }}
              </Form.Item>
              <Form.Item label="" name="centralizedLogin"
                rules={[{ required: true, message: '请选择登录方式' },]}
              >
                <Radio.Group optionType="button" buttonStyle='solid'>
                  <Radio.Button value="1">集中</Radio.Button>
                  <Radio.Button value="2">反采</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
            :
            <>
              <Form.Item
                label=""
                name='phone'
                rules={[{ required: true, message: '请输入您的联系方式' },]}
              >
                <Input placeholder="请输入您的联系方式" />
              </Form.Item>
            </>
        }
      </Form>
    </Modal>
  )
}

export default BindModal;