import React, { useState } from 'react';
import { Modal, Button, Space, Input, Image } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { globalUrl } from '@/utils/global';

const VerifyModal = ({ visible, tokenVal, onCancel, onOk, onRefresh }) => {
  const [inputVal, setInputVal] = useState('');
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      centered
      onOk={() => {
        onOk(inputVal)
      }}
    >
      <Space>
        <Input
          placeholder="图片验证码"
          size="large"
          autoComplete={'off'}
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        />
        <Image
          width={180}
          height={38}
          src={`${globalUrl}/api/getCaptcha?captchaToken=${tokenVal}`}
          preview={false}
        />
        <Button icon={<RedoOutlined />} type='text' onClick={onRefresh} />
      </Space>
    </Modal>
  )
}

export default VerifyModal;