export const colorArray = [
  '#e29e1f',
  "#7EC0EE",
  "#FF9F7F",
  "#FFD700",
  '#5DAC81', //岩竹
  '#336774', // 深蓝
  '#F596AA', // 桃
  '#CA7A2C', // 琥珀
  '#AB3B3A', // 真朱-红
  '#646A58', // 岩叶
  '#6E75A4', // 滕鼠
  '#9E7A7A', // 梅鼠-棕
  '#0fbab7',
  '#ffcc54',
  '#a5d349',
  '#91989F', //白鼠
  '#B5495B', // 莓
  '#554235', // 深褐
  '#376B6D', //御召茶
  '#063f78',
  '#050dd9',
  '#ea8c3a',
  '#f22727',
  '#a7f706',
  '#73f6ae',
  '#99e0f4',
  '#6105d9',
  '#ab0548',
  '#b00404',
  '#684a02',
  '#649700',
  '#e16f91',
  '#056fd9',
  '#e4c686',
  '#8d8c8c',
  '#947A6D', // 胡桃色
  '#69B0AC', //青瓷
  '#BB81BB', // 香芋色
  '#6D2E5B', // 葡萄
  '#B07736', //丁子柒
  '#91AD70', // 柳染
  '#A0674B', //糖茶
  '#867835', //黄海松茶
  '#F9BF45', //玉子-黄
  '#9B90C2', // 香芋2
  '#F6C555', //栀子
  '#96513D', // 小豆-棕偏红
  '#7C73CE', // 紫
  '#5668FF', 
  '#ECF0F2', // 淡灰
  '#3A3F60', // 冷兰
  '#8B7352', // 冷棕
  '#A99786', // 棕
  '#D5A431', 
  '#7E9E91', 
  '#CFCFCF', 
  '#948C77', 
  '#303153', // 墨色
  '#9D9CAC', // 墨色
];
