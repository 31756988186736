
const ERROR_STATUS = {
  'null': '如果为空则为异常',
  'zero': '如果为0为异常',
  'zero2': '如果为0则需要人工计算是否真的为0, 或者大范围股票均为0是异常',
}
// 盘前检查所需的静态字段及显示内容
const CHECK_LIST = [
  { key: 'remark', name: '备注' },
  { key: 'dataPartition', name: '检查数据库新增分区是否正常', status: 'null' },
  { key: 'last5DaysLimitedStocks', name: '前五日一次以上涨停股票列表', status: 'null', desc: '日志：/home/work/logs/craw/get_last_5_days_limited_stocks.log' },
  { key: 'last5DaysStocksUpDownMap', name: '前五日最低到最高map', status: 'null', desc: '日志：/home/work/logs/craw/get_last_5_days_limited_stocks.log' },
  { key: 'stockAlertList', name: '每日股票预警列表', status: 'null', desc: '日志: /home/work/logs/craw/stock_real_alert.logs' },
  { key: 'avgVolume', name: '昨日平均成交量', status: 'zero', desc: '日志：/home/work/logs/craw/calculate_avg.log' },
  { key: 'maxVolumeDaily', name: '昨日峰值成交量', symbol: 'max_volume_daily:symbol', status: 'zero', desc: '日志：/home/work/logs/craw/calculate_avg.log' },
  { key: 'last5DaysArray', name: '前五日成交量参数组', symbol: 'avg_max_volume:symbol', status: 'zero', desc: '日志：/home/work/logs/craw/calculate_avg_volume_last_five_days.log' },
  { key: 'stockDailyPctChg', name: '昨日涨跌幅', symbol: 'stock_daily_pct_chg:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_day_pct_change.log' },
  { key: 'stockLastFiveDaysMaxPctChg', name: '前五日最低到昨收涨跌幅', symbol: 'stock_last_five_days_max_pct_chg:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_five_days_max_pct_chg.log' },
  { key: 'stockLastThreeDaysAmplitude', name: '前三日振幅', symbol: 'stock_last_three_days_amplitude:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_three_days_amplitude.log' },
  { key: 'stockLastTenDaysAmplitude', name: '前十日振幅', symbol: 'stock_last_ten_days_amplitude:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_ten_days_amplitude.log' },
  { key: 'stockLastTwentyDaysAmplitude', name: '前二十日振幅', symbol: 'stock_last_twenty_days_amplitude:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_twenty_days_amplitude.log' },
  { key: 'stockLastDayMinuteAvgVolume', name: '昨日分钟平均成交量', symbol: 'stock_last_day_minute_avg_volume:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_day_minute_avg_volume.log' },
  { key: 'stockLastThreeDayMinuteAvgVolume', name: '前三日分钟平均成交量', symbol: 'stock_last_three_day_minute_avg_volume:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_three_day_minute_avg_volume.log' },
  { key: 'stockLastFiveDayMinuteAvgVolume', name: '前五日分钟平均成交量', symbol: 'stock_last_five_day_minute_avg_volume:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_five_day_minute_avg_volume.log' },
  { key: 'stockLastTenDayMinuteAvgVolume', name: '前十日分钟平均成交量', symbol: 'stock_last_ten_day_minute_avg_volume:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_ten_day_minute_avg_volume.log' },
  { key: 'stockLastTwentyDayMinuteAvgVolume', name: '前二十日分钟平均成交量', symbol: 'stock_last_twenty_day_minute_avg_volume:symbol', status: 'zero2', desc: '日志：/home/work/logs/craw/get_last_twenty_day_minute_avg_volume.log' },
  { key: 'autoTradeBuySubaccountid', name: '实盘复宽1号、复宽2号的盘前初始化任务是否成功——每日9点和16点均会初始化', desc: 'auto_trade_buy:subAccountId:117 是否为0，auto_trade_buy:subAccountId:116 是否为0' },
  { key: 'thStockReport', name: '退市名单检查', desc: 'th_stock_report表的update_time是否最新，stock_alert_list的长度相比较前一日无大幅度增加或者减少，如有大幅增加或减少，重新更新列表或者恢复至前一日' },
  { key: 'shadowBackTest', name: '影子回测产品数据检查', desc: '实盘对应回测产品是否更新至最新交易日' },
  { key: 'aliyunEsCheck', name: '阿里云检查', desc: 'service-two、service-three 是否正常运行，没有宕机、内存占用、磁盘占用，core-one 临时带宽升级是否正常' },
  { key: 'xxlJobCheck', name: '任务调度器正常执行', desc: '复宽1号和复宽2号的autoTradeForLastDayBoardingSell、autoSellMonitor开启' },
  { key: 'tdfCheck', name: 'TDF检查', desc: 'service-two上的tdf-remote-api服务是否正常运行（行情任务）；查看 /home/work/tdf_remote_api/ log / 日期.txt，如未启动 执行/home/work/tdf_remote_api/restart.sh' },
  { key: 'rabbitmqCheck', name: 'rabbitmq', desc: 'http://39.99.152.27:15675/ 查看实盘rabbitmq是否正常运行；如未启动, 启动docker上的《rabbitmq - 2》容器' },
  { key: 'thirdTradeLoginCheck', name: 'TradeX登陆登录任务', desc: '8:50,正常如果不断开连接会自动重连。日志可查看' },
  { key: 'exDividend', name: '开盘前除权除息任务', desc: '9:15,系统日志可以进行查看或者xxljob' },
  { key: 'accountCheck', name: '盘后更新可交易股票数量,昨日资产数据任务', desc: '15:15,15:20;可在系统查看可交易数量' },
];

export {
  CHECK_LIST, ERROR_STATUS
}