import React, { useState, useEffect } from 'react';
import { Table, Button, message, Select, Input, Space, Tag, Modal, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { useMount, useReactive } from 'ahooks';
import ExportJsonExcel from 'js-export-excel';
import { showContrast, updateContrast, deleteContrast } from '@/api/details';
import { singleColumns, colWidth } from '@/utils/utils';
import { isValidString } from '@/utils/utils2';
import _ from 'lodash';
import moment from 'moment';

const FILTER_ARRAY = [{ text: '买入', value: '买入' }, { text: '卖出', value: '卖出' }];
const EDIT_KEY = ['reason', 'remark', 'type'];
const renderSide = (type) => type === '买' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>;
// 差异详情表格
const ReasonTable = ({
  upcount = 0,
  active = '',
  singleDate = '',
  category = {},

  onCopyOne
}) => {
  const [datas, setDatas] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const formState = useReactive({
    remark: '', reason: '', type: '', id: 0,
    filterAcc: []
  });

  useMount(() => {
    _showContrast();
  });

  useEffect(() => {
    if (active === '4') {
      _showContrast();
    }
  }, [upcount]);

  const cols = [
    {
      ...singleColumns(['账户', 'subName', 125]),
      filters: formState.filterAcc,
      onFilter: (value, record) => record.subName.indexOf(value) === 0,
    },
    {
      ...singleColumns(['方向', 'side', 75]),
      filters: FILTER_ARRAY,
      onFilter: (value, record) => record.side.indexOf(value) === 0,
      render: (text) => renderSide(text)
    },
    {
      ...singleColumns(['代码', 'stockCode', 85]),
      sorter: (a, b) => a.stockCode - b.stockCode,
    },
    {
      ...singleColumns(['股票', 'stockName', 85]),
      // sorter: (a, b) => a.stockName - b.stockName,
    },
    {
      ...singleColumns(['账户类型', 'accountType', 105]),
      filters: _.get(category, 'accountType', []).map(r => { return { 'text': r.label, 'value': r.label } }),
      onFilter: (value, record) => record.accountType?.indexOf(value) === 0,
    },
    {
      ...singleColumns(['备注', 'remark', 190]),
      render: (text, record) => isEdit && formState.id === record.id
        ? <Input.TextArea size='small' value={formState.remark}
          onChange={e => formState.remark = e.target.value}
        />
        : text
    },
    {
      ...singleColumns(['原因', 'reason', 180]),
      filters: _.get(category, 'reason', []).map(r => { return { 'text': r.label, 'value': r.label } }),
      onFilter: (value, record) => record.reason.indexOf(value) === 0,
      render: (text, record) => isEdit && formState.id === record.id
        ? <Select size='small' options={_.get(category, 'reason')}
          style={{ width: 155 }} value={formState.reason}
          onChange={(v) => formState.reason = v}
        />
        : text
    },
    {
      ...singleColumns(['类型', 'type', 145]),
      filters: _.get(category, 'type', []).map(r => { return { 'text': r.label, 'value': r.label } }),
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (text, record) => isEdit && formState.id === record.id
        ? <Select size='small' options={_.get(category, 'type')}
          style={{ width: 130 }} value={formState.type}
          onChange={(v) => formState.type = v}
        />
        : text
    },
    {
      ...singleColumns(['日期', 'date', 116]),
      render: (text) => moment(text).format('YYYY-MM-DD')
    },
    {
      ...singleColumns(['操作', 'option', 135]),
      render: (text, record) => {
        return isEdit && formState.id === record.id ? <Space size='small'>
          <Tooltip title='确定'>
            <Button size='small' type='link' icon={<CheckOutlined />}
              onClick={() => _updateContrast(record.id)}
            />
          </Tooltip>
          <Tooltip title='取消'>
            <Button size='small' type='text' icon={<CloseOutlined />}
              onClick={() => setIsEdit(false)}
            />
          </Tooltip>
        </Space>
          : <Space size='small'>
            <Tooltip title='修改'>
              <Button size='small' type='link' icon={<EditOutlined />}
                onClick={() => {
                  setIsEdit(true);
                  formState.id = record.id;
                  EDIT_KEY.map(key => {
                    if (key !== 'remark') {
                      formState[key] = _.chain(category).get(key).filter(o => o.name === record[key]).head().get('code').value();
                    } else {
                      formState[key] = record[key];
                    }
                  })
                }} />
            </Tooltip>
            <Tooltip title='删除'>
              <Button size='small' type='link' danger icon={<DeleteOutlined />}
                onClick={() => confirmDel(record.id)}
              />
            </Tooltip>
            <Tooltip title='复制并编辑'>
              <Button size='small' type='text' icon={<CopyOutlined />}
                onClick={() => onCopyOne(record)}
              />
            </Tooltip>
          </Space >
      }
    },
  ];
  // 获取对比分类原因数据
  async function _showContrast() {
    const res = await showContrast({
      'start': singleDate,
      'end': singleDate,
    });
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      let accs = [];
      getData.map(n => {
        if (accs.indexOf(n.subName) === -1) {
          accs.push(n.subName);
        }
      });
      formState.filterAcc = accs.map(name => { return { text: name, value: name } })
      setDatas(getData);
    }
  }
  // 修改
  async function _updateContrast(id) {
    let params = { id };
    let isChange = false;
    EDIT_KEY.map(key => {
      if (isValidString(formState[key])) {
        isChange = true;
        _.set(params, key, formState[key]);
      }
    });
    if (!isChange) {
      message.info('请修改内容！')
      return;
    }
    const res = await updateContrast(params);
    if (_.get(res, 'code', '') === '200') {
      message.success('修改成功！');
      setIsEdit(false)
      _showContrast();
    } else {
      message.info('修改失败！')
    }
  }

  function confirmDel(id) {
    Modal.confirm({
      title: '是否添加该记录',
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: () => _deleteContrast(id)
    });
  }
  // 删除
  async function _deleteContrast(id) {
    const res = await deleteContrast({ id });
    if (_.get(res, 'code', '') === '200') {
      message.success('删除成功！');
      _showContrast();
    } else {
      message.info('删除失败！')
    }
  }

  // 导出全部
  function exportExcel() {
    let option = {}; let exp = [];

    if (_.size(datas) === 0) {
      message.info('当前无持仓！');
      return
    };
    const noLastCol = _.dropRight(cols);
    datas.map(n => {
      let itm = {};
      noLastCol.map(c => {
        itm[c.title] = _.get(n, c.key, '')
      });
      exp.push(itm);
    });
    option.fileName = `${singleDate}差异对比`;
    option.datas = [
      {
        sheetData: exp,
        sheetName: 'sheet',
        sheetFilter: noLastCol.map(n => n.title),
        sheetHeader: noLastCol.map(n => n.title),
      }
    ]
    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  return (
    <div style={{ minHeight: 655 }}>
      <div style={{ marginBottom: 12, textAlign: 'right' }}>
        <Button onClick={exportExcel}>导出Excel</Button>
      </div>
      <Table
        rowKey="id"
        dataSource={datas}
        columns={cols}
        scroll={{ x: colWidth(cols), y: 635 }}
        pagination={false}
        bordered
        size='small'
      />
    </div>
  )
}

export default ReasonTable;