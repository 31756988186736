import React, { useEffect, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { Spin } from 'antd';
import { isValidArray } from '@/utils/utils2';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { CONTRIBUTE_CHARTS } from '../cutils';
import * as echarts from 'echarts';
import _ from 'lodash';

const REVERT_TYPE = { 'left': 'right', 'right': 'left' };
// 贡献度统计图表
export default function ContributeCharts(props) {
  const [option, setOption] = useState(CONTRIBUTE_CHARTS);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('compare_contribute_charts'));

    let newOption = _.cloneDeep(option);

    const getDatas = _.get(props, 'datas', []);
    const getUntrading = _.get(props, 'unTrading', {});
    const getCtype = _.get(props, 'ctype');
    // console.log(getUntrading);
    let times = [];
    let dataObj = {
      'buyContri': [], 'sellContri': [],
      'buyContriDiff': [], 'sellContriDiff': [],
      'untrade': []
    };
    if (isValidArray(getDatas)) {
      getDatas.map((item, index) => {
        const itmDate = _.get(item, 'date', '');
        times.push(itmDate);
        _.keys(dataObj).map(keyname => {
          const leftVal = _.get(item, `leftCal.${keyname}`, 0);
          const rightVal = _.get(item, `rightCal.${keyname}`, 0);
          dataObj[keyname][index] = _.round(rightVal - leftVal, 4);
        });
        // 未交易,同日单侧未交易求和，然后右侧-左侧
        dataObj['untrade'][index] = _.round(calUntradeContribution(_.get(getUntrading, REVERT_TYPE[getCtype]), itmDate) - calUntradeContribution(_.get(getUntrading, getCtype), itmDate), 4);
      });
    };
    newOption.xAxis.data = times;
    newOption.series = [
      createBaseLine('#f32f54', { name: '相同买入', key: 'buyContri', data: _.get(dataObj, 'buyContri') }),
      createBaseLine('#18c966', { name: '相同卖出', key: 'sellContri', data: _.get(dataObj, 'sellContri') }),
      createBaseLine('#eeb5b4', { name: '不同买入', key: 'buyContriDiff', data: _.get(dataObj, 'buyContriDiff') }),
      createBaseLine('#add9c3', { name: '不同卖出', key: 'sellContriDiff', data: _.get(dataObj, 'sellContriDiff') }),
      createBaseLine('#6583af', { name: '未交易', key: 'untrade', data: _.get(dataObj, 'untrade') }),
    ];
    newOption.legend.data = newOption.series.map(n => n.name);
    //console.log('newOption', newOption)
    setOption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [props.upCount]);

  useUpdateEffect(() => {
    if (props.active === 'contribute') {
      let myChart = props.myChart;
      myChart = echarts.init(document.getElementById('compare_contribute_charts'));
      myChart.resize();
    };
  }, [props.upTab]);

  function calUntradeContribution(untradeData, itmDate) {
    return _.chain(untradeData)
      .filter(o => o.dateIn === itmDate)
      .map(n => n.accountContributionRate)
      .sum()
      .round(4)
      .value() ?? 0;
  };

  return (
    <Spin spinning={_.get(props, 'loading', false)}>
      <div style={{ display: 'flex', marginTop: 28 }}>
        <div
          id="compare_contribute_charts"
          style={{ width: '100%', height: 485 }}
        />
      </div>
    </Spin>
  )
}