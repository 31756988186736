import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, message, AutoComplete } from 'antd';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import PhoneVerify from '@/view/common/PhoneVerify';
import { getVerifPhoneCode, getUserPhone, postVerifyPhone } from '@/api/auto';
import { addTraders,showAllTraders } from '@/api/workbench'

export default function AddTrader(props) {
    const mobxStore = useLocalObservable(() => store);

    const [visible, setvisible] = useState(false);
    const [phoneVisible, setphoneVisible] = useState(false);
    const [vCount, setvCount] = useState(0);
    const [userPhone, setuserPhone] = useState(null);
    const [form] = Form.useForm();
    const [phoneList, setphoneList] = useState([]);

    useEffect(() => {
        async function _getPhone() {
            let userInfo = JSON.parse(JSON.stringify(mobxStore.userInfo));
            const data = await getUserPhone({ params: { email: userInfo.email } });
            // console.log(111, data);
            if (data.code === '200') {
                setuserPhone(data.data.phone);
            }
        }
        _getPhone();
    }, [mobxStore])

    function _click() {
        setphoneVisible(true);
        setvCount(vCount + 1)
    }

    // 接收验证手机号后返回来的code
    async function _phoneVerify(valuesCode) {
        // console.log(111, valuesCode);
        // setvisible(true);
        const data = await postVerifyPhone({ account: valuesCode.phone, verifyCode: valuesCode.phoneCode });
        if (data.code === '200') {
            const data1 = await showAllTraders(); 
            if (data1.code === '200') { 
                let arr = [];
                if(data1.data !== null){
                    data1.data.map(item => {
                        let b={value:item.phone};
                        arr.push(b);
                        return item;
                    })
                }
                setphoneList(arr)
            }else{
                message.error(data.message);
            }
            setphoneVisible(false);
            setvisible(true);
        } else {
            message.error(data.message);
        }
    }
    // 输入完交易员姓名
    async function handleOk() {
        const { name } = await form.validateFields();
        // console.log(111, name);
        const data = await addTraders({ traderInfo: name });
        if (data.code === '200') {
            message.success('添加成功');
            props.goBack();
        } else {
            message.error(data.message);
        }
        setvisible(false);
    }

    return (
        <Observer>
            {() => (
                <>
                    <Button
                        type="dashed"
                        className="colorGrey"
                        style={{ width: '100%', marginTop: 20 }}
                        onClick={_click}
                    >
                        + 添加
                    </Button>

                    <PhoneVerify
                        visible={phoneVisible}
                        vCount={vCount}
                        phoneHandleCancel={() => setphoneVisible(false)}
                        gobackValues={_phoneVerify}
                        getCode={getVerifPhoneCode}
                        userPhone={userPhone}
                    />

                    <Modal
                        title="添加交易员"
                        okText="提交"
                        visible={visible}
                        onOk={handleOk}
                        onCancel={() => setvisible(false)}
                    >
                        <Form
                            initialValues={{}}
                            form={form}
                        >
                            <Form.Item
                                label="请输入交易员手机号"
                                name="name"
                                rules={[{ required: true, message: '请输入交易员手机号!' }]}
                            >
                                <AutoComplete
                                    options={phoneList}
                                    style={{ width: 200 }}
                                    placeholder="请输入交易员手机号"
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </>
            )}
        </Observer>
    )
}