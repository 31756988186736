import React, { useState, memo } from 'react';
import { Row, Col, Card, Typography, Button, Space, Badge, Empty } from 'antd';
import { getFirstWithoutRiskControl, getFirstRiskControl } from '@/api/workbench';
import { AlertFilled, BulbFilled, EllipsisOutlined } from '@ant-design/icons';
import { useMount, useUnmount } from 'ahooks';
import { validateResponse } from '@/utils/utils';
import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;

const Alerts = ({ goNews }) => { //role, 
  const [normalMessage] = useState([]);
  const [riskMessage] = useState([]);
  // const [normalHour, setnormalHour] = useState(0);
  // const [riskHour, setriskHour] = useState(0);
  // const [riskState, setriskState] = useState(false);
  let isUnmont = false;

  useMount(() => {
    getNormal();
    _getRisk();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  async function getNormal() {
    const data = await getFirstWithoutRiskControl();
    if (validateResponse(data, isUnmont)) {
      if (data.data !== null) {
        normalMessage[0] = data.data;
        // const sendTime = moment(data.data.sendTime);
        // const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
        // setnormalHour(nowTime.diff(sendTime, 'hours'));
      } else {
        normalMessage[0] = '';
      }
    }
  }

  async function _getRisk() {
    const data = await getFirstRiskControl();
    if (validateResponse(data, isUnmont)) {
      riskMessage[0] = data.data;
      if (riskMessage[0] !== null) {
        const sendTime = moment(data.data.sendTime);
        const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
        // setriskHour(nowTime.diff(sendTime, 'hours'));
        if (nowTime.diff(sendTime, 'minutes') > 30) {
          // setisDisabled(true);
        }
        if (data.data.type === 'execute_risk_control') {
          // setriskState(true);
        }
      }
    }
  }

  const newRisk = _.size(riskMessage) > 0 ? _.head(riskMessage) : null;
  const newNormal = _.size(normalMessage) > 0 ? _.head(normalMessage) : null;
  return (
    <>
      <Card style={{ minHeight: 280 }}>
        <Row align='middle'>
          <Col span={12}><span className='messageTitle'>风控信息</span></Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="text" onClick={goNews} icon={<EllipsisOutlined />} />
          </Col>
        </Row>
        {newRisk ? <Badge.Ribbon text='New'>
          <div className='messageDiv'>
            <div className='msg_title'>
              <Space>
                <AlertFilled />
                <Text>{_.get(newRisk, 'sender')}</Text>
              </Space>
            </div>
            <Paragraph type='secondary'>
              {_.get(newRisk, 'content', '')}
            </Paragraph>
          </div>
        </Badge.Ribbon> : <Empty />}
      </Card>

      <Card style={{ minHeight: 220, marginTop: 8 }}>
        <Row align='middle'>
          <Col span={12}><span className='messageTitle'>消息</span></Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="text" onClick={goNews} icon={<EllipsisOutlined />} />
          </Col>
        </Row>
        {newNormal ? <Badge.Ribbon text='New'>
          <div className='messageDiv'>
            <div className='msg_title'>
              <Space>
                <BulbFilled />
                <Text>{_.get(newNormal, 'sender')}</Text>
              </Space>
            </div>
            <Paragraph type='secondary'>
              {_.get(newNormal, 'content', '')}
            </Paragraph>
          </div>
        </Badge.Ribbon> : <Empty />}
      </Card>
    </>
  )
}

export default memo(Alerts);