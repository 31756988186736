import React, { useEffect, useState } from 'react';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { Layout, Menu, Button, Space, Badge, Avatar, Dropdown, Image, Row, Col } from 'antd';
import { routeConfig } from '@/router/route.config';
import logoCN from "@/images/logoEN.svg";
import { showAllSubAccount, notRead } from '@/api/workbench';
import {
  UserOutlined, DownOutlined, BellOutlined, MessageOutlined, UnorderedListOutlined, PieChartOutlined,
  ControlOutlined, MenuFoldOutlined, MenuUnfoldOutlined, AccountBookOutlined, UserSwitchOutlined,
  AlertOutlined, LineChartOutlined, PieChartFilled, MergeCellsOutlined, FileDoneOutlined, CloudServerOutlined
} from '@ant-design/icons';
import { useMount } from 'ahooks';
import { isBrowser } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { isValidArray } from '@/utils/utils2';
import _ from 'lodash';
//import '../../index.scss'
import '../../colors.less';

const { Content, Sider, Header } = Layout;
const { SubMenu } = Menu;
const trader_asset_key = '1_1'
const fundManagerItem = [
  { key: '2_12', link: '/home/AssetsShow', pms: { key: "1", from: 'home' }, name: '资产总览', icon: <UnorderedListOutlined /> },
  { key: '2_22', link: '/home/precheck', pms: { key: "9" }, name: '盘前检查', icon: <FileDoneOutlined /> },
  { key: '2_13', link: '/home/FundAdm', pms: { key: "2" }, name: '资金管理', icon: <AccountBookOutlined /> },
  { key: '2_14', link: '/home/TraderAdm', pms: { key: "3" }, name: '交易员管理', icon: <UserSwitchOutlined /> },
  { key: '2_18', link: '/home/TransAnalyse', pms: { key: "6" }, name: '交易分析', icon: <PieChartOutlined /> },
  { key: '2_15', link: '/home/RiskAdm', pms: { key: "4" }, name: '风控管理', icon: <AlertOutlined /> },
  { key: '2_16', link: '/home/FundManagerStockPoll', pms: { key: "5" }, name: '股票管理', icon: <LineChartOutlined /> },
  { key: '2_20', link: '/home/fundAnalysis', pms: { key: "7" }, name: '主力分析', icon: <PieChartFilled /> },
  { key: '2_21', link: '/home/tradeCompare', pms: { key: "8" }, name: '实测对比', icon: <MergeCellsOutlined /> },
  { key: '3', link: '/home/hosting', pms: { key: "hosting" }, name: '湘财托管', icon: <CloudServerOutlined /> },
];

export default function Home(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const mobxStore = useLocalObservable(() => store);
  const [collapsed, setcollapsed] = useState(isMobile ? true : false);
  const [menuKey, setmenuKey] = useState(localStorage.getItem('menuKey') || '2_12');
  const [accountList, setaccountList] = useState([]);
  const [newsUnread, setNewUnread] = useState(0);
  const [riskUnread, setRiskUnread] = useState(0);

  useEffect(() => {
    // console.log(22, mobxStore.userInfo.role);
    // console.log(111, JSON.parse(JSON.stringify(mobxStore.userInfo)));
    if (!mobxStore.userLogin) {
      props.history.push('/login');
    }
    if (checkRole('trader')) {
      if (!localStorage.getItem('menuKey')) {
        setmenuKey(trader_asset_key);
      }
    }
  }, [props, mobxStore, setaccountList]);

  useMount(() => {
    _getData();
    _getNews();
    if (checkRole('fund_manager')) {
      mobxStore._showIndexConfig();
      mobxStore._getCategory();
    };
  });

  async function _getNews() {
    const readRes = await notRead()

    if (_.get(readRes, 'code', '') === '200') {
      const getType = _.get(readRes, 'data', {})
      let total = 0;

      Object.keys(getType).map(keyname => {
        total = total + getType[keyname];
      })

      setNewUnread(total);
      setRiskUnread(_.get(readRes, 'data.risk_signal'));
    }
  }

  async function _getData() {
    if (checkRole('trader')) {
      const data = await showAllSubAccount();
      if (data.code === '200') {
        setaccountList(data.data);
      }
    }
  }

  function handleClick(e) {
    if (e.key === '2_12') {
      localStorage.setItem('url_string', 'home');
    }
    localStorage.setItem('menuKey', e.key);
    setmenuKey(e.key);
  }
  function _toMessage() {
    props.history.push({
      pathname: '/home/fundManagerNews',
    });
  }
  // function _toRiskMessage() {
  //     props.history.push({
  //         pathname: '/home/fundManagerNewsRisk',
  //     });
  // }
  function _getOut() {
    mobxStore.cancelLogin();
    props.history.replace('/login');
  }

  function checkRole(role) {
    return mobxStore.userInfo.role === role ? true : false
  }

  const menu = (
    <Menu
      triggerSubMenuAction='click'
      onClick={(e) => {
        if (e.key === '1') {
          _getOut();
        }
      }}>
      <Menu.Item key={'1'} danger>退出登录</Menu.Item>
    </Menu>
  );

  const renderLinkItem = (name, link, state) => {
    return <Link to={{
      pathname: link,
      state: state,
    }}>{name}</Link>
  };

  const renderTraderMenu = () => {
    return <SubMenu key="1" icon={<ControlOutlined />} title="交易员工作台">
      <Menu.Item key={trader_asset_key}>
        <Link to={"/home/tradersDesk"}>我的资产</Link>
      </Menu.Item>
      {isValidArray(accountList) && accountList.map((item, key) => <Menu.Item key={trader_asset_key + '_' + key}>
        <Link to={{
          pathname: "/home/listsDetails",
          state: { subAccountInfo: item }
        }}>
          {item.subAccountName}
        </Link>
      </Menu.Item>)}
    </SubMenu>
  }

  const iconStyle = { fontSize: 16, color: 'white' };
  const accountName = _.get(mobxStore, 'userInfo.account', 'User');
  const renderAccountName = _.size(accountName) > 7 ? accountName.substring(0, 7) + '...' : accountName;

  const logoComp = <Image src={logoCN} style={{ width: 173, height: 49, paddingTop: 20 }} preview={false} />
  const messageComp = <Badge count={newsUnread} style={{ borderColor: '#d9363e' }}>
    <div>
      <MessageOutlined style={{ ...iconStyle, marginRight: -9 }} />
      <Button key="message" type="text" onClick={() => _toMessage('all')} style={{ color: 'white' }}>
        消息列表
      </Button>
    </div>
  </Badge>;
  const riskComp = <div style={{ width: '120px' }}>
    <Badge count={riskUnread} style={{ borderColor: '#d9363e' }}>
      <div>
        <BellOutlined style={{ ...iconStyle, marginRight: -9 }} />
        <Button key="riskMessage" type="text" onClick={() => _toMessage('risk')} style={{ color: 'white' }}>
          风控通知
        </Button>
      </div>
    </Badge>
  </div>;

  return (
    <Observer>
      {() => (
        <Layout style={{ minHeight: '100vh' }}>
          {/* <Link to={"/home/fundManagerNews"}>消息列表</Link> */}
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={broken => {
                //console.log('broken', broken);
                if (isBrowser) {
                  setcollapsed(broken)
                }
              }}
            >
              <div><Avatar src={logoComp} /></div>
              <Menu
                theme='dark'
                onClick={handleClick}
                defaultOpenKeys={['1', '2']}
                selectedKeys={[menuKey]}
                mode="inline"
              >
                {checkRole('trader') && renderTraderMenu()}
                {checkRole('fund_manager') &&
                  <SubMenu key="2" icon={<ControlOutlined />} title={"管理人工作台"}>
                    {fundManagerItem.map(item => <Menu.Item key={item.key} icon={item.icon}>
                      {renderLinkItem(item.name, item.link, item.pms)}
                    </Menu.Item>)}
                  </SubMenu>}
                {checkRole('visitor') &&
                  <SubMenu key="2" icon={<ControlOutlined />} title={'工作台'}>
                    {[fundManagerItem[0]].map(item => <Menu.Item key={item.key} icon={item.icon}>
                      {renderLinkItem(item.name, item.link, item.pms)}
                    </Menu.Item>)}
                  </SubMenu>}
              </Menu>
            </Sider>

            <Content>
              <Header className="site-layout-background" style={{ padding: 0 }}>
                <Row justify='space-between'>
                  <Col span={2} style={{ fontSize: 16, paddingLeft: 10 }}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                      className: 'trigger',
                      style: { color: 'white' },
                      onClick: () => setcollapsed(!collapsed),
                    })}
                  </Col>
                  <Col xxl={12} xl={12} lg={18} md={22} sm={22} style={{ textAlign: 'right', paddingRight: 25 }}>
                    <Space>
                      <div style={{ width: '100px' }}>
                        {collapsed && isMobile && messageComp}
                        {!isMobile && messageComp}
                      </div>
                      {
                        checkRole('fund_manager') ? riskComp : <div style={{ width: '100px' }}></div>

                      }
                      <div style={{ lineHeight: '31px' }}>
                        <Space>
                          <div style={{ width: '33px' }}>
                            <UserOutlined style={iconStyle} />
                          </div>
                          <Dropdown overlay={menu} trigger={['click']}>
                            <a style={{ color: 'white' }}>
                              {renderAccountName} <DownOutlined />
                            </a>
                          </Dropdown>
                        </Space>
                      </div>
                    </Space>
                  </Col>
                </Row>
              </Header>

              <Switch>
                {
                  routeConfig[5].routes.map((item, index) => {
                    return <Route key={menuKey + index} path={item.path} component={item.component} />
                  })
                }
                {
                  (checkRole('fund_manager') || checkRole('visitor')) &&
                  <Redirect to={{
                    pathname: "/home/AssetsShow",
                    state: { key: "1", from: 'redirect_home' }
                  }} />
                }
                {checkRole('trader') && <Redirect to='/home/tradersDesk' />}
              </Switch>
            </Content>

          </Layout>
        </Layout>
      )}
    </Observer>
  )
}