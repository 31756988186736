import React, { } from 'react';
import { Button, Result, Row, Col } from 'antd';
import logo from '@/images/_logoEN.svg';


export default function EmailActivationFail(props) {
    return (
        <Row gutter={[0, 0]} justify="center">
            <img
                src={logo}
                alt=""
                style={{ width: 300, height: 110, marginTop: 100 }}
            />

            <Col span={24}>
                <Result
                    status="error"
                    title="您的账户激活失败"
                    // subTitle=""
                    style={{}}
                    extra={[
                        // <Button type="primary" key="console" href={''} target="_blank" >
                        //     查看邮箱
                        // </Button>,
                        <Button key="buy" type="primary" danger size="large" onClick={() => props.history.push('/')}>激活失败</Button>,
                    ]}
                />
            </Col>
            <Col span={24}>
                <p style={{ textAlign: 'center', margin: '200px 0 20px' }}>COPYRIGHT © 2021 程创科技出品 </p>
            </Col>
        </Row>
    )
}