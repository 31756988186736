import AssetsShow from '@/view/Home/AccountAdm/AssetsShow/AssetsShow';
import ListsDetails from '@/view/Home/AccountAdm/ListsDetails/ListsDetails';
import SubAccount from '@/view/Home/AccountAdm/SubAccount/SubAccount';
import ProductDetails from '@/view/Home/AccountAdm/ProductDetails/ProductDetails';
import TradersDesk from '@/view/Home/AccountAdm/TradersDesk/TradersDesk';
import AddProduct from '@/view/Home/AccountAdm/AddProduct/AddProduct';
import FundManagerNews from '@/view/Home/AccountAdm/FundManagerNews/FundManagerNews';
import StockScreening from '@/view/Home/StockAdm/StockScreening/StockScreening';
import Trading from '@/view/Home/StockAdm/StockScreening/Trading';
import TransAnalyse from '@/view/Home/AccountAdm/AssetsShow/TransAnalyse';
// 主力新增
import FundAnalysis from '@/view/Home/AccountAdm/AnalysisFund/FundAnalysis';
//持仓
import PositionPage from '@/view/Home/AccountAdm/Position/PositionPage';
//实测分析
import TradeCompare from '@/view/Home/AccountAdm/Compare/TradeCompare';
// 盘前检查
import PrecheckPage from '@/view/Home/Settings/Precheck/Precheck';
// 批量卖出
import BatchSell from '@/view/Home/StockAdm/BatchSell/BatchSell';
// 反采
import HostingPage from '@/view/Home/ReverseAdm/HostingPage';
import ConfigPage from '@/view/Home/pages/ConfigPage';
import { renderRoutes } from './rutils';

const routeMainConfig = [
  renderRoutes("/home/AssetsShow", "基金经理资产总览", AssetsShow),
  renderRoutes("/home/listsDetails", "我的子账户", ListsDetails),
  renderRoutes("/home/subAccount", "产品列表", SubAccount),
  renderRoutes("/home/ProductDetails", "产品详情", ProductDetails),
  renderRoutes("/home/tradersDesk", "交易员工作台", TradersDesk),
  renderRoutes("/home/AddProduct", "添加产品", AddProduct),
  renderRoutes("/home/fundManagerNews", "基金经理消息列表", FundManagerNews),
  renderRoutes("/home/StockScreening", "股票策略", StockScreening),
  renderRoutes("/home/Trading", "交易", Trading),
  renderRoutes("/home/TransAnalyse", "基金经理交易分析", TransAnalyse),
  renderRoutes("/home/fundAnalysis", "主力分析结果", FundAnalysis),
  renderRoutes("/home/position", "持仓管理", PositionPage),
  renderRoutes("/home/tradeCompare", "实测对比", TradeCompare),
  renderRoutes("/home/precheck", "盘前检查", PrecheckPage),
  renderRoutes("/home/batchsell", "批量卖出", BatchSell),
  renderRoutes("/home/hosting", "反采盘中", HostingPage),
  renderRoutes("/home/conifgs", "全局配置", ConfigPage),
]

export default routeMainConfig;