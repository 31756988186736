import React, { useState, useRef } from 'react';
import { Card, message, Tabs, Alert } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useInterval, useMount, useUnmount, useReactive, useSize } from 'ahooks';
import { getVerifPhoneCode, getUserPhone } from '@/api/auto';
import { getProductDetailss, delSecuritiesAccount, getPositionUp5 } from '@/api/details';
import {
  showSingleProductHistory, getfundManagerAssets, getCommission, showIndexKline, showAllProductHistory
} from '@/api/workbench';
import { visShowAssetsAndProducts } from '@/api/reviewApi';
import {
  STRATEGY_TYPE, DATE_TYPE,
  handleMainSeriesData, sliceUnmatchTimes, calCustomIndex, initDateType
} from '@/view/common/Components/list_util';
import {
  isValidArray, isValidString, isValidObj, createDatasTimeKey, isMarketTrading,
  handleCustomIndexPms
} from '@/utils/utils2';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import { validateResponse } from '@/utils/utils';
import Marquee from 'react-fast-marquee';
import MainRateCharts from '@/view/common/MainRateCharts';
import SubContributionCharts from './Components/SubContributionCharts';
import DisplayCharts from '@/view/common/DisplayCharts';
import CommissionModal from './Components/CommissionModal';
import CompareChartsTwo from '@/view/common/CompareChartsTwo';
import PhoneVerify from '@/view/common/PhoneVerify';
import AccountTable from './Components/AccountTable';
import NewAccModal from './Components/NewAccModal';
import AssetsHeader from './Components/AssetsHeader';
import BtCompareCharts from '../ListsDetails/Components/BtCompareCharts';
import TradeRecord from '../ListsDetails/TradeRecord';
import PositionTable from '../ListsDetails/Components/PositionTable';
import LevelCharts from './Components/LevelCharts';
import KRateCharts from '@/view/common/KRateCharts';
import ContributionCharts from '../ListsDetails/Components/ContributionCharts';
import ExtraCompare from './Components/ExtraCompare';
import { RecentTabs, DetailTabsIcon, NewPageHeader } from './de_widget';
import moment from 'moment';
import _ from 'lodash';
import './product.scss'

const { TabPane } = Tabs;
const TODAY = moment().format('YYYY-MM-DD');
const INTERVAL_NUM = 60000;
const REALTIME_TAB = '1';
const MAIN_RATE = 'MAIN_RATE';
let timer = null;
/**
 * 产品页面详情；
 * 230816：增加账户层面的持仓及交易记录；回测对比增加产品层面
 * 240117：增加反采账户，使用/fc接口获取数据
 */
export default function ProductDetails(props) {
  const mobxStore = useLocalObservable(() => store);
  const productParams = useReactive({
    pms: props.location.state.paramsData, riseList: [], symbol: DEFAUL_INDEX_CODE,
  });// 当前产品信息所用的数据，默认加载路由中的数据
  const [userRole] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.userInfo)), 'role', ''));
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  const [allProducts] = useState(JSON.parse(JSON.stringify(mobxStore.allProducts)));
  const [customIndex, setCustomIndex] = useState(JSON.parse(JSON.stringify(mobxStore.customIndex)) ?? [])
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const initType = initDateType(tradeDates, REALTIME_TAB);
  const initTrading = isMarketTrading(initType.isTradeDay);
  const [ptab, setPtab] = useState(String(productParams.pms.productId));
  const [tabActive, setTabActive] = useState(MAIN_RATE);
  const [listData, setlistData] = useState({});
  const [pointDatas, setPointDatas] = useState({ timeList: [], datas: [], newData: [] });
  const [glbDate, setGlbDate] = useState([]);
  const [allassets, setallassets] = useState({});
  const [count7time, setCount7Time] = useState(0); //7 时间更新
  const [isTradeTime] = useState(initTrading);
  const [countMain, setCountMain] = useState(0); // 收益率图表更新
  const [newTabs, setNewTabs] = useState(initType.tab)
  const [newLoading, setNewLoading] = useState(false)
  const [inter, setInter] = useState(initTrading ? INTERVAL_NUM : undefined);
  const [commisVisible, setCommisVisible] = useState({ show: false, data: [] });
  const [indexValue, setIndexValue] = useState({});
  const [idxParams, setIdxParams] = useState({
    'symbol': DEFAUL_INDEX_CODE, 'dateType': initType.type, 'date': TODAY
  });
  const [phoneVisible, setphoneVisible] = useState(false);
  const [vCount, setvCount] = useState(0);
  const [delVal, setDelVal] = useState({});
  const [userPhone, setuserPhone] = useState(null);
  const [validDate, setValidDate] = useState([]); // 收益率时间区间
  const [posCount, setPosCount] = useState(0); // 当前持仓及交易记录update
  // 常量
  const isVisitor = mobxStore.userInfo.role === 'visitor' ? true : false
  const sizeRef = useRef(null);
  const divSize = useSize(sizeRef);
  const centraLogin = _.get(productParams.pms, 'centralizedLogin', 0);
  const isReverse = centraLogin === 2 ? true : false;
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', ''));
  let isUnmont = false;

  useMount(() => {
    setGlbDate([TODAY, TODAY]); // 初始化配置全局日期
    productParams.pms.contributionTime = [TODAY, TODAY];
    setCount7Time(count7time + 1);

    _initGetDatas('init');
    //交易日
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    };
  });

  useUnmount(() => {
    setInter(undefined);
    isUnmont = true;
    timer && clearTimeout(timer);
  });

  useInterval(() => {
    _getAsset();
    _cutDateType(newTabs);
    _getPositionUp5();
    if (isMarketTrading() === false) {
      setInter(undefined);
    }
  }, inter);
  // 初始及重载加载function;
  //【tips】重载内的所有接口重新获取is_reverse，切换后用最新的参数
  function _initGetDatas(loadType = '') {
    // 初始赋值全局数据
    setallassets(productParams.pms);
    productParams.pms.dateType = initType.type;
    productParams.pms.dateToday = TODAY;
    productParams.pms.contributionTime = [TODAY, moment().add('days', 1).format('YYYY-MM-DD')];
    productParams.pms.strategyType = STRATEGY_TYPE[productParams.pms.strategyType] || '';
    // 增加实时数据缓存
    const routeCache = _.get(props, 'location.state.cache', {});
    const routeIdxCache = _.get(props, 'location.state.indexCache', {});
    if (loadType === 'init' && isValidObj(routeCache) && isValidObj(routeIdxCache)) {
      setPointDatas({
        'timeList': _.get(routeCache, 'timeList', []),
        'datas': routeCache,
        'newData': handleMainSeriesData(routeCache, 'TODAY')
      });
      setIndexValue(routeIdxCache);
      updateMain();
    } else {
      _cutDateType(newTabs, null, 'init');
    };
    _showAllProductHistory();
    _get();
    _getAsset();
    _getPositionUp5();

    if (loadType === 'reload') {
      setPosCount(posCount + 1); // 重载或初次加载更新，组件内部会重新获取数据
      if (tabActive !== MAIN_RATE) {
        setTabActive(MAIN_RATE);
      }
    };
  }
  // 获取用户信息
  async function _getPhone() {
    let userInfo = JSON.parse(JSON.stringify(mobxStore.userInfo));
    const data = await getUserPhone({ params: { email: userInfo.email } });
    if (data.code === '200') {
      setuserPhone(data.data.phone);
      // 先验证手机号
      setphoneVisible(true);
      setvCount(vCount + 1)
    }
  }
  // 获取子账户有收益率数据的时间区间
  async function _showAllProductHistory() {
    const is_reverse = _.get(productParams.pms, 'centralizedLogin', 0) === 2 ? true : false;
    if (productParams.pms.productId) {
      const res = await showAllProductHistory(productParams.pms.productId, is_reverse ? 'FC' : 'NOR');
      if (validateResponse(res, isUnmont)) {
        let getDateArray = _.get(res, 'data', [])
        // 返回区间如不包含今天则添加今天，避免获取问题
        if (getDateArray.indexOf(TODAY) === -1) {
          getDateArray.push(TODAY);
        }
        setValidDate(getDateArray);
      }
    }
  }
  // 每3分钟涨幅5%的票；[定时任务]
  async function _getPositionUp5() {
    const is_reverse = _.get(productParams.pms, 'centralizedLogin', 0) === 2 ? true : false;
    if (productParams.pms.productId) {
      const res = await getPositionUp5(productParams.pms.productId, is_reverse ? 'FC' : 'NOR');
      productParams.riseList = createDatasTimeKey(_.get(res, 'data', []), 'time', 's');
    }
  }
  /* 
    收益率获取
    240415: 增加自定义配置指数；特殊symbol值获取批量指数做合并计算，生成新指数值；
  */
  const unmatch_types = ['ALL', 'THREE_YEAR']; // ALL = 成立以来，数据过多或者三年数据还没有，统一同一年进行获取
  async function _showSingleProductHistory(params) {
    const is_reverse = _.get(productParams.pms, 'centralizedLogin', 0) === 2 ? true : false;
    let pms = {
      'productId': params.productId,
      'dateType': params.dateType,
      'date': _.get(params, 'dateToday'),
    }
    let idxPms = {
      'symbol': productParams.symbol,
      'dateType': pms.dateType,
      'date': pms.date
    };
    const idxPms2 = handleCustomIndexPms(idxPms, customIndex);
    if (idxPms2.symbolInit) { // 切换回实时，将symbol切换回默认值；
      productParams.symbol = DEFAUL_INDEX_CODE;
    };
    if (idxPms2.isCusFromStorage) { //如 mobx无数据；从storage拿数据重新赋值
      setCustomIndex(_.get(idxPms2, 'customs', []));
    };
    const data1 = await showSingleProductHistory(pms, is_reverse ? 'FC' : 'NOR');
    // 因增加时间对比进行裁剪，正常将不再对指数数据进行缓存，两个数据需要同时获取
    const res = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
    setIdxParams(idxPms2.params);
    setNewLoading(false);
    if (_.get(data1, 'message', '') !== 'success') {
      message.info(_.get(data1, 'message'))
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    const validPoints = isValidArray(_.get(data1, 'data.pointList', []));
    if (validateResponse(data1, isUnmont) && validPoints) {
      let newTimeList = _.get(data1, 'data.timeList', []);
      // 指数遇到合并则进行计算
      let indexDatas = idxPms2.isBatch
        ? calCustomIndex(_.get(res, 'data', {}), idxPms2.rates, newTimeList, idxPms.dateType)
        : _.get(res, 'data', {});
      let newPoints = _.get(data1, 'data', {});
      if (_.includes(unmatch_types, pms.dateType)) { // 遇到ALL和三年长数，进行裁剪
        const newRes = sliceUnmatchTimes(newPoints, indexDatas); // 传入收益率和指数的response.data对象
        newTimeList = _.get(newRes, 'points.timeList', []); // 重新赋值
        newPoints = _.get(newRes, 'points', {});
        indexDatas = _.get(newRes, 'indexs', {});
      }
      setPointDatas({
        timeList: newTimeList,
        datas: newPoints,
        newData: handleMainSeriesData(newPoints, params.dateType)
      });
      setIndexValue(indexDatas);
    } else {
      setPointDatas({ timeList: [], datas: {}, newData: {} });
      setIndexValue({});
    }
    updateMain();
  }
  // 【原】获取指数数据，因对比只能做成同步，该function只有切换时使用；
  async function _showIndexKline(idx) {
    let params = {
      'symbol': idx ? idx : idxParams.symbol,
      'dateType': idxParams.dateType,
      'date': idxParams.date
    };
    const idxPms2 = handleCustomIndexPms(params, customIndex);
    const res = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
    setIdxParams(idxPms2.params)
    if (validateResponse(res, isUnmont) && !isUnmont) {
      const getData = idxPms2.isBatch
        ? calCustomIndex(_.get(res, 'data', {}), idxPms2.rates, _.get(pointDatas, 'timeList'), params.dateType)
        : _.get(res, 'data', {});
      setIndexValue(getData);
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    updateMain();
  }
  //实时更新产品资产、收益等信息
  async function _getAsset() {
    const is_reverse = _.get(productParams.pms, 'centralizedLogin', 0) === 2 ? true : false;
    let params = { isShow: false, pageNum: 1, pageSize: 10, productId: productParams.pms.productId }
    const data = userRole === 'visitor' ? await visShowAssetsAndProducts() : await getfundManagerAssets(params, is_reverse ? 'FC' : 'NOR');
    if (validateResponse(data, isUnmont) && _.size(_.get(data, 'data.currentProductResultList', [])) === 1) {
      setallassets(_.head(_.get(data, 'data.currentProductResultList', [])));
    }
  }
  //求证券账户表格的总收益和今日收益
  async function _get() {
    const is_reverse = _.get(productParams.pms, 'centralizedLogin', 0) === 2 ? true : false;
    const data = await getProductDetailss({ params: { productId: productParams.pms.productId } }, is_reverse ? 'FC' : 'NOR');
    if (validateResponse(data, isUnmont)) {
      let subList = [];
      let securitiesAccounts = _.get(data, 'data.securitiesAccounts', []);
      securitiesAccounts.map(item => {
        let totalRevenue = 0;
        let dailyRevenue = 0;
        item.subAccountList.map(subs => {
          totalRevenue += subs.totalRevenue;
          dailyRevenue += subs.dailyRevenue;
          subList.push(_.assign(subs, {
            'subAccountId': _.get(subs, 'id'),
            'subAccountName': _.get(subs, 'name'),
            'centralizedLogin': _.get(productParams.pms, 'centralizedLogin', 0),
          }));
          return subs;
        })
        item.totalRevenue = totalRevenue;
        item.dailyRevenue = dailyRevenue;
        return item;
      })
      setlistData(_.assign({ table: securitiesAccounts }, _.get(data, 'data', {})));
      mobxStore.saveAccountCache(subList);
    }
  }

  async function _delSubAccount(record) {
    if (isReverse) { // 删除如反采直接调用接口
      _verifyDelete(record);
    } else {
      setDelVal(record);
      _getPhone();
    }
  }

  async function _getCommission(id) {
    if (id) {
      const res = await getCommission(id, isReverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', []);
        setCommisVisible({
          show: true,
          data: isValidArray(getData) ? getData.map((n, i) => { return { ...n, id: i } }) : []
        })
      }
    }
  }
  // 删除子账户接口
  async function _verifyDelete(valuesCode) {
    if (isReverse) {
      const data = await delSecuritiesAccount({
        subAccountId: valuesCode.id,
      }, 'FC');
      if (data.code === '200') {
        _get();
        message.success('删除成功');
      } else {
        message.error(data.message);
      }
    } else {
      if (delVal.id) {
        const data = await delSecuritiesAccount({
          subAccountId: delVal.id,
          phone: valuesCode.phone,
          code: valuesCode.phoneCode,
        });
        if (data.code === '200') {
          _get();
          message.success('删除成功');
        } else {
          message.error(data.message);
        }
        setphoneVisible(false);
      }
    }
  }

  // 跳转到基金经理查看子账户
  function _goFundManagerListsDetails(record) {
    let findex = _.findIndex(_.get(listData, 'table', []), o => o.id === _.get(record, 'securitiesAccountId', 0));
    record.subAccountName = record.name;
    record.subAccountId = record.id;
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    const newInfo = {
      ...record,
      'strategyType': findex !== -1 ? _.get(listData, `table[${findex}].strategyType`) : 0,
      'centralizedLogin': centraLogin // 子账户添加反采字段
    };
    // console.log(newInfo);
    props.history.push({
      pathname: '/home/listsDetails',
      state: { subAccountInfo: newInfo }
    });
  }

  // 切换图表时间
  async function _cutDateType(e = '', date = null, init = '') {
    setNewTabs(e === '11' ? REALTIME_TAB : e);
    productParams.pms.dateToday = e === '11' ? date : TODAY;
    // 实时：开启定时任务
    setInter(e === REALTIME_TAB && isTradeTime ? INTERVAL_NUM : undefined);
    // 增加年份的切换，tab=2024这类年份，则调用成立以来接口；
    const new_type = parseInt(e) > 100 ? DATE_TYPE['12'] : DATE_TYPE[e];
    productParams.pms.dateType = new_type;
    // 上个接口=ALL直接切换
    if (_.get(idxParams, 'dateType') === 'ALL' && new_type === 'ALL' && init !== 'init') {
      updateMain();
    } else {
      _showSingleProductHistory(productParams.pms);
    }
  }
  // 盈亏全局时间
  function onProfitTimeChange(value) {
    productParams.pms.contributionTime = value;
  }

  function updateMain() {
    setCountMain(_.round(countMain + 0.1, 1));
  }

  const pos_res_pros = {
    'pageKey': 'products', 'role': mobxStore.userInfo.role, 'productId': productParams.pms.productId, 'upCount': posCount,
    'newProps': props, 'isReverse': isReverse, 'isTrading': isTradeTime,
  };
  const main_props = {
    'pageKey': 'products', 'role': mobxStore.userInfo.role, 'productId': productParams.pms.productId, 'upcount': countMain,
    'isTrading': isTradeTime, 'validDateRange': validDate, 'datas': pointDatas, 'indexSymbol': productParams.symbol, 'indexValue': indexValue,
    'dailyRevenueRate': _.round(parseFloat(_.get(allassets, 'dailyRevenueRate', 0)), 2),  // 空收益率数据显示日收益率
    'currentTabs': newTabs, 'loading': newLoading, 'pms': idxParams, 'isReverse': isReverse, 'reloadCount': posCount,
    'customIndex': customIndex, 'tradeDates': tradeDates
  };
  const rev_props = { 'pageKey': 'product', 'active': tabActive, 'id': productParams.pms.productId, 'isReverse': isReverse };
  const header_props = {
    'title': _.get(productParams.pms, 'productName', '-'), 'subTitle': _.get(productParams.pms, 'strategyType', '-'),
    'screenWidth': _.get(divSize, 'width', 0), 'listData': listData, 'isTradeDay': initType.isTradeDay
  };
  const two_props = {
    ...rev_props, 'isTrading': isTradeTime, 'idxPms': idxParams,
    'productInfo': { productId: productParams.pms.productId, dateType: productParams.pms.dateType, date: _.get(productParams.pms, 'dateToday') }
  };
  return (
    <>
      <NewPageHeader {...header_props}
        onBack={() => props.history?.goBack()}
        onJump={(item) => _goFundManagerListsDetails(item)}
      >
        <RecentTabs
          active={ptab}
          pageKey='products'
          items={allProducts}
          id={productParams.pms.productId}
          onItemChange={(activeKey, itemVal) => { // 点击切换产品，重新赋值productParams数据，并重载页面
            setPtab(activeKey);
            productParams.pms = itemVal;
            _initGetDatas('reload');
          }}
        />
      </NewPageHeader>

      <div className="ProductDetails contentView" id="productDetails" ref={sizeRef}>

        <AssetsHeader assets={allassets} />

        <br />
        <div className='productTab'>
          <Tabs activeKey={tabActive} type='card' onChange={(value) => {
            if (value === MAIN_RATE) {
              _cutDateType(newTabs);
            } else {
              setInter(undefined);
            }
            setTabActive(value);
          }}>
            <TabPane tab={<DetailTabsIcon keys={MAIN_RATE} />} key={MAIN_RATE}>
              <MainRateCharts
                {...main_props}
                cutDateType={_cutDateType}
                checkDayRate={(key, date) => _cutDateType(key, date)}
                indexChange={(idx) => {
                  productParams.symbol = idx;
                  _showIndexKline(idx);
                }}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='RATE_K' />} key='RATE_K'>
              <KRateCharts {...pos_res_pros}
                pageKey='product'
                active={tabActive}
                indexSymbol={productParams.symbol}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION' />} key='CONTRIBUTION'>
              <SubContributionCharts {...rev_props}
                glbTimeArray={productParams.pms.contributionTime ?? [TODAY, TODAY]}
                upTimeCount={count7time}
                time2={glbDate}
                onTimeChange={onProfitTimeChange}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION2' />} key='CONTRIBUTION2'>
              <ContributionCharts   {...rev_props}
                pageKey='product'
                productId={productParams.pms.productId}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='DISTRIBUTION' />} key='DISTRIBUTION'>
              <DisplayCharts {...rev_props}
                globalDate={glbDate}
                upTimeCount={count7time}
              />
            </TabPane>
            <TabPane tab={<DetailTabsIcon keys='PRO_LEVEL' />} key='PRO_LEVEL'>
              <LevelCharts {...rev_props} />
            </TabPane>
            {!isVisitor && <TabPane tab={<DetailTabsIcon keys='PRO_COM' />} key='PRO_COM'>
              <CompareChartsTwo {...two_props} />
            </TabPane>}
            {!isVisitor && <TabPane tab={<DetailTabsIcon keys='BT_COM' />} key='BT_COM'>
              <BtCompareCharts {...rev_props}
                hasFc={isFcLogin}
              />
            </TabPane>}
            {!isVisitor && <TabPane tab={<DetailTabsIcon keys='EXTRA_COM' />} key='EXTRA_COM'>
              <ExtraCompare {...two_props} />
            </TabPane>}
          </Tabs>
        </div>

        <Card>
          <AccountTable
            listData={listData}
            visitor={isVisitor}
            centraType={centraLogin}
            getCommission={(id) => _getCommission(id)}
            goBack={() => _get()}
            goFundManagerListsDetails={(record) => _goFundManagerListsDetails(record)}
            delSubAccount={(record) => _delSubAccount(record)}
          />
          {!isVisitor && <NewAccModal pmData={productParams.pms} />}
        </Card>

        <br />
        {/* 
          新增产品层面持仓和交易，两个组件可通用但保持以子账户代码优先
       */}
        {isValidArray(productParams.riseList) && <Alert banner
          message={<Marquee pauseOnHover gradient={false} speed={35}>
            {productParams.riseList.map((r, ri) => <span key={r.name + ri} style={{ marginRight: 16 }}>{`${r.name} ${r.formatDate}`}</span>)}
            <span style={{ marginRight: 16 }}>||</span>
          </Marquee>}
        />}

        <PositionTable
          {...pos_res_pros}
          rises={_.uniq(productParams.riseList.map(n => n.name))}
        />
        <br />
        <TradeRecord
          {...pos_res_pros}
          visitor={isVisitor}
          onTimeChange={(date) => { }} //子账户需输出时间，产品不需要，空操作
        />
      </div>

      <CommissionModal
        visible={_.get(commisVisible, 'show', false)}
        datas={_.get(commisVisible, 'data', [])}
        onCancel={() => {
          setCommisVisible({ visible: false, data: [] })
        }}
      />

      <PhoneVerify
        vCount={vCount}
        visible={phoneVisible}
        phoneHandleCancel={() => setphoneVisible(false)}
        gobackValues={_verifyDelete}
        getCode={getVerifPhoneCode}
        userPhone={userPhone}
      />
    </>
  )
}