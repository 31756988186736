import React, { useState } from 'react';
import { Card, Row, DatePicker, Input, Table, Space, Tag, Button, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { createBasicColums, singleColumns } from '@/utils/utils';
import moment from 'moment';
import _ from 'lodash';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { Search } = Input;
const col_list_1 = [
  ['委托编号', 'clientOrderId', 130],
  ['股票代码', 'stockCode', 80],
  ['股票名称', 'stockName', 100],
]
const col_list_2 = [
  ['委托数量', 'quantity', 80],
  ['成交数量', 'cumQty', 80],
  ['委托均价', 'price', 80],
  ['成交均价', 'avgPrice', 80],
]
const TradeList = ({ datas, clientOrderIdList, onCancel, onTimeSelect, onSelectRecord }) => {
  const [currentPageBottom, setcurrentPageBottom] = useState(1);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const columns = [
    {
      title: '序号',
      width: 70,
      render: (text, record, index) => { return (currentPageBottom - 1) * 10 + index + 1 },
    },
    ...createBasicColums(col_list_1),
    {
      ...singleColumns(['委托时间', 'commissionTime', 120]),
      sorter: (a, b) => { return a.timeKey - b.timeKey },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    },
    ...createBasicColums(col_list_2, [3, 'strongPrice']),
    {
      ...singleColumns(['收益(元)', 'profit', 80, 0, ['no_default']], 'updown'),
    },
    {
      ...singleColumns(['买卖方向', 'side', 80]),
      render: (text) => {
        return text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
      }
    },
    {
      ...singleColumns(['状态', 'orderStatus', 80]),
      render: (text, record, index) => {
        return (text === '完成' ? <span style={{ color: 'green' }}>{text}</span> : text === '未完成' ? <span style={{ color: 'red' }}>{text}</span> : <span style={{ color: 'blue' }}>{text}</span>)
      }
    },
    {
      ...singleColumns(['备注', 'remarks', 140]),
      render: (text) => <div style={{ width: 140 }}>
        <Paragraph>{text || ''}</Paragraph>
      </div>
    },
  ];
  // 如果有拆单数据，需要在拆单子表格中进行撤单
  const columnsBreak = [
    {
      ...singleColumns(['操作', 'opton', 120, 'center']),
      fixed: 'right',
      render: (text, record) => {
        let list = clientOrderIdList;
        return list.indexOf(_.get(record, 'clientOrderId', null)) === -1 ? (
          _.get(record, 'orderStatus', '') === '成交中' && _.get(record, 'isBreak') === false &&
          <Button type="primary" size='small' danger onClick={() => onCancel(record)}>
            撤单
          </Button>
        ) : (
          _.get(record, 'orderStatus', '') === '成交中' &&
          <Button type="primary" disabled={true}>撤单</Button>
        )
      }
    },
  ];
  const columnsBreak2 = [
    {
      ...singleColumns(['操作', 'opton', 120, 'center']),
      fixed: 'right',
      render: (text, record) => {
        let list = clientOrderIdList;
        return list.indexOf(_.get(record, 'clientOrderId', null)) === -1 ? (
          _.get(record, 'orderStatus', '') === '成交中' &&
          <Button type="primary" size='small' danger onClick={() => onCancel(record)}>
            撤单
          </Button>
        ) : (
          _.get(record, 'orderStatus', '') === '成交中' &&
          <Button type="primary" disabled={true}>撤单</Button>
        )
      }
    },
  ];

  const expandedRowRender = (fatRecord) => {
    return (
      <Table
        rowKey="clientOrderId"
        dataSource={_.get(fatRecord, 'breakList', [])}
        columns={_.concat(columns, columnsBreak2)}
        showHeader={false}
        pagination={false}
        size='small'
        border={false}
      />
    )
  }

  return (
    <Card
      title="交易记录"
      bordered={true}
      size='small'
      extra={
        !isMobile && <Row justify="end" align="middle">
          <span style={{ marginRight: 10 }}>选择日期:</span>
          <RangePicker onChange={onTimeSelect} />
          <Search placeholder="请输入股票代码/名称" allowClear enterButton style={{ width: 230, marginLeft: 10 }} onSearch={onSelectRecord} />
        </Row>
      }>
      {isMobile && <Row justify="end" align="middle" style={{ marginBottom: 10 }}>
        <Space direction='vertical' align='end'>
          <span style={{ marginRight: 10 }}>选择日期:</span>
          <RangePicker onChange={onTimeSelect} />
          <Search placeholder="请输入股票代码/名称" allowClear enterButton style={{ width: 230, marginLeft: 10 }} onSearch={onSelectRecord} />
        </Space>
      </Row>}

      <Table
        rowKey="clientOrderId"
        dataSource={datas}
        columns={_.concat(columns, columnsBreak)}
        scroll={{ x: 1340 }}
        bordered
        size='small'
        pagination={{
          defaultPageSize: 10,
          onChange: e => { setcurrentPageBottom(e) }
        }}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          rowExpandable: (record) => _.size(record.breakList) > 0,
        }}
      />
    </Card>
  )
}

export default TradeList;