import { COMMON_CHART } from './chartsInfoUtils';
import moment from 'moment';
import _ from 'lodash';

const TODAY_AVAERAGE = '今仓平均';
const DEFAULT_CARRY = 5;
const renderFloats = (val, mul, pos = DEFAULT_CARRY) => _.round(parseFloat(val) * mul, pos);

const createChartLineColor = (color, lineObj = {}) => { return { lineStyle: { color: color, ...lineObj }, itemStyle: { color: color } } }
const PRE_LIST = [
  { name: '前仓1', key: 1, other: createChartLineColor('#e8caa8') },
  { name: '前仓2', key: 2, other: createChartLineColor('#e2bcba') },
  { name: '前仓3', key: 3, other: createChartLineColor('#d5b080') },
  { name: '前仓4', key: 4, other: createChartLineColor('#c396b8') },
  { name: '前仓5', key: 5, other: createChartLineColor('#b4bd8d') },
  { name: TODAY_AVAERAGE, key: 6, other: createChartLineColor('#004296') }
];
const renderFloatsAdd = (a = 0, b = 0, bPos = 100) => {
  let aa = parseFloat(a);
  let bb = parseFloat(b) * bPos; //因创建完第一步数据是 *100 的，所以累加时要将 b 数据可以进行乘法运算。
  return _.round((aa + bb), DEFAULT_CARRY)
};
const renderFloatsArray = (array = [], mul = 100) => (
  array && _.size(array) > 0 ? array.map(n => _.round(parseFloat(n) * mul, DEFAULT_CARRY)) : []
);
const calDividerAndMulti = (array = [], divs = []) => {
  // divs: 除数数组，应与数据array相等，每个点的除数不同；type: div = 需要除数 pre=前仓则不需要
  let sum = 0;
  return _.size(array) > 0 && _.size(array) === _.size(divs) ? array.map((n, i) => {
    const dVal = renderFloats(n / _.get(divs, `[${i}].value`, 1), 1);
    let finalv = 0;
    if (dVal !== 0 && sum !== 0) {
      let calval = (1 + dVal) * sum;
      sum = calval;
      finalv = renderFloats(calval - 1, 100);
    } else if (dVal !== 0 && sum === 0) {
      sum = 1 + dVal;
      finalv = renderFloats(dVal, 100);
    } else {
      finalv = dVal;
    }
    return finalv;
  }) : []
};
const calDividerAndMulti2 = (array = [], divs = []) => {
  return _.size(array) > 0 && _.size(array) === _.size(divs) ? array.map((n, i) => {
    return renderFloats(n / _.get(divs, `[${i}].value`, 1), 1)
  }) : []
};
// dailyStock 提取当日股票及数量
const handleStockArray = (stocks = {}, datess = [], timess = [], day = false) => {
  // datess 已选日期；timess 数据x轴数据，与数据array大小相同；day是否是跨天数据
  let final = [];
  let sizes = [];
  if (!day) {
    if (_.size(stocks) > 0) {
      // 实时计算，每个时间点返回size值
      Object.keys(stocks).map(datename => {
        if (datess.indexOf(datename) !== -1) {
          final = _.concat(final, stocks[datename]);
        }
      })
      // 实时value统一赋值为汇总值
      return { stocks: _.uniq(final), counts: timess.map(t => { return { key: t, value: _.size(final) } }) }
    }
  }
  if (_.size(stocks) > 0 && _.size(datess) > 0) {
    // 根据已选择日期进行汇总，return的array数量与最后汇总array大小相同；
    //key值存储是否已选日期，!==-1则是已选，value是做连乘时需要除的除数
    let count = 0;
    timess.map((t, idx) => {
      const dateVals = _.get(stocks, t, []);
      const tindex = _.findIndex(datess, o => o === t);
      if (tindex !== -1) { // 已选日期进行汇总股票数量
        final = _.uniq(_.concat(final, dateVals));
      }
      if (tindex !== -1 && count === 0) { // 找到第一位日期数据，value等于当日股票数
        sizes[idx] = { key: tindex, value: _.size(dateVals) };
        count = 1;
      } else if (tindex !== -1 && count > 0) { //第二位及以后数据,value进行累加
        sizes[idx] = { key: tindex, value: _.size(final) };
        count = count + 1;
      } else if (tindex === -1 && count > 0) { // 如果日期不是连续的，如13日和18日，那么中间日期的value会用13日及上一个选择日期的数据
        sizes[idx] = { key: tindex, value: _.size(final) };
      } else { //其他时间点赋值1，因 0 不能作为除数；
        sizes[idx] = { key: tindex, value: 1 };
      }
    })
  }
  return { stocks: final, counts: sizes }; // 返回数组 含股票和数量array
}

// 当日收益率最后一位 =0 时，用前一位数据
function handleLastNoZero(array = []) {
  const getLast = _.last(array);
  const lastIdx = _.size(array) - 1;
  if (getLast === 0 && lastIdx > 0) {
    array[lastIdx] = array[lastIdx - 1]
  }
  return array;
}
// 时间从之前到之后的排序
function orderTimeArray(type, val) {
  if (type === 'obj' && _.isObject(val)) {
    let timeObjArray = [];
    for (const key in val) {
      timeObjArray.push({ name: key, timeKey: moment().diff(moment(key), 'd') });
    }
    return _.orderBy(timeObjArray, ['timeKey'], ['desc']);

  } else if (type === 'array' && _.isArray(val)) {
    let timeArray = []; // 返回数组，去重操作
    let newVal = _.uniq(val);
    timeArray = newVal.map(v => { return { name: v, timeKey: moment().diff(moment(v), 'd') } })
    timeArray = _.orderBy(timeArray, ['timeKey'], ['desc'])
    return timeArray.map(t => t.name);
  }
  return [];
}

// EchartInfo6 初始 options
const upColor = '#ec0000';
const upBorderColor = '#ec0000';
const downColor = '#00da3c';
const downBorderColor = '#00da3c';
const OPTIONS_KLINE = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    formatter: function (params) {
      //console.log(params)
      let maArray = [];
      let maString = '';
      let k_data = _.get(params, '[0]', {});
      if (_.size(params) > 1) {
        maArray = _.drop(params);
        maArray.map(n => {
          maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
        })
      }

      return '开盘: ' + (k_data.value[1] || '') + '<br>' +
        '收盘: ' + (k_data.value[2] || '') + '<br>' +
        '最高: ' + (k_data.value[3] || '') + '<br>' +
        '最低: ' + (k_data.value[4] || '') + '<br>' + maString
    }
  },
  legend: {
    // data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30']
    data: ['分钟K']
  },
  grid: {
    left: '7%',
    right: '7%',
    bottom: '15%'
  },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: [{
    name: '股价',
    scale: true,
    splitArea: {
      show: true
    }
  }, {
    type: 'value',
    name: '波幅(%)',
    position: 'right',
    axisLine: {
      show: true,
      symbol: ['none', 'arrow'],
      symbolSize: [8, 8],
      symbolOffset: 7,
    },
    splitArea: {
      show: true
    },
    splitLine: {
      show: false
    },
    min: 0,
    max: 1,
  }],
  dataZoom: [
    { type: 'inside', start: 0, end: 100 },
    { show: true, type: 'slider', top: '90%', start: 0, end: 100 }
  ],
  series: [
    {
      name: '分钟K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      },
      markPoint: {
        symbol: 'pin',
        // symbolSize:50,
        data: []
      }
    },
    {
      name: 'MA5',
      type: 'line',
      data: [],
      smooth: true,
      showSymbol: false,
      lineStyle: {
        width: 1,
        color: '#f8b318',
        opacity: 0.7
      }
    },
  ]
};


const BT_COMPARE_OPTIONS = {
  'rate': {
    grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
        onZero: false,
      },
      data: []
    },
    yAxis: {
      type: 'value',
      splitArea: {
        show: true
      },
      splitLine: {
        show: false
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
      }
    },
    series: [],
    tooltip: { trigger: 'axis' },
  },
  'avg': {
    grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
    legend: { data: ['收益差', 'MA60', 'MA20', 'MA10', 'MA5'] },
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category', data: [], },
    yAxis: [
      { type: 'value', splitLine: { show: false }, splitNumber: 3, },
      { type: 'value', interval: 0.05 },// 轴拆分线间隔大小,
    ],
    series: [
      {
        name: '',
        type: 'bar',
        yAxisIndex: 0,
        label: { show: true, },
        data: [],
      }
    ]
  },
  'diffRate': {
    grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
    tooltip: { trigger: 'axis' },
    xAxis: { type: 'category', data: [] },
    yAxis: { type: 'value', splitNumber: 4, minInterval: 0.5 },
    series: [
      {
        name: '收益差',
        type: 'bar',
        label: { show: true, position: 'inside' },
        data: [],
      }
    ],
  },
}

const OPTIONS_KLINE_RATE = {
  tooltip: {
    trigger: 'axis',
    axisPointer: { type: 'cross' }
  },
  legend: { data: ['收益K', '指数K'] },
  grid: { left: '5%', right: '5%', bottom: '10%', top: '10%' },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: {
    name: '收益率',
    scale: true,
    splitArea: { show: true }
  },
  // dataZoom: [
  //   { type: 'inside', start: 0, end: 100 },
  //   { show: true, type: 'slider', bottom: '5%', start: 0, end: 100, height: 12 }
  // ],
  series: [
    {
      name: '收益K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      },
    },
    {
      name: '指数K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: '#ff9882',
        color0: '#adb880',
        borderColor: '#ff9882',
        borderColor0: '#adb880'
      },
    },
  ]
}

const EXTRA_COMPARE = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  legend: { data: [] },
  xAxis: {
    type: 'category',
    ...COMMON_CHART.rateX
  },
  yAxis: [{ // 对比指数使用右侧Y轴，成交量作为右侧第二Y轴
    type: 'value',
    ...COMMON_CHART.rateY,
    position: 'left',
  }],
  series: [],
  tooltip: { trigger: 'axis' },
};

export {
  TODAY_AVAERAGE,
  PRE_LIST,
  OPTIONS_KLINE,
  OPTIONS_KLINE_RATE,
  BT_COMPARE_OPTIONS,
  EXTRA_COMPARE,
  // 方法
  createChartLineColor,
  renderFloatsAdd, renderFloatsArray,
  calDividerAndMulti, calDividerAndMulti2,
  handleStockArray,
  handleLastNoZero,
  orderTimeArray,
}