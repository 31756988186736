import axios from 'axios';
import { message } from 'antd';
import { store } from '@/store/mobx';
import { globalUrl } from './global';
import _ from 'lodash';

// axios.defaults.baseURL = '/api';                           
// axios.defaults.baseURL = 'http://47.114.78.153:9111';     // 生产
// axios.defaults.baseURL = 'http://47.114.118.216:3000/';
// axios.defaults.baseURL = 'http://47.114.118.216:8850/';
axios.defaults.baseURL = globalUrl;   // 迁移 

axios.interceptors.request.use(config => {
    // 在发出请求前做点什么
    const token = localStorage.getItem('token');
    const tokenFC = localStorage.getItem('tokenFC');
    // console.log(8888, token, tokenFC);
    if (token) {
        config.headers.authorization = _.includes(config.url, '/fc') ? tokenFC : token;
        // config.headers.authorization = token;
    }
    return config
}, error => {
    // 处理请求错误
    return Promise.reject(error);
})

export default axios;

// 响应拦截器
axios.interceptors.response.use(response => {
    // 在发出响应前做点什么
    // response = response.data;
    if (response.data.code === "401") {
        message.error('登录已超时，请重新登录');
        store.cancelLogin();
    };
    return response.data;

}, error => {
    // 针对特定的http状态码进行处理
    if (error.response) {
        switch (error.response.status) {
            // case 200:
            //     return message.error('服务器成功返回请求的数据。');
            case 201:
                return message.error('新建或修改数据成功。');
            case 202:
                return message.error('一个请求已经进入后台排队（异步任务）。');
            case 204:
                return message.error('删除数据成功。');
            case 400:
                return message.error('发出的请求有错误，服务器没有进行新建或修改数据的操作。');
            case 401:
                // return message.error('请先登录');
                return store.cancelLogin();
            case 403:
                return message.error('用户得到授权，但是访问是被禁止的。');
            case 404:
                return message.error('发出的请求针对的是不存在的记录，服务器没有进行操作。');
            case 406:
                return message.error('请求的格式不可得。');
            case 410:
                return message.error('请求的资源被永久删除，且不会再得到的。');
            case 422:
                return message.error('当创建一个对象时，发生一个验证错误。');
            case 500:
                return message.error('服务器发生错误，请检查服务器。');
            case 502:
                return message.error('网关错误。');
            case 503:
                return message.error('服务不可用，服务器暂时过载或维护。');
            case 504:
                return message.error('网关超时。');
            case 2002:
                return message.error('请先实名认证');
            case 2003:
                return message.error('模拟交易');
            default:
                return error;
        }
    }
});