import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Select, Spin, Space, message } from 'antd';
import { fundManagerAnalysis } from '@/api/workbench';
import * as echarts from 'echarts';
import { NewRangePicker } from '@/view/common/widgets';
import { COUNTING_OPTIONS } from '../list_utils';
import moment from 'moment';
import _ from 'lodash';

const RANGE_TIME = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
const { Option } = Select;

const TransCharts = ({
  indexList = [],
  subAccountId = 0,
  active = '1',
  todayFormat = '',
  isReverse = false,
  newProps = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [tradeCountData, setTradeCountData] = useState({ timeList: [], count: [], amount: [] });
  const [currentParams, setCurrentParams] = useState({});
  const [update, setUpdate] = useState(0);
  const [selectTime, setselectTime] = useState(RANGE_TIME);
  const [selectStocks, setselectStocks] = useState(_.get(indexList, '[0].value', ''));
  const [option, setoption] = useState(COUNTING_OPTIONS);

  useEffect(() => {
    // console.log('更新', active)
    if (active === 'COUNTING') {
      let timeArray = [];
      let stocks = 'symbol' in currentParams ? currentParams.symbol : _.get(indexList, '[0].value', null)
      if ('startTime' in currentParams) {
        timeArray = [currentParams.startTime, currentParams.endTime];
      } else {
        timeArray = [todayFormat, todayFormat];
      }

      transNumber(stocks, timeArray)
    }
  }, [active])
  //交易次数和股票数
  async function transNumber(value, date) {
    setLoading(true)

    let params = {
      type: 6,
      startTime: date[0],
      endTime: date[1],
      subAccountId: subAccountId,
      symbol: value
    }
    setCurrentParams(params);
    const data = await fundManagerAnalysis(params, isReverse ? 'FC' : 'NOR');
    setLoading(false)
    if (_.get(data, 'code', '') === '400') {
      message.info(_.get(data, 'data', '无数据'))
    }
    if (_.get(data, 'code', '') === '200') {
      let arr = data.data.pointList;
      let brr = [];
      let crr = [];
      if (arr.length !== 0) {
        arr.map(item => {
          brr.push(item[0]);
          crr.push(item[1]);
          return item;
        })
      }
      setTradeCountData({
        timeList: _.get(data, 'data.timeList', []),
        count: brr,
        amount: crr
      });
      setUpdate(update + 1);
    }
  }

  useEffect(() => {
    let myChart = newProps.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('list_count_chart'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option);
    newOption.xAxis.data = _.get(tradeCountData, 'timeList', []);
    newOption.series[0].data = _.get(tradeCountData, 'count', []);
    newOption.series[1].data = _.get(tradeCountData, 'amount', []);
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);

  return (
    <Row style={{ backgroundColor: '#fff', padding: 1 }}>
      <Col span={24}>
        <Spin spinning={loading}>
          <Card bordered={false} title='交易次数和股票数' extra={
            <Row justify='end'>
              <Space>
                <span>股票:</span>
                <Select
                  onChange={(value) => {
                    setselectStocks(value);
                    transNumber(value, selectTime);
                  }}
                  value={selectStocks}
                  dropdownMatchSelectWidth={false}
                  style={{ width: 100 }}
                >
                  <Option key={0} value={''}>全部</Option>
                  {
                    indexList.length !== 0 &&
                    indexList.map((item, index) => {
                      return (
                        <Option
                          key={index + 1}
                          value={item.value}
                        >
                          {item.title}
                        </Option>
                      )
                    })
                  }
                </Select>
                <span>选择日期:</span>
                <NewRangePicker
                  dates={selectTime}
                  onSelect={(dateString) => {
                    setselectTime(dateString);
                    transNumber(selectStocks, dateString);
                  }}
                />
              </Space>
            </Row>
          }>
            <div
              id="list_count_chart"
              style={{ width: '100%', height: 450 }}
            />
          </Card>
        </Spin>
      </Col >
    </Row >
  )
};

export default TransCharts;