import React, { useState } from 'react';
import { Button, Form, Select, Modal, message } from 'antd';
import { useLocalObservable, Observer } from 'mobx-react';
import { getTradersSubAccount, addSubAcc } from '@/api/workbench';
import { store } from '@/store/mobx';

const { Option } = Select;


export default function AddSubAccount(props) {
    const mobxStore = useLocalObservable(() => store);
    const [subList] = useState(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
    const [visible, setvisible] = useState(false);
    // const [visibles, setvisibles] = useState(false);
    const [accountList, setccountList] = useState([]);
    const [subAccountList, setsubAccountList] = useState([]);

    const [form] = Form.useForm();

    function handleCancel() {
        setvisible(false);
        form.resetFields();
        setsubAccountList([]);
    }
    function handleCancels() {
        setvisible(false);
        form.resetFields();
        setsubAccountList([]);
    }
    // 选择产品
    function _handleChangeProduct(key) {
        setccountList(subList[key].children);
    }
    // 选择证券账户
    async function _handleChangeAcc(value) {
        props.record.productNameIdDTOList.length !== 0 && props.record.productNameIdDTOList.map(item => {
            if (item.securitiesAccountId === value) {
                message.error('交易员管理的子账户下已存在相同的证券账户')
                handleCancel();
                return item;
            }
            return item;
        }
        )
        const data = await getTradersSubAccount({ securitiesAccountId: value });
        if (data.code === '200') {
            setsubAccountList(data.data.subAccountInfoResultList);
            let arr = data.data.subAccountInfoResultList;
            if (arr.length === 0) {
                message.info('该证券账户下没有可用的子账户');
            }
        }
    }
    async function handleOk() {
        if (subAccountList.length === 0) {
            message.error('证券账户下没有可用的子账户');
            handleCancel();
            return
        }
        const values = await form.validateFields();
        // console.log(props.record.traderId);
        if (values.subAccount.length === 0) return message.error('请选择要添加的交易子账户');
        const data = await addSubAcc({ subAccountId: values.subAccount, traderId: props.record.traderId });
        if (data.code === '200') {
            message.success(data.message);
            props.goBack();
            handleCancel();
        } else {
            message.error(data.message)
        }
    }

    return (
        <Observer>

            {() => (
                <>

                    <Button type="link" onClick={() => setvisible(true)} >添加子账户</Button>


                    <Modal
                        title="添加子账户"
                        okText="添加"
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancels}
                    >

                        <Form
                            initialValues={{}}
                            form={form}
                        >
                            <Form.Item
                                label="产品"
                                name="type"
                                rules={[{ required: true, message: '请输入子账户名称!' }]}
                            >
                                <Select
                                    placeholder="选择账户"
                                    style={{ width: 300 }}
                                    onChange={_handleChangeProduct}
                                >
                                    {
                                      subList && subList.length !== 0 &&
                                        subList.map((item, key) => {
                                            return (
                                                <Option
                                                    key={item.productId}
                                                    value={key}
                                                >
                                                    {item.productName}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="证券账户"
                                name="type1"
                                rules={[{ required: true, message: '选择证券账户' }]}
                            >
                                <Select
                                    placeholder="选择证券账户"
                                    style={{ width: 300 }}
                                    onChange={_handleChangeAcc}
                                >
                                    {
                                        accountList.map(item => {
                                            return (
                                                <Option
                                                    key={item.securitiesAccountId}
                                                    value={item.securitiesAccountId}
                                                >
                                                    {item.securitiesAccountName}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            {
                                subAccountList.length !== 0 &&
                                <Form.Item
                                    label="交易子账户"
                                    name="subAccount"
                                    rules={[{ required: true, message: '选择交易子账户' }]}
                                >
                                    <Select placeholder="选择交易子账户" style={{ width: 300 }} >
                                        {
                                            subAccountList.map(item => {
                                                return (
                                                    <Option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            }

                        </Form>
                    </Modal>
                </>
            )}
        </Observer>
    )
}