import React, { useState, memo } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  List,
  Select,
  Form,
  Divider,
  DatePicker,
  message,
  Input,
  Typography,
  Space,
} from 'antd';
import { useMount, useUnmount } from 'ahooks';
import { showTraderPerformance, showTraderTrends } from '@/api/workbench';
import { visShowTraderTrends } from '@/api/reviewApi';
import { FileOutlined } from '@ant-design/icons';
import { autoCol, validateResponse } from '@/utils/utils';
import { isValidArray } from '@/utils/utils2';
import { useReactive } from 'ahooks';
import axios from 'axios';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');

const Traders = ({ products = [], visitor = false }) => {
  const [form] = Form.useForm();
  const traderState = useReactive({ date: [TODAY, TODAY], name: '' })
  const [tarderPerform, settarderPerform] = useState([]);
  const [down, setdown] = useState([]);
  const [result, setresult] = useState('all');
  const [list, setlist] = useState([]);
  let isUnmont = false;

  useMount(() => {
    if (!visitor) {
      showTrader();
    }
    showTrendsFirst();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  async function showTrader(value) {
    let params = {
      type: result,
      search: value
    }

    if (!value) {
      params = { type: 'all' }
    }

    const data = await showTraderPerformance(params);
    if (validateResponse(data, isUnmont)) {
      if (data.data !== null) {
        settarderPerform(data.data)
      }
    }
  }

  async function showTrendsFirst() {
    let params = {
      beginDate: traderState.date[0],
      endDate: traderState.date[1],
    }
    const res = visitor ? await visShowTraderTrends(params) : await showTraderTrends(params);
    if (validateResponse(res, isUnmont)) {
      setlist(_.get(res, 'data', []));
    }
  }

  async function showTrends() {
    const values = await form.validateFields();
    let params = {
      beginDate: traderState.date[0],
      endDate: traderState.date[1],
      tradeDirection: values.tradeDirection,
      productName: values.productName,
      traderName: traderState.name
    }
    const res = visitor ? await visShowTraderTrends(params) : await showTraderTrends(params);
    if (validateResponse(res, isUnmont)) {
      setlist(_.get(res, 'data', []));
    }
  }

  function exportToExcel() {
    var data = [];
    list.map(item => {
      var a = {};
      var arr = item.split('，');
      a.name = arr[0].substr(0, arr[0].indexOf('委'));
      a.direction = arr[0].slice((arr[0].indexOf('了') - 2), arr[0].indexOf('了'));
      // a.name = arr[0].substr(0,arr[0].indexOf('买')) || arr[0].substr(0,arr[0].indexOf('卖'));
      a.num = arr[0].slice(arr[0].indexOf('了') + 1, arr[0].indexOf('股') + 1);
      a.ming = arr[0].slice(arr[0].indexOf('股') + 1, arr[0].indexOf('（'));
      a.code = arr[0].slice(arr[0].indexOf('股') + 1).replace(/[^\d]/g, ' ');
      a.entrustPrice = arr[1].slice(arr[1].indexOf(':') + 1);
      a.clinchPrice = arr[3].slice(arr[3].indexOf(':') + 1);
      a.clinchAmount = arr[4].slice(arr[4].indexOf(':') + 1);
      if (arr[0].indexOf('卖出') !== -1) {
        a.realRate = arr[5].substr(arr[5].indexOf('：') + 1);
        a.stockRate = arr[6].substr(arr[6].indexOf('：') + 1);
        a.excessRate = arr[7].substr(arr[7].indexOf('：') + 1);
        a.productName = arr[8].substr(0, arr[8].indexOf('\t') + 1);
        a.securitiesAccountName = arr[8].substr(arr[8].indexOf('\t') + 1, arr[8].indexOf('\t'));
        a.time = arr[8].substr(arr[8].lastIndexOf('\t') + 1);
      } else {
        a.realRate = null;
        a.stockRate = null;
        a.excessRate = null;
        a.productName = arr[5].substr(0, arr[5].indexOf('\t') + 1);
        a.securitiesAccountName = arr[5].substr(arr[5].indexOf('\t') + 1, arr[5].indexOf('\t'));
        a.time = arr[5].substr(arr[5].lastIndexOf('\t') + 1);
      }
      // a.ming = arr[0].slice(arr[0].indexOf('手')+1).replace(/[0-9]/g, '');
      data.push(a);
      return item;
    })
    var option = {};
    let dataTable = [];
    if (data) {
      for (let i in data) {
        if (data) {
          let obj = {
            '姓名': data[i].name,
            '方向': data[i].direction,
            '数量': data[i].num,
            '名称': data[i].ming,
            '代码': data[i].code,
            '委托价': data[i].entrustPrice,
            '成交均价': data[i].clinchPrice,
            '成交金额': data[i].clinchAmount,
            '日内实际收益率': data[i].realRate,
            '日内个股收益率': data[i].stockRate,
            '超额收益率': data[i].excessRate,
            '产品名称': data[i].productName,
            '证券账户名称': data[i].securitiesAccountName,
            '时间': data[i].time
          }
          dataTable.push(obj);
        }
      }
    }
    option.fileName = '交易员动态'  //导出的Excel文件名
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: ['姓名', '方向', '数量', '名称', '代码', '委托价', '成交均价', '成交金额', '日内实际收益率', '日内个股收益率', '超额收益率', '产品名称', '证券账户名称', '时间'],
        sheetHeader: ['姓名', '方向', '数量', '名称', '代码', '委托价', '成交均价', '成交金额', '日内实际收益率', '日内个股收益率', '超额收益率', '产品名称', '证券账户名称', '时间'],
      }
    ]
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }
  function selectProduct(e) {
    let arr = [];
    if (e !== 'all') {
      arr.push(e);
      setdown(arr);
    } else {
      if (products.length !== 0) {
        products.forEach(item => {
          arr.push(item.productName);
        })
      }
      setdown(arr);
    }
  }
  async function getLoad() {
    if (!down[0]) {
      let arr = [];
      if (products.length !== 0) {
        products.forEach(item => {
          arr.push(item.productName);
        })
      }
      axios.post(global.api + '/fundManager/exportTraderPerformance', {
        productList: arr,
      }, { responseType: "blob" }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = '文件.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(err => {
        console.log(err);
      })
    } else {
      axios.post(global.api + '/fundManager/exportTraderPerformance', {
        productList: down,
      }, { responseType: "blob" }).then(res => {
        if (res.type !== 'application/json') {
          const url = window.URL.createObjectURL(new Blob([res],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const link = document.createElement('a');
          link.style.display = 'none';
          link.href = url;
          link.download = '文件.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          message.error("文件下载失败");
          return;
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const bothCardStyle = { minHeight: 590 };
  const stockOption = [{ label: '股票', value: 'stock' }, { label: '基金', value: 'fund' }, { label: '期货', value: 'futures' }]
  const resultOption = [{ label: '全部', value: 'all' }, { label: '交易员', value: 'trader' }, { label: '产品', value: 'product' }]
  const productOption = isValidArray(products) ? products.map(n => <Option key={_.get(n, 'productId', 0)} value={_.get(n, 'productName', '')}>{_.get(n, 'productName', '')}</Option>) : [];
  return (
    <>
      <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
        <Col {...autoCol([12])}>
          <Card style={bothCardStyle} bodyStyle={{ padding: 16 }}>
            <Row style={{ marginBottom: 12 }}>
              <Col span={12}>
                <Text strong style={{ fontSize: 15 }}>交易员动态</Text>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button size='small' onClick={exportToExcel} icon={<FileOutlined />}>导出</Button>
              </Col>
            </Row>
            <div style={{ marginBottom: 12, fontSize: 15 }}></div>
            <div>
              <Row justify="start" align="middle">
                <Space>
                  <Text>品种:</Text>
                  <Select defaultValue="股票" size='small'>
                    {stockOption.map(n => <Option key={n.value} value={n.value}>{n.label}</Option>)}
                  </Select>
                  <Text>日期:</Text>
                  <RangePicker
                    format="YYYY-MM-DD"
                    size='small'
                    defaultValue={[moment(moment()), moment(moment())]}
                    onCalendarChange={(date, dateStrings, info) => {
                      const calType = _.get(info, 'range', '');
                      if (calType === 'end') {
                        traderState.date = dateStrings;
                        showTrader('updateTime', dateStrings)
                      }
                    }}
                  />
                </Space>
              </Row>
              <Row justify="start" align="middle">
                <Form form={form} size='small' name="control-hooks" layout='inline' initialValues={{ productName: '', tradeDirection: '' }}>
                  <Form.Item label="操作" name='tradeDirection' style={{ marginRight: 10, marginTop: 10 }}>
                    <Select
                      onChange={showTrends}
                    >
                      <Option value="">全部</Option>
                      <Option value="BUY">买入</Option>
                      <Option value="SELL">卖出</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="产品" name='productName' style={{ marginRight: 10, marginTop: 10 }}>
                    <Select
                      onChange={showTrends}
                      dropdownMatchSelectWidth={false}
                    >
                      <Option value="">全部</Option>
                      {productOption}
                    </Select>
                  </Form.Item>
                </Form>
                <span style={{ marginRight: 10, marginTop: 10 }}>交易员:</span>
                <Search
                  placeholder="请输入交易员"
                  allowClear
                  enterButton
                  size='small'
                  style={{ width: 200, marginTop: 10 }}
                  onSearch={value => {
                    traderState.name = value;
                    showTrends();
                  }} />
              </Row>
            </div>
            <Divider />
            <List
              itemLayout="horizontal"
              dataSource={list}
              pagination={{
                defaultPageSize: 5,
                hideOnSinglePage: true
              }}
              renderItem={item => (
                <List.Item>
                  {item}
                </List.Item>
              )}
            />
          </Card>
        </Col>

        <Col {...autoCol([12])}>
          <Card style={bothCardStyle} bodyStyle={{ padding: 16 }}>
            <Row style={{ marginBottom: 22 }}>
              <Col span={12}>
                <Text strong style={{ fontSize: 15 }}>交易员业绩</Text>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  size='small'
                  onClick={getLoad}
                  icon={<FileOutlined />}
                >
                  导出
                </Button>
              </Col>
            </Row>
            <Row>
              <Col {...autoCol([16, 24])}>
                <Space>
                  <Select size='small' style={{ width: 85 }} defaultValue="全部" onChange={setresult} >
                    {resultOption.map(n => <Option key={n.value} value={n.value}>{n.label}</Option>)}
                  </Select>
                  <Search
                    size='small'
                    placeholder="请输入交易员/产品"
                    allowClear
                    enterButton
                    style={{ width: 175 }}
                    onSearch={showTrader}
                  />
                  <Select defaultValue="股票" style={{ width: 86 }} size='small'>
                    {stockOption.map(n => <Option key={n.value} value={n.value}>{n.label}</Option>)}
                  </Select>
                </Space>
              </Col>
              <Col {...autoCol([8, 24])} style={{ textAlign: 'right' }}>
                <Select
                  style={{ width: 175 }}
                  defaultValue='all'
                  size='small'
                  onChange={selectProduct}
                >
                  <Option value="all">全部</Option>
                  {productOption}
                </Select>
              </Col>
            </Row>
            <Divider />
            {
              tarderPerform.length !== 0 &&
              <List
                itemLayout="horizontal"
                dataSource={tarderPerform}
                pagination={{
                  pageSize: 5,
                }}
                renderItem={item => (
                  <List.Item>{item}</List.Item>
                )}
              />
            }
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default memo(Traders);