import React, { useState } from 'react';
import { Table, Button, Tag, Typography, Modal } from 'antd';
import { LineChartOutlined, InsertRowLeftOutlined } from '@ant-design/icons';
import { showTradeDetail } from '@/api/stock';
import { singleColumns, colWidth } from '@/utils/utils';
import { renderOrderStatus } from '@/view/common/Components/list_util';
import { createDatasTimeKey } from '@/utils/utils2';
import _ from 'lodash';

const { Text } = Typography;
// 交易记录表格
const RecordTable = ({
  list = [],
  orderKey = '', // 区分排序 & 反采
  isProduct = false,
  loading = false,
  is_rev = false,

  showBreak,
  showKline
}) => {
  const [details, setDetails] = useState({ 'list': [], 'stock': '' }); // 获取分笔成交记录
  const [show, setShow] = useState(false);
  //获取分笔成交记录
  async function _showTradeDetail(id, stock) {
    const res = await showTradeDetail(id, is_rev ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200') {
      const newList = createDatasTimeKey(_.get(res, 'data', []), 'transactTime', 'fms', 'asc');
      setDetails({ 'list': newList, 'stock': stock });
      setShow(true);
    }
  }

  // 区分分页
  const columnsIndex = [
    {
      ...singleColumns(['序号', 'indexFirst', 50]),
      render: (text, record, index) => index + 1 ,
    },
  ];
  let columns = [
    {
      ...singleColumns(['委托编号', 'clientOrderId', 130]),
      render: (text, record) => {
        const stockInfo = `${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`;
        return <div>
          <Text>{text}</Text>
          <InsertRowLeftOutlined
            style={{ fontSize: 12, color: '#959595', marginLeft: 3, cursor: 'pointer' }}
            onClick={() => _showTradeDetail(text, stockInfo)}
          />
        </div>
      }
    },
    singleColumns(['子账户', 'name', 130]),
    {
      ...singleColumns(['股票', 'stock', 180]),
      render: (text, record) => <div>
        <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>
        {orderKey !== 'hosting' && <LineChartOutlined
          style={{ fontSize: 12, color: '#003e85', marginLeft: 3, cursor: 'pointer' }}
          onClick={() => showKline(record)}
        />}
      </div>,
    },
    {
      ...singleColumns(['委托时间', 'formatDate', 120]),
      sorter: (a, b) => {
        let aTime = new Date(a.commissionTime).getTime();
        let bTime = new Date(b.commissionTime).getTime();
        return orderKey === 'list' ? a.timeKey - b.timeKey : aTime - bTime;
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    singleColumns(['委托数量', 'quantity', 80]),
    singleColumns(['成交数量', 'cumQty', 80]),
    singleColumns(['委托均价', 'price', 80]),
    {
      ...singleColumns(['成交均价', 'avgPrice', 80]),
      render: (text) => <Text strong>{_.round(text, 4)}</Text>,
    },
    {
      ...singleColumns(['收益(元)', 'profit', 100], 'updown'),
      sorter: (a, b) => a.profit - b.profit,
      sortDirections: ['descend', 'ascend'],
    },
    {
      ...singleColumns(['买卖方向', 'side', 90]),
      filters: [
        { text: '买入', value: 'BUY' },
        { text: '卖出', value: 'SELL' },
      ],
      onFilter: (value, record) => record?.side?.includes(value),
      render: (text) => text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
    },
    {
      ...singleColumns(['状态', 'orderStatus', 80]),
      render: (text) => renderOrderStatus(text)
    },
    singleColumns(['备注', 'remarks', 130]),
  ];
  if (!isProduct && orderKey !== 'hosting') {
    columns = _.filter(columns, o => o.key !== 'name');
  }
  // 不需要区分分页
  const columnsIndex2 = [
    {
      ...singleColumns(['序号', 'indexFirst', 80]),
      render: (text, record, index) => index + 1,
    },
  ];
  const columnsBreak = [
    {
      ...singleColumns(['是否拆单', 'isBreak', 90]),
      fixed: 'right',
      render: (text, record) => text ? <Button type='link' size='small' onClick={() => showBreak(record)}>
        拆单
      </Button> : ''
    }
  ];
  const columnsDetail = [
    singleColumns(['编号', 'clientOrderId', 120]),
    singleColumns(['数量', 'tradeQuantity', 80]),
    singleColumns(['价格', 'tradePrice', 80]),
    singleColumns(['金额', 'tradeAmount', 80]),
    {
      ...singleColumns(['时间', 'formatDate', 120]),
      sorter: (a, b) => a.timeKey - b.timeKey,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
    },
  ];

  const expendCol = _.concat(columnsIndex2, columns, columnsBreak);
  const expend_props = { 'showHeader': false, 'pagination': false, 'size': 'small', 'bordered': false };
  const expandedRowRender = (fatRecord) => {
    return (
      <Table
        rowKey="clientOrderId"
        dataSource={_.get(fatRecord, 'otherRecord', [])}
        columns={expendCol}
        scroll={{ x: colWidth(expendCol) }}
        {...expend_props}
      />
    )
  }

  const modal_props = { 'visible': show, 'title': null, 'footer': null, 'width': 610 }
  const newColumns = _.concat(columnsIndex, columns, columnsBreak);
  return <>
    <Table
      rowKey="clientOrderId"
      dataSource={list}
      columns={newColumns}
      scroll={{ x: colWidth(newColumns), y: 580 }}
      bordered
      size='small'
      loading={loading}
      expandable={{
        expandedRowRender,
        rowExpandable: (record) => _.size(record.otherRecord) > 0,
      }}
      pagination={false}
    />

    <Modal {...modal_props}
      onCancel={() => {
        setShow(false);
      }}
    >
      <div style={{ marginBottom: 12 }}><Text strong>{_.get(details, 'stock', '')}</Text></div>
      <Table
        {...expend_props}
        rowKey="id"
        dataSource={_.get(details, 'list', [])}
        columns={columnsDetail}
        scroll={{ x: colWidth(columnsDetail), y: 720 }}
        showHeader={true}
      />
    </Modal>
  </>
}

export default RecordTable;