import React, { useState } from 'react';
import { Row, Col, Alert } from 'antd';
import { getfundManagerAssets, showSingleProductHistory, showIndexKline } from '@/api/workbench';
import { visShowAssetsAndProducts } from '@/api/reviewApi';
import { withRouter } from "react-router-dom";
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMount, useReactive, useUnmount } from 'ahooks';
import { HOME_PRODUCT_NUMBER, autoCol, replaceMoney, validateResponse } from '@/utils/utils';
import { saveStorage, isValidString, isValidArray } from '@/utils/utils2';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import AstsHeader from './Components/AstsHeader';
import HomeCard from './Components/HomeCard';
import Traders from '../Position/Component/Traders';
import RiskModal from './Components/RiskModal';
import Alerts from './Components/Alerts';
import moment from 'moment';
import _ from 'lodash';
import './assets.scss';

const TODAY = moment().format('YYYY-MM-DD');
let timer = null; let timer2 = null;
const ADD_KEYS = ['totalProduct', 'totalAssets', 'totalRevenue', 'dailyRevenue', 'freeBalance'];
const RATE_KEYS = ['totalRevenueRate', 'dailyRevenueRate'];
// 管理人首页
export default withRouter(function AssetsShow(props) {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [assetss, setassetss] = useState({ currentProductResultList: [] });
  const [allProducts, setAllProduct] = useState([]);
  const [glbAssets] = useState(JSON.parse(JSON.stringify(mobxStore.assetsData))); // 资产信息全局缓存数据
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  const [userRole] = useState(_.get(JSON.parse(JSON.stringify(mobxStore.userInfo)), 'role', ''));
  const isVisitor = userRole === 'visitor' ? true : false;
  const [homeResult, setHomeResult] = useState([]);
  const [isTradeDay, setIsTradeDay] = useState(_.findIndex(tradeDates, o => o.date === TODAY) !== -1 ? true : false);
  const assetState = useReactive({ isShow: true });
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', '')); //fc是否有token，是否正常登录 
  let isUnmont = false;

  useMount(() => {
    const isGlobalData = _.get(glbAssets, 'assets', null);
    // 优先赋值缓存数据，再进行获取更新
    if (!!isGlobalData) {
      const curResult = _.get(glbAssets, 'assets.currentProductResultList', []);
      setassetss(_.get(glbAssets, 'assets'));
      setHomeResult(curResult);
    }
    _gets();
    mobxStore._getStockHoliday(); //全局获取开收盘状态
    //交易日
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer2 = setTimeout(() => {
        const mobxTradeDates = JSON.parse(JSON.stringify(mobxStore.tradeDates));
        setTradeDates(mobxTradeDates);
        setIsTradeDay(_.findIndex(mobxTradeDates, o => o.date === TODAY) !== -1 ? true : false);
      }, 2000);
    };

    if (!isVisitor) {
      //【230926】账户改为mobx全局获取
      mobxStore._getProductAccount(isFcLogin);
      timer = setTimeout(() => {
        setAllProduct(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
      }, 2500);
    }
    mobxStore.savePositionList([]); //返回首页清空持仓缓存数据
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
    timer2 && clearTimeout(timer2);
    isUnmont = true;
  });

  async function _gets() {
    let params = {
      'isShow': assetState.isShow,
      'pageNum': 1,
      'pageSize': HOME_PRODUCT_NUMBER // 首页宫格，最后一位保留更多，获取8个数据
    }
    let globalAssets = {};
    const res = isVisitor ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    const resFc = !isVisitor && isFcLogin ? await getfundManagerAssets({ ...params, 'isShow': false }, 'FC') : {};
    if (validateResponse(res, isUnmont)) {
      let newAssets = {
        'results': _.concat(_.get(res, 'data.currentProductResultList', []), _.get(resFc, 'data.currentProductResultList', [])),
        'infoList': _.concat(_.get(res, 'data.pageInfo.list', []), _.get(resFc, 'data.pageInfo.list', []))
      };
      // 累计集中\反采账户，计算总资产；【tips】返回资产带",""的字符串，需转换数值进行计算
      ADD_KEYS.map(key => {
        const addNum = _.get(replaceMoney(_.get(res, `data.${key}`)), 'number');
        const addFc = _.get(replaceMoney(_.get(resFc, `data.${key}`)), 'number');
        _.set(newAssets, key, _.round(_.add(addNum, addFc), 2));
      });

      if (_.size(newAssets.results) === 0 && params.isShow === true) { // 没有配置首页显示卡，修改isShow，显示全部
        assetState.isShow = false;
        _gets();
        return;
      }
      let newList = [];
      newAssets.results.map(n => {
        let findex = _.findIndex(newAssets.infoList, o => o.id === n.productId);
        if (findex !== -1) {
          let items = _.assign(n, {
            'times': moment(_.get(newAssets.infoList, `[${findex}].createTime`)).format('YYYY-MM-DD'),
            'label': _.get(n, 'productName', ''),
            'value': _.get(n, 'productId', '')
          });
          // 排序用的float值，从字符串资产进行处理
          RATE_KEYS.map(key => {
            _.set(items, key + '_float', _.round(parseFloat(_.get(items, key)), 2));
            _.set(items, key, _.round(parseFloat(_.get(items, key)), 2) + '%');
          });
          newList.push(items);
        }
      });
      // console.log(newAssets)
      // console.log(newList)
      setassetss(newAssets);
      setHomeResult(isVisitor ? newAssets.results : newList);
      globalAssets.assetArr = newAssets; // assetArr已废弃使用
      globalAssets.assets = newAssets;
      mobxStore.changeAssetsData(globalAssets);//缓存资产信息
      // 赋值全部产品，并清空全局子账户
      mobxStore.changeAllProducts(newList);
      mobxStore.saveAccountCache([]);
    };
    // 路由不是POP返回，则获取托管缓存
    if (isTradeDay && _.get(props, 'history.action', '') !== 'POP') {
      mobxStore._hostingCache();
    }
  }
  // 点击更多跳转产品列表
  function _goSubAccount() {
    saveStorage('1', 'subAccount');
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/subAccount',
      state: { 'assetss': assetss, 'chartsData': [], 'chartsXData': [], 'visitor': isVisitor }
    });
  }
  function _goPosition() {
    saveStorage('1', 'position');
    props.history.push({
      pathname: '/home/position',
      state: { products: allProducts }
    });
  }
  // 跳转产品信息时，保存产品数据到全局缓存
  async function _goProductDetails(item) {
    saveStorage('1', 'ProductDetails');
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    let defaultState = { 'paramsData': item };
    // 产品当日数据优先获取再跳转；
    if (isTradeDay) {
      const res = await showSingleProductHistory(
        { 'productId': item.productId, 'dateType': 'TODAY', 'date': TODAY },
        (_.get(item, 'centralizedLogin', 0) === 2 ? true : false) ? 'FC' : 'NOR',
        true // 增加超时判断
      );
      const idxPms = { 'symbol': DEFAUL_INDEX_CODE, 'dateType': 'TODAY', 'date': TODAY }
      const idxRes = await showIndexKline(idxPms, null, true); // 参数无需批量，设置超时
      if (_.get(res, 'code', '') === '200') {
        _.set(defaultState, 'cache', _.get(res, 'data', {}));
        _.set(defaultState, 'indexCache', _.get(idxRes, 'data', {}));
      };
    };
    props.history.push({
      'pathname': '/home/ProductDetails',
      'state': defaultState
    });
  }
  function _goToPage(pathname) {
    saveStorage('1', pathname);
    props.history.push(`/home/${pathname}`);
  };
  //【tips】 alertClose字段仅用于关闭alert，避免回到首页还会显示；userinfoFc空的时候，为反采登录失败；故用token判断是否登录成功；
  return (
    <div className="contentView" id="assetsShow">
      <RiskModal />
      {!_.get(userInfoFC, 'alertClose') && !isFcLogin && <Alert message="反采账户登录失败！" banner type="info" closable
        onClose={() => mobxStore.landUserInfoFC({ alertClose: true })}
      />}

      <AstsHeader
        assetss={assetss}
        visitor={isVisitor}
        goAddProduct={() => _goToPage('AddProduct')}
        goPosition={_goPosition}
        goBatchsell={() => _goToPage('batchsell')}
        goConfig={() => _goToPage('conifgs')}
      />
      <br />

      <Row gutter={[8, 16]}>
        <Col {...autoCol([16, 24])}>
          <HomeCard
            isVisitor={isVisitor}
            homeItem={homeResult}
            goProductDetails={(item) => _goProductDetails(item)}
            goSubAccount={() => _goSubAccount()}
          />
        </Col>
        <Col {...autoCol([8])}>
          <Alerts goNews={() => _goToPage('fundManagerNews')} />
        </Col>
      </Row>

      <Traders products={allProducts} visitor={isVisitor} />
    </div>
  )
})