import FundAssetConfig from '@/view/Home/AccountAdm/FundAssetConfig/FundAssetConfig';
import FundManagerStockPoll from '@/view/Home/StockAdm/StockScreening/FundManagerStockPoll';
import FundAdm from '@/view/Home/AccountAdm/FundManagerDesk/FundAdm';
import TraderAdm from '@/view/Home/AccountAdm/FundManagerDesk/TraderAdm/TraderAdm';
import RiskAdm from '@/view/Home/AccountAdm/FundManagerDesk/RiskAdm/RiskAdm';

const routeSubConfig = [
  {
    path: "/home/fundAssetConfig",
    name: "手动调仓",
    component: FundAssetConfig,
  },
  {
    path: "/home/FundManagerStockPoll",
    name: "基金经理股票管理",
    component: FundManagerStockPoll,
  },
  {
    path: "/home/FundAdm",
    name: "基金经理资金管理",
    component: FundAdm,
  },
  {
    path: "/home/TraderAdm",
    name: "基金经理交易员管理",
    component: TraderAdm,
  },
  {
    path: "/home/RiskAdm",
    name: "基金经理风控管理",
    component: RiskAdm,
  },
]

export default routeSubConfig;