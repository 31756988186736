import React from 'react';
import { Typography, Modal, Table, Tag } from 'antd';
import { singleColumns } from '@/utils/utils';
// import { useMount } from 'ahooks';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

const BreakModal = ({ title, datas, show, onCancel }) => {
  // useMount(() => {
  //   console.log()
  // })

  const columnsIndex2 = [
    {
      ...singleColumns(['序号', 'indexFirst', 80]),
      render: (text, record, index) => index + 1,
    },
  ];
  const columns = [
    { ...singleColumns(['委托编号', 'clientOrderId', 130]) },
    {
      ...singleColumns(['股票', 'stockCode', 120]),
      render: (text, record) => {
        const conbindName = `${_.get(record, 'stockName', '')} - ${text}`;
        return <Text strong>{conbindName}</Text>
      }
    },
    { ...singleColumns(['成交数量', 'cumQty', 80]) },
    {
      ...singleColumns(['成交均价', 'avgPrice', 80]),
      render: (text, record) => <Text strong>{_.round(text, 4)}</Text>,
    },
    {
      ...singleColumns(['委托时间', 'commissionTime', 120]),
      sorter: (a, b) => {
        // let aTime = new Date(a.commissionTime).getTime();
        // let bTime = new Date(b.commissionTime).getTime();
        return a.time_key - b.time_key;
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss.SSS')
    },
    {
      ...singleColumns(['买卖方向', 'side', 80]),
      render: (text) => {
        return text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
      }
    },
    { ...singleColumns(['委托数量', 'quantity', 80]) },
    { ...singleColumns(['委托均价', 'price', 80]) },

    {
      ...singleColumns(['收益(元)', 'profit', 80, 0, ['no_default']], 'updown'),
    },
    {
      ...singleColumns(['状态', 'orderStatus', 80]),
      render: (text, record, index) => {
        return (text === '完成' ? <span style={{ color: 'green' }}>{text}</span> : text === '未完成' ? <span style={{ color: 'red' }}>{text}</span> : <span style={{ color: 'blue' }}>{text}</span>)
      }
    },
    { ...singleColumns(['备注', 'remarks', 130]) },
  ];

  const autoWidth = document.body.clientWidth > 1280 ? _.round(document.body.clientWidth / 2) : 750;
  return <Modal
    width={autoWidth}
    footer={null}
    maskClosable={false}
    visible={show}
    onCancel={onCancel}
    // bodyStyle={{ minHeight: 510 }}
  >
    <Table
      rowKey="clientOrderId"
      dataSource={datas}
      columns={_.concat(columnsIndex2, columns)}
      scroll={{ x: 1400, y: 780 }}
      title={() => <Text strong style={{ fontSize: 17 }}>{title}</Text>}
      size='small'
      pagination={false}
    />
  </Modal>
}

export default BreakModal;