import React from 'react';
import { Typography, message } from 'antd';
import {
  ArrowUpOutlined, ArrowDownOutlined, GoldenFilled, ProjectFilled,
  FundFilled, SlidersFilled, PieChartFilled, SwitcherFilled, BuildFilled,
  PullRequestOutlined, FunnelPlotFilled, DatabaseFilled, ControlFilled,
  ClockCircleFilled, FileTextFilled,
} from '@ant-design/icons';
import { isValidString } from './utils2'
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const HOME_PRODUCT_NUMBER = 9; // 18个产品卡片；包含最后 更多的操作按钮

const TAB_KEYS = {
  'MAIN_RATE': { name: '收益率', key: 'MAIN_RATE', icon: <FundFilled /> },
  'ATTRIBUTION': { name: '收益归因', key: 'ATTRIBUTION', icon: <PullRequestOutlined /> },
  'CONTRIBUTION': { name: '子账户盈亏分布', key: 'CONTRIBUTION', icon: <SlidersFilled /> },
  'CONTRIBUTION2': { name: '个股盈亏分布', key: 'CONTRIBUTION2', icon: <SlidersFilled /> },
  'DISTRIBUTION': { name: '持仓分布', key: 'DISTRIBUTION', icon: <PieChartFilled /> },
  'SIGNAL': { name: '个股交易信号', key: 'SIGNAL', icon: <FunnelPlotFilled /> },
  'COUNTING': { name: '交易次数和股票数', key: 'COUNTING', icon: <DatabaseFilled /> },
  'EARNING': { name: '个股收益比较', key: 'EARNING', icon: <ControlFilled /> },
  'PRO_COM': { name: '产品对比', key: 'PRO_COM', icon: <SwitcherFilled /> },
  'ACC_COM': { name: '账户对比', key: 'ACC_COM', icon: <SwitcherFilled /> },
  'BT_COM': { name: '回测对比', key: 'BT_COM', icon: <BuildFilled /> },
  'PRO_LEVEL': { name: '重仓分布', key: 'PRO_LEVEL', icon: <GoldenFilled /> },
  'RATE_K': { name: '收益率K', key: 'RATE_K', icon: <ProjectFilled /> },
  'REVIEW': { name: '历史持仓复盘', key: 'REVIEW', icon: <ClockCircleFilled /> },
  'EXTRA_COM': { name: '超额分析', key: 'EXTRA_COM', icon: <FileTextFilled /> },
};

function isEmptyStringV2(value) {
  if (value == "undefined" || !value || !/[^\s]/.test(value)) {
    return true;
  } else {
    return false;
  }
}

function validateResponse(res = {}, unmont = false) {
  let valid = false;
  if (_.get(res, 'code', '') === '200') {
    valid = true;
  } else if (_.get(res, 'code', '') === '400') {
    if (isValidString(res.data)) {
      message.warning(_.get(res, 'data', '无数据！'))
    } else {
      message.warning(_.get(res, 'message', '无数据！'))
    }
  } else {
    message.info(_.get(res, 'message', '获取失败!'));
  }
  if (unmont) { // 已经卸载则验证不通过
    valid = false;
  }
  return valid;
}

// 大额资金中文简写
const carryObject = {
  1: '万',
  2: '亿',
  3: '万亿'
}
const handleCarry = (number, currentCarry) => {
  return number >= 10000 || number <= -10000 ?
    _.round(number / 10000, 1) + carryObject[currentCarry + 1]
    : number + carryObject[currentCarry]
}
function renderHugeFund(value) {
  const newValue = _.isNumber(value) ? value : parseInt(value);
  if (newValue > 100000000 || newValue < -100000000) {
    return handleCarry(_.round(newValue / 100000000, 2), 2)
  } else if (newValue > 10000 || newValue < -10000) {
    return handleCarry(_.round(newValue / 10000, 2), 1)
  } else {
    return newValue
  }
}

const MOMENT_TODAY = moment().format('YYYY-MM-DD');

const PRODUCT_STRATEGY_TYPE = {
  1: '量化多头策略',
  2: '指数增强策略',
  3: '期货CTA策略',
  4: '市场中性策略',
  5: '多空灵活策略',
  6: '参与新股发行策略',
  7: '量化套利策略',
  8: '日内回转策略',
  9: '其他策略',
}

// 收益率颜色 + 百分号处理
const updownColorKey = {
  zero: 'black',
  positive: 'red',
  negative: 'green'
}
const replaceMoney = (number = 0) => {
  const pureNum = isValidString(number) ? parseFloat(number.replaceAll(',', '')) : number;
  let splitNumber = _.reverse(_.split(pureNum, '')); // 翻转数组，从小数点后进行遍历
  let c = 0;
  let isDim = true;
  let stringArray = [];
  splitNumber.map(n => {
    let num = n !== '.' && n !== '-' ? parseInt(n) : null;
    if (n === '.') { //遇到小数点开始后循环开始c++
      isDim = false;
    }
    // 每3位进位，push【,】进行分隔，从小数点后整数开始计算，并且最后一位是负数不处理；
    if (c === 3 && !isDim && n !== '-') {
      c = 0;
      stringArray.push(',');
    }
    if (num !== null && !isDim) { //整数位c递增
      c++;
    }
    stringArray.push(n);
  })
  return { 'number': pureNum, 'string': _.join(_.reverse(stringArray), '') };
}
// 根据rate返回带颜色或【%】的收益率string
const renderUpDownRate = (rate = 0, noPrefix = null, restStyle = {}, rType = 'number') => {
  let renderRate = rate ? _.round(parseFloat(rate), 2).toFixed(2) : 0;
  // 替换【,】的string
  if (rType === 'replace' && _.isString(rate)) {
    let repNumber = rate.replaceAll(',', '');
    renderRate = _.round(parseFloat(repNumber), 2).toFixed(2)
  }
  let ud = '';
  if (renderRate === 0) {
    ud = 'zero';
  } else if (renderRate > 0) {
    ud = 'positive';
  } else if (renderRate < 0) {
    ud = 'negative'
  }
  // 重新生成待【,】进位的字符串；
  if (rType === 'replace') {
    renderRate = _.get(replaceMoney(renderRate), 'string');
  }
  return <Text style={{ color: updownColorKey[ud] || 'black', ...restStyle }}>{renderRate + (noPrefix ? '' : '%')}</Text>
}
// 返回颜色version-2 : 返回组件及颜色信息object
const renderUpDownRate2 = (rate = 0, prefix = true, restStyle = {}) => {
  const renderRate = rate ? _.round(parseFloat(rate), 2).toFixed(2) : 0;
  let ud = '';
  if (renderRate === 0) {
    ud = 'zero';
  } else if (renderRate > 0) {
    ud = 'positive';
  } else if (renderRate < 0) {
    ud = 'negative'
  }
  const finalRate = renderRate + (prefix ? '%' : '');
  return {
    text: <Text style={{ color: updownColorKey[ud] || 'black', ...restStyle }}>{finalRate}</Text>,
    color: updownColorKey[ud] || 'black',
    type: ud,
    purTxt: finalRate
  }
}

// 生成标准表格columns；typeArray=[需要增加单列条件的Index,type的值]
const createBasicColums = (colList = [], typeArray = []) => {
  return _.size(colList) > 0 ? colList.map((itemArray, i) => {
    if (_.size(typeArray) > 0 && typeArray.indexOf(i) !== -1) {
      return singleColumns(itemArray, _.last(typeArray))
    }
    return singleColumns(itemArray)
  }) : []
}
// 创建单一列的column信息
const ALIGN_KEYS = ['left', 'center', 'right']; // 单列数据中最后一位默认是align，如需要配置该行其他数据，可以通过非对齐字段进行判断。配合type使用
const SORT_KEYS = ['descend', 'ascend'];
const singleColumns = (pmArray = ['', ''], type = '') => {
  // pmArray 创建单列数据的参数数组; 根据使用频率进行排列及拓展；
  //0：名称 1:key字段 2宽度 3对齐（可用0占位默认值）4:默认排序
  const pmGet = (pathArr) => _.get(pmArray, pathArr, '');
  let obj = {
    title: pmGet(['0']),
    dataIndex: pmGet(['1']),
    key: pmGet(['1']),
    align: pmArray[3] && ALIGN_KEYS.indexOf(pmGet(['3'])) !== -1 ? pmGet(['3']) : ALIGN_KEYS[0]
  }
  if (pmArray[2]) {
    obj.width = pmGet(['2']);
  }
  if (pmArray[4] && _.isArray(pmGet(['4']))) {
    const getFourth = pmGet(['4']);
    obj.sorter = (a, b) => a[pmGet(['4', '1'])] - b[pmGet(['4', '1'])];
    obj.sortDirections = SORT_KEYS;
    if (SORT_KEYS.indexOf(pmGet(['4', '0'])) !== -1) {
      obj.defaultSortOrder = _.head(getFourth);
    }
  }
  // type 生成些标准需求且复用率高的列表渲染内容，更复杂无需在此继续增加type
  if (type === 'dateTime') {
    obj.render = function (text, record) { return text ? moment(text).format('YYYY-MM-DD HH:mm') : '' }
  } else if (type === 'updown') {
    const isRate = _.last(pmArray) === 'rate' ? false : true; // 收益字段，array最后一位是rate，则添加百分号
    obj.render = function (text, record) { return renderUpDownRate(text, isRate) }
  } else if (type === 'strong') {
    obj.render = function (text, record) { return <Text strong>{text}</Text> }
  } else if (type === 'strongPrice') {
    obj.render = function (text, record) {
      const priceVal = text ? _.round(parseFloat(text), 2) : 0;
      return <Text strong>{priceVal}</Text>
    }
  }
  return obj
}

// 自适应：默认中屏幕12，小屏幕24沾满一行
const autoCol = (span = [0, null, null]) => {
  let big = span[0]; let mid = span[1] ?? 12; let small = span[2] ?? 24;
  if (big === 0 && !mid && !small) { // span=[0] 时，将中和小也置为0
    mid = 0; small = 0;
  }
  return { 'xxl': big, 'xl': big, 'lg': mid, 'md': small, 'sm': small, 'xs': small, 'flex': 'auto' }
}

// 渲染 涨红跌绿 颜色
function renderValueColor(value, vType = 'pure', colors = { up: 'red', down: 'green', zero: 'black' }) {
  let pureValue = vType === 'replace' ? _.replace(value || '', ',', '') : value;
  const floatValue = pureValue ? _.round(parseFloat(pureValue), 3) : 0;
  if (floatValue > 0) {
    return colors.up;
  } else if (floatValue < 0) {
    return colors.down;
  } else {
    return colors.zero;
  }
}

function renderValuePrefix(value) {
  const floatValue = value ? _.round(parseFloat(value), 3) : 0;
  if (floatValue > 0) {
    return <ArrowUpOutlined style={{ color: 'red' }} />
  } else if (floatValue < 0) {
    return <ArrowDownOutlined style={{ color: 'green' }} />
  } else {
    return null
  }
}

const colWidth = (cols = []) => {
  return _.size(cols) > 0 ? _.sum(cols.map(c => c.width ?? 0)) : 0
}

export {
  // 静态常量
  MOMENT_TODAY, PRODUCT_STRATEGY_TYPE, HOME_PRODUCT_NUMBER,
  TAB_KEYS,
  // 方法
  isEmptyStringV2,
  validateResponse,
  renderHugeFund,
  renderUpDownRate, renderUpDownRate2, replaceMoney,
  createBasicColums, singleColumns,
  autoCol,
  renderValueColor,
  renderValuePrefix,
  colWidth,
};