import { getAccountSubs, getAllTradeDay, showIndexKline } from '@/api/workbench';
import { showTradeReport, showTradeReportSub } from '@/api/fc';
import { isAllStockHoliday, showCurrentIndexSpeed } from '@/api/stock';
import { showIndexConfig, selectByCategory } from '@/api/details';
import { action, observable, makeObservable, autorun } from 'mobx';
import { getval, setVal } from './server';
import {
  isValidArray, createDatasTimeKey, saveStorage, isMarketTrading, createAccountItem,
  isValidString, CUSTOMIDX_STRING,
} from '@/utils/utils2';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import moment from 'moment';
import _ from 'lodash';

const TODAY = moment().format('YYYY-MM-DD');
const createCatArray = (data) => {
  return data.map(n => {
    return { 'label': n.name, 'value': n.code, 'newId': n.id + n.category, ...n }
  })
};
class Store {
  constructor() {
    makeObservable(this)
  }
  // 登陆令牌
  @observable userLogin = getval('token');
  @observable userLoginFC = getval('tokenFC');
  // 用户信息
  @observable userInfo = getval('userInfo');
  @observable userInfoFC = getval('userInfoFC');
  // 账户下证券账户及子账户列表
  @observable mobxAccountSubs = getval('mobxAccountSubs');
  @observable allProductAcc = {};
  // 当前是否在盘中
  @observable inTrading = 'rest';
  // 首页产品等数据
  @observable assetsData = {};
  @observable assetsAlls = [];
  @observable accountCache = [];
  @observable allProducts = [];   // 当前全部产品信息(详情)
  // tamp管理员首页模块数据
  @observable assetIndexData = {};
  @observable stockAnalysis = {};
  // 数据缓存
  @observable compareCache = {};
  @observable speedCache = {}; // 速率缓存
  @observable positionList = []; // 持仓缓存
  @observable hostingCache = {}; // 托管数据缓存
  // 所有交易日期
  @observable tradeDates = [];
  //自定义配置指数
  @observable customIndex = [];
  //对比分类原因数据
  @observable selectCategory = {};

  // 登陆
  @action landLogin = (token) => {
    this.userLogin = token;
    setVal('token', token);
  }
  @action landLoginFC = (token) => {
    this.userLoginFC = token;
    setVal('tokenFC', token);
  }
  // 退出登陆
  @action cancelLogin = () => {
    this.userLogin = null;
    this.userLoginFC = null;
    this.userInfo = null;
    this.userInfoFC = null;
    localStorage.removeItem('token');
    localStorage.removeItem('tokenFC');
    localStorage.removeItem('menuKey');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userInfoFC');
    localStorage.removeItem('mobxAccountSubs');
    this.assetsData = {};
    this.assetsAlls = [];
    this.accountCache = [];
    this.allProducts = [];
    this.compareCache = {};
    this.speedCache = {};
    this.positionList = [];
    this.hostingCache = {};
    this.assetIndexData = {};
    this.stockAnalysis = {};
  }
  // 登陆接口保存用户信息
  @action landUserInfo = (userInfo) => {
    this.userInfo = userInfo;
    setVal('userInfo', userInfo);
  }
  @action landUserInfoFC = (userInfo) => {
    this.userInfoFC = userInfo;
    setVal('userInfoFC', userInfo);
  }
  // 基金经理 - 保存账户下证券账户及子账户列表
  @action _mobxAccountSubs = (data) => {
    this.mobxAccountSubs = data;
    setVal('mobxAccountSubs', data);
  }

  @action _getProductAccount = async (hasFc) => {
    const data = await getAccountSubs();
    const resFc = hasFc ? await getAccountSubs(null, 'FC') : {};
    if (_.get(data, 'code', '') === '200') {
      // 将证券集合数据加工成适配级联选择器的数据 value为key
      let arr = _.concat(_.get(data, 'data', []), _.get(resFc, 'data', []));
      arr.map((item, key) => {
        item.label = item.productName
        item.value = key
        item.children = item.securitiesAccountList
        item.children.map((item2, key2) => {
          item2.label = item2.securitiesAccountName
          item2.value = key2
          item2.children = item2.subAccountList
          item2.children.map((item3, key3) => {
            item3.label = item3.subAccountName
            item3.value = key3
            return item3
          })
          return item2
        })
        return item;
      });
      this.mobxAccountSubs = arr;
      // 处理产品和子账户，分别生成options
      const getData = _.get(data, 'data', []); const getFcData = _.get(resFc, 'data', []);
      const nors = createAccountItem(getData, 1);
      const fcs = createAccountItem(getFcData, 2);
      //【新】 acc/proLogin 通过id快速找到账户\产品是否是反采账户；
      this.allProductAcc = {
        'product': _.orderBy(_.concat(nors.product, fcs.product), ['value'], ['desc']),
        'account': _.orderBy(_.concat(nors.account, fcs.account), ['value'], ['desc']),
        'accObj': _.assign(nors.accObj, fcs.accObj),
        'productObj': _.assign(nors.productObj, fcs.productObj),
        'accLogin': _.assign(nors.accLogin, fcs.accLogin),
        'productLogin': _.assign(nors.productLogin, fcs.productLogin),
      }
    }
  }
  // 【原】交易员还是页面更新全局state，故保留原方法
  @action changeInTrading = (status) => {
    this.inTrading = status;
  }
  // 首页全局缓存数据
  @action changeAssetsData = (data) => {
    this.assetsData = data;
  }
  @action changeAssetsAlls = (data) => {
    this.assetsAlls = data;
  }
  @action saveAccountCache = (data) => {
    this.accountCache = data;
  }
  @action changeAssetIndex = (data) => {
    this.assetIndexData = data;
  }
  @action changeStockAnalysis = (data) => {
    this.stockAnalysis = data;
  }
  @action savePositionList = (data) => {
    this.positionList = data;
  };
  // 托管数据盘中全局获取作为缓存
  @action _hostingCache = async () => {
    const idxPms = { 'symbol': DEFAUL_INDEX_CODE, 'dateType': 'TODAY', 'date': TODAY }
    const res = await showTradeReport();
    const res2 = await showTradeReportSub();
    const idxRes = await showIndexKline(idxPms);
    const res3 = await showCurrentIndexSpeed();
    const getProduct = isValidString(_.get(res, 'data', [])) ? JSON.parse(_.get(res, 'data', [])) : [];
    const getAccount = isValidString(_.get(res2, 'data', [])) ? JSON.parse(_.get(res2, 'data', [])) : [];
    const getSpeed = _.get(res3, 'data', {});
    const getIdx = _.get(idxRes, 'data', {});
    this.hostingCache = {
      getProduct, getAccount, getSpeed, getIdx, idxPms
    };
  };
  // 当前全部产品信息
  @action changeAllProducts = (data) => {
    this.allProducts = data;
  }
  // 实测缓存
  @action changeCompareCache = (data) => {
    this.compareCache = data;
  }
  // 指数变化速率
  @action changeSpeedCache = (data) => {
    this.speedCache = data;
  }
  // 获取所有交易日期
  @action _getAllTradeDay = async () => {
    const year = moment().year();
    const res = await getAllTradeDay({ year: `${year - 2},${year - 1},${year}` });
    if (_.get(res, 'code', '') === '200') {
      let orders = createDatasTimeKey(
        _.get(res, 'data', []),
        'pure_date',
        'd',
        'asc'
      );
      let final = [];
      // 增加周的信息，间隔日>1则周增加；
      let week_num = 1; let wstart = ''; // 每周的开始日期
      let seaStart = '';
      let curYear = moment(_.get(orders, '[0].date')).year();
      orders.map((n, i) => {
        let cur = _.get(n, 'date');
        let next = _.get(orders, `[${i + 1}].date`);
        let diff = next ? moment(cur).diff(moment(next), 'd') : 0;
        const thisSeason = moment(cur).quarter(); const nextSeaon = moment(next).quarter();
        const splits = _.split(cur, '-');
        if (i === 0) {
          wstart = splits[1] + splits[2];
          seaStart = splits[1] + splits[2];
        }
        if (parseFloat(splits[0]) !== curYear) { // 跨年度，周重新计算
          week_num = 1;
          curYear = parseFloat(splits[0]);
        }
        final.push(_.assign(n, {
          'week': week_num,
          'start': wstart,
          'season': thisSeason,
          'seaStart': seaStart,
          'month': moment(cur).month()  // month()从0 开始
        }));
        // 交易日大于1则跨周末，周递增
        const nex_split = _.split(next, '-');
        if (Math.abs(diff) > 1) {
          week_num++;
          wstart = nex_split[1] + nex_split[2];
        };
        if (nextSeaon !== thisSeason) {
          seaStart = nex_split[1] + nex_split[2];
        }
      })
      this.tradeDates = final;
    }
  };
  // 获取交易时间；
  @action _getStockHoliday = async () => {
    const TOTAY = moment().format('YYYY-MM-DD');
    const holiRes = await isAllStockHoliday({ beginDate: TOTAY, endDate: TOTAY })
    if (_.get(holiRes, 'code', '') === '200') {
      let globalTrading = 'rest'
      if (_.get(holiRes, 'data.isAllHoliday') === false) {
        globalTrading = isMarketTrading() ? 'trading' : 'rest';
      }
      saveStorage('2', globalTrading);
      this.inTrading = globalTrading;
    }
  };
  // 获取自定义配置指数
  @action _showIndexConfig = async () => {
    const res = await showIndexConfig();
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      const newData = getData.map(n => {
        return { 'label': n.name, 'value': CUSTOMIDX_STRING + n.id, 'index': n.index };
      });
      saveStorage('cidx', JSON.stringify(newData));
      this.customIndex = newData;
    }
  };
  @action changeIndexConfig = (data) => {
    if (isValidArray(data)) {
      saveStorage('cidx', JSON.stringify(data));
    }
    this.customIndex = data;
  };
  // 获取对比分类原因数据
  @action _getCategory = async () => {
    const res = await selectByCategory('contrastReason');
    const res2 = await selectByCategory('contrastType');
    const res3 = await selectByCategory('accountType');
    if (_.get(res, 'code', '') === '200') {
      // 实测对比数据格式
      const compareData = {
        'reason': createCatArray(_.get(res, 'data')),
        'type': createCatArray(_.get(res2, 'data')),
        'accountType': createCatArray(_.get(res3, 'data')),
      };
      // 全局配置数据格式
      const getData = _.concat(_.get(res, 'data'), _.get(res2, 'data'), _.get(res3, 'data'));
      const newData = getData.map(n => {
        return { ...n, 'newId': n.id + n.category }
      });
      this.selectCategory = {
        'compare': compareData,
        'config': newData,
      };
    }
  };
}

export const store = new Store();

autorun(() => {
  // console.log(`登陆${store.userInfo}`)
})
