
import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { autoCol, renderValueColor, renderValuePrefix } from '@/utils/utils';
import _ from 'lodash';

const TradeHeader = ({ assetData }) => {
  const common_static_style = { fontSize: 24, fontWeight: '600' }
  const header_list = [
    { title: '总资产', key: 'totalAssets', type: 'normal' },
    { title: '可用资金', key: 'totalBalance', type: 'normal' },
    { title: '总收益', key: 'totalRevenue', type: 'color' },
    { title: '净值', key: 'netValue', type: 'color' },
    { title: '日收益', key: 'dailyRevenue', type: 'color_prefix' },
    { title: '日收益率', key: 'dailyRevenueRate', type: 'color_prefix_rate' },
  ];

  const renderListItem = (item, i) => {
    let sta_props = { valueStyle: common_static_style };
    const getType = _.get(item, 'type', '');
    let getVal = _.get(assetData, item.key, 0);
    if (_.includes(getType, 'rate')) {
      getVal = parseFloat(getVal).toFixed(2) + '%';
    }
    if (item.key === 'netValue') {
      getVal = _.round(getVal, 2)
    }
    if (_.includes(getType, 'color')) {
      sta_props.valueStyle = _.assign(common_static_style, { color: renderValueColor(getVal, 'replace') })
    }
    if (_.includes(getType, 'prefix')) {
      sta_props.prefix = renderValuePrefix(getVal);
    }
    return <Col {...autoCol([4, 6, 12])} key={i + 'header'} className="headViewCol">
      <Statistic title={item.title} value={getVal} {...sta_props} />
    </Col>
  }
  return (
    <Card>
      <Row className="headView" gutter={[8, 8]}>
        {header_list.map((item, i) => renderListItem(item, i))}
      </Row>
    </Card>
  )
}

export default TradeHeader;