import React, { Component } from 'react';
import { Card, Tabs, PageHeader, Typography, Space, Cascader } from 'antd';
import { getProductDetailss } from '@/api/details';
import { nextTradeDate } from '@/api/stock';
import { getAccountSubs } from '@/api/workbench';
import { observer, inject } from 'mobx-react';
import { PlusOutlined, BlockOutlined } from '@ant-design/icons';
import { isValidArray, isValidString } from '@/utils/utils2';
import moment from 'moment';
import PositionTable from './Component/PositionTable';
import AddPage from './Component/AddPage';
// import Traders from './Component/Traders';
import './position.scss';
import _ from 'lodash';

const { TabPane } = Tabs;
const { Text } = Typography;
const TOTAY = moment().format('YYYY-MM-DD');
const CLOSE_TIME = moment(TOTAY + ' 15:00');
@inject('store')
@observer
class PositionPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabKey: '1',
      tabChangeCount: 0,
      options: [],
      subId: 0,
      subVals: {},
      tradeDate: { isToday: false, nextDate: '' }
    }
    this.userInfo = JSON.parse(JSON.stringify(this.props.store.userInfo));
    this.inTrading = JSON.parse(JSON.stringify(this.props.store.inTrading));
    this.userInfoFC = JSON.parse(JSON.stringify(this.props.store.userInfoFC));
    this.products = _.get(props, 'location.state.products', []);
  }

  componentDidMount() {
    this._getAccountSubs();
    this._nextTradeDate();
  }

  _getAccountSubs = async () => {
    const data = await getAccountSubs();
    const resFc = isValidString(_.get(this.userInfoFC, 'token', '')) ? await getAccountSubs(null, 'FC') : {};
    if (_.get(data, 'code', '') === '200') {
      // 将证券集合数据加工成适配级联选择器的数据 value为key; 增加centralizedLogin字段
      const fcData = _.get(resFc, 'data', []);
      let arr = _.concat(
        _.get(data, 'data', []),
        isValidArray(fcData) ? fcData.map(f => { return { ...f, 'centralizedLogin': 2 } }) : []
      );
      arr.map((item, key) => {
        const login_type = _.get(item, 'centralizedLogin') ?? 1;
        item.label = item.productName;
        item.value = key;
        item.children = item.securitiesAccountList;
        item.children.map((item2, key2) => {
          item2.label = item2.securitiesAccountName;
          item2.value = key2;
          item2.children = item2.subAccountList
          item2.children.map((item3, key3) => {
            item3.label = item3.subAccountName
            item3.value = key3;
            _.set(item3, 'centralizedLogin', login_type);
            return item3
          })
          return item2
        })
        return item;
      });
      let newOption = arr.map(n => {
        return {
          label: _.get(n, 'productName', ''), value: _.get(n, 'productId', 0),
          isLeaf: false, centralizedLogin: _.get(n, 'centralizedLogin') ?? 1
        }
      });
      // console.log('newOption', newOption)
      this.setState({ options: newOption });
    }
  }

  _nextTradeDate = async () => {
    const res = await nextTradeDate();
    if (_.get(res, 'code', '') === '200') {
      let finalObj = { isToday: false, nextDate: _.get(res, 'data.preTradeDay', '') }
      if (_.get(res, 'data.isHoliday', null) === false) {
        const currentTime = moment();
        const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
        if (diffCloseMin < 0) {
          finalObj.isToday = true;
        }
        //console.log('tradeDate', finalObj, diffCloseMin);
      }
      this.setState({ tradeDate: finalObj, tabChangeCount: this.state.tabChangeCount + 1 })
    }
  }

  tabChange = (key) => {
    if (key === '1') {
      this.setState({ tabChangeCount: this.state.tabChangeCount + 1 })
    }
    this.setState({ tabKey: key })
  }

  loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // console.log('targetOption', targetOption)
    if (targetOption.value) {
      const res = await getProductDetailss(
        { params: { productId: targetOption.value } },
        _.get(targetOption, 'centralizedLogin', 1) === 2 ? 'FC' : 'NOR'
      );
      if (_.get(res, 'code', '') === '200') {
        const getAccount = _.get(res, 'data.securitiesAccounts', []);
        if (_.size(getAccount) > 0) {
          let itemArray = [];
          getAccount.map(acc => {
            const getSubAccount = _.get(acc, 'subAccountList', [])
            if (_.size(getSubAccount) > 0) {
              getSubAccount.map(s => {
                itemArray.push({
                  label: _.get(s, 'name', ''),
                  value: _.get(s, 'id'),
                  isLeaf: true,
                  centralizedLogin: _.get(targetOption, 'centralizedLogin', 1)
                })
              })
            }
          })
          targetOption.children = itemArray;
        }
      }
    }

    this.setState({ options: [...this.state.options] })
  }

  onChange = (value, selectedOptions) => {
    // console.log(value, selectedOptions);
    if (_.size(value) > 1) {
      this.setState({
        subId: _.last(value),
        subVals: _.last(selectedOptions),
        tabChangeCount: this.state.tabChangeCount + 1
      })
    }
  };

  render() {
    const { options, subId, subVals, tradeDate, tabChangeCount, tabKey } = this.state;
    const tradingBool = this.inTrading === 'trading' ? true : false;
    return (
      <>
        <PageHeader
          title={'持仓管理'}
          onBack={() => {
            this.props.history.goBack();
          }}
          style={{ backgroundColor: 'white', marginBottom: 10 }}
        >
          <Space>
            <Text strong>账户：</Text>
            <Cascader
              options={options}
              loadData={this.loadData}
              onChange={this.onChange}
              changeOnSelect
              style={{ width: 180 }}
            />
          </Space>
        </PageHeader>

        <Card>
          <Tabs activeKey={tabKey} onChange={this.tabChange} type="card">
            <TabPane tab={<span><BlockOutlined />{'交易设置'}</span>} key="1" disabled={!subId}>
              <PositionTable
                subId={subId}
                subVals={subVals}
                trade={tradeDate}
                updateCount={tabChangeCount}
                isTrading={tradingBool}
              />
            </TabPane>
            <TabPane tab={<span><PlusOutlined />{'添加限制'}</span>} key="2" disabled={!subId}>
              <AddPage
                subId={subId}
                subVals={subVals}
                trade={tradeDate}
                isTrading={tradingBool}
                updateKey={tabKey}
                goBackTab={(key) => this.tabChange(key)}
              />
            </TabPane>
            {/* <TabPane tab={<span><UserOutlined />{'交易员'}</span>} key="3">
              <Traders />
            </TabPane> */}
          </Tabs>
        </Card>
      </>
    )
  }
}

export default PositionPage;