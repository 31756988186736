import React from 'react';
import { Row, Col, Table, Typography, Tag, Space, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { singleColumns, colWidth, renderUpDownRate } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;
// 批量卖出表格
const SellTables = ({ datas = [], values = [], accNum = {}, onSelect, onRefresh }) => {
  const columns = [
    {
      ...singleColumns(['股票', 'stock', 200]),
      render: (text, record) => <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>,
    },
    singleColumns(['数量', 'stockAmount', 80]),
    singleColumns(['可用', 'availableStockAmount', 80]),
    singleColumns(['金额', 'totalMarketValue', 85]),
    {
      ...singleColumns(['涨跌幅', 'pctChg', 100]),
      sorter: (a, b) => a.pctChg - b.pctChg,
      render: (text) => renderUpDownRate(text, null, { fontWeight: 500 })
    },
    {
      ...singleColumns(['账户', 'account', 145]),
      fixed: 'right',
      render: (text, record) => {
        const getSubarr = _.get(record, 'subarr', []);
        if (_.size(getSubarr) > 0) {
          return <>
            {getSubarr.map(code => <Tag key={'co' + code} style={{ marginRight: 2 }}>
              {_.get(accNum, code)}
            </Tag>)}
          </>
        }
      }
    }
  ]

  const rowSelection = {
    selectedRowKeys: values,
    onChange: (selectedRowKeys) => {
      onSelect(selectedRowKeys);
    },
  };
  return <>
    <Table
      rowKey="key"
      dataSource={datas}
      columns={columns}
      rowSelection={rowSelection}
      title={() => <Row>
        <Col span={12}>
          <Space>
            <Text strong>持仓</Text>
            <Text type='secondary'>已选</Text>
            <Text>{_.size(values)}</Text>
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button size='small' icon={<SyncOutlined />} onClick={onRefresh}>刷新</Button>
        </Col>
      </Row>}
      scroll={{ x: colWidth(columns), y: 625 }}
      bordered
      size='small'
      pagination={false}
    />
  </>
}

export default SellTables;