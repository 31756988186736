import React, { useState } from 'react';
import { Modal, Form, Select, Input, InputNumber, Button, Row, Col, message } from 'antd';
import { getBrokerages, isRepeatTradeUnit, checkTradeAccount, addSecurityAccount, initAccount, initProductOriginAssets } from '@/api/details';

const { Option } = Select;

const NewAccModal = ({ pmData }) => {
  const [modalForm] = Form.useForm();
  const [submitDisabled, setsubmitDisabled] = useState(true);
  const [brokerages, setBrokerages] = useState([]);
  const [visible, setvisible] = useState(false);
  const [securitiesAccounts, setsecuritiesAccounts] = useState([]);


  async function _click() {
    const data = await getBrokerages();
    if (data.code === '200') {
      setBrokerages(data.data);
    }
    setvisible(true);
  }

  function handleCancel() {
    setvisible(false);
    modalForm.resetFields();
  }

  async function _check() {
    const values = await modalForm.validateFields();
    let brokerage = JSON.parse(values.brokerage);
    let params = {
      brokerageId: String(brokerage.id),
      securityType: brokerage.securityType,
      customerNumber: values.customerNumber,
      normalTradeUnit: String(values.normalTradeUnit),
      creditTradeUnit: String(values.creditTradeUnit),
      password: values.password,
      tradeAccount: String(values.tradeAccount),
      ip: values.ip,
      mac: values.mac,
    }
    const data = await checkTradeAccount(params);
    if (data.code === '200') {
      message.success('校验成功');
      let arr = JSON.parse(JSON.stringify(securitiesAccounts));
      arr.push(params);
      setsecuritiesAccounts(arr);
      setsubmitDisabled(false);
    } else {
      message.error(data.message);
    }
  }

  async function addAccount() {
    let params = { productId: pmData.productId, securitiesAccounts: securitiesAccounts }
    const data = await addSecurityAccount(params);
    if (data.code === '200') {
      const data1 = await initAccount({ securitiesAccounts: securitiesAccounts });
      if (data1.code === '200') {
        const data2 = await initProductOriginAssets({ productId: pmData.productId });
        if (data2.code === '200') {
          message.success('添加成功');
          setvisible(false);
          modalForm.resetFields();
        } else {
          message.error(data.message);
        }
      } else {
        message.error(data.message);
      }
    } else {
      message.error(data.message);
    }
  }

  return (
    <>
      <Modal
        title='添加证券账户'
        visible={visible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[]}
      >
        <Form
          form={modalForm}
          initialValues={{}}
        >
          <Form.Item name="brokerage" rules={[{ required: true, message: '请选择证券账户', }]} >
            <Select placeholder="请选择证券账户" >
              {
                brokerages.length !== 0 && brokerages.map(item => (
                  <Option key={item.id} value={JSON.stringify(item)}>
                    {item.name}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            label=""
            name='customerNumber'
            rules={[{ required: true, message: '请输入证券账户名称', }]}
          >
            <Input placeholder="请输入证券账户名称" />
          </Form.Item>

          <Form.Item
            label=""
            name='normalTradeUnit'
            validateTrigger='onBlur'
            rules={[{
              required: true,
              validator: async (_, value) => {
                if (value) {
                  const data = await isRepeatTradeUnit({ tradeUnit: value });
                  if (data.code === '200') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(data.message));
                } else {
                  return Promise.reject(new Error('请输入普通交易单元'));
                }
              }
            },]}
          >
            <InputNumber placeholder="请输入普通交易单元" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label=""
            name='creditTradeUnit'
            rules={[{ required: true, message: '请输入信用交易单元', }]}
          >
            <InputNumber placeholder="请输入信用交易单元" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label=""
            name='tradeAccount'
            rules={[{ required: true, message: '请输入交易账户', }]}
          >
            <InputNumber placeholder="请输入交易账户" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label=""
            name='password'
            rules={[{ required: true, message: '请输入交易密码', },]}
          >
            <Input placeholder="请输入交易密码" />
          </Form.Item>
          <Form.Item
            label=""
            name="ip"
            validateTrigger='onBlur'
            rules={[{ required: true }, {
              validator: async (_, value) => {
                if (value) {
                  if ((/^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))).){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/.test(value))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('请输入正确的IP地址'));
                }
              }
            }]}
          >
            <Input placeholder="请输入IP地址" />
          </Form.Item>
          <Form.Item
            label=""
            name="mac"
            validateTrigger='onBlur'
            rules={[{ required: true }, {
              validator: async (_, value) => {
                if (value) {
                  if ((/^([0-9a-fA-F]{2})(([/\s:-][0-9a-fA-F]{2}){5})$/.test(value))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('请输入正确MAC地址'));
                }
              }
            }]}
          >
            <Input placeholder="请输入MAC地址" />
          </Form.Item>
          <Form.Item
            label=""
            name='check'
          >
            <Button type="primary"
              style={{ float: 'right' }}
              onClick={_check}
            >校验</Button>
          </Form.Item>
          <Form.Item
            label=""
            name='sub'
          >
            <Row justify="end">
              <Button key="can" onClick={handleCancel}>取消</Button>
              <Button key="sub" disabled={submitDisabled} type="primary" style={{ marginLeft: 20 }} onClick={addAccount}>提交</Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      <Button
        type="dashed"
        className="colorGrey"
        style={{ width: '100%', marginTop: 20 }}
        onClick={_click}
      >
        + 添加
      </Button>
    </>
  )
}

export default NewAccModal;