import React, { useEffect, useState } from 'react';
import { Card,Row,DatePicker,Select } from 'antd';
import * as echarts from 'echarts';
import moment from 'moment';

export default function EchartsInfo3(props) {
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const [stockProductId1, setstockProductId1] = useState('');
    const [stockProductId2, setstockProductId2] = useState('');
    const [times1, settimes1] = useState([moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]);
    const [times2, settimes2] = useState([moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]);
    const [times3, settimes3] = useState(moment().format('YYYY-MM-DD'));
    const [times4, settimes4] = useState([moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]);
    const [times5, settimes5] = useState([moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]);
    const [option, setoption] = useState({
        title: {		
            show:true,			         	
            text:'人气度',
            textStyle:{
                fontSize:15
            }
        },
        xAxis: {
            data: [],
            type:'category',			//---轴类型，默认'category'
            name:'股票名称',				//---轴名称
            axisLine:{					//---坐标轴 轴线
                show:true,					//---是否显示
                symbol:['none', 'arrow'],	//---是否显示轴线箭头
                symbolSize:[8, 8] ,			//---箭头大小
                symbolOffset:7,			//---箭头位置
                lineStyle:{
                    width:1,
                    type:'solid',
                },
            },
            axisTick:{					//---坐标轴 刻度
                show:false,					//---是否显示
            },
            axisLabel:{					//---坐标轴 标签
                show:true,					//---是否显示
                inside:false,				//---是否朝内	
                margin: 5,					//---刻度标签与轴线之间的距离
                overflow:'break',
                width:10,
                interval:0
            },
        },
        yAxis: {
            type:'value',
            name:'单位/人',
            axisLine:{
                show:true, 
                symbol:['none', 'arrow'],
                symbolSize:[8, 8],
                symbolOffset:7,
            }
        },
        series: [{
            type: 'bar',
            name:'人气度',
            data: [],
            // barWidth:40,
            barMaxWidth:50,
            barGap:'10%'
        }],
        tooltip: {
            show:true,					//---是否显示提示框,默认为true
            trigger:'item',				//---数据项图形触发
            padding:5,
        },
    });
    const [option2, setoption2] = useState({
        title: {		
            show:true,			         	
            text:'活跃度', 
            textStyle:{
                fontSize:15
            }     
        },
        xAxis: {
            data: [],
            type:'category',			//---轴类型，默认'category'
            name:'股票名称',				//---轴名称
            axisLine:{					//---坐标轴 轴线
                show:true,					//---是否显示
                symbol:['none', 'arrow'],	//---是否显示轴线箭头
                symbolSize:[8, 8] ,			//---箭头大小
                symbolOffset:7,			//---箭头位置
                lineStyle:{
                    width:1,
                    type:'solid',
                },
            },
            axisTick:{					//---坐标轴 刻度
                show:false,					//---是否显示
            },
            axisLabel:{					//---坐标轴 标签
                show:true,					//---是否显示
                inside:false,				//---是否朝内	
                margin: 5,					//---刻度标签与轴线之间的距离
                overflow:'break',
                width:10,
                interval:0
            },
        },
        yAxis: {
            type:'value',
            name:'单位/次',
            axisLine:{
                show:true, 
                symbol:['none', 'arrow'],
                symbolSize:[8, 8],
                symbolOffset:7,
            }
        },
        series: [{
            type: 'bar',
            name:'活跃度',
            data: [],
            barMaxWidth:50,
            barGap:'10%'
        }],
        tooltip: {
            show:true,					//---是否显示提示框,默认为true
            trigger:'item',				//---数据项图形触发
            padding:5,
        },
    });
    const [option3, setoption3] = useState({
        title: {		
            show:true,			         	
            text:'日收益率', 
            textStyle:{
                fontSize:15
            }       
        },
        xAxis: {
            data: [],
            type:'category',			//---轴类型，默认'category'
            name:'姓名',				//---轴名称
            axisLine:{					//---坐标轴 轴线
                show:true,					//---是否显示
                symbol:['none', 'arrow'],	//---是否显示轴线箭头
                symbolSize:[8, 8] ,			//---箭头大小
                symbolOffset:7,			//---箭头位置
                lineStyle:{
                    width:1,
                    type:'solid',
                },
            },
            axisTick:{					//---坐标轴 刻度
                show:false,					//---是否显示
            },
            axisLabel:{					//---坐标轴 标签
                show:true,					//---是否显示
                inside:false,				//---是否朝内	
                margin: 5,					//---刻度标签与轴线之间的距离
                overflow:'break',
                width:40,
                interval:0
            },
        },
        yAxis: {
            type:'value',
            name:'单位/%',
            axisLine:{
                show:true, 
                symbol:['none', 'arrow'],
                symbolSize:[8, 8],
                symbolOffset:7,
            }
        },
        series: [{
            type: 'bar',
            name:'日收益率',
            data: [],
            barMaxWidth:50,
            barGap:'10%'
        }],
        tooltip: {
            show:true,					//---是否显示提示框,默认为true
            trigger:'item',				//---数据项图形触发
            padding:5,
        },
    });
    const [option4, setoption4] = useState({
        title: {		
            show:true,			         	
            text:'总收益率',   
            textStyle:{
                fontSize:15
            }   
        },
        xAxis: {
            data: [],
            type:'category',			//---轴类型，默认'category'
            name:'姓名',				//---轴名称
            axisLine:{					//---坐标轴 轴线
                show:true,					//---是否显示
                symbol:['none', 'arrow'],	//---是否显示轴线箭头
                symbolSize:[8, 8] ,			//---箭头大小
                symbolOffset:7,			//---箭头位置
                // onZero:false,
                lineStyle:{
                    width:1,
                    type:'solid',
                },
            },
            axisTick:{					//---坐标轴 刻度
                show:false,					//---是否显示
            },
            axisLabel:{					//---坐标轴 标签
                show:true,					//---是否显示
                inside:false,				//---是否朝内	
                margin: 5,					//---刻度标签与轴线之间的距离
                overflow:'break',
                width:40,
                interval:0
            },
        },
        yAxis: {
            type:'value',
            name:'单位/%',
            axisLine:{
                show:true, 
                symbol:['none', 'arrow'],
                symbolSize:[8, 8],
                symbolOffset:7,
            }
        },
        series: [{
            type: 'bar',
            name:'总收益率',
            data: [],
            barMaxWidth:50,
            barGap:'10%'
        }],
        tooltip: {
            show:true,					//---是否显示提示框,默认为true
            trigger:'item',				//---数据项图形触发
            padding:5,
        },
    });
    const [option5, setoption5] = useState({
        title: {		
            show:true,			         	
            text:'交易次数', 
            textStyle:{
                fontSize:15
            }      
        },
        xAxis: {
            data: [],
            type:'category',			//---轴类型，默认'category'
            position:'bottom',
            name:'姓名',				//---轴名称
            axisLine:{					//---坐标轴 轴线
                show:true,					//---是否显示
                symbol:['none', 'arrow'],	//---是否显示轴线箭头
                symbolSize:[8, 8] ,			//---箭头大小
                symbolOffset:7,			//---箭头位置
                lineStyle:{
                    width:1,
                    type:'solid',
                },
            },
            axisTick:{					//---坐标轴 刻度
                show:false,					//---是否显示
            },
            axisLabel:{					//---坐标轴 标签
                show:true,					//---是否显示
                inside:false,				//---是否朝内	
                margin: 5,					//---刻度标签与轴线之间的距离
                overflow:'break',
                width:40,
                interval:0
            },
        },
        yAxis: {
            type:'value',
            name:'单位:次',
            axisLine:{
                show:true, 
                symbol:['none', 'arrow'],
                symbolSize:[8, 8],
                symbolOffset:7,
            }
        },
        series: [{
            type: 'bar',
            name:'交易次数',
            data: [],
            barMaxWidth:50,
            barGap:'10%'
        }],
        tooltip: {
            show:true,					//---是否显示提示框,默认为true
            trigger:'item',				//---数据项图形触发
            padding:5,
        },
    });

    useEffect(() => {
        let myChart = props.myChart;
        let myChart2 = props.myChart2;
        let myChart3 = props.myChart3;
        let myChart4 = props.myChart4;
        let myChart5 = props.myChart5;
        if (myChart !== null && myChart !== "" && myChart !== undefined) {
            myChart.dispose();//销毁
        }
        if (myChart2 !== null && myChart2 !== "" && myChart2 !== undefined) {
            myChart2.dispose();//销毁
        }
        if (myChart3 !== null && myChart3 !== "" && myChart3 !== undefined) {
            myChart3.dispose();//销毁
        }
        if (myChart4 !== null && myChart4 !== "" && myChart4 !== undefined) {
            myChart4.dispose();//销毁
        }
        if (myChart5 !== null && myChart5 !== "" && myChart5 !== undefined) {
            myChart5.dispose();//销毁
        }

        myChart = echarts.init(document.getElementById('echart1'));
        myChart2 = echarts.init(document.getElementById('echart2'));
        myChart3 = echarts.init(document.getElementById('echart3'));
        myChart4 = echarts.init(document.getElementById('echart4'));
        myChart5 = echarts.init(document.getElementById('echart5'));
        myChart.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });
        myChart2.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });
        myChart3.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });
        myChart4.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });
        myChart5.showLoading({
            text: '数据获取中',
            effect: 'whirling'
        });

        let newOption = option;
        if (props.chartsXData1) {
            newOption.xAxis.data = props.chartsXData1;
            newOption.series[0].data = props.chartsData1;
        }
        let newOption2 = option2;
        if (props.chartsXData2) {
            newOption2.xAxis.data = props.chartsXData2;
            newOption2.series[0].data = props.chartsData2;
        }
        let newOption3 = option3;
        if (props.chartsXData3) {
            newOption3.xAxis.data = props.chartsXData3;
            newOption3.series[0].data = props.chartsData3;
            // newOption.yAxis.min = (Math.min.apply(Math,props.chartsData)-1).toFixed(0);
            // newOption.yAxis.max = (Math.max.apply(Math,props.chartsData)+1).toFixed(0);
        }
        let newOption4 = option4;
        if (props.chartsXData4) {
            newOption4.xAxis.data = props.chartsXData4;
            newOption4.series[0].data = props.chartsData4;
        }
        let newOption5 = option5;
        if (props.chartsXData5) {
            newOption5.xAxis.data = props.chartsXData5;
            newOption5.series[0].data = props.chartsData5;
        }
        setoption(newOption);
        setoption2(newOption2);
        setoption3(newOption3);
        setoption4(newOption4);
        setoption5(newOption5);
        myChart.setOption(newOption, true);
        myChart2.setOption(newOption2, true);
        myChart3.setOption(newOption3, true);
        myChart4.setOption(newOption4, true);
        myChart5.setOption(newOption5, true);
        myChart.hideLoading();
        myChart2.hideLoading();
        myChart3.hideLoading();
        myChart4.hideLoading();
        myChart5.hideLoading();
        myChart.resize();
        myChart2.resize();
        myChart3.resize();
        myChart4.resize();
        myChart5.resize();
    }, [props, option,option2,option3,option4,option5])

    function popularity(e,dateString){
        settimes1(dateString);
        if (props.chartsGoBack1) props.chartsGoBack1(dateString[0],dateString[1],stockProductId1);
    }
    function active(e,dateString){
        settimes2(dateString);
        if (props.chartsGoBack2) props.chartsGoBack2(dateString[0],dateString[1],stockProductId1);
    }
    function dailyRevenueRate(e,dateString){
        settimes3(dateString);
        if (props.chartsGoBack3) props.chartsGoBack3(dateString,stockProductId2);
    }
    function totalRevenueRate(e,dateString){
        settimes4(dateString);
        if (props.chartsGoBack4) props.chartsGoBack4(dateString[0],dateString[1],stockProductId2);
    }
    function transNumber(e,dateString){
        settimes5(dateString);
        if (props.chartsGoBack5) props.chartsGoBack5(dateString[0],dateString[1],stockProductId2);
    }
    function stockProductSelect(value){
        setstockProductId1(value);
        props.chartsGoBack1(times1[0],times1[1],value);
        props.chartsGoBack2(times2[0],times2[1],value);
    }
    function traderProductSelect(value){
        setstockProductId2(value);
        props.chartsGoBack3(times3,value);
        props.chartsGoBack4(times4[0],times4[1],value);
        props.chartsGoBack5(times5[0],times5[1],value);
    }

    return (
        <div>
            <Card hoverable title='股票' extra={
                <Row justify="end">
                    <span style={{ marginRight: 10,marginTop:5 }}>产品:</span>
                    <Select
                        onChange={stockProductSelect}  
                        dropdownMatchSelectWidth={false}
                        defaultValue=''
                        style={{width:150}}
                    >
                        <Option value="">全部</Option>
                        {
                            props.transList.length !== 0 &&
                            props.transList.map((item) => {
                                return (
                                    <Option
                                        key={item.productId}
                                        value={item.productId}
                                    >
                                        {item.productName}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Row>
            }>
                <Row justify='end'>
                    <span style={{ marginRight: 10}}>选择日期:</span>
                    <RangePicker format="YYYY-MM-DD" defaultValue={[moment(moment()),moment(moment())]} onChange={popularity}/>
                </Row>
                <div
                    id="echart1"
                    style={{
                        width: '100%',
                        height: props.height ? props.height : 400,
                    }}
                />
                <Row justify='end'>
                    <span style={{ marginRight: 10}}>选择日期:</span>
                    <RangePicker format="YYYY-MM-DD" defaultValue={[moment(moment()),moment(moment())]} onChange={active}/>
                </Row>
                <div
                    id="echart2"
                    style={{
                        width: '100%',
                        height: props.height ? props.height : 400
                    }}
                />    
            </Card>
            <br/>
            <Card hoverable title='交易员' extra={
                <Row justify="end">
                    <span style={{ marginRight: 10,marginTop:5 }}>产品:</span>
                    <Select
                        onChange={traderProductSelect}
                        dropdownMatchSelectWidth={false}
                        defaultValue=''
                        style={{width:150}}
                    >
                        <Option value="">全部</Option>
                        {
                            props.transList.length !== 0 &&
                            props.transList.map((item) => {
                                return (
                                    <Option
                                        key={item.productId}
                                        value={item.productId}
                                    >
                                        {item.productName}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Row>
            }>
            <Row justify='end'>
                    <span style={{ marginRight: 10}}>选择日期:</span>
                    <DatePicker format="YYYY-MM-DD" defaultValue={moment()} onChange={dailyRevenueRate}/>
                </Row>
                <div
                    id="echart3"
                    style={{
                        width: '100%',
                        height: props.height ? props.height : 400
                    }}
                />
                <Row justify='end'>
                    <span style={{ marginRight: 10}}>选择日期:</span>
                    <RangePicker format="YYYY-MM-DD" defaultValue={[moment(moment()),moment(moment())]} onChange={totalRevenueRate}/>
                </Row>
                <div
                    id="echart4"
                    style={{
                        width: '100%',
                        height: props.height ? props.height : 400
                    }}
                />
                <Row justify='end'>
                    <span style={{ marginRight: 10}}>选择日期:</span>
                    <RangePicker format="YYYY-MM-DD" defaultValue={[moment(moment()),moment(moment())]} onChange={transNumber}/>
                </Row>
                <div
                    id="echart5"
                    style={{
                        width: '100%',
                        height: props.height ? props.height : 400
                    }}
                />
            </Card>
        </div>
    )
}