import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Space,
  Typography,
  message,
  Button,
  DatePicker,
  Modal,
  Input,
  Tooltip,
  Segmented,
  InputNumber,
  Radio
} from 'antd';
import { showLimitStockInfo, addTradeList, showStockAssets } from '@/api/stock';
import { FileWordOutlined } from '@ant-design/icons';
import { renderUpDownRate, autoCol, singleColumns } from '@/utils/utils';
import { labelValues, isValidArray, isValidString } from '@/utils/utils2';
import { useReactive } from 'ahooks';
import { SETTING_OBJ } from './putils';
import StockSearch from '@/view/common/Components/StockSearch';
import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const TODAY = moment().format('YYYY-MM-DD');
const SEG_VALUE = {
  'limit': '涨停票',
  'position': '持仓',
  'buysell': '买入卖出',
}
const SEG_OPTIONS = _.keys(SEG_VALUE).map(k => labelValues([SEG_VALUE[k], k]));

const SettingButton = ({ isTrue, icon, text, onClick }) => {
  return <Tooltip title={isTrue ? text[0] : text[1]}>
    <Button
      type={'text'}
      shape='circle'
      icon={isTrue ? icon[0] : icon[1]}
      onClick={onClick}
    />
  </Tooltip>
}
// 快速创建表格skey的唯一id值
const createTableSkey = (datas) => {
  return isValidArray(datas) ? datas.map((n, i) => {
    return { ...n, skey: _.get(n, 'stockCode') + i }
  }) : []
}
const AddPage = ({ subId = 0, subVals = {}, trade = '', updateKey = '', isTrading = false, goBackTab }) => {
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [addList, setAddList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [tradeDate, setTradeDate] = useState(TODAY);
  const [tradeDate2, setTradeDate2] = useState(_.get(trade, 'isToday') ? TODAY : _.get(trade, 'nextDate'));
  const [loading, setLoading] = useState(false);
  const [editTxt, setEditTxt] = useState('');
  const [tableType, setTableType] = useState(SEG_OPTIONS[0].value);
  const modalState = useReactive({ show: false, code: null, markObj: {} });

  const renderSettingCol = (name, key, condition) => {
    const conditionObj = { 1: 2, 2: 1 };
    return {
      ...singleColumns([name, key, 100, 'center']),
      render: (text, record) => {
        const isTrueStatus = text === condition ? true : false
        return <SettingButton
          isTrue={isTrueStatus}
          icon={SETTING_OBJ[key].icon}
          text={SETTING_OBJ[key].text}
          onClick={() => changeListItem(record.stockCode, key, isTrueStatus ? conditionObj[condition] : condition)}
        />
      }
    }
  }
  const columns = [
    singleColumns(['股票代码', 'stockCode', 100]), // 0
    singleColumns(['股票名称', 'stockName', 120]),// 1
    {
      ...singleColumns(['买入日期', 'buyDate', 150]),// 2
      sorter: (a, b) => a.timeKey - b.timeKey,
      render: (text, record) => text ? moment(text).format('YYYY-MM-DD HH:mm') : ''
    },
    {
      ...singleColumns(['成本价', 'costPrice', 80]),// 3
      render: (text, record) => <Text strong>{text || ''}</Text>
    },
    {
      ...singleColumns(['收益率', 'totalProfitRate', 90]),// 4
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.totalProfitRate - b.totalProfitRate,
      render: (text, record) => renderUpDownRate(text ? _.round(parseFloat(text * 100), 2) : 0)
    },
    renderSettingCol('交易限制', 'tradeType', 1), // 5
    { // 6
      ...singleColumns(['交易执行', 'tradeType', 140, 'center']),
      render: (text, record) => {
        return <Radio.Group onChange={(e) => changeListItem(record.stockCode, 'tradeType', e.target.value)} value={text}>
          <Radio value={0}><Text type='secondary'>不操作</Text></Radio>
          <Radio value={1}><Text type='danger'>买入</Text></Radio>
          <Radio value={2}><Text type='success'>卖出</Text></Radio>
        </Radio.Group>
      }
    },
    renderSettingCol('止盈止损', 'profitLossLimit', 2), // 7
    renderSettingCol('当日止损', 'pctChgLimit', 2), // 8
    {// 9
      ...singleColumns(['备注', 'remark', 200]),
      render: (text, record) => {
        const getRemark = text || _.get(modalState.markObj, record.stockCode, '');
        return isValidString(getRemark) ? <div style={{ cursor: 'pointer' }} onClick={() => addRemark(record.stockCode, getRemark)}>
          <Paragraph ellipsis={{ rows: 3, expandable: true }}>
            <Text>{getRemark}</Text>
          </Paragraph>
        </div> :
          <Tooltip title={'添加备注'}>
            <Button
              type={'text'}
              shape='circle'
              icon={<FileWordOutlined />}
              onClick={() => addRemark(record.stockCode)}
            />
          </Tooltip>
      }
    },
    {// 10
      ...singleColumns(['其他', 'stockAmount', 125]),
      render: (text, record, index) => {
        const isBuy = _.get(record, 'tradeType') === 1 ? true : false;
        return isBuy ? <InputNumber
          min={0}
          step={100}
          placeholder="股票数量"
          style={{ width: 120 }}
          value={text || 0}
          onChange={(v) => changeListItem(record.stockCode, 'stockAmount', v)}
        /> : <div style={{ height: 30, width: 120 }}></div>
      }
    },
  ];

  const col_obj = {
    'limit': [0, 1, 5, 9].map(c => columns[c]),
    'buysell': [0, 1, 6, 10, 9].map(c => columns[c]),
    'position': [0, 1, 2, 3, 4, 5, 7, 8, 9].map(c => columns[c]),
  };

  useEffect(() => {
    setSelectRowKey([]);
  }, [updateKey]);

  async function _showLimitStockInfo() {
    const is_reverse = _.get(subVals, 'centralizedLogin') === 2 ? true : false;
    setLoading(true);
    const res = await showLimitStockInfo({ date: tradeDate + ' 00:00:00' }, is_reverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200') {
      const getList = _.get(res, 'data', []);
      let newList = getList.map((n, i) => {
        return { ...n, skey: _.get(n, 'stockCode') + i, remark: '', tradeType: 2, profitLossLimit: 2, pctChgLimit: 2 }
      });
      setAddList(newList);
      setTableType('limit');
    }
    setLoading(false);
  }

  async function _showStockAssets(type) {
    const is_reverse = _.get(subVals, 'centralizedLogin') === 2 ? true : false;
    setLoading(true);
    const res = await showStockAssets({ subAccountId: subId }, is_reverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200') {
      const getList = _.get(res, 'data', []);
      let newList = getList.map((n, i) => {
        return {
          ...n,
          skey: _.get(n, 'stockCode') + i,
          timeKey: n.buyDate ? moment().diff(moment(n.buyDate), 'm') : 0,
          remark: '',
          tradeType: type === 'buysell' ? 0 : 2,
          profitLossLimit: 1,
        }
      });
      setAssetList(newList);
    }
    setLoading(false);
  }

  async function _addTradeList() {
    const is_reverse = _.get(subVals, 'centralizedLogin') === 2 ? true : false;
    if (subId) {
      const findList = tableType === 'limit' ? addList : assetList;
      let stList = [];
      selectRowKey.map(code => {
        let fIndex = _.findIndex(findList, o => o.skey === code);
        if (fIndex !== -1) {
          stList.push(findList[fIndex]);
        }
      })

      let params = {
        subAccountId: subId,
        tradeDate: tradeDate2,
        stocks: stList,
        type: tableType === 'buysell' ? 2 : 1
      }
      const res = await addTradeList(params, is_reverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        message.success(_.get(res, 'message', '更新成功！!'));
        goBackTab('1');
      } else {
        message.info(_.get(res, 'message', '更新失败!'));
      }
    }
  }

  const changeListItem = (code, key, value) => {
    if (code) {
      const findList = tableType === 'limit' ? addList : assetList;
      let temp = _.cloneDeep(findList);
      let fIndex = _.findIndex(findList, o => o.stockCode === code);
      if (fIndex !== -1) {
        temp[fIndex][key] = value
      }
      if (tableType === 'limit') {
        setAddList(temp);
      } else {
        setAssetList(temp);
      }
    }
  }

  const addRemark = (code, txt) => {
    modalState.show = true;
    modalState.code = code;
    setEditTxt(txt || '');
  }

  const onSelect = (vals) => {
    const findList = tableType === 'limit' ? addList : assetList;
    let getOption = _.omit(vals, ['label', 'value']);
    let temp = _.cloneDeep(findList);
    let tempSelect = _.cloneDeep(selectRowKey);
    let newItem = {
      "tsCode": _.get(getOption, 'stockCode'),
      "stockCode": _.get(getOption, 'symbol'),
      "stockName": _.get(getOption, 'stockName'),
      "remark": '',
      "tradeType": 2,
    }
    temp.unshift(newItem);
    tempSelect.unshift(newItem.stockCode);
    if (tableType === 'limit') {
      setAddList(createTableSkey(temp));
    } else {
      setAssetList(createTableSkey(temp));
    }
    setSelectRowKey(tempSelect);
  }

  const rowSelection = {
    selectedRowKeys: selectRowKey,
    onChange: (selectedRowKeys) => {
      setSelectRowKey(selectedRowKeys);
    },
  };
  const newDataSource = tableType === 'limit' ? addList : assetList;
  return (
    <>
      <Row style={{ marginBottom: 15 }} gutter={[8, 8]}>
        <Col {...autoCol([5, 6])}>
          <Segmented
            options={SEG_OPTIONS}
            value={tableType}
            onChange={(v) => {
              setTableType(v);
              if (isTrading || _.size(assetList) === 0) {
                _showStockAssets(v);
              }
            }}
          />
        </Col>
        <Col {...autoCol([5, 6])}>
          <Space>
            <Text>涨停票: </Text>
            <DatePicker
              value={moment(tradeDate) || null}
              format="YYYY-MM-DD"
              onChange={(date, dateStrings) => {
                setTradeDate(dateStrings);
                _showLimitStockInfo(dateStrings);
              }}
            />
          </Space>
        </Col>
        <Col {...autoCol([6])}>
          <StockSearch width={220} onStockChange={(vals) => onSelect(vals)} />
        </Col>
        <Col {...autoCol([8])} style={{ textAlign: 'right' }}>
          <Space>
            <Text>交易日期</Text>
            <DatePicker
              value={moment(tradeDate2) || null}
              format="YYYY-MM-DD"
              onChange={(date, dateStrings) => {
                setTradeDate2(dateStrings);
              }}
            />
            <Button
              type='primary'
              onClick={() => _addTradeList()}
              disabled={_.size(selectRowKey) === 0 || !subId}
            >
              添加
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        rowKey={'skey'}
        dataSource={newDataSource}
        columns={col_obj[tableType]}
        rowSelection={rowSelection}
        scroll={{ x: 980 }}
        size='small'
        loading={loading}
        pagination={{
          pageSize: 10
        }}
      />

      <Modal
        title='备注'
        visible={modalState.show}
        maskClosable={false}
        width={680}
        onCancel={() => {
          modalState.show = false;
          modalState.code = null;
        }}
        onOk={() => {
          changeListItem(modalState.code, 'remark', editTxt);
          _.set(modalState.markObj, modalState.code, editTxt);
          modalState.show = false;
          modalState.code = null;
        }}
      >
        <TextArea
          value={editTxt}
          onChange={(e) => setEditTxt(e.target.value)}
          rows={6}
        />
      </Modal>
    </>
  )
}

export default AddPage;