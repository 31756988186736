import React from 'react';
import { Typography, Modal, Descriptions } from 'antd';
import { RULES_BASE, RULES_DYM, LABEL_ARRAY, gets } from '../cutils';
import { autoCol } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;
const BASE_KEYS = [
  { label: '昨日是否涨停', key: 'isYLimit', valObj: { 1: '涨停', 0: '未涨停' } },
  { label: '卖出时持仓收益率', key: 'positionRate' },
  { label: '卖出类型', key: 'sellType', valObj: { 1: '收盘卖出', 0: '止盈止损' } },
  { label: '卖出止盈阈值', key: 'stopProfit' },
  { label: '卖出止损阈值', key: 'stopLoss' },
];

const RulesModal = ({ values, avgss, show, onCancel }) => {
  const getSide = _.get(values, 'side', '');
  const getValue = gets(values, 'rollingData', {});
  const getCondition = gets(getValue, 'condition');
  return <Modal
    width={780}
    footer={null}
    visible={show}
    onCancel={onCancel}
  >
    {getSide === 'SELL' ? <Descriptions title="执行规则" size='small' bordered column={{ ...autoCol([2, 2, 1]) }}>
      {BASE_KEYS.map(item => {
        const getVal = _.get(values, item.key, '');
        // const newVal = 'valObj' in item ? item.valObj[getVal]:getVal
        return <Descriptions.Item key={item.key} label={item.label} >
          <Text strong>{'valObj' in item ? item.valObj[getVal] : _.round(getVal * 100, 2) + '%'}</Text>
        </Descriptions.Item>
      })}
    </Descriptions> : <>
      <Descriptions size='small' title={<div style={{ textAlign: 'center' }}>
        <Text strong style={{ color: '#1890ff' }}>{gets(values, 'stockName', '')}</Text>
      </div>} column={{ ...autoCol([2, 2, 1]) }}>
        {RULES_BASE.map(n => {
          return <Descriptions.Item key={n.key} label={n.label}>
            <Text strong>{gets(getValue, n.key)}</Text>
          </Descriptions.Item>
        })}
      </Descriptions>
      {RULES_DYM.map((array, index) => <Descriptions
        size='small'
        key={LABEL_ARRAY[index] + index}
        title={<Text strong type={getCondition === index ? 'warning' : ''} style={{ fontSize: 15 }}>
          {LABEL_ARRAY[index]}
        </Text>}
        style={{ marginTop: 8 }}
        column={1}
      >
        {array.map((n, i) => {
          if ('vobj' in n) { // vobj 直接用object里面用返回值动态显示结果
            return <Descriptions.Item key={n.key} label={n.label}>{gets(n.vobj, gets(getValue, n.key))}</Descriptions.Item>
          } else if ('vkey' in n) { // vkey 是常量key，获取替换 @ 字符串
            const kvalue = gets(getValue, n.key);
            const avgs = gets(avgss, n.vkey);
            let finalArr = _.split(n.label, '');
            return <Descriptions.Item key={n.key}>
              {finalArr.map((t, ti) => {
                if (t === '@') {
                  return <Text code key={ti + avgs}>{avgs}</Text>
                } else if (t === 'X') { // X 替换为显示值
                  return <Text code key={ti + kvalue}>{kvalue}</Text>
                } else {
                  return t
                }
              })}
            </Descriptions.Item>
          } else {
            return <Descriptions.Item key={n.key} label={n.label}>
              <Text code>{gets(getValue, n.key)}</Text>
            </Descriptions.Item>
          }
        })}
      </Descriptions>)}
    </>}
  </Modal>
}

export default RulesModal;