import React, { useEffect, useState } from 'react';
import { Spin, Divider, message } from 'antd';
import { renderValueColor } from '@/utils/utils';
import { useReactive, useMount, useInterval, useDebounce } from 'ahooks';
import { showExcessRateHistory, showSpecificExcessRateHistory } from '@/api/stock';
import { NEW_RANGE_ARRAY, TRADE_OPTION, xnamePro, findRangeIndex, renderDiv, renderExtraSize, calExArray } from '@/view/common/Components/list_util';
import { TradeExTable, CalBars, RateSlider, ExBars } from './trade_widget';
import moment from 'moment';
import _ from 'lodash';
import * as echarts from 'echarts';

let timer = null;
const REVERT_NUM = 999999;
const INTERVAL_NUM = 60000;
const ALL_TEXT = '全部';
const ACTIVE_TYPE = {
  '1': '',
  '2': 'range',
  '3': 'range_plus',
  '4': 'ex'
};
const INIT_SLIDER = [-50, 50];
/**
 *  交易统计图表
 *  普通统计数据+统计列表； 超额部分需要根据每一分钟重新获取接口（根据策略显示，不常用）
 */
export default function TradeCharts(props) {
  const [toolData, setToolData] = useState([]);//收益率数据
  const [toolData2, setToolData2] = useState([]); // 超额收益率数据 ！table两个数据源才能切换，一个数据源会出错
  const [allToolData, setAllToolData] = useState([]);
  const [tName, setTName] = useState(ALL_TEXT);
  const [calDiv, setCalDiv] = useState({});
  const [exeDate, setExeDate] = useState(moment().format('YYYY-MM-DD'));
  const [exeData, setExeData] = useState([]);
  const [updateString, setUpdateString] = useState(REVERT_NUM); //递减更新,触发effect更新
  const updateState = useReactive({ update: REVERT_NUM });//递减更新,判断是否更新
  const exState = useReactive({ rate: {}, time: moment().format('HH:mm:ss'), y: 0 });
  const [debounceUpdate, setDebounceUpdate] = useState([]);
  const debouncedValue = useDebounce(debounceUpdate, { wait: 1100 });
  const [option, setoption] = useState({
    ...TRADE_OPTION.bar,
    tooltip: {
      trigger: 'item',
      formatter: renderFormatter
    },
    dataZoom: [],
  });
  const [option2, setOption2] = useState(TRADE_OPTION.line);
  const [inter, setInter] = useState(undefined);
  const [interCheck, setInterCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [marks, setMarks] = useState([]);
  const [calsAll, setCalsAll] = useState({});

  function renderFormatter(params) {
    const getType = _.get(params, 'componentType', '');
    let finalString = '';
    if (getType === 'markPoint') {
      finalString = params.marker + `<span style='font-weight:600;margin-left:12px'>${params.value}</span>`
    } else {
      finalString = params.marker + ' ' + params.name
        + `<span style='font-weight:600;margin-left:12px'>${params.value}</span>`
        + '<br/>'
    }

    return finalString;
  }

  useMount(() => {
    _showExcessRateHistory();
    // 盘中默认自动获取
    if (_.get(props, 'tradeStatus', '') === 'trading') {
      setInter(INTERVAL_NUM);
      setInterCheck(true);
    }
  });

  async function _showExcessRateHistory(date) {
    const getId = _.get(props, 'subId', 0)
    if (getId) {
      setLoading2(true);
      const res = await showExcessRateHistory({
        subAccountId: getId,
        tradeDate: date ?? exeDate
      });
      if (_.get(res, 'code', '') === '200') {
        setExeData(_.get(res, 'data', []));
        setUpdateString(updateString - 1);
      }
      setLoading2(false);
    }
  }
  // 定时更新interval任务; inter!==undefined的时候进行获取
  useInterval(() => {
    _showExcessRateHistory();
  }, inter);

  useEffect(() => {
    let myChart = props.myChart;
    let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    if (myChart2 !== null && myChart2 !== "" && myChart2 !== undefined) {
      myChart2.dispose();
    }
    myChart = echarts.init(document.getElementById('tradeChats'));
    myChart2 = echarts.init(document.getElementById('tradeChats_line'));

    let newOption = option;
    let newOption2 = option2;
    const rangeKeys = ACTIVE_TYPE[props.active];
    const isEx = rangeKeys === 'ex' ? true : false;
    const getData = _.get(props, 'datas', {}); // ！！返回数据向上取整，2.1/2.9收益率返回 3,负数同理，-2.1、-2.9 返回-3
    // update!==初始值为分时数据； 默认props.exDatas是当日全天统计数据
    const getExData = updateState.update !== REVERT_NUM ? exState.rate : _.get(props, 'exDatas', {});
    let itemArray = []; // 所有分类item的汇总数据；
    if (_.size(getData) > 0 || _.size(getExData) > 0) {
      let xArray = []; // x轴
      let final = []; // 图表value
      let markpoints = [];
      //{-1:[{item}]} key=收益范围 value[数组]=每个item里面是多条股票信息
      // 原key值已向上取整进行收益率分类，但需要统一计算所以，修改为用每个item内的收益率进行收益率分类计算...
      const finalRange = NEW_RANGE_ARRAY[ACTIVE_TYPE[props.active]] ?? [];
      const finalGetData = isEx ? getExData : getData;
      final = finalRange.map((r, i) => { // final创建 x轴、图表value、及 markPoint数据  的初始数据；
        const xName = xnamePro(r, rangeKeys); // 不同命名规则，超额划分更精细的组别
        xArray.push(xName.xname);
        markpoints.push({ 'name': xName.xname, 'key': r, 'xval': xName.value, 'type': rangeKeys, 'tRate': 0, 'tSize': 0, 'bsize': 0 });
        return {
          value: 0,
          itemStyle: { color: renderValueColor(r, 'pure', { up: 'red', down: 'green', zero: '#9e9e9e' }) }
        }
      });
      // 分类tab切换，处理获取的数据数据，给final 和 markpoint数据进行赋值、统计等计算
      Object.keys(finalGetData).map(range => {
        const item = finalGetData[range];
        let newItem = [];
        if (item && _.size(item) > 0) {
          newItem = item.map((n, i) => { //用每个item内的收益率确定范围，并在final进行累加
            const newRate = isEx ? _.get(n, 'excessRate', 0) : _.get(n, 'rate', 0); // 收益率/超额收益率
            const rangeObj = findRangeIndex(newRate, rangeKeys); // 找到范围object
            const ridx = rangeObj.idx;
            const tradeCount = n.stockTradeList ? _.floor(_.size(_.get(n, 'stockTradeList', [])) / 2) : null; // 对数，只有一对算作 1，所以单数不算，除以2向下取整
            final[ridx].value = final[ridx].value + 1; // final赋值每个分组的个数value
            markpoints[ridx].tRate = markpoints[ridx].tRate + newRate; // 每个收益率累加汇总
            markpoints[ridx].tSize = markpoints[ridx].tSize + 1; // 每个收益率记录个数
            markpoints[ridx].bsize = markpoints[ridx].bsize + tradeCount; // 买卖对数，最后需要除以单组个数得出平均
            return {
              ...n,
              kIdx: _.get(n, 'stockCode') + i,
              keyName: rangeObj.name,
              orgRange: newRate,
              tradeListCount: tradeCount
            }
          })
        }
        itemArray = _.concat(itemArray, newItem);
      });
      // 【tips】:统计markpoint计算没10%进行统计，统计+是以1%拆分； 
      const newCals = calMarkpoints(markpoints, isEx);
      markpoints = newCals.marks;
      setCalDiv(newCals.cals);
      setMarks(newCals.marks);
      setCalsAll(newCals.cals)
      // 赋值表格内容
      if (isEx) {
        setToolData2(itemArray);
        newOption.title.text = '超额收益率统计'
      } else {
        setToolData(itemArray);
        newOption.title.text = '收益率统计'
      }

      let maxFinal = _.max(final.map(n => n.value));
      newOption.series[0].data = final;
      newOption.xAxis.data = xArray;
      newOption.series[0].markPoint.data = rangeKeys === 'range_plus' ? [] : markpoints; // 统计+  不需要收益率markpoint
      newOption.yAxis.max = renderExtraSize(maxFinal, 'max');
    } else { // 增加无数据置空，否则切换账户+返回空数据会显示缓存数据
      newOption.series[0].data = [];
      newOption.xAxis.data = [];
    }
    if (rangeKeys === 'range_plus') {
      newOption.dataZoom = [
        { type: 'inside', start: 30, end: 60 },
        { show: true, type: 'slider', top: '92%', start: 30, end: 60 },
      ]
    } else {
      newOption.dataZoom = [];
    }
    setAllToolData(itemArray);
    if (_.size(exeData) > 0) {
      let xtime = [];
      let vals = [];
      exeData.map(n => {
        xtime.push(n.time);
        vals.push(_.round(n.sumRates, 4));
      });
      // 点击收益率曲线后，用markpoint表示当前点
      if (_.size(exState.rate) > 0) {
        newOption2.series[0].markPoint.data[0] = {
          name: '当前点', value: exState.time, xAxis: exState.time, yAxis: exState.y,
          symbol: 'arrow', symbolSize: 20, symbolRotate: 180
        }
      }
      if (updateState.update !== REVERT_NUM) {
        newOption.title.text = exeDate + ' | ' + exState.time;
      } else {
        newOption2.series[0].markPoint.data = [];
      }
      newOption2.xAxis.data = xtime;
      newOption2.series[0].data = vals;
    }
    // 点击柱状图显示该收益率的图表数据
    myChart.on('click', 'series.bar', (param) => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        //点击找到对应范围，并更新table数据
        const getx = _.get(param, 'name', '');
        const fData = _.filter(itemArray, o => o.keyName === getx); //这里传itemArray数据，防止state未更新，会是空数组
        setTName(getx);
        if (isEx) {
          setToolData2(fData);
        } else {
          setToolData(fData);
        }
      }, 500);
    })
    myChart2.on('click', 'series.line', (param) => {
      if (_.get(param, 'componentType') === 'series') {
        const getx = _.get(param, 'name', '')
        const gety = _.get(param, 'data', 0)
        setDebounceUpdate([getx, gety])
      }
    })
    // 监听 鼠标移动到收益曲线上
    myChart2.on('mouseover', 'series.line', (param) => {
      // 触发-更新防抖state;
      if (_.get(param, 'componentType') === 'series') {
        const getx = _.get(param, 'name', '')
        const gety = _.get(param, 'data', 0)
        setDebounceUpdate([getx, gety])
      }
    })
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.resize();
    setOption2(newOption2);
    myChart2.setOption(newOption2, true);
    myChart2.resize();
  }, [props.upCount, props.active, updateString]);

  useEffect(() => {
    setTName(ALL_TEXT);
  }, [props.active]);

  // 防抖debounce延时更新后，触发该effect；进行handleTimeChange时间切换获取数据逻辑；
  useEffect(() => {
    exState.y = debouncedValue[1] ?? 0;
    handleTimeChange(debouncedValue[0] ?? 'null', 'hover')
  }, [debouncedValue])
  // 切换时间
  async function handleTimeChange(t, type) { // type: 时间picker切换还是收益率图表中切换时间
    const getId = _.get(props, 'subId', 0);
    const ffidx = _.findIndex(exeData, o => o.time === t);
    if (ffidx === -1) {
      if (type === 'picker') {
        message.info('请选择时间区间内的时间点！');
      }
      return;
    }
    if (getId) {
      setLoading(true);
      const res = await showSpecificExcessRateHistory({
        subAccountId: getId,
        tradeDate: exeDate,
        time: t,
      });
      if (_.get(res, 'code', '') === '200') {
        //[原：本地数据赋值] exState.rate = _.get(exeData, `[${ffidx}].dataRate`, {});
        exState.rate = _.get(res, 'data', {});
        exState.time = t;
        setUpdateString(updateString - 1);
        updateState.update = updateState.update - 1;
      }
      setLoading(false);
    }
  }
  // 提取计算统计funciton
  function calMarkpoints(mkpoints, is_ex) {
    const zindex = _.findIndex(mkpoints, o => o.key === 0);
    let newMarks = [];
    // 找到非0大小的负收益index值，同理用绝对值找到对称的正收益index值
    const findValidNegIndex = _.findIndex(mkpoints, o => o.key < 0 && o.tSize > 0);
    const findValidPasIndex = _.findIndex(mkpoints, o => o.key === Math.abs(_.get(mkpoints, `[${findValidNegIndex}].key`, 0)));
    let negPasObj = {}; // 计算正收益超额部分需要截取的数组
    if (findValidNegIndex !== -1 && findValidPasIndex !== -1) {
      negPasObj.neg = _.slice(mkpoints, findValidNegIndex, zindex + 1); //非0负数组
      negPasObj.pas = _.slice(mkpoints, zindex + 1, findValidPasIndex + 1); // 0-有效正收益数组
      negPasObj.pasEx = _.slice(mkpoints, findValidPasIndex + 1, _.size(mkpoints)) //超额部分数组
    }
    let calObj = {// calExArray返回对象{rate,size},使用时结构对象使用
      nagetive: 0, nagetiveSize: 0, positive: 0, positiveSize: 0, total: 0, totalSize: 0,
      positiveEx: calExArray(negPasObj.pasEx), positiveMain: calExArray(negPasObj.pas),
    }; // 计算正负及汇总平均收益率；
    // bar markpoint 标记收益率及买卖数量
    newMarks = mkpoints.map(n => {
      if (n.key < 0) { // 统计负收益及个数
        calObj.nagetive = calObj.nagetive + n.tRate;
        calObj.nagetiveSize = calObj.nagetiveSize + n.tSize;
      } else if (n.key > 0) {// 统计正收益及个数
        calObj.positive = calObj.positive + n.tRate;
        calObj.positiveSize = calObj.positiveSize + n.tSize;
      }
      calObj.total = calObj.total + n.tRate; // 总收益及个数
      calObj.totalSize = calObj.totalSize + n.tSize;
      const nval = renderDiv(n.tRate, n.tSize, true); //计算该分组平均收益率
      const bval = renderDiv(n.bsize, n.tSize, false); // 计算平均对数
      const showVal = nval + `\n (${bval})`;
      return {
        ...n,
        'value': is_ex ? showVal : nval,
        'xAxis': n.name, 'yAxis': renderExtraSize(n.tSize, n.type),
        'symbol': '', 'symbolSize': 0, 'label': { color: 'black', show: nval ? true : false }
      }
    });

    return { 'marks': newMarks, 'cals': calObj };
  }

  const isRangeType = ACTIVE_TYPE[props.active] === 'ex' ? false : true;
  return (
    <>
      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="tradeChats"
            style={{ width: '100%', height: 400 }}
          />
        </div>

        <ExBars
          is_range={isRangeType}
          loading={loading}
          loading2={loading2}
          date={exeDate}
          time={exState.time}
          isInter={interCheck}
          onDateChange={(date) => {
            setExeDate(date);
            _showExcessRateHistory(date);
            if (interCheck) {
              setInterCheck(false);
              setInter(undefined);
            }
          }}
          onTimeChange={(time) => {
            exState.time = time;
            handleTimeChange(time, 'picker');
          }}
          onThisDay={() => {
            updateState.update = REVERT_NUM;
            setUpdateString(REVERT_NUM);
          }}
          onSync={() => _showExcessRateHistory()}
          onSwitchChange={(checked) => {
            if (checked) {
              setInter(INTERVAL_NUM);
            } else {
              setInter(undefined);
            }
            setInterCheck(checked);
          }}
        />

        <div style={{ display: props.active === '4' ? 'flex' : 'none', marginTop: -8 }}>
          <div
            id="tradeChats_line"
            style={{ width: '99%', height: 240 }}
          />
        </div>

        {isRangeType && <Divider />}
        {isRangeType && <RateSlider
          active={props.active}
          initSlider={INIT_SLIDER}
          onFinish={(min, max, noSlider) => {
            const filterMark = _.filter(marks, o => o.xval >= min && o.xval <= max);
            const newCals = calMarkpoints(filterMark, false);
            setCalDiv(newCals.cals);
            setToolData(_.filter(toolData, o => o.orgRange >= min && o.orgRange <= max));
          }}
          onReset={() => {
            const newCals = calMarkpoints(marks, false);
            setCalDiv(newCals.cals);
            setToolData(allToolData);
          }}
        />}

        <div style={{ paddingLeft: 25, paddingRight: 25, minHeight: isRangeType ? 400 : 370, marginTop: 12 }}>
          {isRangeType && <CalBars
            all={calsAll}
            calValue={calDiv}
          />}

          <div style={{ height: 16 }}></div>

          <TradeExTable
            isEx={isRangeType}
            actv={props.active}
            is_product={_.get(props, 'is_product')}
            txtName={tName}
            allText={ALL_TEXT}
            datas={{ 'data': toolData, 'dataEx': toolData2 }}
            showAll={() => {
              if (isRangeType) {
                setToolData(allToolData)
              } else {
                setToolData2(allToolData)
              }
              setTName(ALL_TEXT)
            }}
            goDetail={(record, list) => props.onDetail(record, list)}
            showKline={(record) => props.showKline(record, isRangeType ? toolData : toolData2)}
          />
        </div>
      </Spin>
    </>
  )
}
