import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { useReactive, useUpdateEffect, useUnmount } from 'ahooks';
import { store } from '@/store/mobx';
import { useLocalObservable } from 'mobx-react';
import { showSubAccountHistory, showSingleProductHistory, showBatchProductHistory, showBatchSubAccountHistory, showIndexKline } from '@/api/workbench';
import { showFundKline } from '@/api/details';
import { isValidArray, isValidObj, isValidNumber, handleCustomIndexPms } from '@/utils/utils2';
import {
  TWO_CHARTS, STATIC_RATE_COLOR, PRE_DAY_CN,
  calMaxMin, calNets, renderSlice, renderFloats, handleRetreat, calRate, isFullTimeRange, handleIndexValue, createBaseLine
} from './Components/chartsInfoUtils';
import { calCustomIndex } from './Components/list_util';
import { MainSlider } from './Components/main_widget';
import { INDEX_CODE_VALUE, DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import TwoBars from './Components/TwoBars';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const TODAY = moment().format('YYYY-MM-DD');
const INDEX_COLOR_OBJ = {
  lineStyle: {
    color: '#B5495B',
    type: 'dashed',
    width: 1,
  },
  itemStyle: {
    color: '#B5495B'
  },
}
let timer = null;
/**
 * 该图表实现为两条指数曲线波幅进行对比显示，故获取暂采用同时获取两次指数数据进行显示;
 */
export default function CompareChartsTwo(props) {
  const mobxStore = useLocalObservable(() => store);
  const [all_product_acc, set_all_product_acc] = useState(JSON.parse(JSON.stringify(mobxStore.allProductAcc)));
  const [accState, setAccState] = useState({
    productObj: _.get(all_product_acc, 'productObj'), accObj: _.get(all_product_acc, 'accObj'),
    accountOptions: _.get(all_product_acc, 'account'), productOptions: _.get(all_product_acc, 'product'),
    accLogin: _.get(all_product_acc, 'accLogin'), productLogin: _.get(all_product_acc, 'productLogin'),
  });
  const [customIndex, setCustomIndex] = useState(JSON.parse(JSON.stringify(mobxStore.customIndex)) ?? [])
  const [update, setUpdate] = useState(0);
  const [update2, setUpdate2] = useState(0);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据
  const [fullSeries, setFullSeries] = useState([]); // 完整series数据
  const [fullSeriesRetreat, setFullSeriesRetreat] = useState([]); // 完整series数据
  const [rateDatas, setRateDatas] = useState({});
  const [checkboxValues, setCheckBoxValues] = useState([]);
  const [checkboxItem, setCheckBoxItem] = useState([]);
  const compareState = useReactive({
    type: _.get(props, 'idxPms.dateType'), getType: '', acc: '', pdct: '', fund: [],
    idxSymbol: DEFAUL_INDEX_CODE, fundNames: {}
  });
  const [option, setoption] = useState(TWO_CHARTS.main);
  const [option2, setoption2] = useState(TWO_CHARTS.retreat);
  const getPageKeys = _.get(props, 'pageKey', '');

  const TYPE_OBJ = { 'acc': 'subAccountId', 'product': 'productId' };
  // 获取账户/产品收益率
  async function _getRates(type = '', gtype = '', id = 0) {
    // promise需要的获取单一产品的方法
    const singleGetRate = async (id, pms, isRev) => {
      const singleGet = type === 'acc' ? await showSubAccountHistory(pms, isRev ? 'FC' : 'NOR') : await showSingleProductHistory(pms, isRev ? 'FC' : 'NOR');
      // 返回格式与批量返回相同 {id:{data数据}}
      return new Promise(resolve => resolve({ [id]: _.get(singleGet, 'data', {}) }));
    }
    // 获取单一私募
    const singleGetFund = async (id, pms) => {
      const singleFund = await showFundKline(pms);
      return new Promise(resolve => resolve({ [id]: _.get(singleFund, 'data', {}) }));
    }

    const currentOptions = _.get(accState, type === 'acc' ? 'accountOptions' : 'productOptions', []);
    //const typeKeys = _.filter(currentOptions, o => o.centralizedLogin === 2).map(n => n.value); // 所有反采id
    const getParamsFromProps = type === 'acc' ? _.get(props, 'accountInfo', {}) : _.get(props, 'productInfo', {});
    if (isValidObj(getParamsFromProps)) {
      let params = {
        'dateType': compareState.type,
        'date': _.get(getParamsFromProps, 'dateToday', TODAY),
      }
      const newId = id ? id : _.get(getParamsFromProps, TYPE_OBJ[type]);
      _.set(params, TYPE_OBJ[type], newId);
      // checkboxItem滞后性，传入新id是无法做promise数组完整的获取
      const curAllIds = _.concat(checkboxItem.map(c => c.keyId), id ? id : newId);
      // 根据id找到是否是反采账户，反采账户不进行批量获取
      const curType = _.chain(currentOptions).filter(o => o.value === newId).head().get('centralizedLogin').value();
      let has_reverse = curType === 2 ? true : false;
      let isNorBatch = gtype === 'batch' ? true : false;
      // checkbox中包含反采账户，并且当前不是在反采账户\产品内，则强制认为是batch，这样handleResData处理数据将没有反采数据，置空操作；
      let needProGet = false;
      let proAll = []; // promise 数组
      if (isValidArray(curAllIds)) {
        // 当前选项中是否包含反采账户
        const revFundArr = curAllIds.map(id => {
          const id_num = parseInt(id);
          const is_rev = _.get(accState, `${type === 'acc' ? 'accLogin' : 'productLogin'}.${id_num}`) === 2 ? true : false;
          const is_fund = !isValidNumber(id_num) ? true : false;
          return is_rev || is_fund;
        });
        // 包含反采则遍历提取id，赋值给promise；【新】私募与反采合并逻辑
        if (_.includes(revFundArr, true)) {
          curAllIds.map(id => {
            const key_id = id;
            const keyId_num = parseInt(id);
            if (isValidNumber(keyId_num) && TYPE_OBJ[type]) {
              const is_rev = _.get(accState, `${type === 'acc' ? 'accLogin' : 'productLogin'}.${keyId_num}`) === 2 ? true : false;
              let singlePms = {
                ...params,
                [TYPE_OBJ[type]]: keyId_num
              };
              proAll.push(singleGetRate(keyId_num, singlePms, is_rev));
            } else if (!isValidNumber(keyId_num)) {
              let fundPms = {
                'dateType': params.dateType,
                'date': params.date,
                'jjdm': key_id
              };
              proAll.push(singleGetFund(key_id, fundPms))
            }
            // 执行promise = true
            if (_.size(proAll) > 0) {
              needProGet = true;
            }
          });
        }
      };
      // 批量增加批量字段; 【新】基金id不是纯数组，批量不获取
      if (isNorBatch) {
        let newKeyIds = [];
        curAllIds.map(id => {
          if (isValidNumber(parseInt(id))) {
            newKeyIds.push(id)
          }
        })
        _.set(params, TYPE_OBJ[type], _.join(newKeyIds, ','));
      };
      let res = {};
      if (type === 'acc' && !needProGet) {
        res = isNorBatch ? await showBatchSubAccountHistory(params) : await showSubAccountHistory(params, has_reverse ? 'FC' : 'NOR');
      } else if (type === 'product' && !needProGet) {
        res = isNorBatch ? await showBatchProductHistory(params) : await showSingleProductHistory(params, has_reverse ? 'FC' : 'NOR');
      }
      // 批量用单一接口获取数据，将使用response的统一格式进行处理
      if (needProGet) {
        const allResult = await Promise.all(proAll);
        let resData = {}
        allResult.map(result => {
          let newResult = {};
          const getId = _.head(_.keys(result));
          // 对fund数据进行收益率计算
          if (!isValidNumber(parseInt(getId))) {
            let newFundData = {
              'netValueList': _.get(result[getId], 'netValue', []),
              'timeList': _.drop(_.get(result[getId], 'timeList', [])),
            };
            if (isValidArray(newFundData.netValueList)) {
              const getNets = _.get(result[getId], 'netValue', []).map(n => parseFloat(n));
              _.set(newFundData, 'pointList', calRate(getNets, getNets[0]));
              _.set(newFundData, 'netValueList', getNets);
            };
            newResult = { [getId]: newFundData };
          } else {
            newResult = result;
          }
          if (result[getId]) {
            resData = _.assign(resData, newResult);
          }
        });
        res = { 'code': '200', 'data': resData };
      }
      if (_.get(res, 'code', '') === '200') {
        const newPms = _.assign(params, {
          'id': _.get(params, TYPE_OBJ[type]),
          'isBatch': isNorBatch,
          'isReverse': needProGet
        });
        handleResData(res, newPms);
      }
    }
  }
  // 处理指数数据
  async function _handleIndexKline(date, newDate) {
    let params = {
      'symbol': compareState.idxSymbol,
      'dateType': compareState.type,
      'date': date,
    };
    const idxPms2 = handleCustomIndexPms(params, customIndex);
    if (idxPms2.symbolInit) {
      compareState.idxSymbol = DEFAUL_INDEX_CODE;
    };
    if (idxPms2.isCusFromStorage) {
      setCustomIndex(_.get(idxPms2, 'customs', []));
    };
    const res = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
    // 格式为原始res，嵌套一层data字段
    let indexRes = idxPms2.isBatch
      ? { 'data': calCustomIndex(_.get(res, 'data', {}), idxPms2.rates, newDate, params.dateType) }
      : res;
    return indexRes;
  }
  // 统一处理返回数据
  async function handleResData(res, pms) {
    const is_batch = _.get(pms, 'isBatch');
    const is_reverse = _.get(pms, 'isReverse');
    const isNeedBatch = is_batch || is_reverse ? true : false;
    // 批量和反采都是以空创建数据；【tips】反采切换周期时直接清空累加数据，需要重新选择
    let initTemp = isNeedBatch && isValidNumber(pms.id) ? {} : _.cloneDeep(rateDatas);
    const getData = _.get(res, 'data', {});
    // 处理批量数据
    if (isNeedBatch && isValidObj(getData)) {
      _.keys(getData).map(ids => {
        const sub_data = _.get(getData, ids, {});
        if (isValidObj(sub_data) && isValidArray(_.get(sub_data, 'timeList'))) {
          _.set(initTemp, ids, handleRateData(sub_data, pms.dateType, isValidNumber(parseInt(ids))));
        }
      })
    } else { // 单独增加的数据进行累加赋值
      if (!isValidObj(getData) || !isValidArray(_.get(getData, 'timeList'))) {
        message.info('暂无数据!');
        compareState.acc = '';
        compareState.pdct = '';
        return;
      }
      _.set(initTemp, pms.id, handleRateData(getData, pms.dateType, isValidNumber(pms.id)));
    }
    let temp = sliceUnmatchDates(initTemp, pms.dateType); // 裁剪数据
    // 指数计算；使用main图表计算逻辑，计算指数曲线
    const newTimes = _.get(temp, 'times');
    const idxRes = await _handleIndexKline(pms.date, newTimes);
    const newIndexs = handleIndexValue(pms.dateType, newTimes, _.assign(idxRes, { 'lastIndex': temp.dataSize }));
    _.set(temp, 'INDEX', {
      'retreat': handleRetreat(newIndexs.priceValues),
      'vol': newIndexs.vol,
      'margin': newIndexs.margin,
      ...newIndexs,
    });
    // 设置统一时间及slider
    setTimeArr(newTimes.map((n, i) => i));
    setTimeNameArr(newTimes);
    setRateDatas(temp);
    setUpdate(_.round(update + 0.1, 1));
    compareState.getType = pms.dateType;
  }
  // 对完整对象进行处理
  function sliceUnmatchDates(fullData = {}, dateType = '') {
    let final = {};
    let dataSize = 0;
    // 找到最大的时间数据
    let maxSize = 0; let maxTimeList = [];
    _.keys(fullData).map(ids => {
      const get_size = _.get(fullData, `${ids}.timeSize`, -1);
      if (maxSize === 0 || get_size > maxSize) {
        maxSize = get_size;
        dataSize = _.size(_.get(fullData, `${ids}.pointList`, []));
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      };
      // 基金数据为基准，赋值999，产品数据按照基金的时间轴进行计算，计算逻辑可不变
      if (get_size === 999) {
        maxSize = 999;
        maxTimeList = _.get(fullData, `${ids}.timeList`, []);
      }
    });
    // 再次遍历数据,赋值final
    _.keys(fullData).map(ids => {
      final[ids] = _.cloneDeep(_.get(fullData, ids));
      //小于最大时间数据进行处理
      if (_.get(fullData, `${ids}.timeSize`, 0) !== maxSize) {
        const isValidShort = isValidObj(_.get(fullData, ids)) && 'short_time' in _.get(fullData, ids) ? true : false
        // 短时间轴(含前一交易日);[前一,9-10,9-11...] ; 
        //【bug-fix】:一直使用timeList字段，会在大于2条线对比下，使用修复过的时间进行对比，导致数据前移，对不上；使用short_time初始时间进行计算
        const getShortTime = isValidShort ? _.get(fullData, `${ids}.short_time`, []) : _.get(fullData, `${ids}.timeList`, []);
        //【bug-fix】 用长时间轴遍历，短时间轴有数据返回对应自己时间的index值;
        let sindex_array = []; // 记录数组方便找到最小值
        let timeIndex = maxTimeList.map(t => {
          let sindex = getShortTime.indexOf(t) - 1; // 下面提取pointsNet数据是需-1，减掉前一index是正确的数据位
          if (sindex > -1) {
            sindex_array.push(sindex);
          }
          return sindex; // 生成长轴时间数组，对应短轴的数据位置；例：[-1,7,8,9...,-2,-2,-2,-2]  -1 =前一，-2为未找到
        });
        // 重新计算短轴时间数据，【bug-fix】短轴时间可早于长轴时间，所以需要重新计算
        let shortNets = timeIndex.map(ti => ti > -1 ? _.get(fullData, `${ids}.pointsNet[${ti}]`) : '-');
        let shortStart = _.min(sindex_array) > 0 ? _.get(fullData, `${ids}.pointsNet[${_.min(sindex_array) - 1}]`) : _.get(fullData, `${ids}.preNet`);
        //【bug-fix】 实时点位不对称，preNet实时为0，所以计算错误；该情况默认start=pointNet第一位净值数据
        if (!shortStart && dateType === 'TODAY') {
          shortStart = _.get(fullData, `${ids}.pointsNet[0]`);
        }
        shortNets[0] = shortStart; // 前一交易日 = shortStart的值
        _.set(final, `${ids}.pointList`, calRate(shortNets, shortStart));
        _.set(final, `${ids}.pointRetreat`, handleRetreat(shortNets));
        _.set(final, `${ids}.timeList`, maxTimeList);
        _.set(final, `${ids}.timeIndex`, timeIndex);
        _.set(final, `${ids}.short_time`, getShortTime);// 获取到的初始时间数据
        _.set(final, `${ids}.short_net`, shortNets);
      }
    });
    final.times = maxTimeList;
    final.dataSize = dataSize;
    return final;
  }
  //处理获取的收益率数据
  function handleRateData(datas = {}, dateType = '', isNor = true) {
    const getPointList = _.get(datas, 'pointList', []);
    const pointNetList = _.get(datas, 'netValueList', []); // 返回的净值数据
    const preNetVal = _.get(datas, 'preNetValue', 0); // 返回的净值数据
    const getTimeList = _.get(datas, 'timeList', []);
    const isToday = dateType === 'TODAY' ? true : false;
    let dataObj = {}; // 返回的data,object
    let points = _.size(getPointList) > 0 ? getPointList.map(n => renderFloats(n, 1)) : [];
    let pointsNet = pointNetList;
    if (isToday) { // 实时收益率先计算净值，然后按照计算回撤
      pointsNet = calNets(points);
    }
    let retreat = handleRetreat(pointsNet); // 回撤数据
    // isNor=是否为普通产品；基金没有前一交易日，已返回第一天作为前一日=0
    dataObj.pointList = isToday ? points : _.concat(isNor ? [0] : [], points);
    dataObj.pointRetreat = isToday ? retreat : _.concat(isNor ? [0] : [], retreat);
    dataObj.timeList = isToday ? getTimeList : _.concat([PRE_DAY_CN], getTimeList);
    dataObj.preNet = isToday ? 0 : preNetVal;
    dataObj.timeSize = isNor ? _.size(getTimeList) : 999;
    _.set(dataObj, 'pointsNet', pointsNet);
    return dataObj;
  }

  useUnmount(() => {
    timer && clearTimeout(timer);
  });
  // tab切换获取当前收益率数据
  useEffect(() => {
    _updatePage();
  }, [props.active]);

  function _updatePage() {
    let is_get = false; let is_acc_get = false;
    if (props.active === 'ACC_COM' && getPageKeys === 'account') {
      _getRates('acc');
      is_get = true;
      is_acc_get = isValidObj(_.get(accState, 'accObj')) && isValidArray(_.get(accState, 'accountOptions')) ? false : true;
    }
    if (props.active === 'PRO_COM' && getPageKeys === 'product') {
      _getRates('product');
      is_get = true;
      is_acc_get = isValidObj(_.get(accState, 'productObj')) && isValidArray(_.get(accState, 'productOptions')) ? false : true;
    }
    //【230926】账户改为mobx全局获取
    if (is_get && is_acc_get) {
      mobxStore._getProductAccount(true);
      // 延时获取更新
      timer = setTimeout(() => {
        let newProductAcc = JSON.parse(JSON.stringify(mobxStore.allProductAcc));
        set_all_product_acc(newProductAcc);
        setAccState({
          productObj: _.get(newProductAcc, 'productObj'), accObj: _.get(newProductAcc, 'accObj'),
          accountOptions: _.get(newProductAcc, 'account'), productOptions: _.get(newProductAcc, 'product'),
          accLogin: _.get(newProductAcc, 'accLogin'), productLogin: _.get(newProductAcc, 'productLogin'),
        });
      }, 2500);
    }
  }

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('compareChartsTwo'));
    myChart2 = echarts.init(document.getElementById('compareChartsTwo_retreat'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });
    // 创建新indexName，合并自定义和原始指数名称
    let newCodeVal = INDEX_CODE_VALUE;
    customIndex.map(item => {
      newCodeVal[item.value] = item.label;
    });

    const pcdtMap = _.get(accState, 'productObj'); const accMap = _.get(accState, 'accObj');
    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let newSeires = []; let retreatSeires = []; let min = 0; let max = 0;
    let legend = []; let legVals = [];
    // 加载完整生成serise数据
    if (isValidObj(rateDatas)) {
      let color_count = 0;
      _.keys(rateDatas).map(keyId => {
        if (!_.includes(['dataSize', 'times', 'INDEX'], keyId)) {
          let getName = getPageKeys === 'account' ? _.get(accMap, keyId) : _.get(pcdtMap, keyId);
          const isFund = !isValidNumber(parseInt(keyId));
          if (isFund) {
            getName = _.get(compareState.fundNames, keyId);
          }
          //const slice_name = sliceName(getName); // 裁切名字 ; 
          let sobj = createBaseLine(STATIC_RATE_COLOR[color_count], {
            'name': getName, 'yAxisIndex': 0, keyId, 'isDot': isFund,
            'data': _.get(rateDatas, `${keyId}.pointList`, []),
          });
          newSeires.push(sobj);
          let robj = createBaseLine(STATIC_RATE_COLOR[color_count], {
            'name': getName, keyId, 'isDot': isFund,
            data: _.get(rateDatas, `${keyId}.pointRetreat`, [])
          });
          retreatSeires.push(robj);
          legend.push({ name: getName, fullName: getName, keyId });
          legVals.push(keyId);
          min = calMaxMin(robj.data, min, 'min');
          max = calMaxMin(robj.data, max, 'max');
          if (color_count >= 9) {
            color_count = 0;
          } else {
            color_count++;
          }
        } else if (keyId === 'INDEX') {
          // const getVol = _.get(rateDatas, 'INDEX.volume', []);
          newSeires.unshift({
            name: newCodeVal[compareState.idxSymbol],
            is_index: true,
            yAxisIndex: 0,
            type: 'line', symbol: 'none',
            data: _.get(rateDatas, 'INDEX.indexValues', []),
            ...INDEX_COLOR_OBJ
          });

          retreatSeires.unshift({
            name: newCodeVal[compareState.idxSymbol],
            is_index_retreat: true,
            type: 'line', symbol: 'none', data: _.get(rateDatas, 'INDEX.retreat', []),
            ...INDEX_COLOR_OBJ
          });
          min = calMaxMin(_.get(rateDatas, 'INDEX.retreat', []), min, 'min');
          max = calMaxMin(_.get(rateDatas, 'INDEX.retreat', []), max, 'max');
        }
      });
    }
    // 赋值slider的滑动数据
    if (_.last(timeArr)) {
      setSliderValue([0, _.last(timeArr)]);
      setSliderValue2([0, _.last(timeArr)]);
    }
    setFullSeries(newSeires);
    setFullSeriesRetreat(retreatSeires);
    // 更新checkbox；默认全选
    setCheckBoxItem(legend);
    setCheckBoxValues(legVals);

    newOption.xAxis.data = _.get(rateDatas, 'times', []); newOption2.xAxis.data = _.get(rateDatas, 'times', []);
    // newOption.legend.data = legArr; newOption2.legend.data = legArr;
    newOption.series = newSeires; newOption2.series = retreatSeires;
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.hideLoading();
    myChart.resize(); myChart2.resize();
    echarts.connect([myChart, myChart2]);
  }, [update]);
  /**
   * slider滑动调用，更新数据；
   * 【逻辑根据平台 MainRateCharts 收益率页面修改，只对收益率进行处理，以下为精简逻辑进行实现
   */
  useUpdateEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    myChart = echarts.init(document.getElementById('compareChartsTwo'));
    myChart2 = echarts.init(document.getElementById('compareChartsTwo_retreat'));
    const isFullRange = isFullTimeRange(sliderValue, timeArr); // 全部时间轴时，说明无需截取，用收盘价作为第一点位数据。
    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    let min = 0; let max = 0;
    const indexSeries = _.filter(fullSeries, o => 'is_index' in o);
    const indexRetreat = _.filter(fullSeriesRetreat, o => 'is_index_retreat' in o);
    let filterSeries = _.concat(indexSeries, _.filter(fullSeries, o => _.includes(checkboxValues, o.keyId)));
    let filterRetreat = _.concat(indexRetreat, _.filter(fullSeriesRetreat, o => _.includes(checkboxValues, o.keyId)));
    if (isFullRange) {// 全部时间赋值完整数据
      const fullTimes = _.cloneDeep(timeNameArr);
      newOption.series = _.cloneDeep(filterSeries);
      newOption2.series = filterRetreat.map(r => { // 回撤需要重新计算最大最小值，重新遍历
        min = calMaxMin(r.data, min, 'min');
        max = calMaxMin(r.data, max, 'max');
        return r;
      });
      newOption.xAxis.data = fullTimes;
      newOption2.xAxis.data = fullTimes;
    } else { // 裁切
      const sliceTimes = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
      let sdata = [];
      filterSeries.map((sir, i) => {
        let sliceRates = []; let sliceOrg = [];
        if ('is_index' in sir) {
          if ('is_vol' in sir) {
            const getVol = _.get(rateDatas, 'INDEX.volume', []);
            const sliceVol = renderSlice(_.concat([0], getVol), sliderValue[0], sliderValue[1]);
            sliceRates = sliceVol;
            sliceOrg = [];
          } else {
            // 处理指数数据
            const indexPrice = _.get(rateDatas, `INDEX.priceValues`, []);
            const slicePriceOrg = renderSlice(indexPrice, sliderValue[0], sliderValue[1]);
            const indexStart = _.get(slicePriceOrg, '[0]', 0);
            sliceRates = calRate(slicePriceOrg, indexStart);
            sliceOrg = slicePriceOrg;
          }
        } else {
          //对series进行遍历裁剪
          const pointsData = _.get(rateDatas, `${sir.keyId}.pointList`, []);
          const pointsNet = calNets(pointsData); // 计算净值
          const slicePoints = renderSlice(pointsData, sliderValue[0], sliderValue[1]); //没有开始点位时保留原收益率曲
          const sliceRateOrg = renderSlice(pointsNet, sliderValue[0], sliderValue[1]);
          // 【bug-fix】如计算时已添加0为第一位，只滑动右侧，开始的计算会出问题，所以slice时start默认支取第0位的数据
          const getStart = _.get(sliceRateOrg, `[0]`);
          sliceRates = isValidNumber(getStart) ? calRate(sliceRateOrg, getStart) : slicePoints; // 重新计算收益率
          sliceOrg = sliceRateOrg;
        }
        sdata.push({ ...sir, data: sliceRates }); // 【bug-fix】第一位无需重新计算0，否则会引起偏移
        // 回撤裁剪
        let fidx = _.findIndex(newOption2.series, o => o.name === sir.name);
        const sliceRetreat = handleRetreat(sliceOrg);
        // 回撤与series因成交量不对等，需要找到index再赋值
        if (fidx > -1) {
          _.set(newOption2, `series[${fidx}].data`, sliceRetreat); // 【bug-fix】裁切回撤无需头部加 0
        }
        min = calMaxMin(sliceRetreat, min, 'min');
        max = calMaxMin(sliceRetreat, max, 'max');
      });
      newOption.series = sdata;
      newOption.xAxis.data = sliceTimes;
      newOption2.xAxis.data = sliceTimes;
      // 【bug-fix】裁切情况下checkbox变化回测无变化
      newOption2.series.map(sir => {
        if (!_.includes(checkboxValues, sir.keyId) && !_.get(sir, 'is_index_retreat')) {
          _.set(sir, 'data', []);
        }
      });
    }
    newOption2.yAxis.min = min; newOption2.yAxis.max = max;

    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true); myChart2.setOption(newOption2, true);
    myChart.resize(); myChart2.resize();
  }, [update2]);

  function updatePage() {
    setUpdate2(_.round(update2 + 0.1, 1));
  }

  function handleClear() {
    setRateDatas({});
    setCheckBoxItem([]);
    setCheckBoxValues([]);
    updatePage();
  }
  // checkbox.group 修改选择项
  const onSubValuesChange = (checkedValues) => {
    setCheckBoxValues(checkedValues);
    updatePage();
  };
  // slider完成后
  function onSliderFinish(v) {
    setSliderValue(v);
    setSliderValue2(v);
    updatePage();
  };
  // 删除chekcbox的选项
  function onDelCheckItem(itm) {
    let tempItem = _.cloneDeep(checkboxItem);
    let tempCheck = _.cloneDeep(checkboxValues);
    let tempRate = _.cloneDeep(rateDatas);
    _.pullAllBy(tempItem, [{ 'keyId': itm.keyId }], 'keyId');
    _.pullAll(tempCheck, [itm.keyId]);
    setCheckBoxItem(tempItem);
    setCheckBoxValues(tempCheck);
    setRateDatas(_.omit(tempRate, [itm.keyId]));
    updatePage();
  }
  // type: acc/product
  function accountChange(type, v) {
    let finalVal = null;
    if (type === 'product') {
      compareState.pdct = v;
      finalVal = v;
    } else if (type === 'fund') {
      compareState['fund'] = v.value;
      compareState.fundNames[v.value] = v.label;
      finalVal = v.value;
    } else {
      compareState[type] = v;
      finalVal = v;
    };
    _getRates(type, 'single', finalVal);
  }
  const charts_height = 385;
  const barsProps = {
    'typeVal': compareState.type, 'compareTypeName': getPageKeys === 'account' ? '账户' : '产品', 'isAccount': getPageKeys === 'account' ? true : false,
    'accountOptions': accState, 'accountVal': { 'acc': compareState.acc, 'pdct': compareState.pdct, 'fund': compareState.fund }, 'idxSymbol': compareState.idxSymbol,
    'isClearDisable': _.size(checkboxItem) === 0 ? true : false, 'checkVal': checkboxValues, 'checkItems': checkboxItem, 'customIndex': customIndex,
    'onCheckChange': onSubValuesChange, 'onClear': handleClear
  };
  return (
    <>
      <TwoBars
        {...barsProps}
        onAccountChange={accountChange}
        onTypeChange={(v) => {
          compareState.type = v;
          // compareState.fund = [];
          if (_.size(checkboxItem) >= 1) { // 超过
            _getRates(getPageKeys === 'account' ? 'acc' : 'product', 'batch');
          }
        }}
        onIdxChange={(v) => {
          compareState.idxSymbol = v;
          _updatePage();
        }}
        onDelItem={onDelCheckItem}
      />

      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex' }}>
          <div
            id="compareChartsTwo"
            style={{ width: '99%', height: charts_height }}
          />
        </div>
      </Spin>

      <MainSlider
        isNotToday={compareState.getType !== 'TODAY' ? true : false}
        isFull={isFullTimeRange(sliderValue, timeArr)}
        timeNameArray={timeNameArr}
        timeArray={timeArr}
        empComp={<div style={{ height: 32 }}></div>}
        value={sliderValue}
        svalue={sliderValue2}
        onSliderChange={(v) => setSliderValue2(v)}
        onSliderAfterChange={(v) => onSliderFinish(v)}
        onReset={() => onSliderFinish([0, _.last(timeArr)])}
      />

      <div style={{ display: 'flex' }}>
        <div
          id="compareChartsTwo_retreat"
          style={{ width: '99%', height: charts_height }}
        />
      </div>
    </>
  )
}