import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  PauseOutlined,
  CheckOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import _ from 'lodash';

const renderIconStyle = (color = 'black') => {
  return { fontSize: 18, color }
}

export const SETTING_OBJ = {
  'tradeType': {
    icon: [<CheckCircleOutlined style={renderIconStyle('green')} />, <MinusCircleOutlined style={renderIconStyle('black')} />],
    text: ['自动交易', '限制交易'],
    positionTxt: ['自动', '限制'],
    on: '#096dd9', off: '#626262',
    positionIcon: [<CloseCircleOutlined />, <CheckCircleOutlined />],
    positionBtnTxt: ['设置为限制', '设置为自动']
  },
  'profitLossLimit': {
    icon: [<PauseOutlined style={renderIconStyle('#91AD70')} />, <CheckOutlined style={renderIconStyle('#AB3B3A')} />],
    text: ['受限', '不受限'],
    positionTxt: ['受限(关闭)', '不受限(开启)'],
    on: '#91AD70', off: '#AB3B3A',
    positionIcon: [<CheckOutlined />, <PauseOutlined />],
    positionBtnTxt: ['开启止盈', '关闭止盈']
  },
  'pctChgLimit': {
    icon: [<PauseOutlined style={renderIconStyle('#a4d36c')} />, <CheckOutlined style={renderIconStyle('#e3dd67')} />],
    text: ['受限', '不受限'],
    positionTxt: ['受限(关闭)', '不受限(开启)'],
    on: '#a4d36c', off: '#e3dd67',
    positionIcon: [<CheckOutlined />, <PauseOutlined />],
    positionBtnTxt: ['开启当日', '关闭当日']
  },
};

export const TRADE_TYPE_OBJ = {
  status: { 0: '不操作', 1: '买入', 2: '卖出' },
  color: { 0: 'black', 1: 'red', 2: 'green' },
}