import React from 'react';
import { Card, Col, Row, Typography, Space, Divider } from 'antd';
import { PlusOutlined, DollarOutlined, UpSquareOutlined, SettingOutlined } from '@ant-design/icons';
import StatisticHeader from '@/utils/StatisticHeader';
import { autoCol } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;

const AstsHeader = ({
  assetss = {},
  visitor = false,

  goAddProduct,
  goPosition,
  goBatchsell,
  goConfig
}) => {
  return <Card style={{ marginTop: 18 }}>
    <Row gutter={[8, 8]}>
      <Col {...autoCol([3])}>
        <StatisticHeader title={'管理的产品数'} value={_.get(assetss, 'totalProduct', '')} />
      </Col>
      <Col {...autoCol([5])}>
        <StatisticHeader title={'产品总金额'} value={_.get(assetss, 'totalAssets', '')} isColor />
      </Col>
      <Col {...autoCol([4])}>
        <StatisticHeader title={'产品总收益'} value={_.get(assetss, 'totalRevenue', '')} isColor />
      </Col>
      <Col {...autoCol([4])}>
        <StatisticHeader title={'产品日收益'} value={_.get(assetss, 'dailyRevenue', '')} isColor />
      </Col>
      <Col {...autoCol([4])}>
        <StatisticHeader title={'空闲资金'} value={_.get(assetss, 'freeBalance', '')} />
      </Col>
      {!visitor && <Col {...autoCol([4])}>
        <Space>
          <div className='optionDiv' onClick={goAddProduct}>
            <Space size='small'>
              <Text strong>添加</Text>
              <PlusOutlined />
            </Space>
          </div>
          <div className='optionDiv' onClick={goPosition}>
            <Space size='small'>
              <Text strong>持仓</Text>
              <DollarOutlined style={{ color: '#ed891f' }} />
            </Space>
          </div>
        </Space>
        <Divider style={{ margin: '12px 0' }} />
        <Space>
          <div className='optionDiv' onClick={goBatchsell}>
            <Space size='small'>
              <Text strong>卖出</Text>
              <UpSquareOutlined style={{ color: 'green' }} />
            </Space>
          </div>
          <div className='optionDiv' onClick={goConfig}>
            <Space size='small'>
              <Text strong>配置</Text>
              <SettingOutlined />
            </Space>
          </div>
        </Space>
      </Col>}
    </Row>
  </Card>
}

export default AstsHeader;