import React, { useState, useEffect } from 'react';
import { Card, Button, Select, Row, Table, Spin, Modal, Input, message, PageHeader } from 'antd';
import { getStockPoolStocks, getStockScreeningList, addStockPool } from '@/api/stock';
import Screen from '@/view/Home/StockAdm/StockScreening/Screen';
import StockPoolList from '@/view/Home/StockAdm/StockScreening/StockPoolList';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
// import { useLocalObservable } from 'mobx-react';
// import { store } from '@/store/mobx';
import { useMediaQuery } from 'react-responsive'
import _ from 'lodash';
import '../stock.scss';

const { Option } = Select;
const { Search } = Input;

export default function StockScreening(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [listData, setlistData] = useState([]); // 股票池及股票池下详细数据
  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [stockPoolList, setstockPoolList] = useState([]);    // 首页股票池列表表格数据
  const [stockPoolListIndex, setstockPoolListIndex] = useState(0);  // 当前 选中的 股票池的index 
  const [spinning, setspinning] = useState(false);
  const [subAccountInfo] = useState(props.location.state.subAccountInfo);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const mobxStore = useLocalObservable(() => store);
  const isReverse = _.get(subAccountInfo, 'centralizedLogin') === 2 ? true : false;

  const columns = [
    {
      title: '股票名称',
      align: 'center',
      dataIndex: 'stockName',
      width: 110,
      filterDropdown: () => {
        return (
          <Search
            value={searchText}
            onChange={searchChange}
            placeholder="请输入股票名称"
            allowClear
            enterButton
            onSearch={onSearch}
          />
        );
      },
    },
    {
      title: '股票代码',
      align: 'center',
      dataIndex: 'stockCode',
      width: 110,
    },
    {
      title: '一分钟涨幅',
      align: 'center',
      width: 120,
      dataIndex: 'minuteChange',
      sorter: (a, b) => Number(a.minuteChange) - Number(b.minuteChange),
      render: (text) => (
        <>{Math.round(text * 10000) / 100 + '%'} </>
      )
    },
    {
      title: '超出均价线占比',
      align: 'center',
      width: 120,
      dataIndex: 'busyBeyondMovingAverage',
      sorter: (a, b) => Number(a.busyBeyondMovingAverage) - Number(b.busyBeyondMovingAverage),
      render: (text) => (
        <>{Math.round(text * 10000) / 100 + '%'} </>
      )
    },
    {
      title: '一分钟成交量',
      align: 'center',
      width: 120,
      dataIndex: 'volumePerMinute',
      sorter: (a, b) => Number(a.volumePerMinute) - Number(b.volumePerMinute),
    },
    {
      title: '操作',
      align: 'center',
      fixed: isMobile ? '' : 'right',
      width: 200,
      render: (record) => (
        <>
          <Button type="primary" shape="round" onClick={() => {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            props.history.push({
              pathname: '/home/Trading',
              state: {
                subAccountInfo: subAccountInfo,
                active: "1",
                record: record
              }
            });
          }}>买入</Button>
        </>
      )
    },
  ]

  function searchChange(e) {
    setSearchText(e.target.value)
  }

  useEffect(() => {
    async function _get() {
      setspinning(true);
      const data = await getStockPoolStocks({ operationId: subAccountInfo.subAccountId, operationType: 'trader' }, isReverse ? 'FC' : 'NOR');     // 股票池详情数据
      const data1 = await getStockScreeningList({ operationId: subAccountInfo.subAccountId, operationType: 'trader' }, isReverse ? 'FC' : 'NOR'); // 股票池首页数据
      // 将data和data1 数据map在一个数组中
      if (data.code === '200') {
        let arrsinfo = data.data;
        let arrslist = data1.data;
        if (arrsinfo !== null && arrslist !== null) {
          arrsinfo.map((item, index) => {
            for (let item1 of arrslist) {
              if (item.stockPoolId === item1.stockPoolId) {
                item.stockScreeningDTOS = item1.stockScreeningDTOS
              }
            }
            if (index === 0) {
              setstockPoolList(item.stockScreeningDTOS);
            }
            return item;
          })
        }
        setlistData(arrsinfo);
      }
      setspinning(false);
    }
    _get();
  }, [])

  async function onSearch(value) {
    if (value === '') {
      setstockPoolList(listData[stockPoolListIndex].stockScreeningDTOS);
    } else {
      var reg = new RegExp(value);
      var arr = [];
      setspinning(true);
      if (stockPoolList.length !== 0) {
        stockPoolList.map(item => {
          if (reg.test(item.stockName)) {
            arr.push(item);
          }
          return item;
        })
        //setstockPoolList(arr);
        setSearchData(arr);
      }
      setspinning(false);
    }
  }
  async function _get() {
    setspinning(true);
    const data = await getStockPoolStocks({ subAccountId: subAccountInfo.subAccountId }, isReverse ? 'FC' : 'NOR');     // 股票池详情数据
    const data1 = await getStockScreeningList({ subAccountId: subAccountInfo.subAccountId }, isReverse ? 'FC' : 'NOR'); // 股票池首页数据
    // 将data和data1 数据map在一个数组中
    if (data.code === '200') {
      let arrsinfo = data.data;
      let arrslist = data1.data;
      arrsinfo.map(item => {
        for (let item1 of arrslist) {
          if (item.stockPoolId === item1.stockPoolId) {
            item.stockScreeningDTOS = item1.stockScreeningDTOS
          }
        }
        return item;
      })
      setlistData(arrsinfo);
      if (listData.length === 0) {
        setstockPoolListIndex(null)
      }
    }
    setspinning(false);
  }
  function _change(e) {
    setstockPoolListIndex(e);
    setstockPoolList(listData[e].stockScreeningDTOS);
  }
  const showModal = () => {
    message.info("暂时无权限添加");
    // setIsModalVisible(true);
  };
  async function handleOk() {
    const stocksPoolName = document.getElementById("value").value
    const data = await addStockPool({ subAccountId: subAccountInfo.subAccountId, stocksPoolName: stocksPoolName }, isReverse ? 'FC' : 'NOR');
    if (data.code === '200') {
      message.success('保存成功');
      _get();
    } else {
      message.error(data.message);
    }
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <PageHeader
        title={'股票池'}
        backIcon={subAccountInfo ? <ArrowLeftOutlined /> : null}
        onBack={() => {
          if (subAccountInfo) {
            props.history?.goBack()
          } else {
            return null
          }
        }}
        style={{ backgroundColor: 'white' }}
      ></PageHeader>

      <div className="StockScreening">
        <Spin spinning={spinning}>
          <Card style={{ marginTop: 20 }}>
            <Card>

              <Row>
                <h2>我的股票池</h2>

                <Select className="span stockPool" onChange={_change} value={stockPoolListIndex} >
                  {
                    listData.length !== 0 && listData.map((item, index) => {
                      return (
                        <Option
                          key={item.stockPoolId}
                          value={index}
                        >
                          {item.stockPoolName}
                        </Option>
                      )
                    })
                  }
                </Select>
                <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" onClick={showModal} />
                <Modal title="股票池名称" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                  <Input id="value" placeholder="请输入要添加的股票池名称" defaultValue="" />
                </Modal>
              </Row>
              <Row justify="end">
                <Screen _goBack={_get} _goStock={_change} ban={true} index={stockPoolListIndex} stockPoolInfos={listData.length !== 0 && listData[stockPoolListIndex]} />
                <StockPoolList _goBack={_get} ban={true} stockPoolInfos={listData.length !== 0 && listData[stockPoolListIndex]} />
              </Row>
            </Card>
            <Table
              rowKey="id"
              dataSource={_.size(searchText) > 0 ? searchData : stockPoolList}
              columns={columns}
              bordered
              scroll={{ x: 810 }}
              pagination={{
                defaultPageSize: 30,
              }}
            />
          </Card>
        </Spin>
      </div>
    </>
  )
}