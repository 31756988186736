import React from 'react';
import { PageHeader } from 'antd';

const CommonHeader = ({ title = '', subTitle = null, isBorder = false, isBack = false, newProps = null, children = null }) => {
  let headerProps = {
    title: title,
    subTitle: subTitle,
    style: { border: isBorder ? '1px solid rgb(235, 237, 240)' : 'none', marginBottom: 10, backgroundColor: 'white' }
  }
  if (isBack) {
    headerProps.onBack = () => { newProps?.history?.goBack() }
  }
  return <PageHeader {...headerProps}>
    {children}
  </PageHeader>
};

export default CommonHeader;