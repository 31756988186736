import React, { useState } from 'react';
import { PageHeader, Tabs, Space } from 'antd';
import { AccountBookOutlined } from '@ant-design/icons';
import { getAccountSubs } from '@/api/workbench';
import { useLocalObservable, Observer } from 'mobx-react';
import { store } from '@/store/mobx';
import { useReactive, useMount } from 'ahooks';
import Records from './Fund/Records';
import FundTransfer from './Fund/FundTransfer';
import SecurityTransfer from './Fund/SecurityTransfer';
import _ from 'lodash';
import './fund.scss';

const { TabPane } = Tabs;

export default function FundAdm(props) {
  const mobxStore = useLocalObservable(() => store);
  const [userInfo] = useState(JSON.parse(JSON.stringify(mobxStore.userInfo)));
  const [subList, setsubList] = useState(JSON.parse(JSON.stringify(mobxStore.mobxAccountSubs)));
  const [upcount, setUpcount] = useState(0);
  const admState = useReactive({
    active: '1', upcount: 0, selectTxt: '',
  });

  useMount(() => {
    _getAcc();
  })

  async function _getAcc() {
    const data = await getAccountSubs();
    const resFc = await getAccountSubs(null, 'FC');
    // console.log('subs', data);
    if (_.get(data, 'code', '') === '200') {
      // 将证券集合数据加工成适配级联选择器的数据 value为key
      const fcData = _.get(resFc, 'data', []).map(n => _.assign(n, { 'fc': true }));
      let arr = _.concat(_.get(data, 'data', []), fcData);
      let secOptions = []; let subOptions = [];
      arr.map((item) => {
        item.label = item.productName
        item.value = item.productId
        item.children = item.securitiesAccountList;
        secOptions.push({
          ...item, children: item.children.map(itm2 => {
            Reflect.deleteProperty(itm2, 'children');
            let newItm2 = {
              ...itm2,
              'label': itm2.securitiesAccountName,
              'value': itm2.securitiesAccountId,
              'fc': _.get(item, 'fc') ? true : false
            };
            return newItm2;
          })
        });
        item.children.map((item2) => {
          item2.label = item2.securitiesAccountName
          item2.value = item2.securitiesAccountId;
          item2.children = item2.subAccountList
          item2.children = item2.children.map((item3) => {
            let newItm3 = {
              ...item3,
              'label': item3.subAccountName,
              'value': item3.subAccountId,
              'fc': _.get(item, 'fc') ? true : false
            };
            subOptions.push(newItm3);
            return newItm3
          });
          return item2
        });
        return item;
      })
      mobxStore._mobxAccountSubs(arr);
      setsubList({
        'full': arr,
        'sec': secOptions,
        'subs': subOptions
      });
      setUpcount(upcount + 1);
    }
  }

  function _goFundManagerListsDetails(record) {
    record.subAccountName = record.name;
    record.subAccountId = record.id;
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/listsDetails',
      state: { subAccountInfo: record }
    });
  };
  const base_props = { 'options': _.get(subList, 'full', []), 'subacc': _.get(subList, 'subs', []), 'upcount': upcount };
  return (
    <Observer>
      {() => (
        <>
          <PageHeader
            title={<Space><AccountBookOutlined /><span>资金管理</span></Space>}
            style={{ backgroundColor: 'white', marginBottom: 10 }}
          ></PageHeader>

          <div className='fundTab'>
            <Tabs activeKey={admState.active} onChange={(v) => admState.active = v} type='card'>
              <TabPane tab='历史托管记录' key="1">
                <Records {...base_props}
                  goManager={(record) => _goFundManagerListsDetails(record)}
                />
              </TabPane>
              <TabPane tab='账户资金' key="2">
                <FundTransfer {...base_props}
                  onListUpdate={() => _getAcc()}
                />
              </TabPane>
              <TabPane tab='证券资金' key="3">
                <SecurityTransfer {...base_props}
                  options={_.get(subList, 'sec', [])}
                  userInfo={userInfo}
                  onListUpdate={() => _getAcc()}
                />
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Observer>
  )
}