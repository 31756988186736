import React, { useState } from 'react';
import { PageHeader, Row, Col, Space, Card, List, Divider, Tag, DatePicker, Typography, Button, Radio, message, Input } from 'antd';
import { EditOutlined, CarryOutOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useReactive, useMount } from 'ahooks';
import { getPrechecks, addPrechecks, updatePrechecks } from '@/api/details';
import { isValidString } from '@/utils/utils2';
import { CHECK_LIST } from '../seutils';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const TODAY = moment().format('YYYY-MM-DD');
const STATUS_OBJ = { // 检查的3种状态值; undefined为错误状态
  'null': ['default', '未检查', 'default', 0], // [tag颜色,tag名称,text颜色,radio的值]
  'true': ['success', '正常', 'success', 1],
  'false': ['error', '异常', 'danger', 2],
  'undefined': ['', '', '', 0]
}
// 盘前检查页面
const PrecheckPage = (props) => {
  const checkState = useReactive({
    forms: {}, edit: {}, remark: '',
    showDesc: true, showSymbol: true, // 选择部分显示功能保留但未使用
  });
  const [checks, setChecks] = useState({});
  const [dates, setDates] = useState(TODAY);
  const [cals, setCals] = useState({});

  useMount(() => {
    _get();
  });
  // 获取盘前检查数据
  async function _get(d) {
    const remove_keys = ['updateTime', 'createTime'];
    const res = await getPrechecks((d ? d : dates) + ' 00:00:00');
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', {}) ?? {}; // res.data = null 为当天无数据；
      let forms = _.cloneDeep(getData);
      remove_keys.map(key => {
        Reflect.deleteProperty(forms, key);
      })
      setChecks(getData);// form删除特定字段后可直接使用
      // 初始化edit对象，form数据根据返回数据创建字段，并赋值或null值
      let initEdit = {}; let cal = { 'null': 0, 'true': 0, 'false': 0 };
      CHECK_LIST.map(k => {
        const dval = _.get(getData, k.key) ?? null;
        _.set(initEdit, k.key, false);
        _.set(forms, k.key, dval); // 无数据的form赋值为null状态
        if (!_.includes(['remark'], k.key)) {
          _.set(cal, String(dval), cal[String(dval)] + 1);
        }
      });
      // console.log('forms', forms)
      checkState.edit = initEdit;
      checkState.forms = forms;
      setCals(cal);
      checkState.remark = ''; // 切换日期清空remark数据
    }
  }
  // 更新或新建
  async function _updates() {
    const isUpdate = 'id' in checks;
    let params = _.cloneDeep(checkState.forms);
    _.set(params, 'checkDate', dates);
    if (isUpdate) {
      _.set(params, 'id', checks.id);
    }
    if (_.get(checkState.edit, 'remark') && isValidString(checkState.remark)) {
      _.set(params, 'remark', checkState.remark);
    }
    const res = isUpdate ? await updatePrechecks(params) : await addPrechecks(params);
    if (_.get(res, 'code', '') === '200') {
      _get();
      message.success(_.get(res, 'message', '操作成功！'));
    } else {
      message.info(_.get(res, 'message', '操作失败！'));
    }
  }
  // 修改编辑状态
  const changeEdit = (key, status) => {
    _.set(checkState.edit, key, status);
  }
  // 修改form数据
  const changeForms = (key, value) => {
    _.set(checkState.forms, key, value);
  }
  //全部确定，赋值form并全部关闭edit模式
  const confirmAll = () => {
    let tempForm = { 'dataPartition': _.get(checkState.forms, 'dataPartition') }; let tempEdit = {};
    CHECK_LIST.map(n => {
      if (!_.includes(['remark', 'dataPartition'], n.key)) {
        _.set(tempForm, n.key, true)
      }
      _.set(tempEdit, n.key, false);
    });
    checkState.edit = tempEdit;
    checkState.forms = tempForm;
    _updates();
  }

  const renderPickerDate = dates !== '' ? moment(dates) : null;
  const has_update = 'updateTime' in checks;
  const has_create = 'createTime' in checks;
  return <>
    <PageHeader
      title={'盘前检查'} style={{ marginBottom: 10, backgroundColor: 'white' }}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }}>
      <Row align='middle'>
        <Col span={16}>
          <Space>
            <Text type='secondary'>未检查:<Text strong>{_.get(cals, 'null') ?? 0} </Text>项</Text>
            <Text type='secondary'>正常:<Text strong type='success'>{_.get(cals, 'true') ?? 0} </Text>项</Text>
            <Text type='secondary'>异常:<Text strong type='danger'>{_.get(cals, 'false') ?? 0} </Text>项</Text>
            {has_update && <Divider type='vertical' />}
            {has_update && <Text type='secondary'>更新时间:{has_update ? moment(_.get(checks, 'updateTime')).format('HH:mm:ss') : ''}</Text>}
            {has_create && <Divider type='vertical' />}
            {has_create && <Text type='secondary'>创建时间:{has_create ? moment(_.get(checks, 'createTime')).format('HH:mm:ss') : ''}</Text>}
            {/* <CheckableTag checked={checkState.showDesc} onChange={(checked) => checkState.showDesc = checked}>
              说明
            </CheckableTag>
            <CheckableTag checked={checkState.showSymbol} onChange={(checked) => checkState.showSymbol = checked}>
              symbol
            </CheckableTag> */}
          </Space>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Space>
            <DatePicker
              value={renderPickerDate}
              onChange={(date, dateString) => {
                setDates(dateString);
                _get(dateString);
              }}
            />
            <Divider type='vertical' />
            <Button onClick={confirmAll} icon={<UnorderedListOutlined />}>
              全部正常
            </Button>
            <Button type='primary' icon={<CarryOutOutlined />} onClick={() => _updates()}>更新</Button>
          </Space>
        </Col>
      </Row>
      <Divider style={{ margin: '12px 0' }} />
      <div style={{ paddingLeft: 8, paddingRight: 8 }}>
        <List
          itemLayout="horizontal"
          dataSource={CHECK_LIST}
          renderItem={item => {
            const getKey = item.key;
            const formStatus = String(_.get(checkState.forms, getKey)); // 字符串化状态，根据状态取值
            const editStatus = _.get(checkState.edit, getKey);
            const getSymbol = _.get(item, 'symbol', '');
            const getDesc = _.get(item, 'desc', '');
            const newSymbol = checkState.showSymbol && isValidString(getSymbol) ? getSymbol + ' ; ' : '';
            const newDesc = checkState.showDesc && isValidString(getDesc) ? getDesc : '';
            // radio模块
            const radioComp = editStatus ? <div onBlur={() => changeEdit(getKey, false)}>
              <Radio.Group value={_.get(STATUS_OBJ, `${formStatus}[3]`, 0)} onChange={(e) => changeForms(getKey, e.target.value === 1 ? true : false)}>
                <Radio value={1}>正常</Radio>
                <Radio value={2}>异常</Radio>
              </Radio.Group>
            </div> : <div style={{ cursor: 'pointer' }} onClick={() => changeEdit(getKey, true)}>
              <Tag color={_.get(STATUS_OBJ, `${formStatus}[0]`, '')}>
                {_.get(STATUS_OBJ, `${formStatus}[1]`, '')}
              </Tag>
            </div>;
            //input备注输入模块
            const inputComp = editStatus ? <Input size='small'
              placeholder='输入备注'
              bordered={false} value={checkState.remark}
              onChange={(e) => checkState.remark = e.target.value}
              onBlur={() => {
                changeEdit('remark', false)
                changeForms('remark', checkState.remark);
              }}
            /> : <div style={{ cursor: 'pointer' }} onClick={() => {
              changeEdit('remark', true);
              checkState.remark = _.get(checkState.forms, 'remark') ?? '';
            }}>
              <Text type='secondary' style={{ marginRight: 4 }}>{_.get(checkState.forms, 'remark', '')}</Text>
              <EditOutlined style={{ color: '#00bad7' }} />
            </div>;
            // list基础模块
            return <List.Item>
              <List.Item.Meta
                title={<Text strong type={_.get(STATUS_OBJ, `${formStatus}[2]`, '')}>{item.name}</Text>}
                description={getKey === 'remark' ? inputComp : newSymbol + newDesc}
              />
              <div>
                {getKey !== 'remark' && radioComp}
              </div>
            </List.Item>
          }}
        />
      </div>
    </Card >
  </>
}

export default PrecheckPage;