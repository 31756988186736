import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Typography,
  message,
  Space,
  DatePicker,
  Segmented,
  Select,
  InputNumber,
  Button
} from 'antd';
import { updateTradeType, showTradeList } from '@/api/stock';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { autoCol, createBasicColums, singleColumns } from '@/utils/utils';
import { SETTING_OBJ, TRADE_TYPE_OBJ } from './putils';
import { labelValues } from '@/utils/utils2';
import moment from 'moment';
import _ from 'lodash';

const { Text, Paragraph } = Typography;
const { Option } = Select;
const TODAY = moment().format('YYYY-MM-DD');
const SEG_OPTION = [labelValues(['交易限制', 1]), labelValues(['买入卖出', 2])];
// option 操作按钮样式
const SettingButton = ({ keyname, isTrue, colors, icon, btnTxt, onClick }) => {
  return <div className='setBtn'
    key={keyname}
    onClick={onClick}
    style={{ backgroundColor: isTrue ? colors[0] : colors[1], color: isTrue ? 'white' : 'black' }}
  >
    {icon}
    <span style={{ marginLeft: 5 }}>{btnTxt}</span>
  </div>
};

const PositionTable = ({ subId = 0, subVals = {}, trade = '', updateCount }) => {
  const [positionDatas, setPositionDatas] = useState([]);
  const [trDate, setTrDate] = useState(TODAY);
  const [loading, setLoading] = useState(false);
  const [tabType, setTabType] = useState(1);
  const [amountObj, setAmountObj] = useState({});
  const [changeObj, setChangeObj] = useState({});
  // 多状态显示列
  const renderSettingCol = (name, key, condition) => {
    return {
      ...singleColumns([name, key, 110]),
      render: (text, record) => {
        const isTrueStatus = text === condition ? true : false;
        return tabType === 1 && key === 'tradeType'
          ? <Text key={key} strong style={{ color: TRADE_TYPE_OBJ['color'][text] }}>{TRADE_TYPE_OBJ['status'][text]}</Text>
          : <Text key={key} strong style={{ color: isTrueStatus ? SETTING_OBJ[key].on : SETTING_OBJ[key].off }}>
            {isTrueStatus ? SETTING_OBJ[key].positionTxt[0] : SETTING_OBJ[key].positionTxt[1]}
          </Text>
      }
    }
  };
  // 渲染多状态的options col
  const renderOptions = (record, key, condition, idx) => {
    const isTrueStatus = _.get(record, key, 0) === condition ? true : false;
    const conditionObj = { 1: 2, 2: 1 };
    const noneComp = key === 'tradeType' ? <Select
      key={idx}
      style={{ width: 120 }}
      value={_.get(record, 'tradeType', null)}
      bordered={false}
      onChange={(val) => _update(record.id, 'tradeType', val, 'type2')}
    >
      <Option value={0}>不操作</Option>
      <Option value={1}>买入</Option>
      <Option value={2}>卖出</Option>
    </Select> : <></>
    return tabType === 1 ? <SettingButton
      key={idx}
      keyname={idx}
      isTrue={isTrueStatus}
      colors={[SETTING_OBJ[key].on, SETTING_OBJ[key].off]}
      btnTxt={isTrueStatus ? SETTING_OBJ[key].positionBtnTxt[0] : SETTING_OBJ[key].positionBtnTxt[1]}
      icon={isTrueStatus ? SETTING_OBJ[key].positionIcon[0] : SETTING_OBJ[key].positionIcon[1]}
      onClick={() => _update(record.id, key, isTrueStatus ? conditionObj[condition] : condition)}
    /> : noneComp
  }

  const col_list = [
    ['股票代码', 'stockCode', 100],//0
    ['股票名称', 'stockName', 110],//1
    ['交易日期', 'tradeDate', 150],//2
  ];
  const options_array = [
    { name: '交易类型', key: 'tradeType', condition: 1 }, //4
    { name: '止盈止损', key: 'profitLossLimit', condition: 2 },//5
    { name: '当日止损', key: 'pctChgLimit', condition: 2 },//6
  ];
  const columns = [
    ...createBasicColums(col_list, [2, 'dateTime']),
    {
      ...singleColumns(['备注', 'remark', 120]), //3
      render: (text) => <Paragraph ellipsis={{ rows: 3, expandable: true }}>
        <Text>{text}</Text>
      </Paragraph>
    },
    ...options_array.map(item => renderSettingCol(item.name, item.key, item.condition)),
    {
      ...singleColumns(['操作', 'option', 300]),//7
      render: (text, record) => {
        return <Space>
          {options_array.map((item, i) => renderOptions(record, item.key, item.condition, i))}
        </Space>
      }
    },
    {
      ...singleColumns(['股票数量', 'stockAmount', 145]), //8
      render: (text, record, index) => {
        const getValue = amountObj[index] || 0;
        return changeObj[index] ? <Space>
          <InputNumber
            min={0}
            step={100}
            placeholder="股票数量"
            style={{ width: 120 }}
            value={getValue}
            onChange={(v) => handleAmountChange(index, v)}
          />
          <Button size='small' type='primary' shape='circle'
            icon={<CheckOutlined />}
            onClick={() => {
              if (!(/^(\d{0,15})00$/.test(getValue))) {
                message.error('请输入整百数！');
                return
              }
              _update(record.id, 'stockAmount', getValue, 'type2')
            }} />
          {/* {changeObj[index] ? : <div style={{ height: 24, width: 24 }}></div>} */}
        </Space> : <div style={{ cursor: 'pointer' }} onClick={() => handleAmountChange(index, getValue)}>
          <Space>
            <Text strong>{getValue}</Text>
            <EditOutlined style={{ color: '#c5c5c5' }} />
          </Space>
        </div>
      }
    },
  ];
  const columns1 = [0, 1, 2, 3, 4, 5, 6, 7].map(v => columns[v]);
  const columns2 = [0, 1, 2, 3, 4, 8, 7].map(v => columns[v])

  useEffect(() => {
    // console.log('subVals', subVals)
    const propsTrade = _.get(trade, 'isToday') ? TODAY : _.get(trade, 'nextDate');
    setTrDate(propsTrade);
    _showTradeList(propsTrade)
  }, [updateCount])

  async function _showTradeList(date, type) {
    const is_reverse = _.get(subVals, 'centralizedLogin') === 2 ? true : false;
    if (subId) {
      setLoading(true);
      let params = {
        date: (date ? date : trDate) + ' 00:00:00',
        subAccountId: subId,
        type: type ?? tabType
      }
      const res = await showTradeList(params, is_reverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        const getData = _.get(res, 'data', []);
        setPositionDatas(getData);
        let temp = {}
        let tempChange = {}
        getData.map((d, i) => {
          temp[i] = _.get(d, 'stockAmount', 0);
          tempChange[i] = false;
        })
        setAmountObj(temp);
        setChangeObj(tempChange);
      }
      setLoading(false);
    }
  }

  async function _update(id, keyname, status, type2) {
    const is_reverse = _.get(subVals, 'centralizedLogin') === 2 ? true : false;
    if (id) {
      let params = {
        id: id,
        [keyname]: status,
      }
      if (type2) {
        params.type = 2;
      }
      const res = await updateTradeType(params, is_reverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        message.success('更新成功！');
        _showTradeList();
      }
    }
  }

  function handleAmountChange(index, v) {
    let temp = _.cloneDeep(amountObj);
    let tempChange = _.cloneDeep(changeObj);
    temp[index] = v;
    tempChange[index] = true;
    setAmountObj(temp);
    setChangeObj(tempChange);
  }

  return (
    <>
      <Row style={{ marginBottom: 10 }} gutter={[8, 8]}>
        <Col {...autoCol([4])}>
          <Segmented
            options={SEG_OPTION}
            value={tabType}
            onChange={(v) => {
              setTabType(v);
              _showTradeList(null, v)
            }} />
        </Col>
        <Col {...autoCol([4])}>
          <DatePicker
            value={moment(trDate) || null}
            format="YYYY-MM-DD"
            onChange={(date, dateStrings) => {
              setTrDate(dateStrings);
              _showTradeList(dateStrings)
            }}
          />
        </Col>
      </Row>

      <Table
        rowKey={'id'}
        loading={loading}
        dataSource={positionDatas}
        columns={tabType === 1 ? columns1 : columns2}
        scroll={{ x: 1000 }}
        size='small'
        pagination={{
          pageSize: 10
        }}
      />
    </>
  )
}

export default PositionTable;