import React, { useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { Descriptions } from 'antd';
import * as echarts from 'echarts';
import _ from 'lodash';
/**
 * 风格仓位及市值统计图表
 */
const MARKET_INDEX_OBJ = { '微盘股': 1, '小市值': 2, '小盘股': 3, '中小盘股': 4, '中大盘股': 5, '大盘股': 6, '超大盘股': 7 };
const MARKET_INDEX_COLOR = { '微盘股': '#5ac2e1', '小市值': '#017bb8', '小盘股': '#9dbb2b', '中小盘股': '#f2ce5d', '中大盘股': '#e9a53d', '大盘股': '#df7164', '超大盘股': '#c7161d' };
const MARKET_INFO = {
  '微盘股': '市值<25亿元',
  '小市值': '25亿元< 市值 <50亿元',
  '小盘股': '50亿元 < 市值 < 100亿元',
  '中小盘股': '100亿元 < 市值 < 300亿元',
  '中大盘股': '300亿元 < 市值 < 500亿元',
  '大盘股': '500亿元 < 市值 < 1000亿元',
  '超大盘股': '市值 > 1000亿元',
};
export default function DisplaySortCharts(props) {
  const [option, setoption] = useState({
    legend: {},
    tooltip: {
      trigger: 'axis',
      showContent: false
    },
    dataset: {
      source: []
    },
    xAxis: { type: 'category' },
    yAxis: { gridIndex: 0 },
    grid: { left: '8%', right: '5%', bottom: '10%', top: '55%' },
    series: []
  });
  const tabActive = _.get(props, 'active', '');

  useUpdateEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('display_sort_charts'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option);

    const getPieList = _.get(props, 'datas', []);
    const is_market = tabActive === '8' ? true : false;
    // console.log('getPieList', getPieList)
    // 图表 echarts 联动和共享数据集; 数据格式相同，以下将数据格式遍历成图表数据格式即可
    let newSource = [
      ['product']
    ];
    let seriesArray = [];
    if (_.size(getPieList) > 0) {
      // 上层进行过数据处理，保留原版数据结构  [{time:日期,data:[{name:板块,value:数值}]}]
      let sortMap = new Map(); let nameIdx = 1;
      // 【bug-fix】通过名称区分板块内容，每日数据未必会返回该板块; nameidx = 板块出现的index值，默认从1开始
      getPieList.map((n, i) => {
        const getDataArr = _.get(n, 'data', []);
        newSource[0][i + 1] = _.get(n, 'time', '');
        getDataArr.map((item, index) => {
          const getName = _.get(item, 'name', '');
          const mapVal = sortMap.get(getName);
          if (mapVal) {
            _.set(newSource, `[${mapVal}][${i + 1}]`, item.value);
          } else {
            // 首次创建该数据内容 ; 市值使用固定index
            const restZeroArr = new Array(_.size(getPieList) - 1).fill(0); // 其余位（总数-1，填充为 0
            _.set(
              newSource,
              `[${is_market ? MARKET_INDEX_OBJ[getName] : nameIdx}]`,
              [getName, item.value, ...restZeroArr]
            );
            sortMap.set(getName, is_market ? MARKET_INDEX_OBJ[getName] : nameIdx);
            nameIdx++;
          }
          seriesArray[is_market ? MARKET_INDEX_OBJ[getName] - 1 : index] = _.assign(
            { type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: { focus: 'series' }, symbol: 'none' },
            is_market ? { 'itemStyle': { 'normal': { 'color': MARKET_INDEX_COLOR[getName] } } } : {}
          );
        })
      })
    };
    newOption.series = _.concat(seriesArray, [{
      type: 'pie',
      id: 'pie',
      radius: '30%',
      center: ['50%', '25%'],
      emphasis: {
        focus: 'self'
      },
      label: {
        formatter: `{b}: {@${_.get(newSource, '[0][1]', '2012')}} ({d}%)`
      },
      encode: {
        itemName: 'product',
        value: _.get(newSource, '[0][1]', '2012'),
        tooltip: _.get(newSource, '[0][1]', '2012')
      },
      itemStyle: is_market ? {
        normal: {
          color: (colors) => {
            let cName = colors.name;
            return MARKET_INDEX_COLOR[cName]; // 通过名称直接拿到颜色值
          }
        }
      } : {}
    }]);
    // console.log('newSource', newSource)
    newOption.dataset.source = newSource;
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
    myChart.on('updateAxisPointer', function (event) {
      const xAxisInfo = event.axesInfo[0];
      if (xAxisInfo) {
        const dimension = xAxisInfo.value + 1;
        myChart.setOption({
          series: {
            id: 'pie',
            label: {
              formatter: '{b}: {@[' + dimension + ']} ({d}%)'
            },
            encode: {
              value: dimension,
              tooltip: dimension
            }
          }
        });
      }
    });
  }, [props.upCount]);
  //position: 'absolute', left: 8, top: 12, width: 288
  const isShow = _.includes(['5', '8'], tabActive);
  return (
    isShow ? <>
      <div style={{ display: 'flex', marginTop: 12 }}>
        <div
          id="display_sort_charts"
          style={{ width: '100%', height: 450 }}
        />
      </div>

      {tabActive === '8' && <div style={{ marginTop: 8 }}>
        <Descriptions column={3} bordered size='small'>
          {_.keys(MARKET_INFO).map(name => <Descriptions.Item key={name} label={name}>{MARKET_INFO[name]}</Descriptions.Item>)}
        </Descriptions>
      </div>}
    </> : <></>
  )
}