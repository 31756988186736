import React, { useState } from 'react';
import { Button, Dropdown, Space, Segmented, Menu, Input } from 'antd';
import { UpSquareOutlined, FileExcelOutlined, SyncOutlined } from '@ant-design/icons';
import { isValidString, labelValues } from '@/utils/utils2';
import _ from 'lodash';

const { Search } = Input;

const PosBars = ({
  roles = '',
  preDays = [],
  isProducts = false,
  noSelect = false,
  noPlate = false,
  noExport = false,
  sval = '',
  eloading = false,

  openPlateModal,
  onExport,
  onSearch,
  onSync,
  onBatchSell,
  onSvalChange
}) => {
  const [searchVal, setSearchVal] = useState('');

  const menu = (
    <Menu
      triggerSubMenuAction='click'
      onClick={onExport}>
      <Menu.Item key={'today'}>导出今日</Menu.Item>
      <Menu.Item key={'all'}>导出全部</Menu.Item>
    </Menu>
  );
  const searchInputProps = { value: searchVal, size: 'small', placeholder: '搜索股票名称或代码', style: { width: 185 } };
  const searchUpDownProps = { size: 'small', type: 'text', danger: true };
  // 用效日期（交易日）的最新三天作为今昨前的时间判断
  const seg_options = [
    labelValues(['全', 'all']),
    labelValues(['今', preDays[0]]), labelValues(['昨', preDays[1]]), labelValues(['前', preDays[2]]),
    labelValues(['其他', 'else']),
  ];

  return <Space size='small'>
    <Button size='small' icon={<SyncOutlined />} type='text' onClick={onSync} />
    
    {roles === 'fund_manager' && !isProducts && <Segmented size='small' options={seg_options}
      value={sval}
      onChange={onSvalChange}
    />}
    <Search {...searchInputProps} onChange={(e) => setSearchVal(e.target.value)} onSearch={onSearch} />

    {isValidString(searchVal) ?
      <Button {...searchUpDownProps}
        onClick={() => {
          onSearch();
          setSearchVal('');
        }}>
        清除
      </Button>
      : <div style={{ width: 44, height: 24 }}></div>}

    {roles === 'trader' ? <Button size='small' type='text'
      disabled={noPlate}
      onClick={openPlateModal}>
      更多
    </Button> : <></>}

    {roles === 'trader' ? <Button size='small' type='primary'
      disabled={noSelect}
      icon={<UpSquareOutlined />}
      onClick={onBatchSell}>
      批量卖出
    </Button> : <></>}
    
    {roles !== 'trader' && !isProducts && <Dropdown overlay={menu} trigger={['click']}>
      <Button loading={eloading} icon={<FileExcelOutlined />} size='small' disabled={noExport}>
        导出
      </Button>
    </Dropdown>}
  </Space>
}
export default PosBars;