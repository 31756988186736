import React from 'react';
import { Card, Col, Row, Button, Alert } from 'antd';
import { StockOutlined } from '@ant-design/icons';
import StatisticHeader from '@/utils/StatisticHeader';
import { autoCol } from '@/utils/utils';
import _ from 'lodash';

const LOCK_STATUS = {
  0: '正常',
  1: '处于预警期',
  2: '平仓锁定中'
}
/**
 *  子账户资产信息，后端返回为 Number 类型，所以百分号需要判断进行添加
 */
const AssetsHeader = ({
  role = '',
  subAssest = {},
  subAccountInfo = {},
  hasSubAccount = false,
  assetData = {},
  isSubListdetail = false,

  goShares
}) => {
  const noTradingBtn = role === 'fund_manager' || role === 'visitor' ? true : false;
  const colSpan = !noTradingBtn ? [autoCol([8]), autoCol([6]), autoCol([6])] : [autoCol([10]), autoCol([7]), autoCol([7])]
  const item_col = autoCol([8]);
  const container_array = [autoCol([10]), autoCol([7]), autoCol([7]), autoCol([8])];
  const cardProps = { className: 'headCard', bodyStyle: { padding: 18 } }

  const HeaderComp = ({ dataSource, firstTitle, type }) => {
    const new_col_array = type === '1' ? container_array : colSpan;
    return <Row gutter={[8, 8]} style={{ marginBottom: 20 }}>
      <Col {...new_col_array[0]}>
        <Card {...cardProps}>
          <Row style={{ marginBottom: 12 }}>
            <Col span={24}>
              <StatisticHeader title={firstTitle} value={_.get(dataSource, 'totalAssets') || '0'} />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col {...item_col}>
              <StatisticHeader title={'当前市值'} value={_.get(dataSource, 'positionsAssets')} />
            </Col>
            <Col  {...item_col}>
              <StatisticHeader title={'可用资金'} value={_.get(dataSource, 'totalBalance')} />
            </Col>
            <Col  {...item_col}>
              <StatisticHeader title={'冻结资金'} value={_.get(dataSource, 'totalFrozenBalance') || '0'} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col  {...new_col_array[1]}>
        <Card {...cardProps}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <StatisticHeader title={'总收益'} value={_.get(dataSource, 'totalRevenue')} isColor />
            </Col>
            <Col span={24}>
              <StatisticHeader title={'净值'} value={_.round(_.get(dataSource, 'netValue'), 4)} isColor />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col  {...new_col_array[2]}>
        <Card {...cardProps}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <StatisticHeader title={'日收益'} value={_.get(dataSource, 'dailyRevenue')} isColor isPrefix />
            </Col>
            <Col span={24}>
              <StatisticHeader
                title={'日收益率'}
                value={_.round(parseFloat(_.get(dataSource, 'dailyRevenueRate')), 2)}
                isColor
                isPrefix
                stProps={{ suffix: "%" }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      {

        !noTradingBtn && isSubListdetail && type === '2' &&
        <Col {...autoCol([4])}>
          <Card {...cardProps} className="headCard headCard4">
            <Row justify="center">
              <Button
                type="primary"
                size="large"
                icon={<StockOutlined />}
                onClick={goShares}
                shape='round'
                disabled={role === 'visitor' ? true : false}
              >
                交易
              </Button>
            </Row>
          </Card>
        </Col>
      }
    </Row>
  }
  return (
    <>
      {_.get(assetData, 'alertStatus') !== 0 && _.get(assetData, 'alertStatus') ? <Alert message={LOCK_STATUS[_.get(assetData, 'alertStatus')] || '锁定中'} type='error' /> : null}
      {hasSubAccount && <HeaderComp dataSource={subAssest} firstTitle={'资产统计'} type='1' />}
      <HeaderComp dataSource={assetData} firstTitle={_.get(subAccountInfo, 'subAccountName', '') + '资产'} type='2' />
    </>
  )
}

export default AssetsHeader;