import axios from '@/utils/axios';
import _ from 'lodash';

//  交易员工作台-我的账户展示
export function showAssetsAndRevenue(data) {
    return axios.get(global.api + '/trader/control/showAssetsAndRevenue', data);
}
//  交易员工作台-子账户列表
export function showAllSubAccount(data) {
    return axios.get(global.api + '/trader/control/showAllSubAccount', data);
}
//  交易员工作台-查看子账户数据
export function showSubAccountData(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubAccountAssetsAndRevenue', data);
}
//  获取消息列表（风控）
export function getListRiskControl(data) {
    return axios.get(global.api + '/message/showListRiskControl', data);
}
//  根据联系人查询消息（风控）
export function queryContactsRiskList(data) {
    return axios.post(global.api + '/message/selectBySenderRiskControl', data);
}
//  获取消息列表（非风控）
export function getListNormal(data) {
    return axios.get(global.api + '/message/showListNormal', data);
}
//  根据联系人查询消息（非风控）
export function queryContactsList(data) {
    return axios.post(global.api + '/message/selectBySenderWithoutRiskControl', data);
}
//  消息已读
export function posMessageRead(data) {
    return axios.post(global.api + '/message/read', data);
}
//  消息删除
export function delMessage(data) {
    return axios.post(global.api + '/message/delete', data);
}
//  消息批量删除
export function delBatchMessage(data) {
    return axios.post(global.api + '/message/batchDelete', data);
}
//  消息列表 - 交易员接受基金经理邀请
export function tradersAccept(data) {
    return axios.get(global.api + `/message/accept`, { params: data });
}
//  消息列表 - 交易拒绝受基金经理邀请
export function tradersRefuse(data) {
    return axios.get(global.api + '/message/refuse', { params: data });
}
//  消息列表 - 交易员查看被邀请子账户信息
export function getSubAccount(data) {
    return axios.get(global.api + '/message/viewSubAccount', { params: data });
}

//  基金经理工作台 - 获取账号下所拥有产品及其证券账户和子账户列表
export function getAccountSubs(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/balance/getAccount', { ...data, timeout: 2600 });
}
//  基金经理工作台 - 展示资产和当前产品
export function getfundManagerAssets(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showAssetsAndProducts', params);
}
//  基金经理工作台 - 历史收益图
export function getHistoryProfitChart(data) {
    return axios.post(global.api + '/fundManager/showHistory', data);
}
//  基金经理工作台 - 资金管理 资金划转记录一览
export function getTransBalanceFlow(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/balance/getTransBalanceFlow', data);
}
//  基金经理工作台 - 资金管理 证券账户资金确认记录
export function getBalanceFlow(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/balance/getConfirmBalanceFlow', data);
}
//  基金经理工作台 - 资金管理 资金划转
export function postCashTransfer(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/balance/cashTransfer', data);
}
//  基金经理工作台 - 交易员管理 展示证券账户交易员信息
export function getSecuritiesTrader(data) {
    return axios.get(global.api + '/fundManager/showSecuritiesTrader', data);
}
//  基金经理工作台 - 交易员管理 删除交易员子账户
export function delTradersSubAccount(data) {
    return axios.post(global.api + '/fundManager/deleteTraderSubAccount', data);
}
//  基金经理工作台 - 交易员管理 删除交易员
export function delPostTrader(data) {
    return axios.post(global.api + '/fundManager/deleteTraders', data);
}
//  基金经理工作台 - 交易员管理 获取证券账户下的子账户
export function getTradersSubAccount(data) {
    return axios.post(global.api + '/fundManager/showSubAccount', data);
}
//  基金经理工作台 - 交易员管理 添加证券交易员
export function addTraders(data) {
    return axios.post(global.api + '/fundManager/addTraders', data);
}
//  基金经理工作台 - 交易员管理 添加交易员子账户
export function addSubAcc(data) {
    return axios.post(global.api + '/fundManager/addSubAccount', data);
}
//  基金经理工作台 - 风控管理 显示当前预警情况
export function getNowWarningInfo(data) {
    return axios.get(global.api + '/fundManager/showNowWarningCondition', data);
}
//  基金经理工作台 - 风控管理 显示本月预警情况与历史占比
export function getWarningCondition(data) {
    return axios.post(global.api + '/fundManager/showWarningCondition', data);
}
//  基金经理工作台 - 风控管理 展示子账户风控规则
export function getSubAccountRules(data) {
    return axios.post(global.api + '/riskControl/showSubAccountRules', data);
}
//  基金经理工作台 - 风控管理 风控规则设定
export function postSubAccountRules(data) {
    return axios.post(global.api + '/riskControl/createTemporaryRules', data);
}
//  交易员工作台 - 子账户模块 历史收益图 + 批量
export function showSubAccountHistory(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubAccountHistory', data);
}
export function showBatchSubAccountHistory(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showBatchSubAccountHistory', data);
}
// 日期返回数据不同
export function showSubAccountHistoryByDate(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubAccountHistoryByDate', data);
}
//  交易员工作台 - 展示子账户持仓收益率
export function showPositionReturn(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/subAccount/showPositionReturn', { params: data });
}
export function exportPositionReturn(data) {
    return axios.get(global.api + '/subAccount/exportPositionReturn', { params: data });
}
//产品层面持仓
export function getAllPosition(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/product/getAllPosition', { params: data });
}

//净值风控选择
// export function Networthrisk(data) {
//     return axios.get(global.api + '/riskControl/showTradeRiskList', data);
// }


//交易风控汇总
export function Transactionsummary(data) {
    return axios.get(global.api + '/riskControl/showCollectTradeRiskList', { params: data });
}

//交易风控订单列表展示
export function Transactionsum(data) {
    return axios.get(global.api + '/riskControl/showTradeRiskList', { params: data });
}


//股票池选择
export function sharesselect() {
    return axios.get(global.api + '/fundManager/showFundManagerStockPool');
}

//设置股票池
export function setupsharesselect(data) {
    return axios.post(global.api + '/fundManager/setStockPool', data);
}
export function plate() {
    return axios.get(global.api + '/stock/getPlate');
}

//  基金经理工作台 - 展示最新一条消息（风控）
export function getFirstRiskControl(data) {
    return axios.get(global.api + '/message/getFirstRiskControl', { params: data });
}

export function Firmoffermonitoring(data) {
    return axios.post(global.api + '/riskControl/realDiskInspection', data);
}


export function CreatePositionRules(data) {
    return axios.post(global.api + '/riskControl/createPositionRules', data);
}

//  基金经理工作台 - 展示最新一条消息（非风控）
export function getFirstWithoutRiskControl(data) {
    return axios.get(global.api + '/message/getFirstWithoutRiskControl', { params: data });
}

//  基金经理工作台 - 展示子账户风控规则
export function showSubAccountRules(data) {
    return axios.post(global.api + '/riskControl/showSubAccountRules', data);
}

//  基金经理工作台 - 手动执行风控
export function excuteRiskControl(data) {
    return axios.post(global.api + '/riskControl/excuteRiskControl', data);
}
//  基金经理工作台 - 交易员业绩列表
export function showTraderPerformance(data) {
    return axios.post(global.api + '/fundManager/showTraderPerformance', data);
}
//  基金经理工作台 - 风控临时预警线设定
export function createTemporaryRulesEasy(data) {
    return axios.post(global.api + '/riskControl/createTemporaryRulesEasy', data);
}
//  基金经理工作台 - 交易员动态
export function showTraderTrends(data) {
    return axios.post(global.api + '/fundManager/showTraderTrends', data);
}
//  交易员工作台 - 消息列表
export function showAll(data) {
    return axios.post(global.api + '/message/showAll', data);
}
export function notRead(data) {
    return axios.get(global.api + '/message/notRead', data);
}
export function readAll(data) {
    return axios.post(global.api + '/message/readAll', data);
}

// 获取左合约
export function leftcontract(data) {
    return axios.post(global.api + '/futures/BackTest/getLeftContract', data);
}

// 右合约
export function rightcontract(data) {
    return axios.post(global.api + '/futures/BackTest/getRightContract', data);
}
// 产品收益率+批量
export function showSingleProductHistory(data, type, timeout = false) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showSingleProductHistory', timeout ? { ...data, timeout: 1100 } : data);
}
export function showBatchProductHistory(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showBatchProductHistory', data);
}
//  基金经理工作台 - 导出交易员业绩(每日统计）
export function exportTraderPerformance(data) {
    return axios.post(global.api + '/fundManager/exportTraderPerformance', data);
}
//  基金经理工作台 - 获取策略选择
export function getStrategyOptions(data) {
    return axios.get(global.api + '/futures/BackTest/getStrategyOptions', data);
}
//  基金经理工作台 - 期货筛选
export function filter(data) {
    return axios.post(global.api + '/futures/BackTest/filter', data);
}
//  基金经理工作台 - 证券账户可划转资金变动接口
export function changeTransfer(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/balance/changeTransfer', data);
}
//  基金经理工作台 - 条件统计范围
export function getScope(data) {
    return axios.post(global.api + '/futures/BackTest/getScope', data);
}
//  基金经理工作台 - 添加期货池
export function addFuturesPool(data) {
    return axios.post(global.api + '/futures/addFuturesPool', data);
}
//  基金经理工作台 - 查看期货池及内容
export function showFuturesPoolFutures(data) {
    return axios.post(global.api + '/futures/showFuturesPoolFutures', data);
}
//  基金经理工作台 - 获取所有未绑定交易员信息
export function showAllTraders(data) {
    return axios.get(global.api + '/fundManager/showAllTraders', { params: data });
}
//  基金经理工作台 - 删除期货池
export function delFuturesPool(data) {
    return axios.post(global.api + '/futures/delFuturesPool', data);
}
//  基金经理工作台 - 删除期货池中期货信息
export function delFuturesOnFuturesPool(data) {
    return axios.post(global.api + '/futures/delFuturesOnFuturesPool', data);
}
//  基金经理工作台 - 获得未到期的交易合约
export function getTradingContractOfNotExpired(data) {
    return axios.get(global.api + '/futures/getTradingContractOfNotExpired', { params: data });
}
//  基金经理工作台 - 添加期货合约到期货池
export function addFuturesContractToPool(data) {
    return axios.post(global.api + '/futures/addFuturesContractToPool', data);
}
//  基金经理工作台 - 交易分析
export function fundManagerAnalysis(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/traderAnalysisFundManager', data);
}
//  交易员工作台 - 交易分析
export function traderAnalysis(data) {
    return axios.post(global.api + '/fundManager/traderAnalysisTrader', data);
}
//  基金经理工作台 - 查看基金经理期货池列表
export function showFundManagerFuturePool(data) {
    return axios.get(global.api + '/futures/showFundManagerFuturePool', { params: data });
}
//  基金经理工作台 - 子账户设置默认期货池
export function setFuturePool(data) {
    return axios.post(global.api + '/futures/setFuturePool', data);
}
//  基金经理工作台 - 查看股价
export function kLineHistory(data) {
    return axios.post(global.api + '/stock/kLineHistory', data);
}
//  基金经理工作台 - 获取股票买卖信号
export function signalHistory(data) {
    return axios.post(global.api + '/stock/signalHistory', data);
}
//  基金经理工作台 - 获基金经理下所有产品
export function getAllProduct(data) {
    return axios.get(global.api + '/product/getAllProduct', { params: data });
}
//  基金经理工作台 - 子账户交易员动态
export function showSubAccountTrends(data) {
    return axios.post(global.api + '/fundManager/showSubAccountTrends', data);
}
//  基金经理工作台 - 获取实时k线
export function kLine(data) {
    return axios.get(global.api + '/stock/kLine', { params: data });
}
//  基金经理工作台 - 获取时间内的股票
export function getTradedStocksBySubId(data) {
    return axios.post(global.api + '/fundManager/getTradedStocksBySubId', data);
}
//  基金经理工作台 - 时间区间内交易员指定股票交易信息点
export function getTradingInfoPoints(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getTradingInfoPoints', data);
}
export function getAutoTradingInfoPoints(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getAutoTradingInfoPoints', params);
}
//  交易员工作台 - 侧边子账户信息展示
export function showAllFutureSubAccount(data) {
    return axios.get(global.api + '/future/trade/showAllSubAccount', { params: data });
}
//  基金经理工作台 - 交易员指定股票超额收益率数据
export function excessRateOfReturnHistory(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/excessRateOfReturnHistory', data);
}
//  基金经理工作台 - 查询子账户交易股票的时间点
export function getTradingStocksTime(data) {
    return axios.post(global.api + '/fundManager/getTradingStocksTime', data);
}
//  基金经理工作台 - 行业仓位展示 产品账户层面
export function getPlateDisplayProductLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPlateDisplayProductLevel', data);
}
//  基金经理工作台 - 个人仓位展示 产品账户层面
export function getPersonalDisplayProductLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPersonalDisplayProductLevel', data);
}
//  基金经理工作台 - 股票仓位展示 产品账户层面
export function getStocksDisplayProductLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getStocksDisplayProductLevel', data);
}
//  基金经理工作台 - 个人仓位展示 子账户层面
export function getPersonalDisplaySubAccountLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPersonalDisplaySubAccountLevel', data);
}
//  基金经理工作台 - 行业仓位展示 子账户层面
export function getPlateDisplaySubAccountLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPlateDisplaySubAccountLevel', data);
}
//  基金经理工作台 - 股票仓位展示 子账户层面
export function getStocksDisplaySubAccountLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getStocksDisplaySubAccountLevel', data);
}
//  是否接收条款
export function isAcceptClause(data) {
    return axios.get(global.api + '/subAccount/isAcceptClause', { params: data });
}
//  接受条款（动作）
export function acceptClause(data) {
    return axios.get(global.api + '/subAccount/acceptClause', { params: data });
}
//  基金经理工作台 - 子账户盈亏贡献度
export function showProfitAndLoss(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showProfitAndLoss', data);
}
export function showProductProfitAndLoss(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductProfitAndLoss', params);
}
//  基金经理工作台 - 子账户日收益贡献度
export function showContribution(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showContribution', data);
}
//  子账户个股收益比较贡献度
export function showStockComparison(data) {
    return axios.post(global.api + '/fundManager/showStockComparison', data);
}
//  基金经理工作台 - 获取时间区间内子账户持仓股票信息
export function getHoldingStocksInfo(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getHoldingStocksInfo', data);
}
// 获取手续费
export function getCommission(id, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/getCommission?productId=' + id);
}
//  获取所有托管策略信息
export function getAutoTradingStrategies(params) {
    return axios.post(global.api + '/fundManager/getAutoTradingStrategies', params);
}

// 根据时间获取指数数据
export function showIndexKline(params, type = '', timeout = false) {
    // 增加批量获取接口
    return axios.post(global.api + `/${type === 'batch' ? 'stock' : 'fundManager'}/showIndexKline`, timeout ? { ...params, timeout: 1100 } : params);
}
export function showIndexKlineByDate(params) {
    return axios.post(global.api + '/fundManager/showIndexKlineByDate', params);
}
// 子账户买入仓位统计
export function showPlatePosition(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showPlatePosition', params);
}
// 风格仓位: 子账户+产品
export function showTradeIndexSort(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showTradeIndexSort', params);
}
export function showTradeIndexSortProduct(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/product/showTradeIndexSort', params);
}
// 重仓分布
export function getHighPositionDistribution(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getHighPositionDistribution', params);
}
// 京沪深分布
export function getPositionDistribution(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getPositionDistribution', params);
}
// 获取子账户/产品历史区间
export function showAllSubAccountHistory(id, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/subAccount/showAllSubAccountHistory?subAccountId=' + id);
}
export function showAllProductHistory(id, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/product/showAllProductHistory?productId=' + id);
}
// 首页产品优化
export function updateProductShow(params) {
    return axios.post(global.api + '/fundManager/updateProductShow', params);
}
export function restProductShow() {
    return axios.get(global.api + '/fundManager/restProductShow');
}
// 指数变化速率
export function showIndexSpeed(params) {
    return axios.post(global.api + '/fundManager/showIndexSpeed', params);
}
// 子账户板块平均收益率曲线
export function showSubAccountPlateHistory(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubAccountPlateHistory', params);
}
// 查看/新增备注
export function getProductRemarks(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/product/showRemark', params);
}
export function addProductRemark(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/product/addRemark', params);
}
export function getSubRemarks(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showRemark', params);
}
export function addSubRemark(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/addRemark', params);
}
// 持仓分类（今，昨，前）
export function showPositionSort(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showPositionSort', params);
}
// 所有交易日期
export function getAllTradeDay(params) {
    return axios.get(global.api + '/fundManager/getAllTradeDay', { params });
}