import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import * as echarts from 'echarts';


export default function EchartsInfo2(props) {
    const [option2, setoption2] = useState({
        tooltip: {
            trigger: "item",
            formatter: "{b} : {d}%",
        },

        // legend: {
        //     orient: "vertical",
        //     x: "left",
        //     data: [
        //         "直达",
        //         "营销广告",
        //         "搜索引擎",
        //         "邮件营销",
        //         "联盟广告",
        //         "视频广告",
        //         "百度",
        //         "谷歌",
        //         "必应",
        //         "其他",
        //     ],
        // },
        series: [
            // {
            //     name: "资产大类",
            //     type: "pie",
            //     selectedMode: "single",
            //     radius: [0, "30%"],
            //     label: {

            //     },
            //     normal: {
            //         position: "inner",
            //         show: false,
            //     },
            //     // data: [
            //     //     { value: 335, name: "其他", selected: true },
            //     //     { value: 679, name: "金融" },
            //     //     { value: 1548, name: "搜索引擎" },
            //     // ],
            // },
            {
                name: "基金名称",
                type: "pie",
                // radius: ["40%", "55%"],
                normal: {
                    // formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                    formatter: "{b}： {per|{d}%}",
                    backgroundColor: "#eee",
                    borderColor: "#aaa",
                    borderWidth: 1,
                    borderRadius: 2,
                    // shadowBlur:3,
                    // shadowOffsetX: 2,
                    // shadowOffsetY: 2,
                    // shadowColor: '#999',
                    padding: [2, 7, 2],
                },
                data: [
                    { value: 335, name: "其他", },
                    { value: 310, name: "金融", },
                    { value: 234, name: "电商", },
                    { value: 135, name: "通讯业务", },
                    { value: 1048, name: "计算机及网络", },
                ],
            },
        ],
    });

    useEffect(() => {
        function _set() {
            let myChart;
            if (myChart !== null && myChart !== "" && myChart !== undefined) {
                myChart.dispose();//销毁
            }

            myChart = echarts.init(document.getElementById('echart_2'));
            myChart.showLoading({
                text: '数据获取中',
                effect: 'whirling'
            });
            let newOption = option2;
            if (props.chartsData) {
                newOption.series[0].data = props.chartsData;
            }

            setoption2(newOption);
            myChart.setOption(newOption, true);
            myChart.hideLoading();
            myChart.resize();
        }
        _set();
    }, [props, option2])


    return (
        <Card hoverable bodyStyle={{ padding: 2 }}>
            <div style={{ height: props.heigth ? props.heigth : 500, width: '100%' }}>
                <div id="echart_2" style={{ height: '100%', width: '100%' }}></div>
            </div>
        </Card>
    )
}