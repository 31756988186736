import React, { useEffect, useState } from 'react';
import {
  Modal,
  InputNumber,
  message,
} from 'antd';
import { getFirstRiskControl, createTemporaryRulesEasy } from '@/api/workbench';
import moment from 'moment';
import _ from 'lodash';

const RiskModal = ({ }) => {
  const [isDisabled, setisDisabled] = useState(false);
  const [dealDisabled, setdealDisabled] = useState(false);
  const [riskValue, setriskValue] = useState(0);
  const [riskMessage] = useState([]);

  useEffect(() => {
    _getFirstRiskControl();

    return () => {
      return
    }
  }, [])

  async function createTemp() {
    if (isDisabled) {
      setdealDisabled(false);
      return;
    }
    const data = await createTemporaryRulesEasy({ subAccountId: riskMessage[0].subAccountId, alertLine: riskValue });
    if (data.code === '200') {
      message.success(data.message);
    } else {
      message.error(data.message);
    }
  }

  async function _getFirstRiskControl() {
    const data = await getFirstRiskControl();
    if (data.code === '200') {
      riskMessage[0] = data.data;
      if (riskMessage[0] !== null) {
        const sendTime = moment(data.data.sendTime);
        const nowTime = moment(moment().format('YYYY-MM-DD HH:mm:ss'));
        // setriskHour(nowTime.diff(sendTime, 'hours'));
        if (nowTime.diff(sendTime, 'minutes') > 30) {
          setisDisabled(true);
        }
        if (data.data.type === 'execute_risk_control') {
          // setriskState(true);
        }
      }
    }
  }

  return (
    <Modal
      title="风控规则"
      visible={dealDisabled}
      onOk={createTemp}
      onCancel={() => { setdealDisabled(false) }}
    >
      <span style={{ marginRight: 20 }}>预警线:</span>
      <InputNumber min={0} value={riskValue} disabled={isDisabled} onChange={setriskValue} step={0.01} />
      <br /><br /><br />
      {isDisabled ? '' : <span style={{ color: 'red' }}>注意：此预警线设置后，两小时内有效，过期后自动恢复初始预警线，进入风控管理进行调整</span>}
    </Modal>
  )
}

export default RiskModal