import axios from '@/utils/axios';
import _ from 'lodash';

//股票-查询股票池及股票查看
export function getStockPoolStocks(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/stock/showStockPoolStocks', data);
}
//股票-获取股票首页列表
export function getStockScreeningList(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/stock/showStockScreeningStocks', data);
}
//股票-删除股票池
export function delStockPoollist(data) {
    return axios.post(global.api + '/stock/deleteStockPool', data);
}
//股票-删除股票池下的单个股票
export function delStockPoolStocks(data) {
    return axios.post(global.api + '/stock/deleteStockPoolStocks', data);
}
//股票-添加股票池
export function addStockPool(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/stock/addStockPool', data);
}
//股票-获取企业性质
export function getCompanyAttribute(data) {
    return axios.get(global.api + '/stock/getCompanyAttribute', data);
}
//股票-获取板块列表
export function getPlate(data) {
    return axios.get(global.api + '/stock/getPlate', data);
}
//股票-筛选股票
export function filterStock(data) {
    return axios.post(global.api + '/stock/filter', data);
}
//股票-添加股票至指定股票池
export function addStocks(data) {
    return axios.post(global.api + '/stock/addStocks', data);
}
//股票-查询股票池黑名单
export function showStockPoolBlack(data) {
    return axios.post(global.api + '/stock/showStockPoolBlack', data);
}
//股票-更新股票池黑名单
export function updStockPoolBlack(data) {
    return axios.post(global.api + '/stock/updStockPoolBlack', data);
}
//股票-股票交易-买入
export function buy(data, type) {
    return type === 'FC' ? axios.post(global.fc + '/trade/buy', data) : axios.post(global.api + '/trade/buy', data);
}
//股票-股票交易-卖出
export function sell(data, type) {
    return type === 'FC' ? axios.post(global.fc + '/trade/sell', data) : axios.post(global.api + '/trade/sell', data);
}
export function batchSellProportion(params, type) {
    return type === 'FC' ? axios.post(global.fc + '/trade/batchSellProportion', params)
        : axios.post(global.api + '/trade/batchSellProportion', params);
    //return axios.post(global.api + '/trade/batchSell', params);  // 原不带比例接口
}
//管理人批量卖出
export function getAllProductPosition() {
    return axios.get(global.api + '/fundManager/getAllProductPosition');
}
export function batchSellProportionByFundManager(params) {
    return axios.post(global.api + '/trade/batchSellProportionByFundManager', params);
}
export function batchSellProportionByFundManagerFC(params) {
    return axios.post(global.fc + '/trade/batchSellProportionByFundManager', params);
}

//股票-获取实时股票行情
export function market(data) {
    return axios.get(global.api + '/stock/market', { params: data });
}
//股票-查询股票信息
export function showStockInfo(data) {
    return axios.post(global.api + '/stock/showStockInfo', data);
}
//股票-获取订单列表
export function showList(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/trade/showList', data);
}
export function getAllTrade(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/product/getAllTrade', data);
}
export function showExcessRateHistory(data) {
    return axios.post(global.api + '/trade/showExcessRateHistory', data);
}
export function showSpecificExcessRateHistory(data) {
    return axios.post(global.api + '/trade/showSpecificExcessRateHistory', data);
}
export function getLatestTrade(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/trade/getLatestTrade', params);
}
export function showTradeDetail(id, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + `/fundManager/showTradeDetail?clientOrderId=${id}`);
}
//股票-撤单
export function cancel(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/trade/cancel', params);
}
//股票-基金经理添加股票池
export function addFundManagerStockPool(data) {
    return axios.post(global.api + '/fundManager/addFundManagerStockPool', data);
}
//股票-获取当前子账户所有可交易股票信息
export function getCurrentStocks(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/stock/getCurrentStocks', { params: data });
}
//股票-删除股票池
export function deleteStockPool(data) {
    return axios.post(global.api + '/stock/deleteStockPool', data);
}
//股票-获取当前子账户所有可卖出股票信息
export function getCurrentPositionStocks(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/stock/getCurrentPositionStocks', { params: data });
}
//股票-获取实时k线
export function kline(data) {
    return axios.get(global.api + '/stock/kLine', { params: data });
}
//股票-查询ETF
export function showETF(data) {
    return axios.post(global.api + '/stock/showETF', data);
}
//期货-得到特定期货订单数据
export function getInstrumentOrder(data) {
    return axios.post(global.api + '/javaCtpCalFund/getInstrumentOrder', data);
}
//期货 - 得到特定期货持仓数据
export function getInstrumentAsset(data) {
    return axios.post(global.api + '/javaCtpCalFund/getInstrumentAsset', data);
}
//期货-开仓
export function opens(data) {
    return axios.post(global.api + '/ctpTradeCal/open', data);
}
//期货-平仓
export function closes(data) {
    return axios.post(global.api + '/ctpTradeCal/close', data);
}
//期货-得到子账号的计算信息
export function getSubAccountCal(data) {
    return axios.post(global.api + '/javaCtpCalFund/getSubAccountCal', data);
}
//期货-得到行情
export function getTick(data) {
    return axios.post(global.api + '/ctpMarketCal/getTick', data);
}
//期货-查询所有用户下子账户总资产
export function showFutureAssetsAndRevenue(data) {
    return axios.get(global.api + '/future/trade/showAssetsAndRevenue', { params: data });
}
//期货-首页查询所有子账号信息
export function showAllSubAccountCal(data) {
    return axios.get(global.api + '/future/trade/showAllSubAccountCal', { params: data });
}
//期货-得到子账号期货池
export function showFuturePool(data) {
    return axios.post(global.api + '/ctpTradeCal/showFuturePool', data);
}
//股票-获取托管配置信息
export function getAutoTradingRule(data) {
    return axios.get(global.api + '/subAccount/getAutoTradingRule', { params: data });
}
//股票-取消自动托管
export function cancelAutoTrading(data) {
    return axios.get(global.api + '/subAccount/cancelAutoTrading', { params: data });
}
//股票-设置托管配置信息
export function setAutoTradingRule(data) {
    return axios.post(global.api + '/subAccount/setAutoTradingRule', data);
}
//期货-返回账号收益率
export function calAccoutPtofitRate(data) {
    return axios.post(global.api + '/javaCtpCalFund/calAccoutPtofitRate', data);
}
//期货-返回账号资金使用率
export function calCapitalRate(data) {
    return axios.post(global.api + '/javaCtpCalFund/calCapitalRate', data);
}
//期货-返回账号交易收益率
export function calTradeProfitRate(data) {
    return axios.post(global.api + '/javaCtpCalFund/calTradeProfitRate', data);
}
//期货-统计模块
export function calOrderAnalysis(data) {
    return axios.post(global.api + '/javaCtpCalFund/calOrderAnalysis', data);
}
//期货-撤单
export function cancelOrder(data) {
    return axios.post(global.api + '/ctpTradeCal/cancelOrder', data);
}



//展示股票涨跌幅分析
export function showStockAnalysis(data) {
    return axios.post(global.api + '/fundManager/showStockAnalysis', data);
}
// 超大单信号
export function showTradingSignal(params) {
    return axios.get(global.api + '/fundManager/showTradingSignal', { params });
}
export function showSpecificTradingSignal(params) {
    return axios.get(global.api + '/fundManager/showSpecificTradingSignal', { params });
}
// 涨停监控
export function showLimitTradingSignal(params) {
    return axios.get(global.api + '/fundManager/showLimitTradingSignal', { params });
}
//最新消息
export function showNews(data) {
    return axios.post(global.api + '/fundManager/showNews', data);
}
//主力分析结果
export function showAnalysis(data) {
    return axios.post(global.api + '/mainFundsAnalysis/showAnalysis', data);
}
// 搜索股票
export function getAllStocks(txt) {
    return axios.get(global.api + '/stock/getAllStocks?search=' + txt);
}
//成本展示
export function showStockCost(data) {
    return axios.post(global.api + '/mainFundsAnalysis/showStockCost', data);
}
//指数
export function indexKlineDetail(data) {
    return axios.post(global.api + '/stock/indexKlineDetail', data);
}
// 板块信息
export function showIndexPlate(params) {
    return axios.get(global.api + `/fundManager/showIndexPlate?start=${_.get(params, 'start', '')}&end=${_.get(params, 'end', '')}&type=${_.get(params, 'type', '')}`);
}
//是否节假日
export function isAllStockHoliday(params) {
    return axios.post(global.api + '/trader/control/isAllStockHoliday', params);
}
//主力成本详情
export function showStockCostDetail(params) {
    return axios.post(global.api + '/mainFundsAnalysis/showStockCostDetail', params);
}
// k线
export function getKLineDetail(data) {
    return axios.post(global.api + '/stock/kLineDetail', data);
}
// 获取季度日期
export function getAvailableQuarter() {
    return axios.get(global.api + '/mainFundsAnalysis/getAvailableQuarter');
}
// 获取季度日期
export function getAllFund(txt) {
    return axios.get(global.api + '/mainFundsAnalysis/getAllFund?search=' + txt);
}
// 获取持仓信息
export function showFundHd(params) {
    return axios.post(global.api + '/mainFundsAnalysis/showFundHd', params);
}
// 获取成分股
export function commonIndex() {
    return axios.get(global.api + '/stock/commonIndex');
}
// 获取指定日期涨停票
export function showLimitStockInfo(params, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/showLimitStockInfo', { params });
}
// 获取持仓股信息
export function showStockAssets(params, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/showStockAssets', { params });
}
// 获取交易名单信息
export function showTradeList(params, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/showTradeList', { params });
}
// 更新股票交易限制
export function updateTradeType(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/updateTradeType', params);
}
// 添加股票交易名单
export function addTradeList(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/addTradeList', params);
}
// 添加股票交易名单
export function nextTradeDate() {
    return axios.get(global.api + '/fundManager/nextTradeDate');
}
// 报告-研报
export function getAllReportTypes() {
    return axios.get(global.api + '/fundManager/getAllReportTypes');
}
export function showReports(params) {
    return axios.post(global.api + '/fundManager/showReports', params);
}
export function showResearchReports(params) {
    return axios.post(global.api + '/fundManager/showResearchReports', params);
}
//全量指数变化速率
export function showCurrentIndexSpeed() {
    return axios.get(global.api + '/fundManager/showCurrentIndexSpeed');
}