import React, { useRef, useState } from 'react';
import { Modal, Form, Select, Input, message, Typography, Cascader } from 'antd';
import { addContrast } from '@/api/details';
import { useUpdateEffect } from 'ahooks';
import Draggable from 'react-draggable';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
// 对比添加表格
const ReasonForm = ({
  visible = false,
  name = '',
  category = {},
  value = {},
  accounts = {},

  oncancel,
}) => {
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef(null);
  const [modalForm] = Form.useForm();
  const [isCopy, setIsCopy] = useState(false);
  // 显示是赋值form
  useUpdateEffect(() => {
    if (visible) {
      modalForm.setFieldsValue(value);
      setIsCopy(_.get(value, 'fromCopy') ? true : false);
    }
  }, [visible]);

  async function handleOk() {
    const values = await modalForm.validateFields();
    // 在copy模式下可切换账户名称
    const newSubname = isCopy && _.get(accounts, 'accoutMap') && _.size(values.accName) === 3
      ? accounts.accoutMap.get(_.last(values.accName))
      : _.get(value, 'name');
    let params = {
      "date": moment(_.get(value, 'commissionTime')).format('YYYY-MM-DD'),
      "subName": newSubname,
      "side": _.get(value, 'side') === 'BUY' ? '买' : '卖',
      "stockCode": _.get(values, 'stockCode'),
      "stockName": _.get(values, 'stockName'),
      "remark": _.get(values, 'remark', ''),
      "reason": _.get(values, 'reason'),
      "type": _.get(values, 'type'),
      "accountType": _.get(values, 'accountType'),
    }
    const res = await addContrast(params);
    if (_.get(res, 'code', '') === '200') {
      message.success(_.get(res, 'message', '操作成功！'));
      handleCancel();
    } else {
      message.info(_.get(res, 'message', '操作失败！'));
    }
  }

  function handleCancel() {
    modalForm.resetFields();
    oncancel();
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  // modal增加可拖拽功能；
  return (
    <Modal
      title={
        <div
          style={{ width: '100%', cursor: 'move' }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => { }}
          onBlur={() => { }}
        >
          <Text strong style={{ fontSize: 15 }}>添加{name}</Text>
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      width={688}
      maskClosable={false}
      onOk={handleOk}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form
        form={modalForm}
        name='compare_reason_form'
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        autoComplete="off"
        size='small'
      >
        {isCopy && <Form.Item
          name={'accName'}
          label={'账户'}
        >
          <Cascader options={_.get(accounts, 'platform', [])}/>
        </Form.Item>}
        <Form.Item
          name={'accountType'}
          label={'对比类型'}
          rules={[{ required: true, message: `请选择对比类型` }]}
        >
          <Select options={_.get(category, 'accountType')} />
        </Form.Item>
        <Form.Item
          name={'stockCode'}
          label={'股票代码'}
          rules={[{ required: true, message: `请填写股票代码` }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'stockName'}
          label={'股票名称'}
          rules={[{ required: true, message: `请填写股票名称` }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'reason'}
          label={'原因'}
          rules={[{ required: true, message: `请选择原因` }]}
        >
          <Select options={_.get(category, 'reason')} />
        </Form.Item>
        <Form.Item
          name={'type'}
          label={'类型'}
          rules={[{ required: true, message: `请选择类型` }]}
        >
          <Select options={_.get(category, 'type')} />
        </Form.Item>
        <Form.Item
          name={'remark'}
          label={'备注'}
        // rules={[{ required: true, message: `请填写备注` }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ReasonForm;