import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import * as echarts from 'echarts';
import { colorArray } from '../colorArray';
import _ from 'lodash';

const OPTION_PIE = {
  legend: {
    type: 'scroll',
    data: []
  },
  dataset: {
    source: []
  },
  xAxis: {
    type: 'category',
    splitLine: {
      show: false
    },
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLable: {
      hideOverLap: true,
    }
  },
  yAxis: { gridIndex: 0 },
  grid: { top: '55%' },
  // tooltip: {
  //     show:true,
  //     trigger:'axis',	
  // },
  tooltip: {
    trigger: 'axis',
    showContent: false
  },
  series: []
}

export default function DisplayMainCharts(props) {
  const [option, setoption] = useState(OPTION_PIE);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('echart7'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = option;
    let newData = _.cloneDeep(props.pieChartData);
    //console.log('newData', newData) 
    /**
     * 【问题】联动图表依旧存在数据过多时切换数据会使线条和饼图颜色不一致；通过console数据发现并没有发现数据问题。
     * 只是少量数据错误就会没有，暂时只通过筛选掉有效数据偏少的数据进行优化；
     */
    let finalPie = [];
    let finalLegend = [];
    let finalSeries = [];
    let dataSetObj = {}; // name:count 数据名称：个数
    newData.pie.map(n => {
      let invalidDataCount = 0; // 记录‘-’无数据个数
      let totalNum = _.size(n);
      const newItem = _.isArray(n) ? n.map(d => {
        if (d === '-') {
          invalidDataCount++;
        }
        dataSetObj[n[0]] = invalidDataCount;
        return _.isString(d) ? d : parseFloat(d.toFixed(3))
      }) : []
      if (invalidDataCount < (_.round(totalNum / 3))) { // ** 无数据小于总数1/3才会显示 
        finalPie.push(newItem);
        dataSetObj[n[0]] = 'show'; //代表下方查看时可以显示数据
      }
    })
    newOption.dataset.source = finalPie;
    let colorCount = 0;
    let colorObj = { '其他': '#f6a100', '现金': '#ffcd00' };
    newData.series.map((s, i) => {
      colorCount = colorCount + 1
      if (colorCount === (_.size(colorArray) - 2)) {
        colorCount = 0;
      }
      if (!colorObj[s.name]) { // 循环colorArray，让pie和series使用同样的颜色
        colorObj[s.name] = colorArray[colorCount]; // 记录name 和 颜色值
      }
      if (dataSetObj[s.name] === 'show' || s.type === 'pie') {  // 按照上方无数据的筛选后，符合条件的数据显示，和pie图的数据
        finalSeries.push({ ...s, itemStyle: { normal: { color: colorObj[s.name] } } })
      }
    });
    let pieIndex = _.findIndex(finalSeries, o => o.type === 'pie');
    //console.log('finalPie', finalPie)
    if (pieIndex !== -1) { // 找到pie的object,修改里面的color属性，保证颜色数据相同
      finalSeries[pieIndex].itemStyle = {
        normal: {
          color: (colors) => { // 用color里面的dataIndex去遍历循环颜色数组，这样保证每个数据index与color进行对应；否则整个饼图是一个颜色
            // let colorIndex = colors.dataIndex;
            let cName = colors.name;
            return colorObj[cName]; // 通过名称直接拿到颜色值
          }
        }
      }
    }
    newData.legend.map(n => {
      if (dataSetObj[n.name] === 'show') {
        finalLegend.push(n);
      }
    })
    newOption.series = finalSeries;
    // console.log(newOption)
    newOption.legend.data = finalLegend;
    setoption(newOption);
    myChart.setOption(newOption);
    myChart.hideLoading();
    myChart.resize();
    myChart.on('updateAxisPointer', function (event) {
      const xAxisInfo = event.axesInfo[0];
      if (xAxisInfo) {
        const dimension = xAxisInfo.value + 1;
        myChart.setOption({
          series: {
            id: 'pie',
            label: {
              formatter: '{b}: {@[' + dimension + ']} ({d}%)'
            },
            encode: {
              value: dimension,
              tooltip: dimension
            }
          }
        });
      }
    });
  }, [props.upCount]);

  const SHOW_ARRAY = ['1', '2', '3'];
  return (
    <Spin spinning={_.get(props, 'loading', false)} style={{
      width: '100%',
      height: 450
    }}>
      <div style={{ display: 'flex' }}>
        <div
          id="echart7"
          style={{
            width: '100%',
            height: 450,
            display: _.includes(SHOW_ARRAY, _.get(props, 'active', '')) ? 'block' : 'none'
          }}
        />
      </div>
    </Spin>
  )
}