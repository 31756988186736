import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Table,
  Typography,
  Space,
  Select,
  Tag,
  AutoComplete,
  message,
  Divider,
  Spin,
} from 'antd';
import { getAllStocks, getStockPoolStocks, getStockScreeningList, showStockCostDetail, showStockCost } from '@/api/stock';
import { useMount, useInterval, useUnmount, useReactive } from 'ahooks';
import { isEmptyStringV2, singleColumns } from '@/utils/utils';
import { isValidArray } from '@/utils/utils2';
import FundCharts from './Components/FundCharts';
import FundBars from './Components/Bars';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;
const INTERVAL_NUM = 5000;
let timer = null;
function renderUpDownText(price, type) {
  const newPrice = price || 0;
  let finalObj = { color: 'black', priceText: newPrice };
  if (newPrice > 0) {
    finalObj.color = 'red';
  } else if (newPrice < 0) {
    finalObj.color = 'green';
  }

  if (type === 'rate') {
    finalObj.priceText = _.round(newPrice * 100, 2) + '%'
  }

  return <Text style={{ color: finalObj.color }}>{finalObj.priceText}</Text>
}
const renderMainColumns = (colArray) => {
  let baseObj = singleColumns(colArray);
  const keys = colArray[1]; const extraKey = _.last(colArray);
  if (keys === 'stockName') {
    baseObj.render = (text, record) => <span>{_.get(record, 'stockName', '') + ' - ' + _.get(record, 'stockCode', '')}</span>
  }
  if (keys === 'match') {
    baseObj.sorter = (a, b) => a[keys] - b[keys];
  }
  if (extraKey === 'sort') {
    baseObj.sorter = (a, b) => a[keys] - b[keys];
    baseObj.render = (text, record) => renderUpDownText(text, _.includes(keys, 'Rate') ? 'rate' : null)
  }
  return baseObj
}
// 主力分析-成本
const CostPage = ({ trading, tabsVal }) => {
  const [stockOption, setStockOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listData, setlistData] = useState([]);
  const [stList, setStList] = useState([]);
  const [stValue, setStValue] = useState('');
  const [poolValue, setPoolValue] = useState(null);
  const [pageKey, setPageKey] = useState('table');
  const [cdList, setCdList] = useState([]);
  const [upCount, setUpCount] = useState(0);
  const [stName, setStName] = useState('');
  const [mainDate, setMainDate] = useState([
    moment().subtract(7, 'd').format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD')
  ]);
  const [costInfo, setCostInfo] = useState({});
  const [inter, setInter] = useState(undefined);
  const [initGet, setInitGet] = useState(true);
  const [costData, setCostData] = useState([]);
  const costState = useReactive({ stockPoolId: '', stocks: '' });

  const _validateResponse = (res) => {
    if (_.get(res, 'code', '') === '200') {
      return true;
    } else {
      message.error(_.get(res, 'message', '获取失败!'));
      return false;
    }
  }

  useMount(() => {
    _getStock();
  });

  useUnmount(() => {
    setInter(undefined);
    timer && clearTimeout(timer);
  });

  useInterval(() => {
    _showStockCost();
  }, inter);

  const clearTimer = () => {
    setInter(undefined);
    setInitGet(true);
  };

  useEffect(() => {
    // console.log('trading', trading)
    if (trading && !isEmptyStringV2(costState.stockPoolId) && !isEmptyStringV2(costState.stocks)) {
      setInter(INTERVAL_NUM);
    }
  }, [tabsVal]);

  const columns = [
    renderMainColumns(['股票名称', 'stockName']),
    renderMainColumns(['外资成本', 'foreignCost', 'sort']),
    renderMainColumns(['内资成本', 'domesticCost', 'sort']),
    renderMainColumns(['外资成本波动', 'foreignCostRate', 'sort']),
    renderMainColumns(['内资成本波动', 'domesticCostRate', 'sort']),
    renderMainColumns(['最新价', 'match']),
    {
      ...singleColumns(['操作', 'option']),
      render: (text, record) => {
        return <Button
          type='text'
          size='small'
          onClick={() => {
            _showStockCostDetail(_.get(record, 'stockCode', null))
            setStName(_.get(record, 'stockName', ''))
          }}>
          {'详情>>'}
        </Button>
      }
    },
  ]

  async function _getStock() {
    const data = await getStockPoolStocks({ operationType: 'fundManager' });     // 股票池详情数据
    const data1 = await getStockScreeningList({ operationType: 'fundManager' }); // 股票池首页数据
    if (_validateResponse(data)) {
      let arrsinfo = data.data;
      let arrslist = data1.data;
      arrsinfo.map((item, index) => {
        for (let item1 of arrslist) {
          if (item.stockPoolId === item1.stockPoolId) {
            item.stockScreeningDTOS = item1.stockScreeningDTOS
          }
        }
        return item;
      })
      setlistData(arrsinfo);
      if (arrsinfo.length === 0) {
        setPoolValue(null);
      }
    }
  }
  // 获取详情
  async function _showStockCostDetail(code, date) {
    if (code) {
      let params = {
        startDate: date ? date[0] : mainDate[0],
        endDate: date ? date[1] : mainDate[1],
        stockCode: code,
      }
      const res = await showStockCostDetail(params);

      const getData = _.get(res, 'data', []);
      let sortData = getData.map(n => {
        return { ...n, tidx: moment(n.executeDate).diff(moment(), 'day') }
      })
      sortData.sort((a, b) => a.tidx - b.tidx);
      //console.log('排序数据', sortData)
      if (_.get(res, 'code', '') === '200') {
        setCdList(sortData);
        setUpCount(_.round(upCount + 0.2, 1));
      }
      setPageKey('charts');
      setCostInfo(params);
    }
  }
  // 搜索股票
  async function _getAllStocks(txt) {
    if (isEmptyStringV2(txt)) {
      return
    }

    try {
      setLoading(true);
      const data = await getAllStocks(txt);
      if (_validateResponse(data)) {
        const newStockData = _.get(data, 'data', []).map(n => { return { label: n.stockName, value: n.symbol, ...n } });
        setStockOption(newStockData);
      }
      setLoading(false);
    } catch (error) {
      message.error('获取出错！')
    }
  }
  // 获取成本数据
  async function _showStockCost() {
    let params = {
      stockPoolId: costState.stockPoolId,
      stocks: costState.stocks,
    }
    const res = await showStockCost(params);
    if (initGet) {
      message.success('获取成功！')
    }
    if (_validateResponse(res)) {
      const getData = _.get(res, 'data', []);
      setCostData(isValidArray(getData) ? getData.map((n, i) => _.assign(n, { key: 'cost' + i })) : []);
      setInitGet(false);
    } else {
      clearTimer();
    }
  }

  const onSelect = (data) => {
    //console.log('onSelect', data)
    let fIndex = _.findIndex(stockOption, o => o.value === data);
    if (fIndex !== -1) {
      let tempList = _.cloneDeep(stList);
      let getOption = _.omit(stockOption[fIndex], ['label', 'value']);
      tempList.push(getOption);
      const uninList = _.unionBy(tempList, 'symbol');
      setStList(uninList);
      autoSearch(null, uninList)
    }
  };

  const onSearch = (searchText) => {
    setStValue(searchText);
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      _getAllStocks(searchText)
    }, 1500);
  };

  function _change(e) {
    setPoolValue(e);
    autoSearch(e, null)
  }
  // 处理搜索字段，并开启定时刷新
  function autoSearch(pool, list) {
    let newList = _.size(stList) > 0 ? JSON.stringify(stList) : '';
    let newPoolIndex = pool ? pool - 1 : poolValue - 1;
    let newPoolValue = 0;
    if (listData[newPoolIndex]) {
      newPoolValue = listData[newPoolIndex].stockPoolId;
    }
    costState.stockPoolId = newPoolValue;
    costState.stocks = list ? JSON.stringify(list) : newList;
    _showStockCost();
    setInter(INTERVAL_NUM);
    message.info('加载中，请稍后~')
  }

  const isTable = pageKey === 'table' ? true : false;
  const poolOptions = isValidArray(listData) && listData.map((n, i) => <Option key={n.stockPoolId} value={i + 1}>{n.stockPoolName}</Option>);
  return <>
    {isTable
      ? <>
        <div style={{ marginBottom: 10, marginTop: 12 }}>
          <Space>
            <Text>已选: </Text>
            {poolValue && <Tag color="orange">{listData[poolValue - 1].stockPoolName}</Tag>}
            {_.size(stList) > 0 ? stList.map((n, i) => <Tag key={'tag' + i} color="cyan">{n.stockName}</Tag>) : null}
          </Space>
        </div>
        <div style={{ marginBottom: 10 }}>
          <Space>
            <Text>选择股票池: </Text>
            <Select onChange={_change} value={poolValue} style={{ width: 200 }}>
              {poolOptions}
            </Select>
          </Space>
        </div>
        <Row justify='space-between' style={{ marginBottom: 10 }}>
          <Col span={10}>
            <Space>
              <Text>搜索股票: </Text>
              <AutoComplete
                options={stockOption}
                style={{ width: 220 }}
                onSearch={onSearch}
                onSelect={onSelect}
                placeholder="输入股票代码或名称"
                value={stValue}
              />
              <Spin spinning={loading} size='small' />
            </Space>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Space>
              <Button
                type='text'
                danger
                disabled={!poolValue && _.size(stList) === 0}
                onClick={() => {
                  setPoolValue(null);
                  setStList([]);
                  clearTimer();
                  setStValue('')
                }}
              >
                清除
              </Button>
            </Space>
          </Col>
        </Row>
      </>
      : <FundBars
        pageKey='Main'
        dateArray={mainDate}
        stockName={stName}
        onBack={() => setPageKey('table')}
        onDateChange={(dateStrings) => {
          _showStockCostDetail(_.get(costInfo, 'stockCode', null), dateStrings);
          setMainDate(dateStrings)
        }}
      />}

    <Divider />

    {isTable ? <Table
      rowKey={'key'}
      dataSource={costData}
      columns={columns}
      size='small'
    /> : <FundCharts
      pageKey='Main'
      chartKey='mainFund'
      updateCount={upCount}
      datas={cdList}
    />
    }
  </>
}

export default CostPage;