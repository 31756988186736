import React, { useState } from 'react';
import { Row, Col, Button, Typography, Input, Space } from 'antd';
import { LeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { NewRangePicker } from '@/view/common/widgets';
import _ from 'lodash';

const { Text } = Typography;
const { Search } = Input;

const FundBars = ({
  dateArray = [],
  stockName = '',
  pageKey = '',
  isShow = false,
  orderType = '',

  onBack,
  onDateChange,
  onInputSearch,
  onClear,
  onSortClear
}) => {
  const [inputVal, setInputVal] = useState('')
  const backButton = <Button
    size='small'
    onClick={onBack}
    icon={<LeftOutlined />}
  >
    返回
  </Button>;
  return <Row align='middle' style={{ marginTop: 12 }}>
    <Col span={8}>
      <Space>
        {pageKey === 'Analysis' && !isShow ? <Search
          placeholder='输入股票名称或代码'
          style={{ width: 240 }}
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          onSearch={onInputSearch}
        /> : null}
        {pageKey === 'Main' || isShow ? backButton : null}
        {pageKey === 'Analysis' && _.size(inputVal) > 0 && <Button type='text' danger icon={<CloseCircleOutlined />} onClick={onClear} />}
        {pageKey === 'Analysis' && orderType && orderType !== '' && <Button type='link' onClick={onSortClear} >清除筛选</Button>}
      </Space>
    </Col>
    <Col span={8} style={{ textAlign: 'center' }}>
      {pageKey === 'Main' && <Text strong>{stockName}</Text>}
      {pageKey === 'Analysis' && isShow && <Text strong style={{ fontSize: 17 }}>{stockName}</Text>}
    </Col>
    <Col span={8} style={{ textAlign: 'right' }}>
      <NewRangePicker dates={dateArray} onSelect={(dateStrings) => {
        onDateChange(dateStrings)
      }} />
    </Col>
  </Row>
}

export default FundBars;