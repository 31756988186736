import axios from '@/utils/axios';
//产品详情-获取账户列表
export function getProductDetailss(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductSecuritiesAndSub', data);
}
//产品详情-获取未被证券账户绑定的所有交易员
export function getAllTraders(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/getAllTraders', { params: data });
}
//产品详情 - 更换交易员
export function updateTrader(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/updateTrader', data);
}
// 产品详情-添加证券账户
export function addSecuritiesAccount(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/addSecuritiesAccount', data);
}
//产品详情-校验子账户名称是否重复
export function isRepeatSub(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/isRepeatSub', { params: data });
}
//产品详情-删除子账户
export function delSecuritiesAccount(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/deleteSubAccount', data);
}
//获取证券列表
export function getBrokerages(data) {
    return axios.get(global.api + '/product/getBrokerages', data);
}
//校验交易账户是否正确
export function checkTradeAccount(data, type) {
    return type === 'FC' ? axios.post(global.fc + '/product/checkTradeAccount', data)
        : axios.post(global.api + '/product/checkTradeAccount', data);
}
//初始化账户资金
export function initAccount(data, type) {
    return type === 'FC' ? axios.post(global.fc + '/product/initAccount', data)
        : axios.post(global.api + '/product/initAccount', data);
}
// 添加产品
export function addProduct(data, type) {
    return type === 'FC' ? axios.post(global.fc + '/product/addProduct', data)
        : axios.post(global.api + '/product/addProduct', data);
}
// 经理查看子账户（股票）- 展示调仓记录
export function getAdjustmentList(data) {
    return axios.get(global.api + '/subAccount/showWarehouseList', { params: data });
}
//经理查看子账户（股票）- 获取股票资产列表
export function getStockList(data) {
    return axios.get(global.api + '/subAccount/showStockList', { params: data });
}
//经理查看子账户（股票）- 获取配置比例
export function getAssetRate(data) {
    return axios.get(global.api + '/subAccount/showAssetRate', { params: data });
}
//基金经理工作台添加产品 - 校验交易单元是否重复
export function isRepeatTradeUnit(data) {
    return axios.post(global.api + '/product/isRepeatTradeUnit', data);
}
//基金经理工作台添加产品 - 更新产品初始资金
export function initProductOriginAssets(data, type) {
    return type === 'FC' ? axios.post(global.fc + '/product/initProductOriginAssets', data)
        : axios.post(global.api + '/product/initProductOriginAssets', data);
}
// 基金经理工作台 - 添加证券账户
export function addSecurityAccount(data) {
    return axios.post(global.api + '/product/addSecurityAccount', data);
}
//资金管理子产品子账户历史托管记录
export function showProductSecuritiesAndSubHistory(data, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductSecuritiesAndSubHistory', { params: data });
}
//查看期货是否交易时间
export function judgeOnfuturesTradeTime() {
    return axios.get(global.api + '/product/judgeOnfuturesTradeTime');
}
// 基金经理工作台 - 证券账户分配子账户
export function distributeSubAccount(data) {
    return axios.post(global.api + '/product/distributeSubAccount', data);
}

/**
 *  账户对比
 */
export function getAllAccount(type = '') {
    return axios.get((type === 'FC' ? global.fc : global.api) + '/tradeAnalysis/getAllAccount', { timeout: 2500 });
}
export function showAllList(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/tradeAnalysis/showAllList', params);
}
export function getRuleInfo(id) {
    return axios.get(global.api + '/tradeAnalysis/getRuleInfo?ruleId=' + id);
}
// 账户\产品收益率对比
export function compareSubHistory(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/compareSubHistory', params);
}
export function compareProductHistory(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/compareProductHistory', params);
}
// 盘前检查 
export function getPrechecks(date) {
    return axios.get(global.api + `/systemCheck/selectOne?checkDate=${date}`);
}
export function addPrechecks(params) {
    return axios.post(global.api + `/systemCheck/add`, params);
}
export function updatePrechecks(params) {
    return axios.post(global.api + `/systemCheck/update`, params);
}
// 3分钟涨幅超5%
export function getPositionUp5(id, type) {
    return axios.get((type === 'FC' ? global.fc : global.api) + `/product/getPositionUp5?productId=${id}`);
}
// 收益率k线
export function indexDailyKline(params) {
    return axios.post(global.api + '/stock/indexDailyKline', params);
}
export function showProductKLine(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductKLine', params);
}
export function showSubKLine(params, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/subAccount/showSubKLine', params);
}
//获取私募基金信息
export function findFund(content = '') {
    return axios.get(global.api + `/stock/findFund?content=${content}`);
}
export function showFundKline(params) {
    return axios.post(global.api + '/fundManager/showFundKline', params);
}
//字典类别，实测对比提交类型
export function selectByCategory(category = '') {
    return axios.get(global.api + `/dictionary/selectByCategory?category=${category}`);
}
export function addDictionary(params) {
    return axios.post(global.api + '/dictionary/addDictionary', params);
}
export function addContrast(params) {
    return axios.post(global.api + '/tradeAnalysis/addContrast', params);
}
export function showContrast(params) {
    return axios.post(global.api + '/tradeAnalysis/showContrast', params);
}
export function updateContrast(params) {
    return axios.post(global.api + '/tradeAnalysis/updateContrast', params);
}
export function deleteContrast(params) {
    return axios.post(global.api + '/tradeAnalysis/deleteContrast', params);
}
// 自定义指数配置
export function showIndexConfig() {
    return axios.get(global.api + '/stock/showIndexConfig');
}
export function addIndexConfig(params) {
    return axios.post(global.api + '/stock/addIndexConfig', params);
}
export function updateIndexConfig(params) {
    return axios.post(global.api + '/stock/updateIndexConfig', params);
}
export function deleteIndexConfig(params) {
    return axios.post(global.api + '/stock/deleteIndexConfig', params);
}