import React, { useState, useEffect } from 'react';
import { Card, Button, Select, Row, Table, Spin, Modal, Input, message } from 'antd';
import { getStockPoolStocks, getStockScreeningList, addFundManagerStockPool, deleteStockPool } from '@/api/stock';
import Screen from '@/view/Home/StockAdm/StockScreening/Screen';
import StockPoolList from '@/view/Home/StockAdm/StockScreening/StockPoolList';
import { PlusOutlined, MinusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import '../stock.scss';

const { Option } = Select;

export default function StockScreening(props) {
    const [listData, setlistData] = useState([]); // 股票池及股票池下详细数据
    const [stockPoolList, setstockPoolList] = useState([]);    // 首页股票池列表表格数据
    const [stockPoolListIndex, setstockPoolListIndex] = useState(0);  // 当前选中的股票池的index 
    const [spinning, setspinning] = useState(false);
    // const [subAccountInfo] = useState(props.location.state.subAccountInfo);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setinputValue] = useState('');

    const columns = [
        {
            title: '股票名称',
            align: 'center',
            dataIndex: 'stockName',
        },
        {
            title: '股票代码',
            align: 'center',
            dataIndex: 'stockCode',
        },
        {
            title: '一分钟涨幅',
            align: 'center',
            dataIndex: 'minuteChange',
            sorter: (a, b) => Number(a.minuteChange) - Number(b.minuteChange),
            render: (text) => (
                <>{text === null ? '--' : Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '超出均价线占比',
            align: 'center',
            dataIndex: 'busyBeyondMovingAverage',
            sorter: (a, b) => Number(a.busyBeyondMovingAverage) - Number(b.busyBeyondMovingAverage),
            render: (text) => (
                <>{Math.round(text * 10000) / 100 + '%'} </>
            )
        },
        {
            title: '一分钟成交量',
            align: 'center',
            dataIndex: 'volumePerMinute',
            sorter: (a, b) => Number(a.volumePerMinute) - Number(b.volumePerMinute),
        },
    ]

    useEffect(() => {
        async function _get() {
            setspinning(true);
            const data = await getStockPoolStocks({ operationType: 'fundManager' });     // 股票池详情数据
            const data1 = await getStockScreeningList({ operationType: 'fundManager' }); // 股票池首页数据
            if (data.code === '200') {
                let arrsinfo = data.data;
                let arrslist = data1.data;
                arrsinfo.map((item, index) => {
                    for (let item1 of arrslist) {
                        if (item.stockPoolId === item1.stockPoolId) {
                            item.stockScreeningDTOS = item1.stockScreeningDTOS
                        }
                    }
                    if (index === 0) {
                        setstockPoolList(item.stockScreeningDTOS);
                    }
                    return item;
                })
                setlistData(arrsinfo);
                if (arrsinfo.length === 0) {
                    setstockPoolListIndex(null);
                }
            }
            setspinning(false);
        }
        _get();
    }, [])

    async function _get() {
        setspinning(true);
        const data = await getStockPoolStocks({ operationType: 'fundManager' });     // 股票池详情数据
        const data1 = await getStockScreeningList({ operationType: 'fundManager' }); // 股票池首页数据
        if (data.code === '200' && data1.code === '200') {
            let arrsinfo = data.data;
            let arrslist = data1.data;
            arrsinfo.map((item, index) => {
                for (let item1 of arrslist) {
                    if (item.stockPoolId === item1.stockPoolId) {
                        item.stockScreeningDTOS = item1.stockScreeningDTOS
                    }
                }
                return item;
            })
            setlistData(arrsinfo);
        }
        setspinning(false);
    }
    async function updateStockTable() {
        setspinning(true);
        const data = await getStockPoolStocks({ operationType: 'fundManager' });     // 股票池详情数据
        const data1 = await getStockScreeningList({ operationType: 'fundManager' }); // 股票池首页数据
        if (data.code === '200' && data1.code === '200') {
            let arrsinfo = data.data;
            let arrslist = data1.data;
            arrsinfo.map((item, index) => {
                for (let item1 of arrslist) {
                    if (item.stockPoolId === item1.stockPoolId) {
                        item.stockScreeningDTOS = item1.stockScreeningDTOS
                    }
                }
                return item;
            })
            setlistData(arrsinfo);
            setstockPoolList(arrsinfo[stockPoolListIndex].stockPoolStocks);
        }
        setspinning(false);
    }
    function _change(e) {
        setstockPoolListIndex(e);
        setstockPoolList(listData[e].stockScreeningDTOS);
    }
    async function reset() {
        const data = await getStockScreeningList({ operationType: 'fundManager' });
        if (data.code === '200') {
            setstockPoolList(data.data[stockPoolListIndex].stockScreeningDTOS);
        }
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    async function delStockPool() {
        Modal.confirm({
            title: '是否删除该股票池！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                const data = await deleteStockPool({ stockPoolId: listData[stockPoolListIndex].stockPoolId });
                if (data.code === '200') {
                    message.success('删除成功');
                    _get();
                    if (!listData[0]) {
                        setstockPoolList([]);
                    } else {
                        setstockPoolListIndex(0);
                        setstockPoolList(listData[0].stockScreeningDTOS);
                    }
                } else {
                    message.error(data.message);
                }
            }
        });
    }
    async function handleOk() {
        const data = await addFundManagerStockPool({ stocksPoolName: inputValue });
        if (data.code === '200') {
            message.success('保存成功');
            _get();
            setstockPoolListIndex(listData.length);
            setstockPoolList([]);
        } else {
            message.error(data.message);
        }
        setinputValue('');
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div className="StockScreening">
            <Spin spinning={spinning}>
                <Card style={{ marginTop: 20 }}>
                    <Card>
                        <Row>
                            <h2>我的股票池</h2>
                            <Select className="span stockPool" onChange={_change} defaultActiveFirstOption='true' value={stockPoolListIndex} >
                                {
                                    listData.length !== 0 && listData.map((item, index) => {
                                        return (
                                            <Option
                                                key={item.stockPoolId}
                                                value={index}
                                            >
                                                {item.stockPoolName}
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                            <Button type="primary" shape="circle" icon={<PlusOutlined />} size="large" onClick={showModal} />
                            <Button type="primary" shape="circle" icon={<MinusOutlined />} size="large" onClick={delStockPool} style={{ marginLeft: 20 }} />
                            <Modal title="股票池名称" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <Input placeholder="请输入要添加的股票池名称" value={inputValue} allowClear onChange={e => { setinputValue(e.target.value) }} />
                            </Modal>
                        </Row>
                        <Row justify="end">
                            <Screen _goBack={_get} _goStock={reset} ban={false} stockPoolInfos={listData.length !== 0 && listData[stockPoolListIndex]} />
                            <StockPoolList _goBack={_get} _update={updateStockTable} ban={false} stockPoolInfos={listData.length !== 0 && listData[stockPoolListIndex]} />
                        </Row>
                    </Card>
                    <Table
                        rowKey="id"
                        dataSource={stockPoolList}
                        columns={columns}
                        bordered
                        pagination={{
                            defaultPageSize: 30,
                        }}
                    />
                </Card>
            </Spin>
        </div>
    )
}