import React from 'react';
import { Col, Row, Button, Descriptions } from 'antd';
import { RightCircleOutlined, AccountBookOutlined } from '@ant-design/icons';
import { autoCol, renderUpDownRate } from '@/utils/utils';
import _ from 'lodash';

const col_list = [
  { label: '账户类别', key: 'subAccountType' },
  { label: '账户名称', key: 'subAccountName' },
  { label: '证券账户', key: 'securitiesAccountName' },
  { label: '基金产品', key: 'productName' },
  { label: '净值', key: 'netValue' },
  { label: '总资产', key: 'totalAsset' },
  { label: '总市值', key: 'position' },
  { label: '可用资金', key: 'balance' },
  { label: '总收益', key: 'totalRevenue', color: true },
  { label: '日收益', key: 'dailyRevenue', color: true },
];

const renderType = (type) => {
  if (type === '1') {
    return '股票'
  } else if (type === '2') {
    return '期货'
  } else {
    return '基金'
  }
}

const AcCard = ({ accounts, onCheck }) => {
  return (
    <Row style={{ marginTop: 20 }} gutter={[16, 16]}>
      {accounts && _.size(accounts) > 0 && accounts.map((acc, i) => {
        return <Col key={'acc' + i} {...autoCol([12, 24])}>
          <div className='normalCard'>
            <Descriptions
              title={<div><AccountBookOutlined /><span style={{ marginLeft: 8 }}>子账户</span></div>}
              column={{ ...autoCol([2, 1, 1]) }}
              size='small'
            >
              {col_list.map((item, ii) => {
                let sub = _.get(acc, item.key, '');

                if (item.key === 'subAccountType') {
                  sub = renderType(_.get(acc, 'subAccountType', ''));
                }
                if (_.get(item, 'color', null)) {
                  sub = renderUpDownRate(sub, true, { fontWeight: '600' }, 'replace')
                }
                return <Descriptions.Item key={'itm' + ii} label={item.label}>
                  {sub}
                </Descriptions.Item>
              })}
            </Descriptions>

            <Button
              type='primary'
              block
              icon={<RightCircleOutlined />}
              onClick={() => onCheck(acc)}
              style={{ marginTop: 15 }}
            >
              查看
            </Button>
          </div>
        </Col>
      })}
    </Row>
  )
}

export default AcCard;