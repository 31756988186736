import axios from '@/utils/axios';

//  账号登陆
export function userLogin(data) {
    return axios.post(global.api + '/login', { ...data, loginType: 'password' });
}
export function userLoginFC(data) {
    return axios.post(global.fc + '/login', { ...data, loginType: 'password' }, { timeout: 2100 });
}

//  验证码登陆
export function userCodeLogin(data) {
    return axios.post(global.api + '/login', { ...data, loginType: 'verify' });
}

//  获取登陆验证码
export function getUserCode(data) {
    return axios.post(global.api + '/getCode', { ...data, randomCodeType: 'LOGIN' });
}
export function getCaptcha(token) {
    return axios.get(global.api + '/getCaptcha?captchaToken=' + token);
}
//  获取登陆验证码
export function getUserInfo(data) {
    return axios.get(global.api + '/account/getRoles', { params: data });
}
export function getRegisterCode(data) {
    return axios.post(global.api + '/getCode', { ...data, randomCodeType: 'REGISTER' });
}
export function getForgetPasswordCode(data) {
    return axios.post(global.api + '/getCode', { ...data, randomCodeType: 'FORGET_PASSWORD' });
}
export function getVerifPhoneCode(data) {
    return axios.post(global.api + '/fundManager/getCode', { ...data, randomCodeType: 'ADD_ACCOUNT' });
}

//  验证基金经理手机号
export function postVerifyPhone(data) {
    return axios.post(global.api + '/verify', { ...data, randomCodeType: 'ADD_ACCOUNT', accountType: 'PHONE' });
}
//  获取忘记邮箱验证码
export function getForgetEmailPasswordCode(data) {
    return axios.post(global.api + '/getCodeEmail', { ...data, randomCodeType: 'FORGET_PASSWORD' });
}
//  判断账号是否重复
export function isRepeatAccount(data) {
    return axios.post(global.api + '/register/isRepeatAccount', data);
}

//  判断手机号是否重复
export function isRepeatPhone(data) {
    return axios.post(global.api + '/register/isRepeatPhone', data);
}
//  判断邮箱是否重复
export function isRepeatEmail(data) {
    return axios.post(global.api + '/register/isRepeatEmail', data);
}
//  注册账号
export function postRegister(data) {
    return axios.post(global.api + '/register/doRegister', data);
}
//  手机号忘记密码
export function postPhoneForgetPassword(data) {
    return axios.post(global.api + '/account/forget', { ...data, forgetType: 'phone' });
}

//  邮箱忘记密码
export function postEmailForgetPassword(data) {
    return axios.post(global.api + '/account/forget', { ...data, forgetType: 'email' });
}
//  获取当前登陆的手机号
export function getUserPhone(data) {
    return axios.get(global.api + '/fundManager/getPhone', data);
}
//  判断邀请码是否可用
export function isAvailableInviteCode(data) {
    return axios.post(global.api + '/register/isAvailableInviteCode', data);
}
//  获取表单提交唯一令牌

export function getRegisterToken(data) {
    return axios.get(global.api + '/register/getRegisterToken', data);
}
//  新版注册第一步（手机验证码）
export function first(data) {
    return axios.post(global.api + '/register/doRegister/step/first', data);
}
//  新版注册第二步（用户名密码绑定）
export function second(data) {
    return axios.post(global.api + '/register/doRegister/step/second', data);
}