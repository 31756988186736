import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Space, Button } from 'antd';
import { useUpdateEffect, useReactive, useMount } from 'ahooks';
import { showList } from '@/api/stock';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { isValidArray, isValidObj, labelValues } from '@/utils/utils2';
import { NewRangePicker } from '@/view/common/widgets';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const DEFALUT_RANGE = [moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
/* 
  收益归因: [原]交易记录里面的统计
*/
export default function PlateCharts(props) {
  const [checkVal, setCheckValue] = useState([]);
  const [checkItem, setCheckItem] = useState([]);
  const [fullSerise, setFullSerise] = useState([]);
  const [gcount, setGcount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const plateState = useReactive({ plateObj: {} });
  const [plateRate, setPlateRate] = useState({});
  const [dates, setDates] = useState(DEFALUT_RANGE);
  const [option, setoption] = useState({
    xAxis: {
      name: '时间',
      type: 'category',
      data: []
    },
    yAxis: {
      type: 'value',
    },
    series: [],
    tooltip: {
      trigger: 'axis',
      enterable: true,
      extraCssText: 'max-height:85%;overflow:scroll',
      confine: true,
      formatter: renderFormatter
    },
  });

  function renderFormatter(params) {
    let finalString = '';
    params.map(n => {
      //const get_time = _.get(n, 'name'); // 日期
      const get_plate = _.get(n, 'seriesName'); // 板块名称
      const data_index = _.get(n, 'dataIndex'); // 数据index位置
      const getCount = _.get(plateState.plateObj, `${get_plate}[${data_index}]`);
      finalString = finalString
        + n.marker + ' ' + n.seriesName
        + `<span style='font-weight:600;margin-left:12px'>${n.value}</span>    
              (${get_plate in plateState.plateObj ? getCount : ''}次)` // 实时无需展示额外括号数据
        + '<br/>'
    });
    // console.log('renderFormatter', params);
    return finalString;
  }

  useMount(() => {
    _showList();
  })

  async function _showList(date) {
    const is_reverse = _.get(props, 'isReverse', false);
    let params = {
      subAccountId: _.get(props, 'subAccountId'),
      state: true,
      beginTime: date ? date[0] : dates[0],
      endTime: date ? date[1] : dates[1],
      content: '',
      continuation: false
    }
    const res = await showList(params, is_reverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200' || _.get(res, 'code', '') === '205') {
      setPlateRate(_.get(res, 'data.plateRate', {}));
      setGcount(_.round(gcount + 0.1, 1));
    }
  }

  useEffect(() => {
    //console.log('charts', props)
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('plate_charts'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = _.cloneDeep(option);
    let times = []; let finals = {}; let finalsNum = {}; let newSerise = [];
    if (isValidObj(plateRate)) {
      let timeList = Object.keys(plateRate).map(date => { // 处理时间object-key的时间
        return { 'date': date, 'timeKey': moment().diff(moment(date), 'd') }
      });
      timeList = _.orderBy(timeList, ['timeKey'], ['desc']); // 时间排序
      timeList.map((t, index) => {
        times[index] = t.date;
        const rateMap = _.get(plateRate, `${t.date}.plateRateMap`, {}); // 板块收益率
        const plateNum = _.get(plateRate, `${t.date}.plateNum`, {}); // 板块个数
        if (isValidObj(rateMap)) {
          for (const platename in rateMap) {
            const plateVal = _.get(rateMap, platename, 0);
            const plateCount = _.get(plateNum, platename, 0);
            if (platename in finals) { // 赋值对应的index位置，板块信息不是固定的，没有就还会是0
              finals[platename][index] = plateVal;
              finalsNum[platename][index] = plateCount;
            } else { // final板块空的时候，创建getDatas大小相同的全0数组
              finals[platename] = _.fill(Array(_.size(plateRate)), 0);
              finalsNum[platename] = _.fill(Array(_.size(plateRate)), 0);
              _.set(finals, `${platename}[${index}]`, plateVal);
              _.set(finalsNum, `${platename}[${index}]`, plateCount);
            }
          }
        }
      })
    }
    plateState.plateObj = isValidObj(finalsNum) ? finalsNum : {}; //赋值个数，tooltip里面直接调用
    // console.log('final', finals)
    // console.log('finalsNum', finalsNum)
    const finalKeys = _.keys(finals);
    let checkOptions = [];
    finalKeys.map((keyname, i) => { // 创建series数据
      let sitem = createBaseLine(null, { 'name': keyname, 'order': i });
      sitem.data = trimPlateArray(finals[keyname]);
      newSerise.push(sitem);
      checkOptions.push(labelValues([keyname, i])); // 【bug-fix】用中文做value，checkbox会发生点击不选中的问题，使用Number||英文
    })
    setFullSerise(newSerise);
    const sliceKeys = isValidArray(checkVal) ? checkVal : [0, 1, 2, 3, 4];
    newOption.series = _.filter(newSerise, o => _.includes(sliceKeys, o.order));
    newOption.xAxis.data = times;
    setCheckItem(checkOptions);
    setCheckValue(sliceKeys);
    // console.log('newOption', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [gcount]);
  // checkbox选择更新
  useUpdateEffect(() => {
    let myChart = props.myChart;
    myChart = echarts.init(document.getElementById('plate_charts'));
    let newOption = _.cloneDeep(option);
    newOption.series = _.filter(fullSerise, o => _.includes(checkVal, o.order));
    myChart.setOption(newOption, true);
    myChart.resize();
  }, [updateCount]);
  // 板块并不是每天都会记录，所以空数据进行处理
  const trimPlateArray = (array) => {
    let newArray = [];
    if (isValidArray(array)) {
      array.map((n, i) => newArray[i] = !n ? 0 : _.round(n, 4));
    }
    return newArray
  }
  // checkbox.group 修改选择项
  const onCheckValueChange = (checkedValues) => {
    // console.log('checkedValues', checkedValues)
    setCheckValue(checkedValues);
    setUpdateCount(_.round(updateCount + 0.1, 1));
    //props.plateChange(checkedValues); // 上层保存check的state，这样可以再页面不切换时保存板块状态
  };

  const btnProps = { size: 'small', type: 'text' };
  return (
    <>
      <div style={{ marginBottom: 12, marginTop: 8, textAlign: 'right' }}>
        <Space>
          <Button {...btnProps} onClick={() => onCheckValueChange(checkItem.map(c => c.value))}>全选</Button>
          <Button {...btnProps} danger onClick={() => onCheckValueChange([])}>取消</Button>
          <NewRangePicker dates={dates} onSelect={(dateString) => {
            setDates(dateString);
            _showList(dateString);
          }} />
        </Space>
      </div>

      <div className='controlBar'>
        <Checkbox.Group
          style={{ width: '100%' }}
          value={checkVal}
          onChange={onCheckValueChange}
        >
          <Row gutter={[8, 8]}>
            {isValidArray(checkItem) && checkItem.map((item, i) => {
              return <Col span={3} key={i}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            })}
          </Row>
        </Checkbox.Group>
      </div >
      <div style={{ display: 'flex' }}>
        <div
          id="plate_charts"
          style={{
            width: '100%',
            height: 440
          }}
        />
      </div>
    </>
  )
}