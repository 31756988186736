import axios from '@/utils/axios';
// 盘中获取
export function showTradeReport() {
    return axios.get(global.api + '/fundManager/showTradeReport');
}
export function showTradeReportSub() {
    return axios.get(global.api + '/fundManager/showTradeReportSub');
}
//重仓分布档位
export function getHighPositionLevel(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/getHighPositionLevel', data);
}
//按日期获取收益率
export function showSubAccountHistory(data, type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showSubAccountHistory', data);
}
export function showProductHistoryInterval(data,type) {
    return axios.post((type === 'FC' ? global.fc : global.api) + '/fundManager/showProductHistoryInterval', data);
}
