import React from 'react';
import { Typography } from 'antd';
import { DEFAUL_INDEX_CODE } from './indexCode';
import moment from 'moment';
import _ from 'lodash';

const STORAGE_TYPE = {
  '1': 'url_string',
  '2': 'trading_status', // 全局存储交易状态，全局缓存数据用
  'cmp': 'compare_account', // 对比交易时存储上一次账户id
  'h_pdt': 'home_product', // 首页卡片存储习惯
  'sub_acc': 'sub_account', // subAccount 分页
  'cidx': 'custom_index', // subAccount 分页
};
// 自定义指数value自定义字符
const CUSTOMIDX_STRING = 'CusTom';

const saveStorage = (type, val) => {
  try {
    localStorage.setItem(STORAGE_TYPE[type], val);
  } catch (error) { }
}
const getStorage = (type) => {
  try {
    let val = localStorage.getItem(STORAGE_TYPE[type]);
    return val ?? ''
  } catch (error) { }
}
// 创建简单表格项
const simpleColumns = (pmArray = ['', ''], rest = {}) => {
  let obj = {
    title: pmArray[0],
    dataIndex: pmArray[1],
    key: pmArray[1],
    ...rest
  }
  if (pmArray[2]) {
    obj.width = pmArray[2];
  }
  return obj;
}
// 属性判断
const isValidArray = (array) => array && _.isArray(array) && _.size(array) > 0 ? true : false;
const isValidObj = (object) => object && _.isObject(object) && _.size(object) > 0 ? true : false;
const isValidNumber = (number) => number && _.isNumber(number) ? true : false;
const isValidString = (string) => string && _.isString(string) && string !== '' ? true : false;

// 数字创建每三位逗号分隔的进位格式，返回字符串；
function addNumberSplit(number) {
  let finalStirng = '';
  let splitNumber = _.reverse(_.split(number, ''));
  let c = 0;
  let isDim = splitNumber.indexOf('.') !== -1 ? true : false; // 修复整数c无法递增，一直保持!isDim的状态
  let stringArray = [];
  splitNumber.map(n => {
    let num = n !== '.' ? parseInt(n) : null;
    if (n === '.') {
      isDim = false;
    }
    if (c === 3 && !isDim && n !== '-') {
      c = 0;
      stringArray.push(',');
    }
    if (num !== null && !isDim) {
      c = c + 1;
    }
    stringArray.push(n);
  })
  finalStirng = _.join(_.reverse(stringArray), '');
  if (_.includes(finalStirng, '.00')) {
    finalStirng = finalStirng.replaceAll('.00', '')
  }
  return finalStirng;
}

const updownColorKey = {
  zero: 'black',
  positive: 'red',
  negative: 'green'
}
// 根据rate返回带颜色或【%】的收益率string
const RateText = ({
  rate = 0,
  carry = 2,
  noPrefix = null,
  rType = 'number',
  size = 'strong',
  color = false,
}) => {
  let renderRate = rate ? _.round(parseFloat(rate), carry).toFixed(carry) : 0;
  // 替换【,】的string
  if (rType === 'replace' && _.isString(rate)) {
    let repNumber = rate.replaceAll(',', '');
    renderRate = _.round(parseFloat(repNumber), carry).toFixed(carry)
  }
  let ud = '';
  if (renderRate === 0) {
    ud = 'zero';
  } else if (renderRate > 0) {
    ud = 'positive';
  } else if (renderRate < 0) {
    ud = 'negative'
  }
  // 重新生成待【,】进位的字符串； utils2里面拆分出来单独可使用的function
  if (rType === 'replace') {
    let splitNumber = _.reverse(_.split(renderRate, ''));
    let c = 0;
    let isDim = splitNumber.indexOf('.') !== -1 ? true : false;;
    let stringArray = [];
    splitNumber.map(n => {
      let num = n !== '.' ? parseInt(n) : null;
      if (n === '.') {
        isDim = false;
      }
      if (c === 3 && !isDim && n !== '-') {
        c = 0;
        stringArray.push(',');
      }
      if (num !== null && !isDim) {
        c = c + 1;
      }
      stringArray.push(n);
    })
    renderRate = _.join(_.reverse(stringArray), '');
    if (_.includes(renderRate, '.00')) {
      renderRate = renderRate.replaceAll('.00', '')
    }
  };
  let fcolor = 'black';
  if (!color) { // 不自定义颜色使用rate的颜色
    fcolor = updownColorKey[ud];
  }
  return <Typography.Text style={{ 'color': fcolor, 'fontWeight': size === 'strong' ? 600 : 400 }}>
    {renderRate + (noPrefix ? '' : '%')}
  </Typography.Text>
}

const labelValues = (array) => {
  let obj = { label: array[0], value: array[1] };
  if (array[2]) {
    obj.children = array[2]
  }
  return obj;
};
// 时间排序及创建timeKey字段; pure_date=数组内为时间字符串，不是对象格式
const format_obj = {
  'd': 'YYYY-MM-DD',
  's': 'HH:mm:ss', 'fs': 'YYYY-MM-DD HH:mm:ss',
  'm': 'HH:mm', 'fm': 'YYYY-MM-DD HH:mm',
  'fms': 'YY-MM-DD HH:mm:ss:SSS', 'ms': 'HH:mm:ss:SSS',
};
const key_obj = {
  d: 'd', s: 's', m: 'm', ms: 'millisecond',
  fs: 's', fm: 'm', fms: 'millisecond',
};
const createDatasTimeKey = (array = [], compareKey = '', timeString = 'd', orders = 'desc') => {
  let final = [];
  if (isValidArray(array) && isValidString(compareKey)) {
    final = array.map(n => {
      const cval = compareKey === 'pure_date' ? n : _.get(n, compareKey);
      return _.assign(compareKey === 'pure_date' ? { 'date': n } : { ...n }, {
        'timeKey': moment(cval).diff(moment(), key_obj[timeString]),
        'formatDate': moment(cval).format(format_obj[timeString] || 'YYYY-MM-DD'),
      })
    });
  }
  return _.orderBy(final, ['timeKey'], [orders]);
}
// 指定日期之后的日期禁止点击
const disabledDateFunc = (current, targetDate) => {
  const momentTarget = targetDate ? moment(targetDate) : moment();
  const tooLate = current.diff(momentTarget, 'days') > 0;
  return !!tooLate;
};

//bt回测 和 实测对比中的账户处理 ; 返回platformArray
const plateform_items = [
  { key: 'simx', name: 'SIMX' },
  { key: 'simxVirtual', name: 'SIMX_虚拟' },
  { key: 'tamp', name: 'TAMP' },
  { key: 'tamp_fc', name: 'TAMP_反采' },
  { key: 'bt', name: 'BT_每日更新' },
  { key: 'bt_testing', name: 'BT_回测中' },
  { key: 'bt_rest', name: 'BT_其他' },
  { key: 'btVirtual', name: 'BT_虚拟' },
];
const TWO_VIR = ['simxVirtual', 'btVirtual']; // 虚拟产品key
const BT_SPLIT_KEY = ['bt', 'bt_testing', 'bt_rest']; // bt分拆的key
// 提取出产品内部的符合条件的子账户；同TradeCompare处理逻辑
const labelIncludesFalse = (label) => { // 检查是否包含中英文括号
  const bracket_en = ["(", ")"]; const bracket_cn = ["（", "）"];
  let ens = bracket_en.map(b => _.includes(label, b));
  let cns = bracket_cn.map(b => _.includes(label, b));
  return !ens.includes(false) || !cns.includes(false) ? true : false; // 中英文有一组都不包含false的，则匹配完整括号 返回true
}

function handlePlatformAccount(res = {}, resFc = {}, isAcc = false) {
  let accMap = new Map(); let proMap = new Map();
  let accCompare = new Map();
  // 格式: {simx_210:名称....}  通过字符串区分平台，避免重复
  const createAccountItem = (account, type) => {
    const setType = type === 'tamp_fc' ? 'tamp' : type;
    const proNameKey = _.includes(TWO_VIR, type) ? 'name' : 'productName'; // simx虚拟账户名称和id的字段不同
    const proIdKey = _.includes(TWO_VIR, type) ? 'id' : 'productId';
    let array = [];
    if (isValidArray(account)) {
      account.map(product => {
        // 每个group对象内，增加baseType字段，可判断账户基准
        let newItem = {
          ...product,
          label: _.get(product, proNameKey),
          children: [],
          value: _.get(product, proIdKey),
          centralizedLogin: type === 'tamp_fc' ? 2 : 1
        };
        proMap.set(setType + '_' + newItem.value, newItem.label);
        // 增加产品兼容性；product页面则不再遍历子账户数据
        isAcc && isValidArray(_.get(product, 'securitiesAccountList', [])) && product.securitiesAccountList.map(acc => {
          isValidArray(_.get(acc, 'subAccountList', [])) && acc.subAccountList.map(sub => {
            let newSub = {
              ...sub,
              label: _.get(sub, 'subAccountName', ''),
              value: _.get(sub, 'subAccountId', 0),
              centralizedLogin: type === 'tamp_fc' ? 2 : 1
            };
            accMap.set(setType + '_' + newSub.value, newSub.label); // 用id作为key，label作为value，可根据id找到名称
            accCompare.set(newSub.value, newSub.label); // compare使用 value-label进行设置
            newItem.children.push(newSub);
          })
        });
        // bt分拆
        if (_.includes(BT_SPLIT_KEY, type)) {
          const isValidBt = labelIncludesFalse(newItem.label);
          if (type === 'bt' && isValidBt) {  // bt 回测，产品名称包含“括号”的才进行显示
            array.push(newItem);
          } else if (!isValidBt) {
            // 其他里面 isShow = 1的增加回测中分类
            if (type === 'bt_testing' && _.get(newItem, 'isShow') === '1') {
              array.push(newItem);
            } else if (type === 'bt_rest' && _.get(newItem, 'isShow') === '0') {
              array.push(newItem);
            }
          }
        } else {
          array.push(newItem);
        }
      })
    }
    return array; // tamp直接返回账户array
  };
  const newPlateformItems = isAcc ? _.filter(plateform_items, o => !_.includes(TWO_VIR, o.key)) : plateform_items;
  let platformArray = newPlateformItems.map(k => {
    let itemData = [];
    if (k.key === 'tamp_fc') {
      itemData = createAccountItem(_.get(resFc, 'data.tamp'), 'tamp_fc');
    } else {
      // bt 拆分需继续调用bt数据
      itemData = createAccountItem(
        _.get(res, `data.${_.includes(BT_SPLIT_KEY, k.key) ? 'bt' : k.key}`, []),
        k.key
      );
    }
    return labelValues([k.name, k.key, itemData]);
  });
  // console.log(platformArray)
  return {
    'platformArray': platformArray,
    'accoutMap': accMap,
    'productMap': proMap, // 增加产品层面名称
    'accCompare': accCompare //compare 不同map字段
  };
};
// 全局创建所有账户及产品options
function createAccountItem(datas, loginType) {
  let product = []; let account = []; let accObj = {}; let productObj = {};
  let accLogin = {}; let productLogin = {};
  if (isValidArray(datas)) {
    datas.map(p => {
      let productItem = {
        label: _.get(p, 'productName'),
        value: _.get(p, 'productId'),
        centralizedLogin: loginType
      };
      product.push(productItem);
      _.set(productObj, productItem.value, productItem.label);
      _.set(productLogin, productItem.value, loginType);

      isValidArray(_.get(p, 'securitiesAccountList', [])) && p.securitiesAccountList.map(acc => {
        const getAccList = _.get(acc, 'subAccountList', []);
        isValidArray(getAccList) && acc.subAccountList.map(sub => {
          let newSub = {
            ...sub,
            label: _.get(sub, 'subAccountName', ''),
            value: _.get(sub, 'subAccountId', 0),
            centralizedLogin: loginType
          };
          _.set(accObj, newSub.value, newSub.label);// 用id作为key，label作为value，可根据id找到名称
          _.set(accLogin, newSub.value, loginType);
          account.push(newSub);
        })
      });
    })
  }
  return { product, account, accObj, productObj, accLogin, productLogin }
}

// 计算当前是否是开盘时间
const isMarketTrading = (isTradeDay = true) => {
  const TOTAY = moment().format('YYYY-MM-DD');
  const OPEN_TIME = moment(TOTAY + ' 9:30');
  const CLOSE_TIME = moment(TOTAY + ' 15:00');
  const currentTime = moment();
  let isGet = false;
  const diffOpenMin = currentTime.diff(OPEN_TIME, 'm');
  const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
  if (diffOpenMin >= 0 && diffOpenMin <= 120) {
    isGet = true;
  };
  if (diffCloseMin >= -120 && diffCloseMin <= 0) {
    isGet = true;
  };
  // 非交易日直接返回false
  if (!isTradeDay) {
    isGet = false;
  }
  return isGet;
};
// 今日是否是交易日，并且已收盘
const isMarketClosed = (trades = []) => {
  const currentTime = moment();
  const today = currentTime.format('YYYY-MM-DD');
  let dindex = _.findIndex(trades, o => o.date === today);
  const close_time = moment(today + ' 15:00');
  const diffCloseMin = currentTime.diff(close_time, 'm');
  return diffCloseMin > 0 && dindex !== -1 ? true : false;
};

// 判断指数参数是否包含自定义指数
const handleCustomIndexPms = (pms = {}, csIndex = []) => {
  let newCusIndex = [];
  let isCusFromStorage = false;
  if (isValidArray(csIndex)) {
    newCusIndex = csIndex;
  } else { // 如mobx为空，获取storage数据
    const getCinde = getStorage('cidx');
    newCusIndex = JSON.parse(isValidString(getCinde) ? getCinde : '');
    isCusFromStorage = true;
  };
  let isBatchIndex = false;
  let symbolInit = false;
  let batchRate = {};
  // 包含自定义字符，修改pms；
  if (_.includes(pms.symbol, CUSTOMIDX_STRING)) {
    let findex = _.findIndex(newCusIndex, o => o.value === pms.symbol);
    const indexArr = _.get(newCusIndex, `[${findex}].index`, []);
    _.set(pms, 'symbol', _.join(indexArr.map(n => {
      batchRate[n.code] = n.rate;
      return n.code;
    }), ','));
    isBatchIndex = true;
    // 实时返回默认指数；
    if (_.get(pms, 'dateType') === 'TODAY') {
      symbolInit = true;
      pms.symbol = DEFAUL_INDEX_CODE;
      isBatchIndex = false;
    }
  };
  // 返回params参数，和rate比例值对象；
  return {
    'isBatch': isBatchIndex, 'symbolInit': symbolInit,
    'rates': batchRate, 'customs': newCusIndex,
    'params': pms, 'isCusFromStorage': isCusFromStorage,
  }
};

export {
  CUSTOMIDX_STRING,
  //方法
  saveStorage, getStorage,
  simpleColumns,
  isValidArray, isValidObj, isValidNumber, isValidString,
  addNumberSplit,
  RateText,
  labelValues,
  createDatasTimeKey,
  disabledDateFunc,
  handlePlatformAccount,
  createAccountItem,
  isMarketTrading, isMarketClosed,
  handleCustomIndexPms
}