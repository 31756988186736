
import React, { useState } from 'react';
import { Alert } from 'antd';
import { showAssetsAndRevenue, showAllSubAccount } from '@/api/workbench';
import { isAllStockHoliday } from '@/api/stock';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useMount, useUnmount } from 'ahooks';
import AcCard from './Components/AcCard';
import DeskHeader from './Components/DeskHeader';
import moment from 'moment';
import _ from 'lodash';

const TOTAY = moment().format('YYYY-MM-DD');
const OPEN_TIME = moment(TOTAY + ' 9:30');
const CLOSE_TIME = moment(TOTAY + ' 15:00');

let timer = null;

export default function TradersDesk(props, { history }) {
  const mobxStore = useLocalObservable(() => store);
  const [accountList, setaccountList] = useState([]);
  const [accountData, setaccountData] = useState({});

  function createString(size) {
    var arr1 = new Array(size);
    for (var i = 0; i < arr1.length; i++) {
      arr1[i] = '*';
    }
    return arr1.join('')
  }

  useMount(() => {
    _getData();
    _isTradingTime();
  });

  useUnmount(() => {
    timer && clearInterval(timer);
  });

  async function _get() {
    timer = setInterval(() => {
      _getData();
    }, 30000);
  }
  async function _getData() {
    const data = await showAssetsAndRevenue();
    if (_.get(data, 'code', '') === '200') {
      setaccountData(data.data);
    }
    const data1 = await showAllSubAccount();
    if (_.get(data1, 'code', '') === '200') {
      if (data1.data !== null) {
        data1.data.map(item => {
          item.netValue = item.netValue.toFixed(4);
          return item;
        })
      }

      setaccountList(handleAccountData(data1));
    }
  }

  async function _isTradingTime() {
    const currentTime = moment();

    let holiRes = await isAllStockHoliday({
      beginDate: TOTAY,
      endDate: TOTAY
    })

    if (_.get(holiRes, 'code', '') === '200') {
      if (_.get(holiRes, 'data.isAllHoliday') === false) {
        const diffOpenMin = currentTime.diff(OPEN_TIME, 'm');
        const diffCloseMin = currentTime.diff(CLOSE_TIME, 'm');
        let isGet = false;
        if (diffOpenMin >= 0 && diffOpenMin <= 120) {
          isGet = true;
        }
        if (diffCloseMin >= -120 && diffCloseMin <= 0) {
          isGet = true;
        }
        console.log('是否在交易时间:', isGet)
        mobxStore.changeInTrading(isGet ? 'trading' : 'rest');
        if (isGet) {
          _get();
        }
      }
    }
  }

  function handleAccountData(data) {
    const getData = _.get(data, 'data', []);
    let newData = getData && getData.map(item => {
      let secAccountName = item.securitiesAccountName;
      let productName = item.productName;
      let secArray = _.split(secAccountName, '');
      let productArray = _.split(productName, '');
      let sizeAccName = _.size(secAccountName);
      let sizeProductName = _.size(productName);
      let newName = '';
      let newProduct = '';
      if (_.isString(secAccountName)) {
        if (sizeAccName > 4) {
          newName = [secArray[0], secArray[1], createString(sizeAccName - 4), secArray[sizeAccName - 2], secArray[sizeAccName - 1]].join('')
        } else if (sizeAccName <= 4 && sizeAccName >= 2) {
          newName = [secArray[0], secArray[1], createString(sizeAccName - 2)].join('');
        } else {
          newName = secAccountName;
        }
      }
      if (_.isString(secAccountName)) {
        if (sizeProductName > 2) {
          newProduct = [productArray[0], productArray[1], createString(sizeProductName - 2)].join('')
        } else {
          newProduct = productName;
        }
      }

      return { ...item, securitiesAccountName: newName, productName: newProduct }
    });

    return newData;
  }

  function _seeSubAccount(record) {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/listsDetails',
      state: { subAccountInfo: record }
    });

  }

  return (
    <div className="contentView" >
      {
        accountList === null &&
        <Alert
          message="Warning"
          description="请联系基金经理，关联交易子账户"
          type="warning"
          showIcon
          closable
        />
      }
      <div>
        <DeskHeader
          accDatas={accountData}
        />

        <AcCard
          accounts={accountList}
          onCheck={(val) => _seeSubAccount(val)}
        />
      </div>
    </div>
  )
}