import React, { } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import SiderSelectView from './SiderSelectView';
// import EchartsInfo from '@/view/common/EchartsInfo';
import EchartsInfo2 from './EchartsInfo2'
import './FundAssetConfig.scss';


export default function FundAssetConfig(props) {
    return (
        <div className="FundAssetConfig">
            <div className="siderView">
                <div className="colorGrey" >配置选项</div>
                <br />
                <SiderSelectView
                    title="策略"
                    icon={<TagsOutlined />}
                    value={'稳定价值策略'}
                />
                <SiderSelectView
                    title="风险"
                    icon={<TagsOutlined />}
                    iconColor="rgb(253,133,7)"
                    value={'进取型'}
                />
                <SiderSelectView
                    title="基金池"
                    icon={<TagsOutlined />}
                    iconColor="rgb(63,163,63)"
                    value={'魔活儿金融基金池'}
                />
                <SiderSelectView
                    title="调仓"
                    icon={<TagsOutlined />}
                    iconColor="rgb(253,133,7)"
                    value={'月度自动调仓'}
                />
                <br />
                <hr className="siderHr" />
                <br />
                <div className="colorGrey" >配置操作</div>
                <br />
                <Button type="primary" style={{ width: '100%', marginBottom: 10 }}>保存配置</Button>
                <Button type="primary" style={{ width: '100%', marginBottom: 10 }}>创建组合</Button>
            </div>
            <div className="contentView">
                <div className="contentTitle">
                    <Row style={{ marginTop: 20 }}>
                        <Col span={1} />
                        <Col span={6}>
                            <SiderSelectView iconColor="rgb(217,217,217)" title="配置名称" icon={<TagsOutlined />} />
                        </Col>
                        <Col span={2} />
                        <Col span={7}>
                            <div className="contentTitleT">累计收益</div>
                            <div className="contentTitlNum">-10.27%</div>
                        </Col>
                        <Col span={7}>
                            <div className="contentTitleT" >日收益 <span className="contentTitleDate colorGrey" >(2021-04-16)</span> </div>
                            <div className="contentTitlNum">-10.27%</div>
                        </Col>
                    </Row>
                </div>
                {/* <Card title="历史收益率回撤">
                    <EchartsInfo />
                </Card> */}
                <Card title="资产配置比例">
                    <EchartsInfo2 heigth={400} />
                </Card>
            </div>
        </div>
    )
}