import React, { useState } from 'react';
import { PageHeader, Card, Tabs, Typography, Space, Tag, Button } from 'antd';
import { AccountBookOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useInterval, useMount, useUnmount, useReactive } from 'ahooks';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { showTradeReport, showTradeReportSub } from '@/api/fc';
import { showIndexKline } from '@/api/workbench';
import { showCurrentIndexSpeed } from '@/api/stock';
import { validateResponse } from '@/utils/utils';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import { isValidArray, isValidObj, isValidString, isMarketTrading } from '@/utils/utils2';
import { initDateType } from '@/view/common/Components/list_util';
import { calNets } from '@/view/common/Components/chartsInfoUtils';
import { handlePositions } from '@/view/Home/AccountAdm/ListsDetails/list_utils';
import MainRateCharts from '@/view/common/MainRateCharts';
import PositionTable from '@/view/Home/AccountAdm/ListsDetails/Components/PositionTable';
import RecordTable from '@/view/Home/AccountAdm/ListsDetails/Components/RecordTable';
import AssetsHeader from '@/view/Home/AccountAdm/ProductDetails/Components/AssetsHeader';
import { IndexSelect } from '@/view/common/Components/main_widget';
import { RealtimeBtn } from '@/view/common/widgets';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const MS_FORMAT = 'MM-DD HH:mm:ss:SSS';
const TODAY = moment().format('YYYY-MM-DD');
const INTERVAL_NUM = 30000; const TIME_INTERVAL = 10000;
const EmpBlock = <div style={{ height: 22, width: 12, display: 'inline-block' }}></div>;
const GET_KEY_OBJ = { // switch代表切换，另外一个key
  'product': {
    'rate': 'historyInfoToLaySingleProduct',
    'trade': 'tradeOrderResults',
    'id': 'productId',
    'active': 'active',
    'switch_act': 'subActive',
    'nameKey': 'productName',
    'tabLeft': <><Tag color='orange'>产品</Tag>{EmpBlock}</>
  },
  'list': {
    'rate': 'subAccountHistory',
    'trade': 'tradeOrderResults',
    'id': 'subAccountId',
    'active': 'subActive',
    'switch_act': 'active',
    'nameKey': 'name',
    'tabLeft': <><Tag color='blue'>账户</Tag>{EmpBlock}</>
  },
};
/* 
  反采托管账户页面;
  组件与产品相同，故数据调整按照账户组件需求调整数据结构
  240305 增加子账户数据，增加新的tab，可在产品和子账户直接切换；GET_KEY_OBJ维护切换的key信息
*/
const HostingPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [hostingCache] = useState(JSON.parse(JSON.stringify(mobxStore.hostingCache)));
  const init_type = initDateType(tradeDates, '1');
  const initTrading = isMarketTrading(init_type.isTradeDay);
  const [indexValue, setIndexValue] = useState({});
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [countMain, setCountMain] = useState(0);
  const [idxParams, setIdxParams] = useState({});
  const [isTradeTime, setIsTradeTime] = useState(initTrading);
  const [inter, setInter] = useState(initTrading ? INTERVAL_NUM : undefined);
  const [timeInter, setTimeInter] = useState(TIME_INTERVAL);
  const hostState = useReactive({
    active: '', init: true, symbol: DEFAUL_INDEX_CODE,
    subActive: '', type: 'product'
  });
  let isUnmont = false;

  useMount(() => {
    // 全局缓存处理，可直接赋值
    if (isValidObj(hostingCache)) {
      setProducts(handleResult(hostingCache.getProduct, hostingCache.getSpeed, 'product'));
      setAccounts(handleResult(hostingCache.getAccount, hostingCache.getSpeed, 'list'));
      setIndexValue(hostingCache.getIdx);
      setIdxParams(hostingCache.idxPms);
      hostState.init = false;
      hostState.active = String(_.get(_.head(hostingCache.getProduct), 'productId'));
      setCountMain(_.round(countMain + 0.1, 1));
    } else {
      _getHostingData('mount');
    }
  });

  useUnmount(() => {
    setInter(undefined);
    setTimeInter(undefined);
    isUnmont = true;
  });
  // 自启动定时；inter获取数据，盘中自动获取； timeInter一直更新，符合条件启动或关闭inter
  useInterval(() => {
    _getHostingData();
  }, inter);

  useInterval(() => {
    const is_trading = isMarketTrading(init_type.isTradeDay);
    // console.log('is_trading', is_trading)
    if (is_trading === false && inter) {
      setInter(undefined);
    } else if (is_trading === true && !inter) {
      setInter(INTERVAL_NUM);
      setIsTradeTime(true);
    }
  }, timeInter);

  // 获取托管账户接口
  async function _getHostingData(initType) {
    let idx_pms = { 'symbol': hostState.symbol, 'dateType': 'TODAY', 'date': TODAY };
    const res = await showTradeReport();
    const res2 = await showTradeReportSub();
    const idxRes = await showIndexKline(idx_pms);
    const res3 = (isTradeTime || initType === 'mount') ? await showCurrentIndexSpeed() : {};
    if (validateResponse(res, isUnmont)) {
      const getProduct = isValidString(_.get(res, 'data', [])) ? JSON.parse(_.get(res, 'data', [])) : [];
      const getAccount = isValidString(_.get(res2, 'data', [])) ? JSON.parse(_.get(res2, 'data', [])) : [];
      const getSpeed = _.get(res3, 'data', {});
      setProducts(handleResult(getProduct, getSpeed, 'product'));
      setAccounts(handleResult(getAccount, getSpeed, 'list'));
      //指数
      setIndexValue(_.get(idxRes, 'data', {}));
      setIdxParams(idx_pms);
      // 首次加载切换第一个tab
      if (initType === 'mount') {
        hostState.init = false;
        hostState.active = String(_.get(_.head(getProduct), 'productId'));
      }
      setCountMain(_.round(countMain + 0.1, 1));
    }
  }

  function handleResult(data = [], speeds = {}, key = '') {
    // console.log(key, data);
    if (isValidArray(data)) {
      const rateKey = _.get(GET_KEY_OBJ, key + '.rate');
      const tradeKey = _.get(GET_KEY_OBJ, key + '.trade');
      data.map(items => {
        const point_list = _.get(items, `${rateKey}.pointList`, []);
        const point_net = key === 'list' ? calNets(point_list) : _.get(items, `${rateKey}.netValueList`, []);
        let retreat = [];
        if (isValidArray(point_net)) {
          point_net.map((n, i) => {
            let cur = 0;
            if (i > 0) {
              const beforeMax = _.max(_.slice(point_net, 0, i + 1));
              cur = beforeMax ? _.round(((n - beforeMax) / beforeMax) * 100, 6) : 0;
            }
            retreat.push(cur);
          });
        }
        // 收益率转义,float类型
        _.set(items, `${rateKey}.pointList`, point_list.map(n => parseFloat(n)))
        // 增加回撤
        _.set(items, `${rateKey}.pointRetreat`, retreat)
        // 修改持仓列表中的格式化时间
        const getTrades = _.get(items, tradeKey, []);
        _.set(items, `${tradeKey}`, isValidArray(getTrades) ? getTrades.map(r => {
          return _.assign(r, {
            'formatDate': moment(r.commissionTime).format(MS_FORMAT),
          });
        }) : []);
        // 持仓修改收益率
        const newPosition = handlePositions(items.position, speeds, 'hosting');
        _.set(items, 'position', newPosition.newData);
        _.set(items, 'marketMap', newPosition.marketMap);
      })
    }
    return data;
  }
  // 根据key值找到对应的item，并且返回
  function findActiveItem(active = '', datas = [], key = '') {
    const find_key = _.get(GET_KEY_OBJ, `${key}.id`);
    return isValidString(active) ? _.head(_.filter(datas, o => String(o[find_key]) === active)) : {};
  };
  // 处理定时任务
  function handleInters() {
    if (inter) {
      setInter(undefined);
      setTimeInter(undefined);
    } else {
      setInter(INTERVAL_NUM);
      // setTimeInter(TIME_INTERVAL);
    }
  }
  // 账户和产品tab的功能方法
  function renderTabs(key) {
    const curData = key === 'product' ? products : accounts;
    const curActive = _.get(GET_KEY_OBJ, `${key}.active`);
    if (isValidArray(curData)) {
      return <Tabs
        activeKey={hostState[curActive]}
        tabPosition='top'
        tabBarExtraContent={{ left: _.get(GET_KEY_OBJ, `${key}.tabLeft`) }}
        onChange={(activeKey) => {
          hostState[curActive] = activeKey; // 切换active
          hostState[_.get(GET_KEY_OBJ, `${key}.switch_act`)] = ''; // 将另外一个active置为空字符串
          hostState.type = key; //type赋值为当前的key
          setCountMain(_.round(countMain + 0.1, 1));
        }}
      >
        {curData.map(n => <Tabs.TabPane
          tab={<>{key === 'list' && <AccountBookOutlined />}<span>{_.get(n, _.get(GET_KEY_OBJ, `${key}.nameKey`))}</span></>}
          key={String(_.get(n, _.get(GET_KEY_OBJ, `${key}.id`)))}>
        </Tabs.TabPane>)}
      </Tabs>
    } else {
      return <></>
    }
  }
  // active=productId的值，用于筛选； active为字符串类型，需转换
  const newItems = findActiveItem(
    hostState[_.get(GET_KEY_OBJ, `${hostState.type}.active`)],
    hostState.type === 'product' ? products : accounts,
    hostState.type
  );
  // console.log(hostState.active, productItems);
  return <div style={{ overflowX: 'hidden' }}>
    <PageHeader
      title={<Space>
        <Text>湘财托管</Text>
        <RealtimeBtn
          isClose={inter === undefined ? true : false}
          onChange={handleInters}
        />
      </Space>}
      style={{ marginBottom: 6, backgroundColor: 'white' }}
      extra={[<Button key='syncBtn' size='small' type='text' icon={<SyncOutlined />} onClick={_getHostingData}>刷新</Button>]}
    >
      {renderTabs('product')}
      {renderTabs('list')}
      {!init_type.isTradeDay && isValidArray(tradeDates) && <Space><ExclamationCircleOutlined /><Text>今日无交易</Text></Space>}
    </PageHeader>

    <div style={{ padding: 8 }}>

      <AssetsHeader assets={newItems} typeName={hostState.type === 'product' ? '产品' : '账户'} />

      <Card style={{ marginTop: 12, marginBottom: 12 }} bodyStyle={{ padding: 12 }}>
        <div style={{ position: 'absolute', top: 19, right: 90, zIndex: 5 }}>
          <IndexSelect active={'TODAY'} idv={hostState.symbol}
            onIndexChange={(v) => {
              hostState.symbol = v;
              _getHostingData();
            }} />
        </div>
        <MainRateCharts
          pageKey='hosting'
          currentTabs={'hosting'} //代替每日日期选择，故不填 1 即可
          upcount={countMain}
          datas={_.get(newItems, _.get(GET_KEY_OBJ, hostState.type + '.rate'), {})}
          dailyRevenueRate={_.round(parseFloat(_.get(newItems, 'dailyRevenueRate', 0)), 2)} // 空收益率数据显示日收益率
          indexSymbol={hostState.symbol}
          indexValue={indexValue}
          pms={idxParams}
          hostPosition={_.get(newItems, 'position')}
          onPositionSync={() => _getHostingData()}
        />
      </Card>

      <PositionTable
        pageKey='hosting'
        upCount={countMain}
        rlist={_.get(newItems, 'position')}
        mkMap={_.get(newItems, 'marketMap')}
        isTrading={isTradeTime}
      />

      <div style={{ marginTop: 12, padding: 8, backgroundColor: 'white', borderRadius: 4 }}>
        <div style={{ marginBottom: 16, marginTop: 8 }}>
          <Text strong style={{ fontSize: 15 }}>交易记录</Text>
        </div>
        <RecordTable
          orderKey='hosting'
          list={_.get(newItems, 'tradeOrderResults', [])}
        />
      </div>
    </div>
    <div style={{ height: 25 }}></div>
  </div>
}

export default HostingPage;