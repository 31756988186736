import React from 'react';
import { Statistic } from 'antd';
import { renderValueColor, renderValuePrefix } from './utils';
import _ from 'lodash';

/**
 *  资产信息头部组件，拿到 value 进行渲染红涨绿跌及其他信息，
 * 'replace'代表对字符串值进行处理，包括将每个百分位增加 "逗号" 的字符串数据； 会进行拆分，按照数值判断涨跌，最后再次拼装成字符串；  
 *  如数字类型则直接判断返回
 */
const StatisticHeader = ({ title = '', value = 0, isColor = false, isPrefix = false, fSize = 0, stProps = {} }) => {
  let renderStyle = { fontSize: 24, fontWeight: '600' }
  let sProps = { ...stProps }
  if (fSize) {
    renderStyle.fontSize = fSize;
  }
  if (isColor) {
    renderStyle.color = renderValueColor(value, 'replace');
  }
  if (isPrefix) {
    sProps.prefix = renderValuePrefix(value);
  }

  return <Statistic
    title={title}
    value={value}
    valueStyle={renderStyle}
    {...sProps}
  />
}

export default StatisticHeader;