
import React from 'react';
import {
  Col, Space, Divider, Descriptions, Typography
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { renderUpDownRate2, autoCol } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;
const NewDivider = () => <Divider style={{ margin: '12px 0' }} />

const renderFloat = (v = 0, f = 2) => parseFloat(v).toFixed(f)

const TradeInfo = ({
  askPrice = [0, 0, 0, 0, 0],
  bidPrice = [0, 0, 0, 0, 0],
  containsETF = 0,
  listData = {},
  recordData = {},
  form
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  function calRate() {
    const match = _.get(listData, 'match', 0) || 0;
    const preClose = _.get(listData, 'preClose', 0) || 0;
    return renderUpDownRate2((match - preClose) / preClose * 100);
  }
  function getListDataVal(key, non = 0) {
    return _.get(listData, key, non);
  }
  const info3List = [
    { label: '开盘价', key: 'open' },
    { label: '收盘价', key: 'match' },
    { label: '最高价', key: 'high' },
    { label: '最低价', key: 'low' },
    { label: '昨收价', key: 'preClose' },
    { label: '涨跌幅', key: 'match' },
    { label: '成交量', key: 'volume' },
    { label: '成交额', key: 'turnover' },
    { label: '涨停价', key: 'highLimited' },
    { label: '跌停价', key: 'lowLimited' },
  ]
  const sellInfos = [
    { title: '卖5', value: askPrice[4] },
    { title: '卖4', value: askPrice[3] },
    { title: '卖3', value: askPrice[2] },
    { title: '卖2', value: askPrice[1] },
    { title: '卖1', value: askPrice[0] },
  ];
  const buyInfos = [
    { title: '买1', value: bidPrice[0] },
    { title: '买2', value: bidPrice[1] },
    { title: '买3', value: bidPrice[2] },
    { title: '买4', value: bidPrice[3] },
    { title: '买5', value: bidPrice[4] },
  ];
  const stock_code = _.get(recordData, 'tsCode') ? _.get(recordData, 'tsCode') : _.get(recordData, 'stockCode', '');
  const rate_obj = calRate();
  return (
    <>
      <Col {...autoCol([8])} style={{ paddingTop: isMobile ? 18 : 2, textAlign: isMobile ? 'center' : 'left' }}>
        <Space size="middle">
          <Text strong style={{ fontSize: 16 }}>{stock_code}</Text>
          <Text strong style={{ fontSize: 16 }}>{_.get(recordData, 'stockName')}</Text>
        </Space>
        <NewDivider />
        <Space size="large">
          <Col>昨收<span className="headCardTetx" style={{ marginLeft: 10 }}>{getListDataVal('preClose', '')}</span></Col>
          <Col><span style={{ marginRight: 10 }}>涨跌幅</span>{rate_obj.text}</Col>
        </Space>
        <NewDivider />
        {sellInfos.map((item, i) => (
          <div key={'sel' + i}>
            <Space size="large">
              <Text strong>{item.title}</Text>
              <Text style={{ color: 'green' }}>{containsETF === 1 ? renderFloat(item.value, 3) : renderFloat(item.value, 2)}</Text>
              <Text style={{ color: 'green' }}>{item.value || ''}</Text>
            </Space>
          </div>
        ))}
        <NewDivider />
        <div>
          当前报价<Text strong style={{ marginLeft: 10 }}>{renderFloat(getListDataVal('match'), 2)}</Text>
        </div>
        <NewDivider />
        {buyInfos.map((item, i) => (
          <div key={'buy' + i}>
            <Space size="large">
              <Text strong>{item.title}</Text>
              <Text style={{ color: 'red' }}>{containsETF === 1 ? renderFloat(item.value, 3) : renderFloat(item.value, 2)}</Text>
              <Text style={{ color: 'red' }}>{item.value || ''}</Text>
            </Space>
          </div>
        ))}
        <NewDivider />
        <Space size="large">
          <Col>涨停<Text strong style={{ marginLeft: 10, color: 'red' }}>{getListDataVal('highLimited')}</Text></Col>
          <Col>跌停<Text strong style={{ marginLeft: 10, color: 'green' }}>{getListDataVal('lowLimited')}</Text></Col>
        </Space>
      </Col>
      {isMobile && <NewDivider />}
      <Col {...autoCol([8])} style={{ paddingLeft: 12, marginTop: isMobile ? 15 : 0 }}>
        <Descriptions title={''} column={isMobile ? 2 : 1} size='large'>
          {info3List.map((item, index) => {
            let show = _.get(listData, item.key, '')
            if (item.key === 'match') {
              show = rate_obj.purTxt
            }
            return <Descriptions.Item key={item.key + index} label={item.label}>
              {show}
            </Descriptions.Item>
          })}
        </Descriptions>
      </Col>
    </>
  )
}

export default TradeInfo;