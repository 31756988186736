import React, { Component } from 'react';
import { message, Tabs } from 'antd';
import { observer, inject } from 'mobx-react';
import CommonHeader from '@/view/common/Components/CommonHeader';
import MainPage from './MainPage';
import CostPage from './CostPage';
import HoldPage from './HoldPage';
import _ from 'lodash';
import './fund.scss'

const { TabPane } = Tabs;
message.config({ maxCount: 2 });
@inject('store')
@observer
class FundAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabsVal: '1',
    }
    this.tradeTime = this.props.store.inTrading === 'trading' ? true : false;
  }

  onTabChange = (key) => {
    this.setState({ tabsVal: key })
  }

  render() {
    const { tabsVal } = this.state;
    return (
      <>
        <CommonHeader title={'主力分析'} />

        <div className='fundTabs'>
          <Tabs defaultActiveKey="1" onChange={this.onTabChange} type='card'>
            <TabPane tab="主力" key="1">
              <MainPage tabsVal={tabsVal} />
            </TabPane>
            <TabPane tab="成本" key="2">
              <CostPage trading={this.tradeTime} tabsVal={tabsVal} />
            </TabPane>
            <TabPane tab="持仓" key="3">
              <HoldPage />
            </TabPane>
          </Tabs>
        </div>
      </>
    )
  }
}


export default FundAnalysis;