import React, { useState } from 'react';
import axios from 'axios';
import { useMount } from 'ahooks';
import { Row, Col, Form, Space, Input, Button, Select, Modal, message, Tag, PageHeader } from 'antd';
import {
  getBrokerages, addProduct, initAccount, initProductOriginAssets, isRepeatSub, judgeOnfuturesTradeTime
} from '@/api/details';
import { PRODUCT_STRATEGY_TYPE } from '@/utils/utils';
import { isValidArray } from '@/utils/utils2';
import BindModal from './BindModal';
import ModalComp from './ModalComp';
import _ from 'lodash';

const { Option } = Select;
const productStrOptions = Object.keys(PRODUCT_STRATEGY_TYPE).map(keyname => <Option key={keyname} value={keyname}>{PRODUCT_STRATEGY_TYPE[keyname]}</Option>)

export default function AddProduct(props) {
  const [bindingType, setbindingType] = useState(1);
  const [brokerageType, setbrokerageType] = useState('1');
  const [vcount, setvCount] = useState(0);
  const [brokeragesList, setBrokeragesList] = useState([]);
  const [brokerages, setBrokerages] = useState([]);
  const [securitiesAccounts, setsecuritiesAccounts] = useState([]);
  const [phone, setphone] = useState(null);
  const [form] = Form.useForm();
  const [isModalVisible, setisModalVisible] = useState(false);
  const [inputVisible, setinputVisible] = useState(false);
  const [selectSec, setselectSec] = useState(false);
  const [addListData, setaddListData] = useState([]);
  const [centerTransfor, setcenterTransfor] = useState([]);
  const [result, setresult] = useState([]);
  const [distributeList, setdistributeList] = useState([]);
  const [InputValue, setInputValue] = useState('');
  const [secuAcId, setsecuAcId] = useState(null);
  const [subVisible, setsubVisible] = useState(false);

  useMount(() => {
    _get();
  })

  async function _get() {
    const data = await getBrokerages();
    if (data.code === '200') {
      setBrokeragesList(data.data);
    }
  }
  // console.log('securitiesAccounts', securitiesAccounts)
  // 提交添加产品选项
  async function onFinish(values) {
    setsubVisible(true);
    let formValue = _.cloneDeep(values);
    let params = { ...formValue, bindType: String(values.bindType) };
    if (bindingType === 1) {
      if (securitiesAccounts.length === 0) return message.error('请绑定证券账户');
      params.securitiesAccounts = securitiesAccounts;

      if (brokerageType === '2') {
        const isTradeTime = await judgeOnfuturesTradeTime();
        if (_.get(isTradeTime, 'code') === '200' && _.get(isTradeTime, 'data', null)) {
          // 在交易时间内
        } else {
          return message.error('不在交易时间');
        }
      }

    } else if (bindingType === 2) {
      if (!phone) return message.error('请绑定证券账户手机号');
      params.phone = phone;
    }
    params.initAsset = [];
    const centralize = _.get(_.head(securitiesAccounts), 'centralizedLogin', '');
    Modal.confirm({
      title: '是否添加该产品',
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await addProduct(params, centralize === '2' ? 'FC' : 'NOR');
        if (data.code === '200') {
          // message.success('添加成功');
          if (bindingType === 1) {
            const data1 = await initAccount({ securitiesAccounts: params.securitiesAccounts }, centralize === '2' ? 'FC' : 'NOR');
            // console.log('初始化', data1);
            if (data1.code === '200' && bindingType === 1) {
              const data2 = await initProductOriginAssets({ productId: String(data.data.productId) }, centralize === '2' ? 'FC' : 'NOR');
              if (data2.code === '200') {
                message.success('添加成功');
                setresult(data2.data);
              } else {
                message.error(data2.message);
              }
            }
          }
          props.history.push({
            pathname: '/home/AssetsShow',
            state: { key: "1" }
          });

        } else {
          message.error(data.message);
        }
        setselectSec(true);
      }
    });
  }
  // 打开线上线下绑定弹窗
  async function _bindingType() {
    //console.log('获取表单数据', form.getFieldsValue())
    const newBrokerageType = _.get(form.getFieldsValue(), 'accountType');
    if (bindingType === 1) {
      if (securitiesAccounts.length === 3) {
        return message.error('线上绑定证券账户不能超过3个');
      }
    }

    if (brokeragesList.length !== 0) {
      if (newBrokerageType === '1') {
        let brr = [];
        brokeragesList.map(item => {
          if (item.brokerageType === 1) {
            brr.push(item);
          }
          return item;
        })
        setBrokerages(brr);
      }
      if (newBrokerageType === '2') {
        let brr = [];
        brokeragesList.map(item => {
          if (item.brokerageType === 2) {
            brr.push(item);
          }
          return item;
        })
        setBrokerages(brr);
      }
    }
    setvCount(vcount + 1);
    setbrokerageType(newBrokerageType);
  }

  function showStocks(value) {
    if (result.length !== 0) {
      result.map(item => {
        if (item.customerNumber === value.customerNumber) {
          setaddListData(item.stockAssetList);
          setsecuAcId(item.securitiesAccountId);
        }
        return item;
      })
    }
    setisModalVisible(true);
  }
  function _actFilterStocks(index) {
    const arr = JSON.parse(JSON.stringify(addListData));
    if (!arr[index].isAdd) {
      arr[index].isAdd = true;
    } else {
      arr[index].isAdd = !arr[index].isAdd;
    }
    setaddListData(arr);
  }
  function _holdStockPool() {
    // if(addListData.length === 0){
    //     message.info('所有资产都已分配至交易账户');
    //     props.history.push({
    //         pathname: '/home/fundManagerDesk',
    //         state: { key:"1" }
    //     });
    //     return;
    // }
    const arr = JSON.parse(JSON.stringify(addListData));
    let newArr = [];
    let brr = [];
    for (let item of arr) {
      if (!item.isAdd) {
        newArr.push(item);
      } else {
        brr.push(item);
      }
    }
    setcenterTransfor(newArr);
    setaddListData(newArr);
    if (brr.length === 0) {
      return message.error('请选择要添加的股票');
    } else {
      setdistributeList(brr);
      setinputVisible(true);
    }
  }
  // 切换线上线下绑定 清空数据
  function _bindingTypeChange(e) {
    setbindingType(e);
    setsecuritiesAccounts([]);
    setphone(null);
  }
  // 删除当前指定线上绑定
  function _securitiesAccountsDel(e, index) {
    e.preventDefault();
    let arr = JSON.parse(JSON.stringify(securitiesAccounts));
    arr.splice(index, 1);
    setsecuritiesAccounts(arr);
  }
  async function handleOk() {
    //校验输入的子账户名字是否重复
    const data1 = await isRepeatSub({ id: secuAcId, name: InputValue });
    if (data1.code === '200') {
      //证券账户分配子账户
      let arr = [];
      let object = {};
      object.subAccountName = InputValue;
      object.securitiesAccountId = secuAcId;
      object.stockAssetList = distributeList;
      arr.push(object);
      // const data2 = await distributeSubAccount(JSON.stringify(arr));
      axios({
        method: 'POST',
        url: global.api + '/product/distributeSubAccount',
        data: JSON.stringify(arr),
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((res) => {
        if (res.code === '200') {
          message.success(res.message);
          setinputVisible(false);
          setaddListData(centerTransfor);
          if (result.length !== 1) {
            setisModalVisible(false);
            return;
          }
          if (addListData.length === 0) {
            message.info('所有资产都已分配至交易账户');
            console.log('原跳转期货账户，需重新设计该逻辑')
            // props.history.push({
            //     pathname: '/home/fundManagerDesk',
            //     state: { key: "1" }
            // });
            return;
          }
        } else {
          message.error(res.message);
          return;
        }
      })
    } else {
      message.error(data1.message);
      return;
    }
  }

  return (
    <>
      <PageHeader
        title={'添加产品'}
        onBack={() => {
          props.history.goBack();
        }}
        style={{ backgroundColor: 'white', marginBottom: 10 }}
      ></PageHeader>

      <div style={{ backgroundColor: '#fff', height: '82vh', minHeight: 705 }}>
        <Row style={{ paddingTop: 168 }}>
          <Col span={6}></Col>
          <Col span={12}>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{ bindType: bindingType }}
            >
              <Form.Item
                label="产品登记编号"
                name='registraionNumber'
                rules={[{ required: true, message: '请输入产品登记编号', },]}
              >
                <Input placeholder="请输入产品登记编号" />
              </Form.Item>

              <Form.Item
                label="备案产品名称"
                name='name'
                rules={[{ required: true, message: '请输入备案产品名称', },]}
              >
                <Input placeholder="请输入备案产品名称" />
              </Form.Item>

              <Form.Item
                label="产品策略类型"
                name="strategyType"
                rules={[{ required: true, message: '请选择产品策略类型', },]}
              >
                <Select placeholder="请选择产品策略类型">
                  {productStrOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label="备案账户类型"
                name="accountType"
                rules={[{ required: true, message: '请选择备案账户类型', },]}
              >
                {/* <Select placeholder="" onChange={e=>{setbrokerageType(e)}}> */}
                <Select placeholder="">
                  <Option value='1'>证券账户</Option>
                  <Option value='2'>期货账户</Option>
                  <Option value='3'>大宗商品账户</Option>
                </Select>
              </Form.Item>

              <Row gutter={[8, 8]}>
                <Col span={20}>
                  <Space>
                    <Form.Item
                      label="绑定证券账户方式及类型"
                      name="bindType"
                      rules={[{ required: true, message: '请选择绑定证券账户及类型', },]}
                    >
                      <Select placeholder="" style={{ width: 120 }} onChange={_bindingTypeChange}>
                        <Option value={1} >线上绑定</Option>
                        <Option value={2} >线下绑定</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="accountType"
                      rules={[{ required: true, message: '请选择备案账户类型', },]}
                    >
                      <Select placeholder="请选择证券账户类型" style={{ width: 165 }} onChange={e => { setbrokerageType(e) }}>
                        <Option value='1'>股票账户</Option>
                        <Option value='2'>期货账户</Option>
                        <Option value='3'>大宗商品账户</Option>
                      </Select>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={4}>
                  <Button type="primary" style={{ marginLeft: 20 }} onClick={_bindingType}>
                    {bindingType === 1 ? isValidArray(securitiesAccounts) ? '添加' : '继续添加' : '绑定'}
                  </Button>
                </Col>
              </Row>

              <Row>
                {isValidArray(securitiesAccounts) &&
                  securitiesAccounts.map((item, index) => (
                    <Tag
                      style={{ margin: '10px 10px 30px 10px' }}
                      color="#2db7f5"
                      key={index}
                      closable
                      onClose={e => _securitiesAccountsDel(e, index)}
                    >
                      {item.customerNumber}
                    </Tag>
                  ))}
              </Row>

              <Form.Item>
                <Button size='large' disabled={subVisible} type="primary" htmlType="submit">提交</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={6}></Col>
        </Row>
        {/* 选绑定证券账户的方式 */}
        <BindModal
          bindType={bindingType}
          brokType={brokerageType}
          brokDatas={brokerages}
          secAccout={securitiesAccounts}
          showCount={vcount}
          onSecAccChange={(val) => setsecuritiesAccounts(val)}
          onPhoneChange={(v) => setphone(v)}
        />

        <ModalComp
          showPool={isModalVisible}
          showName={inputVisible}
          showAcc={selectSec}
          secAccout={securitiesAccounts}
          addLists={addListData}
          onFilterStock={(v) => _actFilterStocks(v)}
          onShowStock={(v) => showStocks(v)}
          onInputChange={(v) => setInputValue(v)}
          onHoldPool={_holdStockPool}
          onNameOk={handleOk}
        />
      </div>
    </>
  )
}