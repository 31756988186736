import React, { useState } from 'react';
import {
  Button,
  Table,
  Row,
  Col,
  Typography,
  Space,
  Spin,
  Select,
  AutoComplete,
  message,
} from 'antd';
import { useMount } from 'ahooks';
import { getAllStocks, getAvailableQuarter, getAllFund, showFundHd } from '@/api/stock';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { isEmptyStringV2, singleColumns } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;
let timer = null;

const HoldPage = ({ }) => {
  const [stockOption, setStockOption] = useState([]);
  const [fundOption, setFundOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tLoading, setTLoading] = useState(false);
  const [stValue, setStValue] = useState('');
  const [fdValue, setFdValue] = useState('');
  const [quarterList, setQuarterList] = useState([]);
  const [qValue, setQValue] = useState(null);
  const [holdInfo, setHoldInfo] = useState({});
  const [tableInfo, setTableInfo] = useState({});
  const [cur, setCur] = useState(1);
  const [searType, setSearType] = useState('');
  const [isFiltered, setIsFiltered] = useState('');

  useMount(() => {
    _getAvailableQuarter();
  });

  async function _getAllStocks(txt) {
    if (isEmptyStringV2(txt)) {
      return
    }

    try {
      setLoading(true);
      const data = await getAllStocks(txt);
      if (_.get(data, 'code', '') === '200') {
        const newStockData = _.get(data, 'data', []).map(n => { return { label: n.stockName, value: n.symbol, ...n } });
        setStockOption(newStockData);
        setLoading(false);
      } else {
        message.error('获取出错！')
        setLoading(false);
      }
    } catch (error) {
      message.error('获取出错！')
    }
  }

  async function _getAllFund(txt) {
    if (isEmptyStringV2(txt)) {
      return
    }

    setLoading(true);
    const data = await getAllFund(txt);
    if (_.get(data, 'code', '') === '200') {
      const newStockData = _.get(data, 'data', []).map(n => { return { label: n.fundName, value: n.code } });
      setFundOption(newStockData);
      setLoading(false);
    } else {
      message.error('获取出错！')
      setLoading(false);
    }
  }

  async function _getAvailableQuarter() {
    const data = await getAvailableQuarter();
    if (_.get(data, 'code', '') === '200') {
      setQuarterList(_.get(data, 'data', []))
    }
  }

  async function _showFundHd(page, type) {
    let params = {
      quarterDate: qValue || '',
      pageNum: page ?? cur,
      pageSize: 20,
      orderType: type ?? searType
    }
    if (_.get(holdInfo, 'fund', null)) {
      params.code = _.get(holdInfo, 'fund.value');
    }
    if (_.get(holdInfo, 'stock', null)) {
      params.symbol = _.get(holdInfo, 'stock.value');
    }
    setTLoading(true);
    const data = await showFundHd(params);
    if (_.get(data, 'code', '') === '200') {
      let newInfos = _.get(data, 'data', {});
      let lists = [];
      if (_.size(_.get(newInfos, 'pageInfo.list', [])) > 0) {
        lists = _.get(newInfos, 'pageInfo.list', []).map((n, i) => _.assign(n, { 'index': i }))
      }
      _.set(newInfos, 'pageInfo.list', lists);
      setTableInfo(newInfos);
    }
    setTLoading(false);
  }

  const onFundSelect = (data) => {
    let fIndex = _.findIndex(fundOption, o => o.value === data);
    if (fIndex !== -1) {
      setHoldInfo({ ...holdInfo, fund: fundOption[fIndex] });
      setFdValue(fundOption[fIndex].label);
    }
  };
  const onStockSelect = (data) => {
    let fIndex = _.findIndex(stockOption, o => o.value === data);
    if (fIndex !== -1) {
      setHoldInfo({ ...holdInfo, stock: stockOption[fIndex] });
      setStValue(stockOption[fIndex].label);
    }
  };

  const onSearch = (type, searchText) => {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      if (type === 'stock') {
        _getAllStocks(searchText);
      } else {
        _getAllFund(searchText);
      }
    }, 1000);
  };

  function onFundSearchChange(searchText) {
    setFdValue(searchText);
    onSearch('fund', searchText);
    if (searchText === '') {
      setHoldInfo({ ...holdInfo, fund: null });
    }
  }

  function onStockSearchChange(searchText) {
    setStValue(searchText);
    onSearch('stock', searchText);
    if (searchText === '') {
      setHoldInfo({ ...holdInfo, stock: null });
    }
  }

  function _change(e) {
    setQValue(e);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    _showFundHd(1, dataIndex);
    setIsFiltered(dataIndex);
    setSearType(dataIndex)
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          点击排序
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined
        style={{
          color: dataIndex === isFiltered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  const columns = [
    singleColumns(['股票名称', 'stockName']),
    singleColumns(['股票代码', 'symbol']),
    singleColumns(['基金名称', 'fundName']),
    singleColumns(['基金代码', 'code']),
    {
      ...singleColumns(['数量（单位：万手）', 'hdNum']),
      ...getColumnSearchProps('hdNum')
    },
    {
      ...singleColumns(['占净值比', 'rate']),
      ...getColumnSearchProps('rate')
    },
    {
      ...singleColumns(['持仓市值（万元人民币）', 'hdMarket']),
      ...getColumnSearchProps('hdMarket')
    },
  ];

  const fundAuto = (width) => <AutoComplete
    options={fundOption}
    style={{ width: width }}
    onSearch={onFundSearchChange}
    onSelect={onFundSelect}
    placeholder={`输入基金代码或名称`}
    value={fdValue}
  />
  const stockAuto = (width) => <AutoComplete
    options={stockOption}
    style={{ width: width }}
    onSearch={onStockSearchChange}
    onSelect={onStockSelect}
    placeholder={`输入股票代码或名称`}
    value={stValue}
  />

  return (
    <>
      <Row style={{ marginBottom: 12, marginTop: 12 }}>
        <Col span={6}>
          <Select onChange={_change} value={qValue} style={{ width: 200 }} placeholder='请选择季度日期'>
            {
              quarterList.length !== 0 && quarterList.map((item, index) => {
                return (
                  <Option
                    key={index}
                    value={item}
                  >
                    {item}
                  </Option>
                )
              })
            }
          </Select>
        </Col>
        <Col span={12}>
          <Space>
            {fundAuto(180)}
            {stockAuto(180)}

            <Button type='text' danger disabled={stValue === '' && fdValue === ''} onClick={() => {
              setHoldInfo({ fund: null, stock: null });
              setStValue('');
              setFdValue('');
              setQValue(null)
            }}>
              清空
            </Button>

            <Spin spinning={loading} size='small' />
          </Space>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button
            type='primary'
            icon={<SearchOutlined />}
            onClick={() => _showFundHd(1)}
          >
            搜索
          </Button>
        </Col>
      </Row>
      <Table
        rowKey={'index'}
        loading={tLoading}
        size='small'
        dataSource={_.get(tableInfo, 'pageInfo.list', [])}
        footer={() => <div>
          <Text>{'总持股数量（单位：万手）:  '}</Text>
          <Text style={{ color: 'red' }} strong>{_.get(tableInfo, 'totalHdNum', '')}</Text>
        </div>}
        columns={columns}
        pagination={{
          current: _.get(tableInfo, 'pageInfo.pageNum', 0),
          pageSize: 20,
          total: _.get(tableInfo, 'pageInfo.total', 0),
          onChange: (page, pageSize) => {
            setCur(page);
            _showFundHd(page);
          },
          showSizeChanger: false
        }}
      />
    </>
  )
}

export default HoldPage;