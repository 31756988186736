import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { showContribution } from '@/api/workbench';
import { useMount } from 'ahooks';
import * as echarts from 'echarts';
import { colorArray } from '@/view/common/colorArray';
import { NewRangePicker } from '@/view/common/widgets';
import _ from 'lodash';

export default function SubContributionCharts(props) {
  const [profitDatas, setProfitDatas] = useState({ timeList: [], datas: [], nameObj: {} });
  const [selectTimes, setSelectTimes] = useState([]);
  const [upcount, setUpcount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [option, setoption] = useState({
    xAxis: {
      type: 'category',
      name: '时间',
      data: [],
      splitLine: {
        show: false
      },
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
        onZero: false,
      },
    },
    yAxis: [
      {
        type: 'value',
        name: '贡献度(%)',
        position: 'left',
        axisLine: {
          show: true,
          symbol: ['none', 'arrow'],
          symbolSize: [8, 8],
          symbolOffset: 7,
        },
        splitArea: {
          show: true
        },
        splitLine: {
          show: false
        },
      }
    ],
    series: [],
    tooltip: {
      trigger: 'axis',
      showDelay: 0,
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      },
      padding: 5,
    },
    legend: {
      type: 'scroll',
      selectedMode: 'multiple',
    },
  });

  useMount(() => {
    const init_time = _.get(props, 'glbTimeArray', []);
    _showContribution(init_time);
  });

  async function _showContribution(dates) {
    const getId = _.get(props, 'id', 0);
    const is_reverse = _.get(props, 'isReverse', false);
    if (getId) {
      setLoading(true)

      let params = {
        productId: getId,
        beginDate: dates[0],
        endDate: dates[1],
      }
      const data = await showContribution(params, is_reverse ? 'FC' : 'NOR');
      //  console.log('盈亏', data)
      if (_.get(data, 'code', '') === '200') {
        setProfitDatas({
          timeList: _.get(data, 'data.timeList', []),
          datas: _.get(data, 'data.subAccountHistoryList', []),
          nameObj: _.get(data, 'data.subAccountIdNameMap', {})
        });
        setUpcount(upcount + 1);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    //console.log('个股盈亏chart:', props)
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('profit_product'));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });
    /**
     * 选取当天复选账户内，绝对值波动最大的数值（data数组内的数据），选取所有复选账户内波动最大前20个复选账户
     */
    let newOption = _.cloneDeep(option);
    newOption.xAxis.data = _.get(profitDatas, 'timeList', []);
    newOption.series = handleSeriesData();
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

  }, [upcount]);

  function handleSeriesData() {
    const result = _.get(profitDatas, 'datas', []);
    const dataKeys = _.head(result) ? Object.keys(_.head(result)) : [];
    let series = [];
    if (_.size(dataKeys) > 0) {
      dataKeys.map(keyname => {
        let sobj = {};
        sobj.name = keyname;
        sobj.type = 'line';
        sobj.data = result.map(r => r[keyname] || 0);
        sobj.symbol = 'none';
        series.push(sobj);
      });
    }
    let colorCount = 0;
    let finalData = _.isArray(series) && _.size(series) > 0 ? series.map((child, index) => {
      let ceilData = child.data.map(n => _.round(n, 5));
      if (index > 19) {
        colorCount = colorCount + 1
      }
      if (colorCount === 19) {
        colorCount = 0;
      }
      return {
        ...child,
        name: _.get(profitDatas, `nameObj.${child.name}`) ?? child.name,
        itemStyle: {
          normal: {
            color: index > 19 ? colorArray[colorCount] : colorArray[index],
            lineStyle: {
              color: index > 19 ? colorArray[colorCount] : colorArray[index]
            }
          }
        },
        data: ceilData
      }
    }) : []
    return finalData;
  }

  useEffect(() => {
    setSelectTimes(props.time2);
  }, [props.upTimeCount]);

  return (
    <Row style={{ backgroundColor: '#fff' }}>
      <Col span={24}>
        <Spin spinning={loading}>
          <Card bordered={false} bodyStyle={{ padding: 3 }} size='small' extra={
            <Row justify='end'>
              <span style={{ marginRight: 10, marginTop: 5 }}>选择日期:</span>
              <NewRangePicker dates={selectTimes} onSelect={(dateStrings) => {
                setSelectTimes(dateStrings);
                _showContribution(dateStrings)
                props.onTimeChange(dateStrings);
              }} />
            </Row>
          }>
            <div
              id="profit_product"
              style={{
                width: '100%',
                height: 450
              }}
            />
          </Card>
        </Spin>
      </Col>
    </Row>
  )
}