import { COMMON_CHART } from '@/view/common/Components/chartsInfoUtils';
import { isValidArray, isValidString, isValidNumber, isValidObj, labelValues } from '@/utils/utils2';
import { PLATE_CODE } from '@/utils/indexCode';
import moment from 'moment';
import _ from 'lodash';

const upColor = '#ec0000';
const upBorderColor = '#ec0000';
const downColor = '#00da3c';
const downBorderColor = '#00da3c';
const COMPARE_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    formatter: function (params) {
      //console.log(params)
      let maArray = [];
      let maString = '';
      let k_data = _.get(params, '[0]', {});
      if (_.size(params) > 1) {
        maArray = _.drop(params);
        maArray.map(n => {
          maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
        })
      }

      return '开盘: ' + (k_data.value[1] || '') + '<br>' +
        '收盘: ' + (k_data.value[2] || '') + '<br>' +
        '最高: ' + (k_data.value[3] || '') + '<br>' +
        '最低: ' + (k_data.value[4] || '') + '<br>' + maString
    }
  },
  legend: {
    // data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30']
    data: ['分钟K', '超额收益率']
  },
  grid: {
    left: '10%',
    right: '10%',
    bottom: '15%'
  },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: [{
    name: '股价',
    scale: true,
    splitArea: {
      show: true
    }
  }, {
    name: '超额收益率(%)',
    axisLine: {
      show: true,
      symbol: ['none', 'arrow'],
      symbolSize: [8, 8],
      symbolOffset: 7,
    },
    splitArea: {
      show: true
    },
    splitLine: {
      show: false
    }
  }],
  dataZoom: [
    { type: 'inside', start: 0, end: 100 },
    { show: true, type: 'slider', top: '90%', start: 0, end: 100, height: 12 }
  ],
  series: [
    {
      name: '分钟K',
      type: 'candlestick',
      data: [],
      yAxisIndex: 0,
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      }
    },
    {
      name: '超额收益率',
      type: 'line',
      yAxisIndex: 1,
      data: [],
      smooth: true,
      connectNulls: true,
      symbol: 'none',
    },
  ]
}

const COUNTING_OPTIONS = {
  xAxis: {
    type: 'category',
    data: [],
    splitLine: {
      show: false
    },
    boundaryGap: false,
    axisTick: {
      show: false
    },
  },
  yAxis: [
    {
      type: 'value',
      name: '交易次数/次',
      position: 'left',
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
      },
      splitArea: {
        show: true
      },
      splitLine: {
        show: false
      },
      minInterval: 1,
    },
    {
      type: 'value',
      name: '股票数量/个',
      position: 'right',
      axisLine: {
        show: true,
        symbol: ['none', 'arrow'],
        symbolSize: [8, 8],
        symbolOffset: 7,
      },
      splitArea: {
        show: true
      },
      splitLine: {
        show: false
      },
      minInterval: 1,
    }
  ],
  series: [
    {
      name: '交易次数',
      data: [],
      type: 'line',
      smooth: true,
      symbol: 'none',
      yAxisIndex: 0,
    },
    {
      name: '股票数量',
      data: [],
      type: 'line',
      smooth: true,
      symbol: 'none',
      yAxisIndex: 1,
    },
  ],
  tooltip: {
    trigger: 'axis',
    showDelay: 0,
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
  legend: {
    type: 'scroll',
    data: ['交易次数', '股票数量'],
    selectedMode: 'multiple',
  },
}

const CONTRIBUTION_OPTIONS = {
  grid: { left: '8%', right: '6%', bottom: '10%', top: '10%' },
  xAxis: {
    type: 'category',
    name: '时间',
    ...COMMON_CHART.rateX
  },
  yAxis: [
    COMMON_CHART.rateY,
  ],
  series: [],
  tooltip: {
    trigger: 'axis',
    showDelay: 0,
    axisPointer: {
      type: 'cross',
      lineStyle: {
        type: 'dashed',
        width: 1
      }
    },
    padding: 5,
  },
  legend: { selectedMode: 'multiple' },
};
// 处理持仓数据，增加自定义字段
function handlePositions(datas = [], speeds = {}, type = '') {
  const MS_FORMAT = type === 'hosting' ? 'MM-DD HH:mm:ss:SSS' : 'YY-MM-DD HH:mm:ss:SSS';
  const DAY_FORMAT = type === 'hosting' ? 'MM-DD' : 'YYYY-MM-DD';
  const TODAY = moment().format(DAY_FORMAT);
  const openMoment = moment(TODAY + ' 09:00:00');
  // 全局板块涨速； 乘100后，持仓直接根据code使用
  let newSpeed = {};
  if (isValidObj(speeds)) {
    _.keys(speeds).map(code => {
      const spVal = parseFloat(speeds[code]) ?? 0;
      newSpeed[code] = isValidNumber(spVal) ? _.round(spVal * 100, 2) : 0;
    });
  };
  // 持仓票，板块排序Key，时间格式；返回新对象
  let newData = []; let plateMap = new Map(); let count = 1;
  let plateSingleMap = new Map();
  let marketMap = new Map(); // 板块累计持仓比
  newData = isValidArray(datas) ? datas.map(n => {
    const getPlate = _.get(n, 'plate', null);
    const plateArr = _.split(getPlate, ','); // 板块拆分为数组
    const one_plate = _.head(plateArr);
    const getMkRate = _.round(_.get(n, 'totalMarketRate'), 2);
    const one_plate_code = _.get(PLATE_CODE, one_plate);
    let newPlateKey = 0;
    if (one_plate && !plateMap.has(one_plate)) {
      plateMap.set(one_plate, count);
      newPlateKey = count;
      marketMap.set(one_plate_code, getMkRate);
      count++;
    } else if (one_plate && plateMap.has(one_plate)) {
      newPlateKey = plateMap.get(one_plate);
      marketMap.set(one_plate_code, _.round(marketMap.get(one_plate_code) + getMkRate, 4));
    };
    // 多个板块逗号拆分，map内不重复的所有板块
    if (isValidArray(plateArr)) {
      plateArr.map(a => {
        if (!plateSingleMap.has(a)) {
          plateSingleMap.set(a, count);
        }
      })
    }
    const buyDate_moment = moment(_.get(n, 'buyDate', ''));
    return {
      ...n,
      'plateKey': newPlateKey,
      'plate_arr': plateArr,
      'one_plate': _.size(plateArr) > 1 ? one_plate + '*' : one_plate,
      'one_plate_code': one_plate_code,
      'plateSpeed': _.get(newSpeed, one_plate_code, 0),
      'formatDate': buyDate_moment.format(MS_FORMAT),
      'totalRevenue': _.round(_.get(n, 'totalRevenue', 0) * 100, 2),
      'formatDate_day': buyDate_moment.format(DAY_FORMAT), // 区分天
      'orderKey': openMoment.diff(buyDate_moment, 'millisecond'), // 排序用
      'speedIncrease': _.get(n, 'speedIncrease', 0) ?? 0,
    }
  }) : [];
  // console.log(marketMap);
  let optionsPlate = []; // 将map转化成options的数组
  Array.from(plateSingleMap)?.map(arr => {
    const plItem = _.head(arr);
    if (isValidString(plItem)) {
      optionsPlate.push(labelValues([plItem, plItem]));
    }
  });
  return { newData, optionsPlate, marketMap };
}

export {
  COMPARE_OPTION,
  COUNTING_OPTIONS,
  CONTRIBUTION_OPTIONS,
  // 方法
  handlePositions,
}