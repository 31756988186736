import React, { useState } from 'react';
import { Button, message, Modal, Row, Table } from 'antd';
import { MinusOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { delStockPoolStocks } from '@/api/stock';

export default function StockPoolList(props) {
    const [visible, setvisible] = useState(false);

    const columns = [
        {
            title: '股票名称',
            dataIndex: 'stockName',
        },
        {
            title: '股票代码',
            dataIndex: 'stockCode',
        },
        {
            title: '年化波动率',
            dataIndex: 'annualVolatility',
        },
        {
            title: '近一周换手率',
            dataIndex: 'turnoverRateWeek',
        },
        {
            title: '近一周日均成交量',
            dataIndex: 'turnoverDailyWeek',
        },
        {
            title: '近一周涨跌幅',
            dataIndex: 'priceLimitWeek',
        },
        {
            title: '操作',
            render: (text, record, index) => (
                <>
                    <Button
                        type="primary"
                        icon={<DeleteOutlined />}
                        shape="round"
                        danger
                        onClick={() => _delfilterStocks(record)}
                    />
                </>
            )
        },
    ]
    function _open() {
        if (!props.stockPoolInfos) {
            return message.error('请选择股票池');
        }
        setvisible(true);
    }
    async function _delfilterStocks(record) {
        let arr = [];
        arr.push(record.stockCode);
        const data = await delStockPoolStocks({ stockPoolId: record.stockPoolId, stockCodes: arr });
        if(data.code === '200'){
            message.success('删除成功');
            // props._goBack();
            props._update();
        }else{
            message.error(data.message);
        }

    }

    async function _delStockPool() {
        Modal.confirm({
            title: '是否清空该股票池！',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                let arr = [];
                let brr = props.stockPoolInfos.stockPoolStocks;
                if(brr !== null){
                    brr.map(item => {
                        arr.push(item.stockCode);
                        return item;
                    })
                }
                const data = await delStockPoolStocks({ stockPoolId: props.stockPoolInfos.stockPoolId,stockCodes: arr });
                if (data.code === '200') {
                    message.success('删除成功');
                    setvisible(false);
                    // props._goBack();
                    props._update();
                } else {
                    message.error(data.message);
                }
            }
        });
    }

    return (
        <>
            <Button
                size="large"
                type="primary"
                className="span"
                onClick={_open}
                disabled={props.ban}
            >
                查看股票池
            </Button>

            <Modal
                title="股票池列表详情"
                width={1000}
                visible={visible}
                onCancel={() => setvisible(false)}
                footer={null}
            >
                <div>
                    <Row align="middle">
                        <h2>{props.stockPoolInfos && props.stockPoolInfos.stockPoolName}</h2>

                        {/* <Button
                            size="large"
                            type="primary"
                            shape="circle"
                            icon={<PlusOutlined />}
                            style={{ margin: '0 30px 0 100px' }}
                        /> */}
                        <Button
                            size="large"
                            type="primary"
                            shape="circle"
                            icon={<MinusOutlined />}
                            style={{ margin: '0 20px 0 50px' }}
                            onClick={_delStockPool}
                        />
                    </Row>

                </div>
                <Table
                    rowKey="id"
                    style={{ marginTop: 20 }}
                    dataSource={props.stockPoolInfos && props.stockPoolInfos.stockPoolStocks}
                    columns={columns}
                    bordered
                    pagination={{
                        defaultPageSize: 30,
                    }}
                />
            </Modal>
        </>
    )
}