import React, { useState } from 'react';
import { PageHeader, Row, Col, Card, Tabs, Button, message, Segmented } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useReactive, useMount } from 'ahooks';
import {
  selectByCategory, addDictionary,
  showIndexConfig, addIndexConfig, updateIndexConfig, deleteIndexConfig
} from '@/api/details';
import { CUSTOMIDX_STRING, labelValues } from '@/utils/utils2';
import ConfigTable from './Components/ConfigTable';
import AddForm from './Components/AddForm';
// import moment from 'moment';
import _ from 'lodash';

const { TabPane } = Tabs;
const SEG_OPTIONS = [
  labelValues(['类型', 'contrastType']),
  labelValues(['原因', 'contrastReason']),
  labelValues(['账户类型', 'accountType'])
];
const NAME_OBJ = {
  'compare': '类型', 'idx': '指数',
};
/* 
  全局配置项；适用于简单配置表单及表格，做简单增删改查任务，可以数组渲染生成；
*/
const ConfigPage = (props) => {
  const mobxStore = useLocalObservable(() => store);
  const configState = useReactive({
    tabActive: 'compare', catVal: 'contrastType', formOpen: false, catName: '类型',
    loading: false,
  });
  const [categorys, setCategorys] = useState([]);
  const [idxConfig, setIdxConfig] = useState([]);
  const [formEdit, setFormEdit] = useState({});

  useMount(() => {
    _getCategory();
  });

  // 获取对比分类原因数据
  async function _getCategory() {
    configState.loading = true;
    const res = await selectByCategory('contrastReason');
    const res2 = await selectByCategory('contrastType');
    const res3 = await selectByCategory('accountType');
    if (_.get(res, 'code', '') === '200' && _.get(res2, 'code', '') === '200') {
      const getData = _.concat(_.get(res, 'data'), _.get(res2, 'data'), _.get(res3, 'data'));
      const newData = getData.map(n => {
        return { ...n, 'newId': n.id + n.category }
      })
      setCategorys(newData);
    }
    configState.loading = false;
  };
  // 更新或新建
  async function _add(values) {
    let params = {
      'category': configState.catVal,
      'name': values.name,
      'categoryName': _.chain(categorys).filter(o => o.category === configState.catVal).head().get('categoryName').value(),
    }
    const res = await addDictionary(params);
    if (_.get(res, 'code', '') === '200') {
      _getCategory();
      message.success(_.get(res, 'message', '操作成功！'));
      configState.formOpen = false
    } else {
      message.info(_.get(res, 'message', '操作失败！'));
    }
  };
  // 获取指数配置
  async function _showIndexConfig() {
    configState.loading = true;
    const res = await showIndexConfig();
    if (_.get(res, 'code', '') === '200') {
      const getData = _.get(res, 'data', []);
      const newData = getData.map(n => _.assign(n, { 'newId': n.id }));
      const newData2 = getData.map(n => {
        return { 'label': n.name, 'value': CUSTOMIDX_STRING + n.id, 'index': n.index };
      });
      setIdxConfig(newData);
      mobxStore.changeIndexConfig(newData2);
    }
    configState.loading = false;
  };
  // 更新或新建
  async function _newUpdateIndexConfig(type, value) {
    let res = {};
    if (type === 'new') {
      res = await addIndexConfig(value);
    } else if (type === 'edit') {
      res = await updateIndexConfig(value);
    } else if (type === 'del') {
      res = await deleteIndexConfig({ id: value });
    }
    if (_.get(res, 'code', '') === '200') {
      _showIndexConfig();
      message.success(_.get(res, 'message', '操作成功！'));
      configState.formOpen = false;
      setFormEdit({});
    } else {
      message.info(_.get(res, 'message', '操作失败！'));
    }
  };

  const newCategory = _.filter(categorys, o => o.category === configState.catVal);
  return <>
    <PageHeader
      title={'全局配置'} style={{ marginBottom: 10, backgroundColor: 'white' }}
      onBack={() => props.history?.goBack()}
    ></PageHeader>

    <Card bodyStyle={{ padding: 8 }}>
      <Tabs activeKey={configState.tabActive} onChange={(v) => {
        configState.tabActive = v;
        configState.catName = NAME_OBJ[v];
        if (v === 'idx') {
          _showIndexConfig();
        }
      }}
        tabBarExtraContent={{
          'right': <Button icon={<PlusOutlined />} onClick={() => {
            configState.formOpen = true;
          }}>添加</Button>
        }}
      >
        <TabPane tab={'实测类别'} key={'compare'}>
          <Row style={{ marginBottom: 12 }}>
            <Col span={12}>
              <Segmented
                options={SEG_OPTIONS}
                value={configState.catVal}
                onChange={(v) => {
                  configState.catVal = v;
                  configState.catName = _.chain(SEG_OPTIONS).filter(o => o.value === v).head().get('label').value();
                }}
              />
            </Col>
          </Row>
          <ConfigTable pageKey={'compare'} datas={newCategory} loading={configState.loading} />
        </TabPane>
        <TabPane tab={'指数'} key={'idx'}>
          <ConfigTable
            pageKey={'idx'}
            datas={idxConfig}
            loading={configState.loading}
            onEdit={record => {
              setFormEdit(record);
              configState.formOpen = true;
            }}
            onDelete={id => _newUpdateIndexConfig('del', id)}
          />
        </TabPane>
      </Tabs>
    </Card>

    <AddForm
      pageKey={configState.tabActive}
      name={configState.catName}
      editVal={formEdit}
      visible={configState.formOpen}
      oncancel={() => {
        configState.formOpen = false;
        setFormEdit({});
      }}
      onSubmit={(values, ftype) => {
        if (configState.tabActive === 'compare') {
          _add(values);
        } else {
          _newUpdateIndexConfig(ftype, values);
        }
      }}
    />
  </>
}

export default ConfigPage;