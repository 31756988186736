import React, { useState } from 'react';
import { Input, Button, Modal, message, Table, Card } from 'antd';
import { singleColumns } from '@/utils/utils';
import { isValidArray } from '@/utils/utils2';
import _ from 'lodash';

const ModalComp = ({
  showPool,
  showName,
  showAcc,
  secAccout,
  addLists,
  onFilterStock,
  onShowStock,
  onInputChange,
  onHoldPool,
  onNameOk,
}) => {
  const [num, setnum] = useState(0);

  const addColumns = [
    singleColumns(['名称', 'stockName', null, 'center']),
    singleColumns(['代码', 'stockCode', null, 'center']),
    singleColumns(['数量', 'stockAmount', null, 'center']),
    singleColumns(['市值', 'stockCost', null, 'center']),
    {
      title: '操作',
      render: (text, record, index) => (
        <>
          {record.isAdd ?
            <Button type="primary" shape="round" danger onClick={() => onFilterStock(num + index)}>取消</Button>
            :
            <Button type="primary" shape="round" onClick={() => onFilterStock(num + index)}>添加</Button>}
        </>
      )
    },
  ];

  return <>
    <Modal
      title="股票池名称"
      visible={showPool}
      okText="提交"
      // onCancel={()=>{setisModalVisible(false)}} 
      cancelButtonProps={{ disabled: true }}
      onOk={onHoldPool}
    >
      <Table
        rowKey="id"
        style={{ marginTop: 10 }}
        dataSource={addLists}
        columns={addColumns}
        bordered
        pagination={{
          defaultPageSize: 10,
          onChange: (current, pageSize) => { setnum((current - 1) * pageSize) }
        }}
      />
    </Modal>
    <Modal
      title="子账户名称"
      visible={showName}
      // onCancel={()=>{setinputVisible(false)}} 
      onOk={onNameOk}
      cancelButtonProps={{ disabled: true }}
    >
      <Input placeholder="请输入要添加的子账户名称" onChange={e => onInputChange(e.target.value)} />
    </Modal>
    <Modal
      title="选择证券账户"
      visible={showAcc}
      // onCancel={()=>{setselectSec(false)}} 
      onOk={() => { message.info('请选择证券账户') }}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
    >
      {isValidArray(secAccout) && secAccout.map(item => {
        return (
          <Card
            bordered
            bodyStyle={{ padding: '10px' }}
            hoverable={true}
            onClick={() => onShowStock(item)}
          >{item.customerNumber}</Card>
        )
      })}
    </Modal>
  </>
}

export default ModalComp;