import React, { useEffect, useState } from 'react';
import {
  Table, Row, Col, Typography, Tag, Button, Descriptions, Space, Slider, message,
  DatePicker, TimePicker, Switch, Divider, Spin
} from 'antd';
import { CaretUpOutlined, ReloadOutlined, EnvironmentOutlined, LineChartOutlined } from '@ant-design/icons';
import { useReactive, useUpdateEffect } from 'ahooks';
import { singleColumns } from '@/utils/utils';
import { RateText } from '@/utils/utils2';
import { renderOrderStatus, renderDiv } from '@/view/common/Components/list_util';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

const TradeExTable = ({
  isEx = false,
  is_product = false,
  datas = { 'data': [], 'dataEx': [] },
  txtName = '',
  allText = '',
  actv = '', //上次props.active发送变化

  showAll,
  goDetail,
  showKline,
}) => {
  const pageInfo = useReactive({ page: 1, pageSize: 7 });

  useEffect(() => {
    pageInfo.page = 1;
  }, [actv]);

  const columns = [
    {
      ...singleColumns(['序号', 'index', 70]),
      render: (text, record, index) => (pageInfo.page - 1) * pageInfo.pageSize + index + 1
    },
    {
      ...singleColumns(['股票', 'stock']),
      render: (text, record) => <div>
        <Text>{`${_.get(record, 'stockCode')}-${_.get(record, 'stockName')}`}</Text>
        <LineChartOutlined style={{ fontSize: 12, color: '#003e85', marginLeft: 3, cursor: 'pointer' }} onClick={() => showKline(record)} />
        {!is_product && <EnvironmentOutlined style={{ fontSize: 12, color: '#ff4d4f', marginLeft: 3, cursor: 'pointer' }} onClick={() => goDetail(record)} />}
      </div>,
    },
    {
      ...singleColumns(['收益', 'profit']),
      render: (text, record) => <RateText rate={text} noPrefix={true} />
    },
    singleColumns(['范围', 'keyName']),
    {
      ...singleColumns(['收益率', 'orgRange']),
      sorter: (a, b) => a.orgRange - b.orgRange,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (text, record) => <RateText rate={text} />
    },
  ];
  const columns2 = [
    {
      ...singleColumns(['序号', 'index', 70]),
      render: (text, record, index) => (pageInfo.page - 1) * pageInfo.pageSize + index + 1
    },
    { ...singleColumns(['股票名称', 'stockName']) },
    singleColumns(['股票代码', 'stockCode']),
    {
      ...singleColumns(['涨跌幅', 'pctChange']),
      render: (text, record) => <RateText rate={text} />
    },
    singleColumns(['范围', 'keyName']),
    {
      ...singleColumns(['超额收益率', 'orgRange']),
      sorter: (a, b) => a.orgRange - b.orgRange,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (text, record) => <RateText rate={text} carry={4} />
    },
    singleColumns(['买卖数量(对)', 'tradeListCount']),
  ];

  const expandedRowRender = (fatRecord) => {
    const getDataSource = _.get(fatRecord, 'stockTradeList', []);
    const columns3 = [
      {
        ...singleColumns(['股票名称', 'stockName', 100]),
        render: (text, record) => <Button type='link' size='small' onClick={() => {
          let newSource = getDataSource.map(n => {
            return { ...n, timeKey: moment().diff(moment(n.commissionTime), 'm') }
          })
          goDetail(record, newSource);
        }}>
          {text}
        </Button>
      },
      {
        ...singleColumns(['委托时间', 'commissionTime', 120]),
        render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
      },
      singleColumns(['委托数量', 'quantity', 80], 'strongPrice'),
      singleColumns(['成交数量', 'cumQty', 80], 'strongPrice'),
      singleColumns(['委托均价', 'price', 80], 'strongPrice'),
      singleColumns(['成交均价', 'avgPrice', 80], 'strongPrice'),
      singleColumns(['收益(元)', 'profit', 100], 'updown'),
      {
        ...singleColumns(['买卖方向', 'side', 80]),
        render: (text) => {
          return text === 'BUY' ? <Tag color={'red'}>买入</Tag> : <Tag color={'green'}>卖出</Tag>
        }
      },
      {
        ...singleColumns(['状态', 'orderStatus', 80]),
        render: (text) => renderOrderStatus(text)
      },
      {
        ...singleColumns(['下单方式', 'tradeMode', 110]),
        render: (text, record, index) => {
          if (text === 1) {
            return '手动下单'
          } else if (text === 2) {
            return <Text>{'自动下单'}</Text>
          }
        }
      },
    ]

    return (
      <Table
        rowKey="clientOrderId"
        dataSource={getDataSource}
        columns={columns3}
        pagination={false}
        size='small'
      />
    )
  }

  return <div>
    <Table
      rowKey={isEx ? 'clientOrderId' : 'kIdx'}
      dataSource={isEx ? _.get(datas, 'data') : _.get(datas, 'dataEx')}
      columns={isEx ? columns : columns2}
      bordered
      size='small'
      title={() => <Row>
        <Col span={12}>
          <Text strong>{txtName}</Text>
        </Col>
        <Col span={txtName !== allText ? 12 : 0} style={{ textAlign: 'right' }}>
          <Button size='small' onClick={() => showAll()}>查看全部</Button>
        </Col>
      </Row>}
      pagination={{
        current: pageInfo.page,
        pageSize: pageInfo.pageSize,
        onChange: (page, pageSize) => {
          pageInfo.page = page;
          pageInfo.pageSize = pageSize;
        }
      }}
      expandable={{
        expandedRowRender: isEx ? null : expandedRowRender,
        expandRowByClick: true
      }}
    />
  </div>
}

//统计显示组件
const CalBars = ({ all = {}, calValue = {} }) => {
  const basicArray = [
    { title: '正平均', color: 'red', valKey: 'positive', sizeKey: 'positiveSize' },
    // { title: '正平均', color: '#fa7414', valKey: 'positiveMain.rate', sizeKey: 'positiveMain.size' },
    // { title: '正平均超额', color: '#fa7414', valKey: 'positiveEx.rate', sizeKey: 'positiveEx.size' },
    { title: '负平均', color: 'green', valKey: 'nagetive', sizeKey: 'nagetiveSize' },
    // { title: '总平均', color: 'black', valKey: 'total', sizeKey: 'totalSize' },
    { title: '总个数', key: 'all', sizeKey: 'totalSize' }
  ];
  return <Descriptions size='small' bordered column={3}>
    {basicArray.map((n, i) => {
      const crate = _.get(calValue, n.valKey, 0);
      const csize = _.get(calValue, n.sizeKey, 0);
      const all_size = _.get(all, n.sizeKey, 0);
      if (_.get(n, 'key', '') === 'all') {
        return <Descriptions.Item key={n.title + i} label={n.title}>
          <Text>{`${all_size ?? 0}支`}</Text>
        </Descriptions.Item>
      } else {
        return <Descriptions.Item key={n.title + i} label={n.title}>
          <Space size='small'>
            <Text strong style={{ color: n.color }}>{renderDiv(crate, csize, true)}</Text>
            <Text type='secondary'>{`(${csize ?? 0}支)`}</Text>
          </Space>
        </Descriptions.Item>
      }
    })}
  </Descriptions>
}

let SLIDER_MARKS = {}; // 创建slider的标识
[-40, -30, -20, -10, 0, 10, 20, 30, 40].map(n => _.set(SLIDER_MARKS, n, String(n) + '%'));
// 收益率区间组件
const RateSlider = ({ active = '', initSlider = [], onFinish, onReset }) => {
  const [sliderValue, setSliderValue] = useState(initSlider);

  useUpdateEffect(() => {
    setSliderValue(initSlider);
  }, [active]);

  const no_slider = sliderValue[0] === initSlider[0] && sliderValue[1] === initSlider[1] ? true : false;
  return <Row align='middle' justify='center'>
    <Col span={4} style={{ textAlign: 'center' }}><Text strong>范围：</Text></Col>
    <Col span={16}>
      <Slider min={-50} max={50}
        step={active === '2' ? 10 : 1} // 按照markpoint的拆分颗粒度来判断的
        range={{ draggableTrack: true, }}
        value={sliderValue}
        marks={SLIDER_MARKS}
        onChange={(v) => setSliderValue(v)}
        onAfterChange={(v) => {
          const min = v[0]; const max = v[1];

          if (min >= 0) {
            message.info('范围最小值不得大于0！');
            return;
          }
          if (max <= 0) {
            message.info('范围最大值不得下于0！');
            return;
          }
          onFinish(min, max, no_slider);
        }}
      />
    </Col>
    <Col span={4} style={{ textAlign: 'center' }}>
      <Space size='small'>
        <Text>{`${sliderValue[0]}% to ${sliderValue[1]}%`}</Text>
        <Button type='link' size='small'
          disabled={no_slider}
          onClick={() => {
            onReset();
            setSliderValue(initSlider);
          }}>重置</Button>
      </Space>
    </Col>
  </Row>
}

// 超额部分的操作bar
const picker_style = { width: 130 };
const ExBars = ({
  is_range = false,
  loading = false,
  loading2 = false,
  date = '',
  time = '',
  isInter = false,

  onDateChange,
  onTimeChange,
  onThisDay,
  onSync,
  onSwitchChange
}) => {
  return <Divider style={is_range ? { display: 'none' } : {}}>
    <Space>
      <div style={{ width: 35, height: 22 }}>
        <Spin spinning={loading} size='small' />
      </div>
      <DatePicker
        style={picker_style}
        size='small'
        value={moment(date)}
        onChange={(date, dateString) => onDateChange(dateString)}
      />
      {/* <Text>{exState.time}</Text> */}
      <TimePicker
        style={picker_style}
        size='small'
        value={moment(date + ' ' + time)}
        secondStep={60}
        onChange={(date, dateString) => onTimeChange(dateString)}
      />
      <Button icon={<CaretUpOutlined />} size='small' onClick={onThisDay}>
        当日
      </Button>
      <Button loading={loading2} icon={<ReloadOutlined />} size='small' onClick={onSync} />
      <Switch
        checkedChildren="自动"
        unCheckedChildren="手动"
        checked={isInter}
        onChange={onSwitchChange}
      />
      <div style={{ width: 35 }}> </div>
    </Space>
  </Divider>
}

export {
  TradeExTable,
  CalBars,
  RateSlider,
  ExBars
}