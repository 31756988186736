import React, { useState } from 'react';
import { Row, Col, Divider, Spin, message, Segmented } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useReactive, useMount, useUnmount, useUpdateEffect } from 'ahooks';
import { indexDailyKline, showProductKLine, showSubKLine } from '@/api/details';
import { createDatasTimeKey, isValidArray, labelValues } from '@/utils/utils2';
import { IndexSelect } from './Components/main_widget';
import { OPTIONS_KLINE_RATE } from './Components/chartsInfoUtils2';
import { calRate, calNets, isFullTimeRange, renderSlice } from './Components/chartsInfoUtils';
import { MainSlider } from './Components/main_widget';
import * as echarts from 'echarts';
import _ from 'lodash';

const KARR_KEY = ['open', 'close', 'high', 'low'];
const RANGE_OPTIONS = [labelValues(['日', 'DAY']), labelValues(['周', 'WEEK'])];
let timer = null;
// 创建最大最小初始值
const createHighLowObj = (prices) => {
  return { 'high': _.max(prices), 'low': _.min(prices), 'startPrice': _.head(prices) }
};
// 收益率K线图
const KRateCharts = ({
  pageKey = '',
  indexSymbol = '',
  productId = 0,
  subAccountId = 0,
  newProps = {},
  active = '',
  isReverse = false,
}) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [update, setUpdate] = useState(0);
  const [kvalue, setKvalue] = useState({});
  const [kindex, setKindex] = useState({});
  const [orgRes, setOrgRes] = useState({});
  const [update2, setUpdate2] = useState(0);
  const [update3, setUpdate3] = useState(0);
  const [sliderValue, setSliderValue] = useState([]);
  const [sliderValue2, setSliderValue2] = useState([]); // slider直接读取的value，中间处理set赋值后不做任何处理，避免处理数据是的差错而改变
  const [timeArr, setTimeArr] = useState([0]);
  const [timeNameArr, setTimeNameArr] = useState([]); // 完整时间数据

  const kState = useReactive({
    preClose: 0, kval: [], idxKval: [], idxPreClose: 0,
    rangeType: 'DAY', loading: false, symbol: indexSymbol
  });
  const [option, setoption] = useState({
    ...OPTIONS_KLINE_RATE,
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' },
      formatter: function (params) {
        let finalString = '';
        params.map(n => {
          const get_x = _.get(n, 'axisValue', '');
          const get_name = _.get(n, 'seriesName');
          const kvalArr = get_name === '指数K' ? Array.from(kState.idxKval) : Array.from(kState.kval);
          const pres = get_name === '指数K' ? kState.idxPreClose : kState.preClose;
          // 统一使用kstate里面的数据，指数可现实指数值
          let tidx = _.findIndex(kvalArr, o => o[0] === get_x);
          const open = _.get(kvalArr, `[${tidx}][1]`, ''); const close = _.get(kvalArr, `[${tidx}][2]`, '');
          const high = _.get(kvalArr, `[${tidx}][3]`, ''); const low = _.get(kvalArr, `[${tidx}][4]`, '');
          const amp = _.round(((high - low) / low) * 100, 2); // 计算振幅
          const pre = _.get(kvalArr, `[${tidx - 1}][2]`, 0); // 昨日收盘
          const amp2 = tidx > 0 ? _.round(((close - pre) / pre) * 100, 2) : _.round(((close - pres) / pres) * 100, 2);
          let baseString = get_name + ':' + '<br>' +
            '开盘: ' + open + '<br>' +
            '收盘: ' + close + '<br>' +
            '最高: ' + high + '<br>' +
            '最低: ' + low + '<br>';
          let ampString = '振幅: ' + `<span style='color:${amp > 0 ? 'red' : 'green'};'>${amp}%</span>` + '<br>' +
            '波幅: ' + `<span style='color:${amp2 > 0 ? 'red' : 'green'};'>${amp2}%</span>` + '<br>';

          finalString = finalString + baseString + ampString + '<br/>';
        })
        return finalString;
      }
    },
  });
  const isProduct = pageKey === 'product' ? true : false;

  useMount(() => {
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
        _getKline();
      }, 2000);
    } else {
      _getKline();
    }
  });

  useUnmount(() => {
    timer && clearTimeout(timer);
  });

  // 上层更新重载
  useUpdateEffect(() => {
    if (active === 'RATE_K') {
      _getKline();
    }
  }, [active]);

  // 获取收益、指数K线数据
  async function _getKline() {
    const newId = isProduct ? productId : subAccountId;
    if (newId) {
      kState.loading = true;
      let params = {
        [isProduct ? 'productId' : 'subAccountId']: newId
      };
      const res = isProduct ? await showProductKLine(params, isReverse ? 'FC' : 'NOR') : await showSubKLine(params, isReverse ? 'FC' : 'NOR');
      const resIdx = await indexDailyKline({ 'symbol': kState.symbol });
      if (_.get(res, 'code', '') === '200') {
        setOrgRes({ res, resIdx });
        kState.rangeType = 'DAY';
        setUpdate(_.round(update + 0.1, 1));
      } else {
        message.info(_.get(res, 'message', '获取失败！'));
      }
      kState.loading = false;
    }
  }
 // 生成k线数据
 function createKlineData(datas, rtype = '', typeKey = '', ftype = 'full') {
  let timeList = []; let newValueList = [];
  let priceList = []; let fullList = [];
  let orgPrice = []; let orgFull = [];
  let finals = {}; let recal = {};
  const isRange = rtype === 'WEEK' ? true : false;
  const isIndex = typeKey === 'index' ? true : false;
  const isSlice = ftype === 'slice' ? true : false;
  let newDatas = [];
  // 区分 周、日创建newData
  if (isRange) {
    newDatas = datas.map(itm => {
      return { 'date': itm.sdate, 'karr': KARR_KEY.map(k => _.round(_.get(itm, k) ?? 1, 4)) }
    });
  } else {
    newDatas = _.keys(datas).map(date => {
      return { 'date': date, 'karr': KARR_KEY.map(k => _.round(_.get(datas[date], k) ?? 1, 4)) }
    });
  }
  // 排序日期
  const orderDatas = createDatasTimeKey(newDatas, 'date', 'd', 'asc');
  // 指数和收益率都需要重新计算，指数重新恢复净值
  if (isIndex || isSlice) {
    // 【bug-fix】所有计算开始值指定与第一个点收盘价对比;
    const getStart = _.get(orderDatas, '[0].karr[1]', 0);
    KARR_KEY.map((k, i) => {
      const keyVal = orderDatas.map(n => _.get(n, `karr[${i}]`));
      const keyValRate = calRate(keyVal, getStart);
      _.set(recal, k, calNets(keyValRate));
    });
  }
  // 遍历order数据，生成k线所需数组
  orderDatas.map((n, i) => {
    const times = _.get(n, 'date');
    const getKarr = _.get(n, 'karr', []);
    let values = isIndex || isSlice ? [] : _.get(n, 'karr', []);
    timeList.push(times);
    // 指数计算，最大最小值重新计算，减少偏差
    if (isIndex || isSlice) {
      values[0] = _.get(recal, `open[${i}]`);
      values[1] = _.get(recal, `close[${i}]`);
      values[2] = _.max([values[0], values[1], _.get(recal, `high[${i}]`)]);
      values[3] = _.min([values[0], values[1], values[2], _.get(recal, `low[${i}]`)]);
    }
    newValueList.push(values);
    // 指数使用指数值作为tooltip，显示用计算过的净值
    priceList.push(values[1]);
    orgPrice.push(getKarr[1]);
    fullList.push(_.concat([times], values));
    orgFull.push(_.concat([times], getKarr));
  });
  // 图表信息参数
  finals = {
    'time': timeList,
    'kval': newValueList,
    'cal': createHighLowObj(priceList),
    'tooltip': createHighLowObj(orgPrice)
  }
  return {
    'infos': finals,
    'full': fullList,
    'idxFull': orgFull // chart tooltips显示使用
  }
}
// 加载
useUpdateEffect(() => {
  let myChart = newProps.myChart;
  if (myChart !== null && myChart !== "" && myChart !== undefined) {
    myChart.dispose();
  }
  myChart = echarts.init(document.getElementById('krate_charts'));
  myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

  const getRate = _.get(orgRes, 'res.data');
  const getIndex = _.get(orgRes, 'resIdx.data');
  let sliceIdx = {};
  _.keys(getRate).map(date => {
    if (date in getIndex) {
      _.set(sliceIdx, date, getIndex[date]);
    }
  })
  // 加载计算日和周数据
  const rateKline = createKlineData(getRate, 'DAY', 'rate');
  const indexKline = createKlineData(sliceIdx, 'DAY', 'index');
  let newOption = handleOptionState(rateKline, indexKline);

  const rateRange = createWeeks(_.get(rateKline, 'full', []));
  const indexRange = createWeeks(_.get(indexKline, 'idxFull', []));
  const rateKlineWeek = createKlineData(rateRange, 'WEEK', 'rate');
  const indexKlineWeek = createKlineData(indexRange, 'WEEK', 'index');

  setKvalue({ 'DAY': rateKline, 'WEEK': rateKlineWeek });
  setKindex({ 'DAY': indexKline, 'WEEK': indexKlineWeek });

  const getTime = _.get(rateKline, 'infos.time', []);
  const get_time_arr = getTime.map((n, i) => i)
  setTimeArr(get_time_arr);
  setTimeNameArr(getTime);
  // 赋值slider的滑动数据
  if (_.last(get_time_arr)) {
    setSliderValue([0, _.last(get_time_arr)]);
    setSliderValue2([0, _.last(get_time_arr)]);
  }
  setoption(newOption);
  myChart.setOption(newOption, true);
  myChart.hideLoading();
  myChart.resize();
}, [update]);
// 切换周，日
useUpdateEffect(() => {
  let myChart = newProps.myChart;
  myChart = echarts.init(document.getElementById('krate_charts'));
  // 切换直接使用kvalue数据并赋值slider
  let newOption = handleOptionState(kvalue[kState.rangeType], kindex[kState.rangeType]);
  const getTime = _.get(kvalue[kState.rangeType], 'infos.time', []);
  const get_time_arr = getTime.map((n, i) => i)
  setTimeArr(get_time_arr);
  setTimeNameArr(getTime);
  // 赋值slider的滑动数据
  if (_.last(get_time_arr)) {
    setSliderValue([0, _.last(get_time_arr)]);
    setSliderValue2([0, _.last(get_time_arr)]);
  }
  myChart.setOption(newOption, true);
  myChart.hideLoading();
  myChart.resize();
}, [update2]);
// slider切换数据
useUpdateEffect(() => {
  let myChart = newProps.myChart;
  myChart = echarts.init(document.getElementById('krate_charts'));

  const isFull = isFullTimeRange(sliderValue, timeArr);
  let newOption = [];
  if (isFull) {
    newOption = handleOptionState(kvalue[kState.rangeType], kindex[kState.rangeType]);
  } else {
    // 截取数据，根据截取日期，从日时间内找到区间内全部时间
    const sliceTimeName = renderSlice(timeNameArr, sliderValue[0], sliderValue[1]);
    let sliceDayTime = []; let isValid = false;
    _.get(kvalue, `DAY.infos.time`, []).map(date => {
      if (isValid) {
        sliceDayTime.push(date);
      }
      if (date === sliceTimeName[0]) {
        sliceDayTime.push(date);
        isValid = true;
      };
      if (date === _.last(sliceTimeName)) {
        sliceDayTime.push(date);
        isValid = false;
      }
    });
    // 使用原始返回数据截取，重新进行日、周的计算
    let newResRate = {}; let newResIdx = {};
    sliceDayTime.map(date => {
      _.set(newResRate, date, _.get(orgRes, 'res.data.' + date));
      _.set(newResIdx, date, _.get(orgRes, 'resIdx.data.' + date));
    });
    let sliceIdx = {};
    _.keys(newResRate).map(date => {
      if (date in newResIdx) {
        _.set(sliceIdx, date, newResIdx[date]);
      }
    });
    const rateKline = createKlineData(newResRate, 'DAY', 'rate', 'slice');
    const indexKline = createKlineData(sliceIdx, 'DAY', 'index', 'slice');
    const rateRange = createWeeks(_.get(rateKline, 'full', []));
    const indexRange = createWeeks(_.get(indexKline, 'idxFull', []));
    const rateKlineWeek = createKlineData(rateRange, 'WEEK', 'rate', 'slice');
    const indexKlineWeek = createKlineData(indexRange, 'WEEK', 'index', 'slice');
    // 根据日、周数据进行赋值
    const finalRate = kState.rangeType === 'DAY' ? rateKline : rateKlineWeek;
    const finalIndex = kState.rangeType === 'DAY' ? indexKline : indexKlineWeek;
    newOption = handleOptionState(finalRate, finalIndex);
  }
  myChart.setOption(newOption, true);
  myChart.hideLoading();
  myChart.resize();
}, [update3])
// 计算周k线，用交易日期的week字段判断是否为当周数据，
const createWeeks = (fullData = []) => {
  let newRange = [];
  let weekRange = [];
  let weekObj = { 'open': 0, 'close': 0, 'high': 0, 'low': 0, 'sdate': '' };
  let lastWeek = 0;
  fullData.map((kv, ki) => {
    let dindex = _.findIndex(tradeDates, o => o.date === kv[0]);
    const curWeek = _.get(tradeDates, `[${dindex}].week`, 0);
    const isLastOne = ki === (_.size(fullData) - 1);
    if (dindex !== -1) {
      if (lastWeek === 0) {
        weekObj.sdate = kv[0];
        weekObj.open = kv[1];
      }
      if ((lastWeek !== 0 && curWeek !== lastWeek) || isLastOne) {
        newRange.push(weekObj);
        weekObj = { 'open': kv[1], 'close': 0, 'high': 0, 'low': 0, 'sdate': kv[0] };
        weekRange = [];
      }
      weekObj.close = kv[2];
      weekRange = _.concat(weekRange, [kv[3], kv[4]]);
      weekObj.high = _.max(weekRange);
      weekObj.low = _.min(weekRange);
      lastWeek = curWeek;
    }
  });
  return newRange;
};
// 切换日、周，截取，都需要重新计算options，并且赋值state更新tooltip数据
function handleOptionState(rate, index) {
  let tempOption = _.cloneDeep(option);
  const getTime = _.get(rate, 'infos.time', []);
  tempOption.xAxis.data = getTime;
  tempOption.series[0].data = _.get(rate, 'infos.kval', []);
  tempOption.series[1].data = _.get(index, 'infos.kval', []);
  // 合并计算最大最小值
  const ymin = _.min([_.get(rate, 'infos.cal.low'), _.get(index, 'infos.cal.low')]);
  const ymax = _.max([_.get(rate, 'infos.cal.high'), _.get(index, 'infos.cal.high')]);
  tempOption.yAxis.min = _.round(ymin * 0.9, 2);
  tempOption.yAxis.max = _.round(ymax * 1.1, 2);
  //tooltips计算振幅使用
  kState.kval = _.get(rate, 'full');
  kState.preClose = _.get(rate, 'cal.startPrice');
  kState.idxKval = _.get(index, 'idxFull'); // 指数使用org数据
  kState.idxPreClose = _.get(index, 'tooltip.startPrice');
  return tempOption;
}
// slider完成后
function onSliderFinish(v) {
  setSliderValue(v);
  setSliderValue2(v);
  setUpdate3(_.round(update3 + 0.1, 1));
};

return (
  <div style={{ backgroundColor: '#fff' }}>
    <Row>
      <Col span={12}>
        <Segmented options={RANGE_OPTIONS} value={kState.rangeType} onChange={(v) => {
          kState.rangeType = v;
          setUpdate2(_.round(update2 + 0.1, 1));
        }} />
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <IndexSelect size='normal' idv={kState.symbol} onIndexChange={(v) => {
          kState.symbol = v;
          _getKline();
        }} />
      </Col>
    </Row>

    <Divider style={{ margin: '12px 0' }} />

    <Spin spinning={kState.loading}>
      <div style={{ display: 'flex' }}>
        <div id={"krate_charts"} style={{ width: '100%', height: 515 }} />
      </div>
    </Spin>

    <MainSlider
      isNotToday
      isFull={isFullTimeRange(sliderValue, timeArr)}
      timeNameArray={timeNameArr}
      timeArray={timeArr}
      value={sliderValue}
      svalue={sliderValue2}
      onSliderChange={(v) => setSliderValue2(v)}
      onSliderAfterChange={(v) => onSliderFinish(v)}
      onReset={() => onSliderFinish([0, _.last(timeArr)])}
    />
  </div>
)
}

export default KRateCharts;