import React, { useEffect, useState } from 'react';
import { Row, Col, Space, Segmented, Spin, Button, message, Drawer, DatePicker, Select, Popover } from 'antd';
import { LeftOutlined, DoubleLeftOutlined, DoubleRightOutlined, RightOutlined, ClockCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { useReactive, useUpdateEffect } from 'ahooks';
import { getKLineDetail } from '@/api/stock';
import { labelValues, isValidArray, disabledDateFunc } from '@/utils/utils2';
import { calMaLine } from './Components/chartsInfoUtils';
import { OPTIONS_KLINE } from './Components/chartsInfoUtils2';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

// 封装日期范围选择组件
const NewRangePicker = ({ dates = [], onSelect }) => {
  const sevenDay = [moment().subtract(7, 'd'), moment()];
  const thirtyDay = [moment().subtract(30, 'd'), moment()];
  const thisMonth = [moment().startOf('month'), moment()];
  const threeMonth = [moment().subtract(90, 'd'), moment()];
  const yearFromNow = [moment(`${moment().year()}01-01`), moment()];

  function onTimeChange(date, dateStrings, info) {
    const calType = _.get(info, 'range', '');
    if (calType === 'end') {
      onSelect(dateStrings, date);
    }
  }

  const newRangeValue = !dates[0] || !dates[1] ? null : [moment(dates[0]), moment(dates[1])];
  return <RangePicker value={newRangeValue}
    onCalendarChange={onTimeChange}
    disabledDate={(current) => disabledDateFunc(current)}
    ranges={{
      '7日': sevenDay, '30日': thirtyDay, '本月': thisMonth, '季度': threeMonth, '今年': yearFromNow
    }}
  />
}

const FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(FORMAT);
const RANGE_TIME = [moment().subtract(92, 'd').format(FORMAT), TODAY];
const { RangePicker } = DatePicker;
let newKoptions = OPTIONS_KLINE;
Reflect.deleteProperty(newKoptions, 'legend');
// K线通用弹出层组件
const KlineDrawer = ({ pageKey = 'kline', show = false, newProps = {}, value = {}, lists = {}, onClose }) => {
  const mobxStore = useLocalObservable(() => store);
  const [tradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const [update, setUpdate] = useState(0);
  const [kvalue, setKvalue] = useState({});
  const [stockOptions, setStockOptions] = useState([]);
  const kState = useReactive({
    begin: TODAY, end: TODAY, loading: false, type: 'minute',
    stockName: '', stockCode: 0, kval: [], preClose: 0
  });
  const [option, setoption] = useState({
    ...newKoptions,
    grid: { left: '5%', right: '5%', bottom: '14%', top: '10%' },
    dataZoom: [
      { type: 'inside', start: 0, end: 100 },
      { show: true, type: 'slider', bottom: '5%', start: 0, end: 100, height: 12 }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross' },
      formatter: function (params) {
        // console.log(params)
        let maArray = [];
        let maString = '';
        const ks = _.get(params, '[0]', {});
        const get_x = _.get(ks, 'axisValue', '');
        if (_.size(params) > 1) {
          maArray = _.drop(params);
          maArray.map(n => {
            maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
          })
        }
        const kvalArr = Array.from(kState.kval);
        // console.log('kval', kvalArr);
        const open = ks.value[1] ?? ''; const close = ks.value[2] ?? '';
        const high = ks.value[3] ?? ''; const low = ks.value[4] ?? '';
        const amp = _.round((high - low) / low, 4); // 计算振幅
        let tidx = _.findIndex(kvalArr, o => o[0] === get_x);
        const pre = _.get(kvalArr, `[${tidx - 1}][2]`, 0); // 昨日收盘
        const amp2 = tidx > 0 ? _.round((close - pre) / pre, 4) : _.round((close - kState.preClose) / kState.preClose, 4)
        let baseString = '开盘: ' + open + '<br>' +
          '收盘: ' + close + '<br>' +
          '最高: ' + high + '<br>' +
          '最低: ' + low + '<br>';
        let ampString = '振幅: ' + `${_.round(amp * 100, 2)}%` + '<br>' +
          '波幅: ' + `${_.round(amp2 * 100, 2)}%` + '<br>';
        return kState.type === 'minute'
          ? baseString + maString
          : baseString + ampString + maString;
      }
    },
  });

  useEffect(() => {
    if (show) {
      kState.stockCode = _.get(value, 'stockCode', '');
      kState.stockName = _.get(value, 'stockName', '');
      const uniqList = _.uniqBy(isValidArray(lists) ? lists.map(n => labelValues([n.stockName, n.stockCode])) : [], 'value');
      setStockOptions(uniqList);
      _klineDetail();
    }
  }, [show])

  // 获取K线数据；分钟/日K
  async function _klineDetail() {
    if (kState.stockCode) {
      kState.loading = true;
      let params = {
        symbol: kState.stockCode,
        beginDate: kState.begin,
        endDate: kState.end,
        type: kState.type
      }
      const res = await getKLineDetail(params);
      if (_.get(res, 'code', '') === '200') {
        const is_same = moment(params.beginDate).isSame(params.endDate, 'D'); // 同一天不显示日期
        let timeList = []; let newValueList = [];
        let priceList = []; let fullList = [];
        if (isValidArray(_.get(res, 'data.pointList'))) {
          _.get(res, 'data.pointList', []).map(n => { // time open close high low revenue volume amount(成交额)
            const newTime = params.type === 'daily' ? moment(n[0]).format(FORMAT) : n[0];
            const values = _.drop(n);
            timeList.push(is_same ? moment(n[0]).format('HH:mm:ss') : newTime);
            priceList.push(parseFloat(n[2]));
            newValueList.push(values);
            fullList.push(_.concat([newTime], values));
          })
        }
        setKvalue({
          'time': timeList,
          'kval': newValueList,
          'cal': {
            high: _.max(priceList),
            low: _.min(priceList),
            startPrice: _.get(res, 'data.preClose', 0)
          }
        });
        //tooltips计算振幅使用
        kState.kval = fullList;
        kState.preClose = _.get(res, 'data.preClose', 0);
        setUpdate(_.round(update + 0.1, 1));
      } else {
        message.info(_.get(res, 'message', '获取失败！'));
      }
      kState.loading = false;
    }
  }

  useUpdateEffect(() => {
    let myChart = newProps.myChart; // [tips]:图表不挂在props，会找不到图表dom报错
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    // 【bug-fix】不同组件调用同一个chart，使用不同id，否则切换后会空白不显示
    myChart = echarts.init(document.getElementById('kline_charts' + `_${pageKey}`));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });
    let newOption = _.cloneDeep(option);

    newOption.xAxis.data = _.get(kvalue, 'time', []);
    newOption.series[0].data = _.get(kvalue, 'kval', []);
    newOption.series[1].data = calMaLine(_.get(kvalue, 'kval', []), 5);
    const getHigh = _.get(kvalue, 'cal.high', 0);
    const getLow = _.get(kvalue, 'cal.low', 0);
    const getStart = _.get(kvalue, 'cal.startPrice', 0);
    // 计算波幅
    const min = _.round(((getLow - getStart) / getLow) * 100, 2);
    const max = _.round(((getHigh - getStart) / getHigh) * 100, 2);
    newOption.yAxis[1].min = min > 0 ? 0 : min;
    newOption.yAxis[1].max = max < 0 ? 0 : max;

    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [update]);

  // 切换一日或一月的时间切换
  function handleTimeSwitch(type) {
    const isMinute = kState.type === 'minute' ? true : false;
    const day_num = 30;
    if (type === 'left') {
      if (isMinute) {
        let curIdx = _.findIndex(tradeDates, o => o.date === kState.begin);
        kState.begin = _.get(tradeDates, `[${curIdx - 1}].date`, TODAY);
      } else {
        kState.begin = moment(kState.begin).subtract(day_num, 'd').format(FORMAT);
      }
    } else {
      const diffDay = moment().diff(moment(kState.end), 'd');
      let newEnd = 0;
      if (diffDay >= day_num) {
        newEnd = day_num;
      } else {
        newEnd = diffDay;
      }
      if (isMinute) {
        let curIdx = _.findIndex(tradeDates, o => o.date === kState.end);
        kState.end = _.get(tradeDates, `[${curIdx + 1}].date`, TODAY);
      } else {
        kState.end = moment(kState.end).add(newEnd, 'd').format(FORMAT);
      }
    }
    _klineDetail();
  }
  // console.log('tradeDates', tradeDates);
  const drawerProps = { height: 485, visible: show, placement: 'top', maskClosable: false, onClose: onClose };
  const titleComp = <Select bordered={false} value={kState.stockCode} onChange={v => {
    kState.stockCode = v;
    _klineDetail();
  }}>{stockOptions.map((item, i) => <Select.Option key={item.value + i} value={item.value}>{item.label}</Select.Option>)}
  </Select>;
  const extraComp = <NewRangePicker dates={[kState.begin, kState.end]} onSelect={(dateStrings) => {
    kState.begin = dateStrings[0];
    kState.end = dateStrings[1];
    _klineDetail();
  }} />;
  return <Drawer {...drawerProps} title={titleComp} extra={extraComp}>
    <Row justify='center'>
      <Col span={12} style={{ textAlign: 'center' }}>
        <Space>
          <Button size='small' type='text' icon={kState.type === 'minute' ? <LeftOutlined /> : <DoubleLeftOutlined />} onClick={() => handleTimeSwitch('left')} />
          <Segmented options={[labelValues(['分钟K', 'minute']), labelValues(['日K', 'daily'])]}
            value={kState.type}
            size='small'
            onChange={v => {
              kState.type = v;
              kState.begin = v === 'daily' ? RANGE_TIME[0] : TODAY;
              kState.end = v === 'daily' ? RANGE_TIME[1] : TODAY;
              _klineDetail();
            }}
          />
          <Button size='small' type='text' disabled={moment().diff(moment(kState.end), 'day') <= 0 ? true : false}
            icon={kState.type === 'minute' ? <RightOutlined /> : <DoubleRightOutlined />}
            onClick={() => handleTimeSwitch('right')}
          />
        </Space>
      </Col>
    </Row>
    <Spin spinning={kState.loading}>
      <div style={{ display: 'flex' }}>
        <div
          id={"kline_charts" + `_${pageKey}`}
          style={{ width: '100%', height: 335 }}
        />
      </div>
    </Spin>
  </Drawer>
}

// 实时开关按钮
const RealtimeIcon = ({ color = "#e0620d" }) => <svg t="1705286864479" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6161" width="15" height="15"><path d="M886.8 78.8H135.2c-54.2 0-98.4 44.2-98.4 98.4v565.4c0 54.2 44.2 98.4 98.4 98.4h751.6c54.2 0 98.4-44.2 98.4-98.4V177.2c0-54.2-44-98.4-98.4-98.4z m-751.6 60h751.6c21.2 0 38.4 17.2 38.4 38.4V438h-78.6l-67.6-92.6c-5.6-7.8-14.6-12.4-24.2-12.4h-0.4c-9.8 0.2-18.8 5-24.2 13L616 512.8 576.8 452c-5.4-8.6-14.8-13.8-25-13.8h-0.2c-10 0-19.4 5-25 13.4L466 543.2l-105.6-223.6c-4.6-9.8-14.2-16.4-25.2-17.2-10.8-0.8-21.2 4.6-27.2 13.8l-93.6 145.6H96.8V177.2c0-21.2 17.2-38.4 38.4-38.4z m751.6 642.2H135.2c-21.2 0-38.4-17.2-38.4-38.4V521.8h134.2c10.2 0 19.8-5.2 25.2-13.8l73.2-113.8 105.4 222.8c4.6 9.8 14.2 16.4 25 17.2h2.2c10 0 19.4-5 25-13.4l64.4-97.4 38.4 60.2c5.4 8.4 14.8 13.6 24.8 13.8h0.4c9.8 0 19.2-4.8 24.8-13l116-169.2 51.6 70.6c5.6 7.8 14.6 12.4 24.2 12.4h93.8v244.6c-0.2 21-17.4 38.2-38.6 38.2z m-120-565.4c0-16.6 13.4-30 30-30h46.2c16.6 0 30 13.4 30 30s-13.4 30-30 30h-46.2c-16.6 0-30-13.4-30-30zM170.6 883.2h680.8v60H170.6v-60z" p-id="6162" fill={color}></path></svg>
const RealtimeBtn = ({ isClose, onChange }) => {
  const realContent = (<div>
    <Button icon={isClose ? <ClockCircleOutlined /> : <MinusCircleOutlined />}
      type={isClose ? 'link' : 'text'}
      onClick={onChange}
    >
      {`${isClose ? '开启' : '关闭'}实时`}
    </Button>
  </div>);

  return <Popover
    placement='bottom'
    title={null}
    content={realContent}
    trigger="click"
  >
    <Button icon={<RealtimeIcon color={isClose ? '#bfbfbf' : '#e0620d'} />}
      type='text' size='small'
    />
  </Popover>
}

export {
  KlineDrawer,
  NewRangePicker,
  RealtimeBtn
}