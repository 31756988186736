import React from 'react';
import { Row, Col, Select, Tooltip, Space, Checkbox, Segmented, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ClearOutlined } from '@ant-design/icons';
import { labelValues, isValidNumber } from '@/utils/utils2';
import { IndexSelect } from './main_widget';
import FundSearch from './FundSearch';
import _ from 'lodash';

const { Text } = Typography;
const dateOptions = [labelValues(['今日', 'TODAY']), labelValues(['周', 'ONE_WEEK']),
labelValues(['月', 'ONE_MONTH']), labelValues(['季', 'THREE_MONTH']), labelValues(['半年', 'SIX_MONTH']),
labelValues(['年', 'ONE_YEAR']), labelValues(['三年', 'THREE_YEAR']), labelValues(['全部', 'ALL'])
];
// 账户产品对比图表的bar组件
const TwoBars = ({
  typeVal = '',
  accountVal = {},
  accountOptions = {},
  idxSymbol = '',
  compareTypeName = '',
  isClearDisable = false,
  isAccount = false,
  checkVal = [],
  checkItems = [],
  customIndex = [],

  onTypeChange,
  onAccountChange,
  onIdxChange,
  onClear,
  onCheckChange,
  onDelItem
}) => {
  const selectProps = { style: { width: 155 }, size: 'small' };
  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginTop: 8, marginBottom: 8, paddingLeft: 8, paddingRight: 8, minHeight: 35 };

  return <>
    <Space>
      <Segmented size='small' options={dateOptions} value={typeVal} onChange={onTypeChange} />

      <Text type='secondary'>{compareTypeName}</Text>
      {isAccount ? <Select {...selectProps}
        value={_.get(accountVal, 'acc', '')}
        options={_.get(accountOptions, 'accountOptions')}
        onChange={(v) => onAccountChange('acc', v)}
      /> : <Select  {...selectProps}
        value={_.get(accountVal, 'pdct', '')}
        options={_.get(accountOptions, 'productOptions')}
        onChange={(v) => onAccountChange('product', v)}
      />}
      {typeVal !== 'TODAY' && !isAccount && <Text type='secondary'>{'基金'}</Text>}
      {typeVal !== 'TODAY' && !isAccount && <FundSearch
        onFundChange={(v) => onAccountChange('fund', v)}
      />}

      <Text type='secondary'>{'指数'}</Text>
      <IndexSelect active={typeVal} idv={idxSymbol}
        cindex={customIndex}
        onIndexChange={onIdxChange}
      />

      <Button size='small' type='text' danger icon={<ClearOutlined />}
        disabled={isClearDisable}
        onClick={onClear}
      />
    </Space>

    <div style={controlBarStyle}>
      <Checkbox.Group
        style={{ width: '100%' }} value={checkVal} onChange={onCheckChange}
      >
        <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
          {checkItems.map((itm, i) => {
            const get_kid = _.get(itm, 'keyId', '');
            return <Col span={3} key={i}>
              <Space size='small'>
                <Checkbox value={get_kid}>{_.get(itm, 'name')}</Checkbox>
                {!isValidNumber(parseInt(get_kid)) && <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onDelItem(itm)}
                >
                  <CloseCircleOutlined style={{ color: '#a1a1a1' }} />
                </div>}
              </Space>
            </Col>
          })}
        </Row>
      </Checkbox.Group>
    </div >
  </>
}

export default TwoBars;