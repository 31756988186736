import React from 'react';
import { Typography, Table } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { simpleColumns, isValidArray } from '@/utils/utils2';
// import { autoCol } from '@/utils/utils';
import { RULES_BASE, RULES_DYM, LABEL_ARRAY, gets } from '../cutils';
import _ from 'lodash';

const { Text } = Typography;
/**
 *  策略详情列表；与Rules样式不同，数据逻辑相同；
 *  动态表头，avgsValue是动态表头所需的数据； @ 是需要代替的字符； 
 */
const StrategyTable = ({ datas = [], avgsValue = {} }) => {
  const columns_code = {
    ...simpleColumns(['名称', 'stockCode', 155]),
    fixed: 'left',
    render: (text, record) => `${_.get(record, 'stockName', '')}  ${text}`
  }
  const columns_base = RULES_BASE.map(n => {
    return {
      ...simpleColumns([n.label, n.key, n.width]),
      sorter: (a, b) => a[n.key] - b[n.key],
      sortDirections: ['descend', 'ascend'],
    }
  });
  let columns_dym = []
  RULES_DYM.map((array, i) => {
    columns_dym.push({  // 每组增加一个标题头部，区分条件分隔
      ...simpleColumns([LABEL_ARRAY[i], LABEL_ARRAY[i], 90]),
      render: (text, record) => _.get(record, 'condition', null) === i ? <CheckCircleOutlined style={{ color: '#1890ff' }} /> : ''
    })
    array.map(n => {
      const newLabel = 'vkey' in n ? _.replace(n.label, '@', gets(avgsValue, n.vkey)) : n.label; // 替换 @ 显示动态常量表头..
      let sortCol = {
        sorter: (a, b) => a[n.key] - b[n.key],
        sortDirections: ['descend', 'ascend'],
      };
      let baseCol = {
        ...simpleColumns([newLabel, n.key, n.width]),
        render: (text, record) => {
          if (!text) { // 无数据返回 空字符串，保证排序时不会因空数据而失效；
            return '';
          }
          let newText = 'vobj' in n ? n['vobj'][text] : text; // vobj 通过返回值显示固定显示内容，直接从object里面获取
          return _.get(record, 'condition', null) === i ? <Text strong style={{ color: '#1890ff' }}>{newText}</Text> : newText;
        }
      }
      // vkey字段返回都是Number类型，可排序，其他则可不必排序
      columns_dym.push('vkey' in n ? _.assign(baseCol, sortCol) : baseCol);
    });
  });

  const columnsFull = _.concat(columns_code, columns_base, columns_dym);
  const x_width = _.sum(columnsFull.map(n => n.width)); // 累加width字段，修改在cutils配置数据中修改即可；
  // 重新拼装表格data，rollingdata和股票基础信息，无需其他字段
  const newData = isValidArray(datas) ? datas.map((n, i) => {
    return _.assign(
      { key: gets(n, 'stockCode', '') + i, stockCode: gets(n, 'stockCode', ''), stockName: gets(n, 'stockName', '') },
      gets(n, 'rollingData', {})
    )
  }) : [];

  return (
    <div style={{
      height: 725,
      overflow: 'auto',
      padding: '0 16px'
    }}>
      <Table
        rowKey="key"
        dataSource={newData}
        columns={columnsFull}
        scroll={{ x: x_width, y: 650 }}
        bordered
        size='small'
        pagination={false}
      />
    </div>
  )
}

export default StrategyTable;