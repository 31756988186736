import React, { useEffect, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { Spin, Space, Typography } from 'antd';
import { isEmptyStringV2 } from '@/utils/utils';
import { createBaseLine } from '@/view/common/Components/chartsInfoUtils';
import { DIFF_CHART_OPTION } from '../cutils';
import _ from 'lodash';
import * as echarts from 'echarts';

const { Text } = Typography;
// 价格差区间时间统计图表
export default function DiffCountCharts(props) {
  const [option, setOption] = useState({
    ...DIFF_CHART_OPTION,
    yAxis: { type: 'value', splitNumber: 3 },
    legend: {
      data: ['全部', '买入', '卖出'],
      type: 'scroll'
    },
  });
  const [option2, setOption2] = useState({
    ...DIFF_CHART_OPTION,
    yAxis: { type: 'value', splitNumber: 4 },
    legend: { data: [], type: 'scroll' },
  });

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    myChart = echarts.init(document.getElementById('diffCountCharts'));
    myChart2 = echarts.init(document.getElementById('diffRateCharts'));

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);

    const getDatas = _.get(props, 'datas', []);
    //console.log(getDatas)
    let times = [];
    let sdata = [
      createBaseLine('#0060ab', { name: '全部' }),
      createBaseLine('#e82200', { name: '买入' }),
      createBaseLine('#009755', { name: '卖出' })
    ];
    let sdata2 = [ // 收益率差
      createBaseLine('#6ab273', { name: '相同A' }),
      createBaseLine('#03a17c', { name: '相同B' }),//1
      createBaseLine('#aacf53', { name: '不同A' }),//2
      createBaseLine('#cfdc26', { name: '不同B' }),//3
      createBaseLine('#8ebbce', { name: '权重相同A' }),//4
      createBaseLine('#0060ab', { name: '权重相同B' }),//5
      createBaseLine('#eacc88', { name: '权重不同A' }),//6
      createBaseLine('#b4bfd0', { name: '权重不同B' }),//7
    ];
    if (_.size(getDatas) > 0) {
      getDatas.map(item => {
        times.push(_.get(item, 'date', ''));
        sdata[0].data.push(_.get(item, 'leftCal.total', 0) - _.get(item, 'leftCal.validCount', 0))
        sdata[1].data.push(_.get(item, 'leftCal.buyTotal', 0) - _.get(item, 'leftCal.vBuyTotal', 0))
        sdata[2].data.push(_.get(item, 'leftCal.sellTotal', 0) - _.get(item, 'leftCal.vSellTotal', 0))

        sdata2[0].data.push(_.get(item, 'leftCal.sr_avg'));
        sdata2[2].data.push(_.get(item, 'leftCal.sr_diff_avg'));
        sdata2[4].data.push(_.get(item, 'leftCal.sr_avg2'));
        sdata2[6].data.push(_.get(item, 'leftCal.sr_diff_avg2'));
        // 无2需区分左右，用左侧数据右侧计算即可..
        sdata2[1].data.push(_.get(item, 'rightCal.sr_avg'))
        sdata2[3].data.push(_.get(item, 'rightCal.sr_diff_avg'))
        sdata2[5].data.push(_.get(item, 'rightCal.sr_avg2'))
        sdata2[7].data.push(_.get(item, 'rightCal.sr_diff_avg2'))
      })
    };
    newOption.xAxis.data = times;
    newOption.series = sdata;
    newOption2.series = sdata2;
    newOption2.xAxis.data = times;
    newOption2.legend.data = sdata2.map(n => n.name);
    //console.log('newOption', newOption)
    setOption(newOption); setOption2(newOption2);
    myChart.setOption(newOption, true);
    myChart.resize();
    myChart2.setOption(newOption2, true);
    myChart2.resize();
  }, [props.upCount]);

  useUpdateEffect(() => {
    if (props.active === '2') {
      let myChart = props.myChart; let myChart2 = props.myChart;
      myChart = echarts.init(document.getElementById('diffCountCharts'));
      myChart2 = echarts.init(document.getElementById('diffRateCharts'));
      myChart.resize();
      myChart2.resize();
    };
  }, [props.upTab]);

  const renderTitle = (type) => {
    const dir_obj = { 'left': ['baseType', 'accLeftName'], 'right': ['rightType', 'accRightName'] };
    let finalString = '';
    if (!isEmptyStringV2(_.get(props.fullParams, dir_obj[type][1]))) {
      finalString = _.toUpper(_.get(props.fullParams, dir_obj[type][0], '')) + '-' + _.get(props.fullParams, dir_obj[type][1])
    }
    return finalString;
  }

  const chart_props = { style: { width: '100%', height: 355 } };
  return (
    <>
      <Spin spinning={_.get(props, 'loading', false)}>
        <div style={{ display: 'flex', marginTop: 28 }}>
          <div id="diffCountCharts" {...chart_props} />
        </div>
      </Spin>

      <div style={{ marginTop: 24, textAlign: 'center' }}>
        <Space>
          <Text code>A:</Text>
          <Text strong>{renderTitle('left')}</Text>
          <Text code>B:</Text>
          <Text strong>{renderTitle('right')}</Text>
        </Space>
      </div>

      <div style={{ display: 'flex', marginTop: 24 }}>
        <div id="diffRateCharts"{...chart_props} />
      </div>
    </>
  )
}