
export const getval = function (name, val = "") {
  try {
    if (localStorage.getItem(name) !== undefined && localStorage.getItem(name) !== '') {
      // return JSON.parse(localStorage.getItem(name));
      if (name === 'token') {
        return localStorage.getItem(name);
      } else {
        return JSON.parse(localStorage.getItem(name));
      }
    } else {
      return val;
    }
  } catch (e) {
    return "";
  }
}

export const setVal = function (name, obj) {
  if (name === 'token' || name === 'tokenFC') {
    localStorage.setItem(name, obj);
  } else {
    let ret = JSON.stringify(obj);
    localStorage.setItem(name, ret);
  }
}
