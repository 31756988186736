import React, { useState } from 'react';
import { Modal, Checkbox, Row, Col, Tag, Button, Space, Typography, InputNumber, Radio, Divider, message } from 'antd';
import { useReactive, useUpdateEffect } from 'ahooks';
import { UpSquareOutlined, EditOutlined } from '@ant-design/icons';
import { isValidArray, isValidNumber } from '@/utils/utils2';
import _ from 'lodash';

const { Text } = Typography;
// 交易员选择板块的批量卖出
const PlateModal = ({ show = false, upcount = 0, plates = [], rowChecks = [], lists = [], onCancel, onFinish }) => {
  const [checks, setChecks] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [editMode, setEditMode] = useState(false); // 切换股票的编辑模式
  const inputState = useReactive({ nu: 1, deno: 3, radio: 1 }); //分子,分母
  // 已选择的股票带入的selecList
  useUpdateEffect(() => {
    if (isValidArray(rowChecks)) {
      setSelectList(_.filter(lists, o => _.includes(rowChecks, o.stockCode)));
    }
  }, [upcount])
  // checkbox
  const onCheckChange = (checkedValues) => {
    setChecks(checkedValues);
    // 每次选择筛选出对应板块的票
    let plist = [];
    lists.map(n => {
      let valid = false;
      if (isValidArray(_.get(n, 'plate_arr'))) {
        n.plate_arr.map(pl => {
          // 有板块符合则push数据
          if (_.includes(checkedValues, pl)) {
            valid = true;
          }
        });
      }
      if (valid) {
        plist.push(n);
      }
    });
    setSelectList(plist);
  };
  // modal完成选择
  const handleFinish = (type) => {
    let final = {
      'newList': selectList.map(n => n.stockCode),
      'proportion': calFraction(),
      type,
    }
    if (!isValidArray(final.newList)) {
      message.info('请选择股票！');
      return;
    }
    if (!isValidNumber(final.proportion)) {
      message.info('请输入正确的比例！');
      return;
    }
    onFinish(final);
    clearChecks();
  }
  // 删除seleclist数据
  const delListItem = (code) => {
    let tempList = _.cloneDeep(selectList);
    setSelectList(_.filter(tempList, o => o.stockCode !== code));
  }
  // 计算及检查自定义分数是否正确并返回比例值
  const calFraction = () => {
    let fval = 0;
    if (inputState.radio === 'other') { //自定义分数转化为小数，保留2位【例:0.33 = 1/3
      const num_nu = parseInt(inputState.nu); const num_deno = parseInt(inputState.deno);
      if (isValidNumber(num_nu) && isValidNumber(num_deno)) {
        fval = _.round(num_nu / num_deno, 2);
      } else {
        message.info('请输入正确的比例值！')
        return false;
      }
    } else {
      fval = parseFloat(inputState.radio);
    }
    return fval;
  }

  function clearChecks() {
    setChecks([]);
    setSelectList([]);
    setEditMode(false);
  }
  //console.log(selectList)
  const btns = { disabled: !isValidArray(selectList) };
  const inputNumObj = { style: { width: 55 }, size: 'small', min: 0, step: 1, disabled: inputState.radio !== 'other' }
  return <Modal
    visible={show}
    footer={null}
    title={null}
    width={610}
    onCancel={() => {
      onCancel();
      clearChecks();
    }}
  >
    <div style={{ marginBottom: 12 }}>
      <Text strong>更多</Text>
    </div>
    <Checkbox.Group style={{ width: '100%' }} value={checks} onChange={onCheckChange}>
      <Row gutter={[8, 8]}>
        {isValidArray(plates) && plates.map((p, i) => <Col span={8} key={_.get(p, 'label', '') + i}>
          <Checkbox value={_.get(p, 'label', '')}><Tag>{_.get(p, 'label', '')}</Tag></Checkbox>
        </Col>)}
      </Row>
    </Checkbox.Group>
    <Space style={{ marginTop: 8 }}>
      <Text type='secondary'>比例:</Text>
      <Radio.Group onChange={(e) => inputState.radio = e.target.value} value={inputState.radio}>
        <Radio value={1}>全仓</Radio>
        <Radio value={0.5}>半仓</Radio>
        <Radio value={'other'}>其他</Radio>
      </Radio.Group>
      <Divider type='vertical' />
      <Space size='small'>
        <InputNumber {...inputNumObj} value={inputState.nu} onChange={(v) => inputState.nu = v} />
        <Text>/</Text>
        <InputNumber {...inputNumObj} value={inputState.deno} onChange={(v) => inputState.deno = v} />
      </Space>
    </Space>
    <Divider />
    <div>
      <Space>
        <Text type='secondary'>已选 <Text strong>{_.size(selectList)}</Text>只票</Text>
        {_.size(selectList) > 0 && <div style={{ cursor: 'pointer' }} onClick={() => setEditMode(!editMode)}>
          {editMode ? <Text>完成</Text> : <EditOutlined style={{ color: '#056de8' }} />}
        </div>}
      </Space>
    </div>
    <div style={{ height: 195, overflowY: 'scroll', overflowX: 'hidden', marginTop: 8 }}>
      <Row gutter={[8, 8]}>
        {isValidArray(selectList) && selectList.map((item, i) => {
          const stock_name = `${_.get(item, 'stockName', '')}(${_.get(item, 'stockCode', '')})`
          return <Col key={'stock' + i} span={8}>
            {editMode ? <Tag key={_.get(item, 'stockCode')} // 【tips】配置唯一key值，否则删除后会乱
              closable onClose={() => delListItem(item.stockCode)}
            >
              {stock_name}
            </Tag> : <Text>{stock_name}</Text>}
          </Col>
        })}
      </Row>
    </div>
    <Row justify='end' style={{ marginTop: 12 }}>
      <Col span={12}>
        <Space>
          <Button {...btns} onClick={() => handleFinish('add')}>添加至列表</Button>
          <Button  {...btns} type='primary' icon={<UpSquareOutlined />} onClick={() => handleFinish('sell')}>
            直接卖出
          </Button>
        </Space>
      </Col>
    </Row>
  </Modal>
}

export default PlateModal;