import React from 'react';
import { Col, Row, Space } from 'antd';
import StatisticHeader from '@/utils/StatisticHeader';
import { autoCol } from '@/utils/utils';
import _ from 'lodash';
/**
 *  交易员账户资产信息，后端返回为 String 类型，所以包括 % 也都是后端返回
 */
const DeskHeader = ({ accDatas }) => {
  // console.log(accDatas)
  return <Row gutter={[16, 16]}>
    <Col {...autoCol([7])}>
      <div className='headCard_2 colorCardFund'>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <StatisticHeader title={'总资产'} value={_.get(accDatas, 'totalAssets', 0)} />
          </Col>
          <Col span={12}>
            <StatisticHeader title={'总市值'} value={_.get(accDatas, 'positionsAssets', 0)} />
          </Col>
          <Col span={12}>
            <StatisticHeader title={'可用资金'} value={_.get(accDatas, 'totalBalance', 0)} />
          </Col>
        </Row>
      </div>
    </Col>
    <Col {...autoCol([7])}>
      <div className='headCard_2 colorCardFund'>
        <StatisticHeader title={'总收益'} value={_.get(accDatas, 'totalRevenue', 0)} isColor />
      </div>
    </Col>
    <Col {...autoCol([7])}>
      <div className='headCard_2 colorCardFund'>
        <Space direction='vertical'>
          <StatisticHeader title={'日收益'} value={_.get(accDatas, 'dailyRevenue', 0)} isColor />
          <StatisticHeader
            title={'日收益率'} // 日收益率字段特殊处理，value直接parseFloat, 颜色有header组件渲染生成
            value={parseFloat(_.get(accDatas, 'dailyRevenueRate', 0)).toFixed(2) + '%'}
            isColor
          />
        </Space>
      </div>
    </Col>
  </Row>
}

export default DeskHeader;