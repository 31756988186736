import React, { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Typography, Tooltip, Space } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import { renderHugeFund, singleColumns } from '@/utils/utils';
import _ from 'lodash';

const { Text } = Typography;

const renderTooltip = (txt, children) => <Tooltip title={txt}>{children}</Tooltip>
const renderColorText = (price = 0, typeArr = [], txtStyle = {}) => {
  const newPrice = _.isNumber(price) ? price : parseFloat(price);
  let finalObj = { color: 'black', priceText: newPrice };
  if (newPrice > 0) {
    finalObj.color = 'red';
  } else if (newPrice < 0) {
    finalObj.color = 'green';
  }
  if (_.includes(typeArr, 'rate')) {
    finalObj.priceText = newPrice + '%'
  }
  if (_.includes(typeArr, 'huge')) {
    return renderTooltip(newPrice, <Text style={{ color: finalObj.color }}>{renderHugeFund(newPrice)}</Text>)
  }
  return <Text style={{ color: finalObj.color, ...txtStyle }}>{finalObj.priceText}</Text>
};

function renderColumns(keyArray, type) {
  let colObj = singleColumns(keyArray);
  if (type === 'stockName') {
    colObj.render = (text, record) => <Text>{_.get(record, 'stockName', '') + ' - ' + _.get(record, 'stockCode', '')}</Text>
  }
  if (type === 'updown') {
    colObj.render = (text, record) => renderColorText(text);
  }
  if (type === 'hugefund') {
    colObj.render = (text, record) => renderTooltip(text, renderHugeFund(text));
  }
  if (type === 'hugefund_color') {
    colObj.render = (text, record) => renderColorText(text, ['huge']);
  }
  return colObj;
}

const FundTable = ({
  mainData,
  loading,
  pageCurrent,
  searchValue,
  clearCount,

  onPageChange,
  goDetail,
  onSort
}) => {
  const [isFiltered, setIsFiltered] = useState('');

  useEffect(() => {
    // console.log('effect!!!!')
    if (_.get(searchValue, 'orderType', '') === '' && _.get(searchValue, 'orderType', '') !== isFiltered) {
      setIsFiltered('')
    }
  }, [clearCount])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    onSort(dataIndex);
    setIsFiltered(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Button type="primary" icon={<SearchOutlined />} size="small" style={{ width: 90 }}
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        >
          点击排序
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined style={{
        color: dataIndex === isFiltered ? '#1890ff' : undefined,
      }}
      />
    ),
    // onFilter: (value, record) => console.log('onFilter', value),
    // onFilterDropdownVisibleChange: (visible) => {
    //   if (visible) {
    //   }
    // },
  });

  const columns = [
    renderColumns(['股票信息', 'stockName'], 'stockName'),
    singleColumns(['日期', 'tradeDate'], 'dateTime'),
    renderColumns(['收盘价', 'closePrice'], 'updown'),
    {
      ...singleColumns(['涨跌幅', 'changeRate']),
      render: (text, record) => {
        const getRecentRate = _.get(record, 'recentChangeRate', []);
        return <>
          {renderColorText(text, ['rate'], { fontSize: 17, fontWeight: '500' })}
          {_.size(getRecentRate) > 0 && <br />}
          {_.size(getRecentRate) > 0 && <Space>
            {getRecentRate.map(n => renderColorText(_.get(n, 'change_rate', ''), ['rate'], { color: 'black' }))}
          </Space>}
        </>
      }
    },
    { ...renderColumns(['主力', 'mainFunds'], 'hugefund'), ...getColumnSearchProps('mainFunds') },
    { ...renderColumns(['外资主力', 'foreignFunds'], 'hugefund'), ...getColumnSearchProps('foreignFunds') },
    { ...renderColumns(['内资主力', 'dvalue'], 'hugefund_color'), ...getColumnSearchProps('dValue') },
    {
      ...singleColumns(['操作', 'option']),
      render: (text, record) => {
        return <Button type='text' size='small' onClick={() => goDetail(record)}>{'详情>>'}</Button>
      }
    },
  ]

  const flowList = [
    { key: 'extremeInflow', name: '超大单流入' }, { key: 'extremeOutflow', name: '超大单流出' },
    { key: 'largeInflow', name: '大单流入' }, { key: 'largeOutflow', name: '大单流出' },
    { key: 'mediumInflow', name: '中单流入' }, { key: 'mediumOutflow', name: '中单流出' },
    { key: 'smallInflow', name: '小单流入' }, { key: 'smallOutflow', name: '小单流出' },
  ]

  const expandRow = (record) => {
    return <>
      <Row gutter={[8, 8]} style={{ textAlign: 'left' }}>
        {flowList.map((n, i) => <Col span={6} key={n.key + i}>
          <Tooltip title={_.get(record, n.key, '无')}>
            <Text>{n.name + ' : ' + renderHugeFund(_.get(record, n.key))}</Text>
          </Tooltip>
        </Col>)}
      </Row>
    </>
  }

  return (
    <Table
      rowKey={'id'}
      loading={loading}
      dataSource={_.get(mainData, 'list', [])}
      columns={columns}
      size='small'
      expandable={{
        expandedRowRender: record => expandRow(record),
        expandRowByClick: true
      }}
      pagination={{
        current: pageCurrent,
        pageSize: 20,
        total: _.get(mainData, 'total', 0),
        onChange: onPageChange,
        showSizeChanger: false
      }}
    />
  )
}

export default FundTable;