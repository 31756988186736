import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Spin,
  Checkbox,
  Space,
  Typography,
  Tooltip,
  Button,
  Tag,
  Divider
} from 'antd';
import { showProfitAndLoss, showSubAccountHistoryByDate, showIndexKlineByDate } from '@/api/workbench';
import { IndexSelect } from '@/view/common/Components/main_widget';
import { RATE_TD_KEYS_PROFIT, TWO_DAY_KEYS_PROFIT, DEFAULT_CARRY, renderFloats } from '@/view/common/Components/chartsInfoUtils';
import {
  TODAY_AVAERAGE, PRE_LIST, orderTimeArray, handleStockArray, handleLastNoZero, createChartLineColor,
  renderFloatsAdd, renderFloatsArray, calDividerAndMulti, calDividerAndMulti2,
} from '@/view/common/Components/chartsInfoUtils2';
import { useMount } from 'ahooks';
import { NewRangePicker } from '@/view/common/widgets';
import { CONTRIBUTION_OPTIONS } from '../list_utils';
import * as echarts from 'echarts';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { CheckableTag } = Tag;
const TODAY = moment().format('YYYY-MM-DD');
/**
 *  子账户盈亏图表；拆分汇总统计
 */
export default function ReviewCharts(props) {
  const [rangeVal, setRangeVal] = useState([TODAY, TODAY]);
  const [idValue, setIdValue] = useState('000001');
  const [profitDatas, setProfitDatas] = useState({});
  const [updateCount, setUpdateCount] = useState(0);
  const [dateSelect, setDateSelect] = useState([]);
  const [limitSelect, setLimitSelect] = useState([]);
  const [dateVal, setDateVal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indexRate, setIndexRate] = useState({});
  const [getParams, setGetParams] = useState({});
  const [preSelect, setPreSelect] = useState([]);
  const [preValues, setPreValues] = useState([]);
  const [showPre, setShowPre] = useState(false);
  const [showTwoDay, setShowTwoDay] = useState(false);
  const [option, setoption] = useState(CONTRIBUTION_OPTIONS);

  useMount(() => {
    getIndexData();
  });

  function isReloadIndexData(date, symbol) {
    const isTrading = _.get(props, 'isTrading');
    const pmtime1 = _.get(getParams, 'startTime', '');
    const pmtime2 = _.get(getParams, 'endTime', '');
    const pmsymbol = _.get(getParams, 'symbol', '');
    if (date[0] === pmtime1 && date[1] === pmtime2 && pmsymbol === symbol) {
      setUpdateCount(updateCount + 1); // 无需获取数据是，直接更新图表
      return false;
    }
    if (isTrading && (date[0] === TODAY || date[1] === TODAY)) {
      return true;
    }
    return true;
  }

  async function getIndexData(symbol, date) {
    const is_reverse = _.get(props, 'isReverse', false);
    const getId = _.get(props, 'subAccountId', 0);
    const time1 = date ? date[0] : rangeVal[0];
    const time2 = date ? date[1] : rangeVal[1];
    const symbol1 = symbol ? symbol : idValue
    const isLoad = isReloadIndexData([time1, time2], symbol1);
    if (getId && isLoad) {
      let pms = {
        subAccountId: getId,
        startTime: date ? date[0] : rangeVal[0],
        endTime: date ? date[1] : rangeVal[1],
        type: 'byDay'
      }
      const res = await showProfitAndLoss(pms, is_reverse ? 'FC' : 'NOR');
      let params = {
        subAccountId: getId,
        startTime: time1,
        endTime: time2,
      }
      const resData = await showSubAccountHistoryByDate(params, is_reverse ? 'FC' : 'NOR');
      let params2 = {
        symbol: symbol1,
        startTime: time1,
        endTime: time2,
      }
      const resIndexs = await showIndexKlineByDate(params2);
      setGetParams({ startTime: params.startTime, endTime: params.endTime, symbol: params2.symbol });
      if (_.get(resIndexs, 'code', '') === '200' && _.get(resData, 'code', '') === '200') {
        setProfitDatas(_.get(res, 'data', {}))
        const getTimeList = _.get(resData, 'data.timeList', []);
        const getPointList = _.get(resData, 'data.pointList', []);
        const getAnayDatas = _.get(resData, 'data.rateAnalysisList', []);
        const getTimeIndex = _.get(resIndexs, 'data.timeList', []);
        const getIndexList = _.get(resIndexs, 'data.pointList', []);
        const getPreClose = _.get(resIndexs, 'data.preClose', 0);
        let indexValues = [];
        let datObj = { other: [] };
        RATE_TD_KEYS_PROFIT.map(item => {
          datObj[item.key] = [];
        });
        // 收益率及指数计算，和EchartsInfo处理方法相同
        getTimeList.map((t, i) => {
          const idxTime = getTimeIndex.indexOf(t);
          const rateValue = getPreClose === 0 ? 0 : _.floor((getIndexList[idxTime] - getPreClose) / getPreClose, DEFAULT_CARRY) * 100;
          if (idxTime !== -1) {
            indexValues[i] = parseFloat(rateValue.toFixed(DEFAULT_CARRY));
          } else {
            indexValues[i] = '-'
          }
        });
        // 处理昨仓进仓数据；同EchartsInfo 处理数据方法
        _.size(getAnayDatas) > 0 && getAnayDatas.map((anay, index) => {
          let item = { time: getTimeList[index] };
          // 实时不进行累计收益率计算
          TWO_DAY_KEYS_PROFIT.map(keyname => {
            let val = renderFloats(anay[keyname], 1);
            datObj[keyname].push(val);
            item[keyname] = renderFloats(val, 100);
          })
          datObj.other.push(item);
        })
        Object.keys(datObj).map(keyname => {
          let sum = 0;
          let newValArray = [];
          let orrrg = [];
          let nonZero = false;
          datObj[keyname].map((val, i) => {
            if (nonZero && sum !== 0) {
              let calval = (1 + val) * sum;
              sum = calval;
              newValArray.push(renderFloats(calval - 1, 100));
            } else if (val !== 0 && sum === 0) {
              newValArray.push(renderFloats(val, 100));
              sum = 1 + val;
              nonZero = true;
            } else {
              newValArray.push(renderFloats(val, 100));
            }
            orrrg.push(val);
          })
          datObj[keyname] = newValArray;
        })

        let newIndexRate = {
          time: getTimeList,
          index: indexValues,
          rate: renderFloatsArray(getPointList, 1),
          analysis: datObj
        }
        setIndexRate(newIndexRate);
        // console.log('newIndexRate', newIndexRate)
        setUpdateCount(updateCount + 1);
      }
      setLoading(false);
    }
  }

  function handleTotalData() {
    const getTimes = _.get(profitDatas, 'time', []);
    const getPreTimes = _.get(profitDatas, 'preDailyStock', []); // 前仓日期及股票
    const dataObj = _.get(profitDatas, 'profitPint', {}); // 汇总收益率
    const dailyStock = _.get(profitDatas, 'dailyStock', {});
    const dailyLimitStock = _.get(profitDatas, 'dailyLimitStock', {});
    const averageObj = _.get(profitDatas, 'averageRateList', {}); //平均收益率
    const isDay = rangeVal[0] !== rangeVal[1] ? true : false;// 是否跨天，是否当日数据
    //【拿到日期及汇总股票代码】
    const newStockInfo = handleStockArray(dailyStock, dateVal, getTimes, isDay);
    const newStockLimitInfo = handleStockArray(dailyLimitStock, dateVal, getTimes, isDay);
    // 计算除数需求：从选择日期开始，每个日期除以股票数 = 当日 + 之前日期股票数之和；
    let stockArray = _.get(newStockInfo, 'stocks', []);
    let stockLimitArray = _.get(newStockLimitInfo, 'stocks', []); //涨停票
    let newSeriesArray = [];
    let newAverLimitArray = [];
    let newAverList = [];
    let newIndexObj = { rate: [], idx: [] };
    // 前仓收益率
    let preStockArray = { 5: [], 4: [], 3: [], 2: [], 1: [] }; // 前仓股票数组
    let preStockValues = { 5: [], 4: [], 3: [], 2: [], 1: [] }; // 前仓数据
    const preCount = Object.keys(preStockValues); //固定生成3天的前仓数据
    const preTimesArray = Object.keys(getPreTimes);
    const fullTimes = orderTimeArray('array', _.concat(preTimesArray, dateSelect)); // 完整日期array
    const fullDaliyStock = { ...getPreTimes, ...dailyStock }; // 合并成完整日期 obj
    // 【收益率计算：】
    if (_.size(dataObj) > 0 && _.size(averageObj) > 0) {
      // 累计计算时，将所有股票的值累计成一个array，汇总所有收益率
      Object.keys(dataObj).map(symbol => {
        const symbolValues = _.get(dataObj, symbol, []); // symbol:[] 数据结构
        if (stockArray.indexOf(symbol) !== -1) {
          if (_.size(newSeriesArray) === 0) {
            newSeriesArray = renderFloatsArray(symbolValues, 100); //第一步创建数组并填充第一位股票数据
          } else if (_.size(newSeriesArray) === _.size(symbolValues)) {
            //每只股票数据size相同，所以循环接下来所有数据满足该条件，将下面数据进行累加求和。
            newSeriesArray = newSeriesArray.map((n, i) => renderFloatsAdd(n, symbolValues[i], 100))
          }
        }
      })
      // 累加前1-5天的股票数据，并记录前1-5日股票数量作为不同前仓的除数
      if (_.size(preTimesArray) === 5) {
        preCount.map(key => { // 5天遍历
          getTimes.map((time, i) => { // 每天按照数据日期生成点位数据
            const tindex = _.findIndex(fullTimes, o => o === time);
            // 前仓1 = 当日用前一天股票数进行计算 前仓2 = 当日往前2天的股票数计算 .. (以此类推)
            const preDate = tindex > 4 ? fullTimes[tindex - parseInt(key)] : null;
            if (fullTimes) { // 拿到前n天的日期，然后创建数据
              const getPretimeStock = _.get(fullDaliyStock, preDate, [])
              preStockArray[key][i] = { value: _.size(getPretimeStock) }; // 创建除数；
              let finalPre = 0; // 累加计算
              getPretimeStock.map(stock => {
                const vv = renderFloats(_.get(averageObj, `${stock}[${i}]`, 0), 1);
                finalPre = finalPre + vv;
              })
              preStockValues[key][i] = finalPre;
            }
          })
        })
      }
      // 平均收益率，元数据与最后计算方法不同，方式相同；
      Object.keys(averageObj).map(symbol => {
        const mul = isDay ? 1 : 100; //运算中的百分比率，当日乘100，跨天不变
        if (stockArray.indexOf(symbol) !== -1) {
          const averSymbolVal = _.get(averageObj, symbol, []);
          if (_.size(newAverList) === 0) {
            newAverList = renderFloatsArray(averSymbolVal, mul);
          } else if (_.size(newAverList) === _.size(averSymbolVal)) {
            newAverList = newAverList.map((n, i) => renderFloatsAdd(n, averSymbolVal[i], mul))
          }
        }
        if (stockLimitArray.indexOf(symbol) !== -1) {
          const averSymbolVal = _.get(averageObj, symbol, []);
          if (_.size(newAverLimitArray) === 0) {
            newAverLimitArray = renderFloatsArray(averSymbolVal, mul);
          } else if (_.size(newAverLimitArray) === _.size(averSymbolVal)) {
            newAverLimitArray = newAverLimitArray.map((n, i) => renderFloatsAdd(n, averSymbolVal[i], mul))
          }
        }
      });
      // 计算累加和之后，每个值除以当前所选的股票数量 [计算公式同EchartInfos【累加乘公式】]
      if (isDay) {
        newAverList = calDividerAndMulti(newAverList, _.get(newStockInfo, 'counts', []));
        newAverLimitArray = calDividerAndMulti(newAverLimitArray, _.get(newStockLimitInfo, 'counts', []));
        preCount.map(key => {
          preStockValues[key] = calDividerAndMulti(preStockValues[key], preStockArray[key]);
        })
      } else { //当日收益率不需要连乘，汇总后除以股票个数即可
        newAverList = calDividerAndMulti2(newAverList, _.get(newStockInfo, 'counts', []));
        newAverLimitArray = calDividerAndMulti2(newAverLimitArray, _.get(newStockLimitInfo, 'counts', []));
      }
    }
    // 根据时间匹配 指数及收益 的值
    if (_.size(getTimes) > 0) {
      const idxTime = _.get(indexRate, 'time', []);
      getTimes.map((t, i) => {
        const indexT = idxTime.indexOf(t)
        if (indexT !== -1) {
          newIndexObj.rate[i] = _.get(indexRate, `rate[${indexT}]`);
          newIndexObj.idx[i] = _.get(indexRate, `index[${indexT}]`);
        } else {
          newIndexObj.rate[i] = '-'
          newIndexObj.idx[i] = '-'
        }
      })
    }
    // 创建图形：
    const SERIES_ARRAY = [
      { name: '汇总收益率', data: newSeriesArray, zero: true, other: createChartLineColor('#7d3a7e') },
      { name: '账户收益率', data: newIndexObj.rate, zero: true, other: createChartLineColor('#009283') },
      { name: '平均收益率', data: newAverList, zero: true, other: createChartLineColor('#f3d244') },
      // { name: '涨停收益率', data: newAverLimitArray, zero: true, other: createChartLineColor('#f03705') },
      {
        name: '指数波幅(%)', data: newIndexObj.idx, other: createChartLineColor('#B5495B', { type: 'dashed', width: 1 })
      },
    ]
    let spreArray = []; // 前仓完整数组
    let filterSpre = []; // 根据select最后展示数组
    PRE_LIST.map(n => {
      // 今日平均不增加图形，只计算
      if (preSelect.indexOf(n.key) !== -1 && n.key !== 6) {
        filterSpre.push({ ...n, data: preStockValues[n.key] });
      }
      spreArray.push({ ...n, data: preStockValues[n.key] || [] });
    });
    //近两日
    const twoDayArray = RATE_TD_KEYS_PROFIT.map(key => {
      return { name: key.name, data: _.get(indexRate, `analysis.${key.key}`, []), other: createChartLineColor(key.color, { opacity: 0.7 }) }
    });
    // 前仓及前仓平均计算
    if (_.size(filterSpre) > 0 && showPre) {
      const isSelectTodayAverage = preSelect.indexOf(6) !== -1 ? true : false;
      let averArray = []; // 平均=前仓数累加/前仓数量
      if (isSelectTodayAverage) { // 今仓平均与前仓累加，计算多日平均
        const daData = _.chain(twoDayArray).filter(o => o.name === TODAY_AVAERAGE).head().get('data').value();
        averArray = _.cloneDeep(daData); // 如不深层遍历会跟随averArray进行累加【tips】；
      }
      filterSpre.map(s => {
        const sdata = _.get(s, 'data', []);
        sdata.map((ss, i) => {
          if (averArray[i]) {
            averArray[i] = renderFloatsAdd(averArray[i], ss, 1)
          } else {
            averArray[i] = ss;
          }
        })
      });
      // 如加今仓需多一位除数
      const mul_div = isSelectTodayAverage ? _.size(filterSpre) + 1 : _.size(filterSpre);
      filterSpre.push({
        name: '多日平均',
        data: averArray.map(n => renderFloats(n / mul_div, 1)),
        other: createChartLineColor('#ffa676')
      })
    }

    setPreValues(isDay ? spreArray : []); // 用原始ARRAY赋值select模块

    const finals = _.concat(SERIES_ARRAY, showPre ? filterSpre : [], showTwoDay ? twoDayArray : []);
    let series = finals.map(s => {
      const isZero = _.get(s, 'zero', false);
      const getOther = _.get(s, 'other', {});
      let newData = _.get(s, 'data', []);
      if (isZero) { // && isDay
        newData = handleLastNoZero(s.data);
      }
      return { name: s.name, data: newData, type: 'line', symbol: 'none', ...getOther };
    })

    return { time: getTimes, sData: series }
  }

  // 切换到 汇总 时调用；刷新图表数据
  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('list_review_chart'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option);
    if (_.size(profitDatas) > 0) {
      const getdailystock = _.get(profitDatas, 'dailyStock', {});
      const getLimitstock = _.get(profitDatas, 'dailyLimitStock', {});

      const nsData = handleTotalData();
      newOption.xAxis.data = nsData.time;
      newOption.series = nsData.sData;
      // 排序
      let newSelectArray = orderTimeArray('obj', getdailystock);
      let lmArray = []; //没有涨停票的日期
      Object.keys(getLimitstock).map(keyname => {
        if (_.size(getLimitstock[keyname]) === 0) {
          lmArray.push(keyname);
        }
      })
      setDateSelect(newSelectArray.map(n => n.name));
      setLimitSelect(lmArray);
    }
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
  }, [updateCount])

  const onCheckChange = (checkedValues) => {
    setDateVal(checkedValues);
    setUpdateCount(updateCount + 1);
  };

  const onCheckPreChange = (checkedValues) => {
    setPreSelect(checkedValues);
    setUpdateCount(updateCount + 1);
  };

  const controlBarStyle = { backgroundColor: '#f9f9f9', borderRadius: '4px', marginBottom: 16, paddingLeft: 10, paddingRight: 10 };
  const isCrossDay = rangeVal[0] !== rangeVal[1] ? true : false;
  return (
    <div style={{ backgroundColor: '#fff', padding: 6 }}>
      <Card bordered={false}>
        <Row justify='space-between' gutter={[16, 16]} style={{ marginBottom: 12 }}>
          <Col span={8}>
            <IndexSelect size='normal' idv={idValue} onIndexChange={(v) => {
              setIdValue(v);
              getIndexData(v);
            }} />
          </Col>
          <Col span={8}>
            <Space>
              <span>选择日期:</span>
              <NewRangePicker dates={rangeVal} onSelect={(dateString) => {
                setRangeVal(dateString);
                getIndexData(null, dateString);
              }} />
            </Space>
          </Col>
        </Row>
        {_.size(dateSelect) > 0 && <div style={controlBarStyle}>
          <Row justify='space-between' style={{ paddingTop: 8, marginBottom: 10 }}>
            <Col span={6}>
              <Button type='link' onClick={() => {
                if (_.size(dateVal) === _.size(dateSelect)) {
                  setDateVal([]);
                } else {
                  setDateVal(dateSelect)
                }
                setUpdateCount(updateCount + 1);
              }}>
                {_.size(dateVal) === _.size(dateSelect) ? '全不选' : '全选'}
              </Button>
            </Col>
            {isCrossDay && <Col span={8} style={{ textAlign: 'right' }}>
              <CheckableTag
                checked={showPre}
                onChange={(checked) => {
                  setShowPre(checked);
                  setUpdateCount(updateCount + 1);
                  if (checked) {
                    setPreSelect(PRE_LIST.map(k => k.key))
                  }
                }}
              >前仓</CheckableTag>
              <Divider type='vertical' />
              <CheckableTag
                checked={showTwoDay}
                onChange={(checked) => {
                  setShowTwoDay(checked);
                  setUpdateCount(updateCount + 1);
                }}
              >近两日</CheckableTag>
            </Col>}
          </Row>
          <Row>
            <Col span={showPre ? 18 : 24}>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={dateVal}
                onChange={onCheckChange}
              >
                <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
                  {dateSelect.map((date, i) => {
                    const noLimit = limitSelect.indexOf(date) !== -1 ? true : false;
                    return <Col span={showPre ? 4 : 3} key={date + i}>
                      <Checkbox value={date}>{noLimit ? <Tooltip title='当日无涨停票'>
                        <Text type='warning'>{date}</Text>
                      </Tooltip> : date}</Checkbox>
                    </Col>
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
            <Col span={showPre ? 1 : 0}><Divider type='vertical' style={{ height: '89%' }} /></Col>
            <Col span={showPre ? 5 : 0}>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={preSelect}
                onChange={onCheckPreChange}
              >
                <Row gutter={[8, 8]} style={{ paddingTop: 8, paddingBottom: 8 }}>
                  {preValues.map((p, i) => {
                    return <Col span={12} key={p.key + i}>
                      <Checkbox value={p.key}>
                        <Text style={{ color: _.get(PRE_LIST, `[${i}].other.itemStyle.color`, 'black') }}>{p.name}</Text>
                      </Checkbox>
                    </Col>
                  })}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        </div>}
        <Spin spinning={loading}>
          <div
            id="list_review_chart"
            style={{ width: '100%', height: 475 }}
          />
        </Spin>
      </Card>
    </div >
  )
}