import React, { useState } from 'react';
import { Col, Row, Space, List, Button, Input, message, InputNumber, Typography, Segmented, Cascader } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getBalanceFlow, changeTransfer } from '@/api/workbench';
import { getVerifPhoneCode, getUserPhone, postVerifyPhone } from '@/api/auto';
import { useMount, useUpdateEffect } from 'ahooks';
import { renderHugeFund } from '@/utils/utils';
import { isValidNumber } from '@/utils/utils2';
import PhoneVerify from '@/view/common/PhoneVerify';
import _ from 'lodash';

const { Text } = Typography;

const SecurityTransfer = ({
  options = [],
  subacc = [],
  userInfo = {},
  upcount = 0,
  onListUpdate
}) => {
  const [cascaderValue, setcascaderValue] = useState([]);
  const [listBottom, setlistBottom] = useState([]);
  const [sliderFundsConfirm, setsliderFundsConfirm] = useState(0); // 资金确认证券账户总资产
  const [valueBottom, setvalueBottom] = useState(0);
  const [enDisabled, setenDisabled] = useState(true);
  const [phoneVisible, setphoneVisible] = useState(false);
  const [vCount, setvCount] = useState(0);
  const [userPhone, setuserPhone] = useState(null);
  const [verifyBack, setverifyBack] = useState(0);
  const [casItems, setCasItems] = useState([]);

  useMount(() => {
    _getInfo();
    _getPhone();
  });

  useUpdateEffect(() => {
    if (_.size(cascaderValue) === 2) {
      handleChange(cascaderValue);
    };
  }, [upcount]);

  async function _getInfo(id) {
    const data2 = await getBalanceFlow(
      { 'securitiesAccountId': id ? id : null },
      findFcAccout(id) ? 'FC' : 'NOR'
    );
    setlistBottom(data2.data);
  }

  async function _getPhone() {
    const data = await getUserPhone({ params: { email: userInfo.email } });
    if (data.code === '200') {
      setuserPhone(data.data.phone);
    }
  }

  async function handleChange(value) {
    if (value.length === 0) {
      return;
    }
    if (value.length === 2) {
      let casVal = findCascaderValue(value);
      setvalueBottom(0);
      setsliderFundsConfirm(_.get(casVal, '[1].securitiesBalance', 0));
      setCasItems(casVal);
      _getInfo(_.get(casVal, '[1].securitiesAccountId', 0));
    } else {
      message.error('该产品下暂无证券账户！');
    }
  }

  async function _phoneVerify(valuesCode) {
    // console.log(111, valuesCode);
    const account = _.get(casItems, '[1]', {});
    const data = await postVerifyPhone({ account: valuesCode.phone, verifyCode: valuesCode.phoneCode });
    if (data.code === '200') {
      // setvaluesCode(valuesCode);
      setphoneVisible(false);
      if (verifyBack === 1) {
        let params = {
          securitiesAccountId: account.securitiesAccountId,
          balance: valueBottom,
          phone: valuesCode.phone,
          code: valuesCode.phoneCode,
          direction: 1
        }
        const data = await changeTransfer(params, findFcAccout(params.securitiesAccountId) ? 'FC' : 'NOR');
        if (data.code === '200') {
          message.success("入资成功");
          onListUpdate();
          setvalueBottom(0);
        } else {
          message.error(data.message);
        }
      } else if (verifyBack === 2) {
        let params = {
          securitiesAccountId: account.securitiesAccountId,
          balance: valueBottom,
          phone: valuesCode.phone,
          code: valuesCode.phoneCode,
          direction: 2
        }
        const data = await changeTransfer(params, findFcAccout(params.securitiesAccountId) ? 'FC' : 'NOR');
        if (data.code === '200') {
          message.success("出资成功");
          onListUpdate();
          setvalueBottom(0);
        } else {
          message.error(data.message);
        }
      }
    } else {
      message.error(data.message);
    }
  }

  async function submitIn() {
    if (valueBottom === 0) {
      return message.error('变动资金量不能为0');
    }
    let account = _.get(casItems, '[1]', {});
    if (_.get(account, 'centralizedLogin', 0) === 2) {
      _centraSubmit(1)
    } else {
      setverifyBack(1);
      setphoneVisible(true);
      setvCount(vCount + 1)
    }
  }

  async function submitOut() {
    if (valueBottom === 0) {
      return message.error('变动资金量不能为0');
    }
    let account = _.get(casItems, '[1]', {});
    if (_.get(account, 'centralizedLogin', 0) === 2) {
      _centraSubmit(2)
    } else {
      setverifyBack(2);
      setphoneVisible(true);
      setvCount(vCount + 1)
    }
  }

  async function _centraSubmit(type) {
    const account = _.get(casItems, '[1]', {});
    let params = {
      securitiesAccountId: account.securitiesAccountId,
      balance: valueBottom,
      direction: type
    }
    const data = await changeTransfer(params, findFcAccout(params.securitiesAccountId) ? 'FC' : 'NOR');
    if (data.code === '200') {
      message.success("入资成功");
      onListUpdate();
      setvalueBottom(0);
    } else {
      message.error(data.message);
    }
  };
  // 查找账户是反采账户
  function findFcAccout(id) {
    let sindex = _.findIndex(subacc, o => o.value === id);
    return sindex > -1 ? _.get(subacc, `[${sindex}].fc`) : false;
  };
  // 根据varray返回的层级数，保存每一层选择的对象，返回size=3的数据
  const valuekeys = ['productId', 'securitiesAccountId', 'subAccountId'];
  function findCascaderValue(varray) {
    const fastIndex = (array, key, val) => {
      let fidx = _.findIndex(array, o => o[key] === val);
      return array[fidx];
    };
    let final = [{}, {}, {}]
    let counter = 0;
    varray.map(v => {
      final[counter] = fastIndex(
        counter === 0 ? options : _.get(final, `[${counter - 1}].children`, []),
        valuekeys[counter],
        v
      );
      counter++;
    });
    return final;
  }

  const control_bar = { marginTop: 50, padding: '16px 12px 16px 12px', backgroundColor: '#f5f5f5', borderRadius: 4 };
  return (
    <>
      <Row style={{ marginBottom: 8 }} align='middle'>
        <Col span={14}>
          <div style={{ backgroundColor: '#f5f5f5', padding: 4, borderRadius: 4, width: 315 }}>
            <Cascader
              placeholder='账户选择'
              bordered={false}
              options={options}
              value={cascaderValue}
              onChange={(v) => {
                handleChange(v);
                setcascaderValue(v);
              }}
              style={{ width: 315 }}
            />
          </div>
        </Col>
        <Col span={10} style={{ textAlign: 'center' }}>
          {_.size(casItems) === 3
            ? <Segmented defaultValue={_.get(casItems, '[1].label')} options={[_.get(casItems, '[1].label'), '全部消息']} onChange={v => {
              _getInfo(v === '全部消息' ? null : _.get(casItems, '[1].value'));
            }} />
            : <Text>全部消息</Text>}
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ minHeight: 350 }}>
        <Col span={14} style={{ paddingLeft: 12, height: 330 }}>
          <div style={control_bar}>
            <Row align="middle">
              <Col span={6}><Text strong>证券账户总资金</Text></Col>
              <Col span={6}>
                <Text strong style={{ color: 'green', fontSize: 15 }}>
                  {_.round(sliderFundsConfirm, 2)}
                </Text>
              </Col>
              <Col span={2}></Col>
              <Col span={6}>
                <Text>{sliderFundsConfirm > 0 ? renderHugeFund(sliderFundsConfirm) : ''}</Text>
              </Col>
            </Row>
            <Row style={{ marginTop: 40 }} align='middle'>
              <Col span={6}><Text>证券账户资金变动</Text></Col>
              <Col span={10}>
                <Space>
                  <Button type="primary"
                    shape="circle"
                    icon={<MinusOutlined />}
                    onClick={() => {
                      setvalueBottom(valueBottom - 10000);
                      if (valueBottom <= 10000) {
                        setenDisabled(true);
                      }
                    }}
                    disabled={enDisabled}
                  />
                  <InputNumber min={0}
                    step={10000}
                    value={valueBottom}
                    onChange={value => {
                      setvalueBottom(value);
                      if (value >= 10000) {
                        setenDisabled(false)
                      } else { setenDisabled(true) }
                    }
                    }
                  />
                  <Text>{valueBottom > 0 ? renderHugeFund(valueBottom) : ''}</Text>
                  <Button type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setvalueBottom(valueBottom + 10000);
                      if (valueBottom >= 0) {
                        setenDisabled(false);
                      }
                    }}
                  />
                </Space>
              </Col>
              <Col span={6}>
                <Space>
                  <Button
                    type='primary'
                    danger
                    disabled={!isValidNumber(valueBottom)}
                    style={{ borderRadius: '6px' }}
                    onClick={submitIn}
                  >入资</Button>
                  <Button
                    type='primary'
                    disabled={!isValidNumber(valueBottom)}
                    style={{ borderRadius: '6px' }}
                    onClick={submitOut}
                  >出资</Button>
                </Space>
              </Col>
              <PhoneVerify
                visible={phoneVisible}
                vCount={vCount}
                phoneHandleCancel={() => setphoneVisible(false)}
                gobackValues={_phoneVerify}
                getCode={getVerifPhoneCode}
                userPhone={userPhone}
              />
            </Row>
          </div>
        </Col>
        <Col span={10}>
          <List
            itemLayout="horizontal"
            dataSource={listBottom}
            size='small'
            pagination={{
              defaultPageSize: 5,
              hideOnSinglePage: true,
            }}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  description={
                    <Input.TextArea
                      bordered={false}
                      value={item}
                      autoSize={true}
                    />
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  )
}

export default SecurityTransfer;