import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Select, Row, Col, Card, Table, Upload, message, Input, Spin } from 'antd';
import { addStocks, getCompanyAttribute, getPlate, filterStock,showStockPoolBlack,updStockPoolBlack,showStockInfo,showETF } from '@/api/stock';
import { UploadOutlined } from '@ant-design/icons';

export default function Screen(props) {
    const [visible, setvisible] = useState(false);
    const [spinning, setspinning] = useState(false);
    const [preseVisible, setpreseVisible] = useState(false);
    const [isModalVisible, setisModalVisible] = useState(false);
    const [stockPoolName, setstockPoolName] = useState('');
    const [listData, setlistData] = useState([]);
    const [addListData, setaddListData] = useState([]);
    const [blackListData, setblackListData] = useState([]);
    const [companyAttributes, setcompanyAttributes] = useState([]);
    const [plates, setplates] = useState([]);
    const [num, setnum] = useState(0);
    const [value, setvalue] = useState('');
    const { Search } = Input;
    
    const [form] = Form.useForm();
    const columns = [
        {
            title: '股票名称',
            dataIndex: 'stockName',
            filterDropdown: () => {
                return (
                  <Search placeholder="请输入股票名称" style={{width:150}} allowClear enterButton onSearch={onSearch}/>
                );
            },
        },
        {
            title: '股票代码',
            dataIndex: 'stockCode',
        },
        {
            title: '年化波动率',
            dataIndex: 'annualVolatility',
            sorter: (a, b) => Number(a.annualVolatility) - Number(b.annualVolatility),
        },
        {
            title: '近一周日均换手率',
            dataIndex: 'turnoverRateWeek',
            sorter: (a, b) => Number(a.turnoverRateWeek) - Number(b.turnoverRateWeek),
        },
        {
            title: '近一周成交量',
            dataIndex: 'turnoverDailyWeek',
            sorter: (a, b) => Number(a.turnoverDailyWeek) - Number(b.turnoverDailyWeek),
        },
        {
            title: '近一周涨跌幅',
            dataIndex: 'priceLimitWeek',
            sorter: (a, b) => Number(a.priceLimitWeek) - Number(b.priceLimitWeek),
        },
        {
            title: '操作',
            render: (text, record, index) => (
                <>
                    {
                        record.isAdd ?
                            <Button type="primary" shape="round" danger onClick={() => _actFilterStocks(num+index)}>取消</Button>
                            :
                            <Button type="primary" shape="round" onClick={() => _actFilterStocks(num+index)}>添加</Button>
                    }
                </>
            )
        },
    ]

    const addColumns = [
        {
            title: '股票名称',
            dataIndex: 'stockName',
        },
        {
            title: '股票代码',
            dataIndex: 'stockCode',
        },
        {
            title: '年化波动率',
            dataIndex: 'annualVolatility'
        },
        {
            title: '近一周日均换手率',
            dataIndex: 'turnoverRateWeek'
        },
        {
            title: '近一周成交量',
            dataIndex: 'turnoverDailyWeek'
        },
        {
            title: '近一周涨跌幅',
            dataIndex: 'priceLimitWeek'
        }
    ]
    const blackColumns = [
        {
            title: '股票名称',
            dataIndex: 'stockName',
        },
        {
            title: '股票代码',
            dataIndex: 'stockCode',
        },
        {
            title: '操作',
            render: (text, record, index) => (
                <>
                    <Button type="primary" onClick={() => {
                        blackListData.splice(index,1);
                        const data = updStockPoolBlack({ stockPoolId: props.stockPoolInfos.stockPoolId,stockPoolBlackLists:blackListData });
                        if (data.code === '200') {
                            message.success('删除成功');
                            setblackListData(blackListData);
                        } else {
                            message.error(data.message);
                        }
                    }}>删除</Button>
                </>
            )
        },
    ]


    useEffect(() => {
        async function _get() {
            const data = await getCompanyAttribute();
            const data1 = await getPlate();
            // console.log('企业性质', data);
            // console.log('板块', data1);
            setcompanyAttributes(data.data);
            setplates(data1.data);
        }
        _get();
    }, [])

    async function onSearch(value){
        if(value === ''){
            const values = await form.validateFields();
            values.stockPoolId = props.stockPoolInfos.stockPoolId;
            setspinning(true);
            const data = await filterStock(values);
            if (data.code === '200') {
                setlistData(data.data);
            } else {
                message.error(data.message);
            }
            setspinning(false);
        }else{
            var reg = new RegExp(value);
            var arr = [];
            setspinning(true);
            if(listData.length !== 0){
                listData.map(item => {
                    if(reg.test(item.stockName)){
                        arr.push(item);
                    }
                    return item;
                })
                setlistData(arr);
            }
            setspinning(false);
        }
    }
    function handleCancel() {
        setvisible(false);
        setaddListData([]);
        setlistData([]);
        form.resetFields();
    }
    // 重置
    function _reset() {
        // form.resetFields();
        form.setFieldsValue({
            indexCode: '',
            plate: '',
            appearOnMarketDays: '',
            companyAttribute: '',
            st: '',
            annualizedVolatilityRank: '',
            turnoverRateRank: '',
            pctRank: '',
            volumeRank: '',
            topDays: ''
        });
    }
    // 股票筛选
    async function _retrieval() { 
        const values = await form.validateFields();
        values.stockPoolId = props.stockPoolInfos.stockPoolId;
        setspinning(true);
        const data = await filterStock(values);
        if (data.code === '200') {
            setlistData(data.data);
        } else {
            message.error(data.message);
        }
        setspinning(false);
    }
    // 操作进股票池保存的列表
    function _actFilterStocks(index) {
        const arr = JSON.parse(JSON.stringify(listData));
        if(!arr[index].isAdd){
            arr[index].isAdd = true;
        }else{
            arr[index].isAdd = !arr[index].isAdd;
        }
        setlistData(arr);
    }
    // 点击确认保存股票池 
    function _holdStockPool() {
        const arr = JSON.parse(JSON.stringify(listData));
        let newArr = [];
        for (let item of arr) {
            if (item.isAdd) {
                newArr.push(item);
            }
        }
        // console.log(111, newArr);
        if (newArr.length === 0) return message.error('请添加要保存的股票');
        setaddListData(newArr);
        setpreseVisible(true);
    }
    const excelProps = {
        name: 'file',
        action: global.api + '/stock/importExcel',
        // data: {stockPoolId: props.stockPoolInfos.stockPoolId},
        headers: {
            authorization: localStorage.getItem('token'),
        },
        onChange(info) {
            setspinning(true);
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
                setspinning(false);
            }
            if (info.file.status === 'done') {
                if (info.file.response.code === '200') {
                    message.success(`${info.file.name} 上传完成`);
                    setlistData(info.file.response.data);
                } else {

                    message.error(info.file.response.message);
                }
                setspinning(false);
            } else if (info.file.status === 'error') {
                setspinning(false);
                message.error(`${info.file.name} 上传失败`);
            }
        },
    };
    async function _preseName() {
        // console.log(111, stockPoolName, listData);
        const data = await addStocks({ stockPoolId: props.stockPoolInfos.stockPoolId, stockPoolStocks: addListData });
        if (data.code === '200') {
            message.success('添加成功');
            setpreseVisible(false);
            setstockPoolName('');
            handleCancel();
            props._goBack();
            props._goStock();
        } else {
            message.error(data.message);
        }
    }
    async function handleOk() {
        setisModalVisible(false);
        const data = await updStockPoolBlack({ stockPoolId: props.stockPoolInfos.stockPoolId,stockPoolBlackLists:blackListData });
        if (data.code === '200') {
            message.success('添加成功');
        } else {
            message.error(data.message);
        }
        setstockPoolName('');
    }
    function _open() {
        if (!props.stockPoolInfos) {
            return message.error('请选择股票池');
        }
        setvisible(true);
    }
    async function showModal() {
        const data = await showStockPoolBlack({ stockPoolId: props.stockPoolInfos.stockPoolId});
        if (data.code === '200') {
            setblackListData(data.data[0]);
            setstockPoolName('');
        } else {
        }
        setisModalVisible(true);
    }
    function blackHandleCancel() {
        setisModalVisible(false);
        setblackListData([]);
    }
    async function seeETF(){
        const data = await showETF({stockPoolId:props.stockPoolInfos.stockPoolId,search:value});
        if (data.code === '200') {
            setlistData(data.data);
        } else {
            message.error(data.message);
        }
    }
    async function black() {
        const data = await showStockInfo({ stockInfo: stockPoolName});
        if(!data){
            message.error("请输入正确的股票名或代码");
        }
        // var bbb= JSON.parse(JSON.stringify(data).replace(/name/g,"stockName"));
        // console.log(bbb)
        const newBlack = {}
        newBlack.stockName = data.name;
        newBlack.stockCode = data.tsCode;
        newBlack.symbol = data.symbol;
        blackListData.push(newBlack);
    }
    return (
        <>
            <Button
                size="large"
                type="primary"
                onClick={_open}
                disabled={props.ban}
            >添加股票</Button>

            <Modal
                title="股票池名称"
                okText="保存"
                visible={preseVisible}
                width={800}
                onCancel={() => { setpreseVisible(false); setstockPoolName('') }}
                onOk={_preseName}
            >
                {/* <Input placeholder="请输入要保存的股票池名称" value={stockPoolName} onChange={e => setstockPoolName(e.target.value)} /> */}

                <Table
                    rowKey="id"
                    style={{ marginTop: 20 }}
                    dataSource={addListData}
                    columns={addColumns}
                    bordered
                    pagination={{
                        defaultPageSize: 30,
                    }}
                />

            </Modal>
            <Modal title="设置黑名单" okText="保存" visible={isModalVisible} onOk={handleOk} onCancel={blackHandleCancel}>
                <Input placeholder="请输入要加入黑名单的股票名称或代码" value={stockPoolName} onChange={e => setstockPoolName(e.target.value)} onBlur={black}/>
                <Table
                    rowKey="id"
                    style={{ marginTop: 20 }}
                    dataSource={blackListData}
                    columns={blackColumns}
                    bordered
                    pagination={{
                        defaultPageSize: 30,
                    }}
                />
            </Modal>
            <Modal
                title="股票池筛选"
                okText="保存"
                width={1000}
                visible={visible}
                onCancel={handleCancel}
                onOk={_holdStockPool}
            >
                <Spin spinning={spinning}>
                    <Form
                        initialValues={{}}
                        form={form}
                    >
                        <Row>
                            <Card
                                title="股票池设置"
                                bordered={false}
                                style={{ width: '100%' }}
                                extra={
                                    <Row justify="end">
                                        <Button type="primary" onClick={showModal}>设置黑名单</Button>
                                        <Upload {...excelProps}>
                                            <Button icon={<UploadOutlined />} style={{ marginLeft: 10 }}>上传Excel</Button>
                                        </Upload>
                                    </Row>
                                }
                            >
                                <Row gutter={20}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="指数代码"
                                            name="indexCode"
                                            rules={[{ required: true, message: '请选择指数代码' }]}
                                            initialValue="全部"
                                        >
                                            <Select placeholder="沪深300">
                                                <Select.Option value="沪深300">沪深300</Select.Option>
                                                <Select.Option value="中证500">中证500</Select.Option>
                                                <Select.Option value="中证1000">中证1000</Select.Option>
                                                <Select.Option value="上证50">上证50</Select.Option>
                                                <Select.Option value="科创50">科创50</Select.Option>
                                                <Select.Option value="全部">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="板块"
                                            name="plate"
                                            rules={[{ required: true, message: '请选择板块!' }]}
                                            initialValue="全部"
                                        >
                                            <Select placeholder="全部">
                                                {
                                                    plates.length !== 0 && plates.map((item, index) => (
                                                        <Select.Option key={index} value={item}>{item}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="上市时间"
                                            name="appearOnMarketDays"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择上市时间' }]}
                                        >
                                            <Select placeholder="全部">
                                                <Select.Option value="THREE_MONTH">三个月以上</Select.Option>
                                                <Select.Option value="ONE_YEAR">一年以上</Select.Option>
                                                <Select.Option value="THREE_YEAR">三年以上</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="企业性质"
                                            name="companyAttribute"
                                            initialValue="全部"
                                            rules={[{ required: true, message: '请选择企业性质!' }]}
                                        >
                                            <Select placeholder="全部">
                                                {
                                                    companyAttributes.length !== 0 && companyAttributes.map((item, index) => (
                                                        <Select.Option key={index} value={item}>{item}</Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            label="是否st"
                                            name="st"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择是否st!' }]}
                                        >
                                            <Select placeholder="非st股">
                                                <Select.Option value="ST">st股</Select.Option>
                                                <Select.Option value="NST">非st股</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Card>

                            <Card title="选股指标" bordered={false} style={{ width: '100%' }}>
                                <Row gutter={[20, 20]}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="年化波动率"
                                            name="annualizedVolatilityRank"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择年化波动率' }]}
                                        >
                                            <Select placeholder="年化波动率前10%">
                                                <Select.Option value="TOP10">年化波动率前10%</Select.Option>
                                                <Select.Option value="TOP10_20">年化波动率前10%-前20%</Select.Option>
                                                <Select.Option value="TOP20_30">年化波动率前20%-前30%</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={10}>
                                        <Form.Item
                                            label="近一周日均换手率"
                                            name="turnoverRateRank"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择近一周日均换手率' }]}
                                        >
                                            <Select placeholder="近一周日均换手率前10%">
                                                <Select.Option value="TOP10">近一周日均换手率前10%</Select.Option>
                                                <Select.Option value="TOP10_20">近一周日均换手率前10%-前20%</Select.Option>
                                                <Select.Option value="TOP20_30">近一周日均换手率前20%-前30%</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={9}>
                                        <Form.Item
                                            label="近一周上涨/下跌率"
                                            name="pctRank"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择近一周上涨/下跌率' }]}
                                        >
                                            <Select placeholder="近一周上涨前10%">
                                                <Select.Option value="UP_TOP10">近一周上涨前10%</Select.Option>
                                                <Select.Option value="DOWN_TOP10">近一周下跌前10%</Select.Option>
                                                <Select.Option value="UP_TOP10_20">近一周上涨前10%-前20%</Select.Option>
                                                <Select.Option value="DOWN_TOP10_20">近一周下跌前10%-前20%</Select.Option>
                                                <Select.Option value="UP_TOP20_30">近一周上涨前20%-前30%</Select.Option>
                                                <Select.Option value="DOWN_TOP20_30">近一周下跌前20%-前30%</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
        
                                    <Col span={10}>
                                        <Form.Item
                                            label="近一周日均成交量"
                                            name="volumeRank"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择近一周日均成交量' }]}
                                        >
                                            <Select placeholder="近一周日均成交量前10">
                                                <Select.Option value="TOP10">近一周日均成交量前10%</Select.Option>
                                                <Select.Option value="TOP10_20">近一周日均成交量前10%-前20%</Select.Option>
                                                <Select.Option value="TOP20_30">近一周日均成交量前20%-前30%</Select.Option>
                                                <Select.Option value="ALL">全部</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={9}>
                                        <Form.Item
                                            label="出现在龙虎榜时间"
                                            name="topDays"
                                            initialValue='ALL'
                                            rules={[{ required: true, message: '请选择出现在龙虎榜时间' }]}
                                        >
                                            <Select placeholder="一周内出现在龙虎榜">
                                                <Select.Option value="ONE_WEEK">一周内出现在龙虎榜</Select.Option>
                                                <Select.Option value="ONE_MONTH">一月内出现在龙虎榜</Select.Option>
                                                <Select.Option value="THREE_MONTH">三月内出现在龙虎榜</Select.Option>
                                                <Select.Option value="NONE">未出现在龙虎榜</Select.Option>
                                                <Select.Option value="ALL">全部出现在龙虎榜</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="end">
                                    <Button type="primary" onClick={_reset}>重置</Button>
                                    <Button type="primary" style={{ marginLeft: 20 }} onClick={_retrieval} >检索</Button>
                                </Row>
                                <Row>
                                    <Input style={{width:150,marginRight:10}} placeholder='请输入关键字' allowClear onChange={e=>{setvalue(e.target.value)}}/>
                                    <Button type="primary" onClick={seeETF}>查看ETF</Button>
                                </Row>
                            </Card>
                        </Row>
                    </Form>

                    <Table
                        rowKey="id"
                        style={{ marginTop: 10 }}
                        dataSource={listData}
                        columns={columns}
                        bordered
                        pagination={{
                            defaultPageSize: 10,
                            onChange: (current, pageSize) => {setnum((current-1)*pageSize)}
                        }}
                    />
                </Spin>

            </Modal>
        </>
    )
}