import React, { useState } from 'react';
import { PageHeader, Card, Col, Row, Button, Table, message, Checkbox, Modal } from 'antd';
import { RedoOutlined, ExclamationCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useUnmount, useMount, useReactive, useInterval } from 'ahooks';
import { getfundManagerAssets, restProductShow, updateProductShow } from '@/api/workbench';
import { visShowAssetsAndProducts } from '@/api/reviewApi';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { autoCol, singleColumns, replaceMoney, colWidth, HOME_PRODUCT_NUMBER } from '@/utils/utils';
import { RateText, saveStorage, getStorage, isValidString } from '@/utils/utils2';
import StatisticHeader from '@/utils/StatisticHeader';
import moment from 'moment';
import _ from 'lodash';

const { confirm } = Modal;
const INTERVAL_NUM = 60000;
const ADD_KEYS = ['totalProduct', 'totalAssets', 'totalRevenue', 'dailyRevenue', 'freeBalance'];
const RATE_KEYS = ['totalRevenueRate', 'dailyRevenueRate'];
const sub_storage_key = 'sub_acc';
const renderColumns = (keyArray = [], typeArray = []) => {
  let rateProps = {}
  if (_.includes(typeArray, 'noPrefix')) {
    rateProps.noPrefix = true;
  }
  if (_.includes(typeArray, 'normal')) {
    rateProps.size = 'normal';
    rateProps.color = 'black';
  }
  return {
    ...singleColumns(keyArray),
    render: (text, record) => {
      return <RateText rate={text} rType='replace' {...rateProps} />
    }
  }
}

export default function SubAccount(props) {
  const mobxStore = useLocalObservable(() => store);
  const [listData] = useState(JSON.parse(JSON.stringify(mobxStore.assetsAlls)));
  const [filterList, setFilterList] = useState(listData);
  const [assetss] = useState(props.location.state.assetss);
  const [isVisitor] = useState(props.location.state.visitor);
  const [tradeTime] = useState(mobxStore.inTrading === 'trading' ? true : false);
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  const [allassets, setallassets] = useState({});
  const [isHomeArray, setIsHomeArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inter, setInter] = useState(undefined);
  const pageState = useReactive({
    page: 1, pageSize: 10, total: 0
  });
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', ''));

  const columns = [
    {
      ...singleColumns(['产品名称', 'productName']),
      sorter: (a, b) => a.productId - b.productId,
      sortDirections: ['descend', 'ascend'],
    },
    singleColumns(['产品总资产', 'totalAssets']),
    renderColumns(['产品总收益', 'totalRevenue'], ['noPrefix']),
    renderColumns(['产品总收益率', 'totalRevenueRate']),
    renderColumns(['产品日收益', 'dailyRevenue'], ['noPrefix']),
    renderColumns(['产品日收益率', 'dailyRevenueRate']),
    renderColumns(['总市值', 'positionsAssets'], ['noPrefix', 'normal']),
    renderColumns(['可用资金', 'productBalance'], ['noPrefix', 'normal']),
    {
      ...singleColumns(['显示在首页', 'isHome']),
      render: (text, record) => {
        const pid = _.get(record, 'productId');
        const isCheck = isHomeArray.indexOf(pid) !== -1 ? true : false
        return <Checkbox
          checked={isCheck}
          disabled={_.get(record, 'centralizedLogin', 0) === 2}
          onChange={(e) => onCheckChange(e, pid)}
        />
      }
    },
    {
      ...singleColumns(['操作', 'option']),
      render: (text, record) => <Button type="link" icon={<RightCircleOutlined />}
        onClick={() => _goProductDetails(record)}
      />
    },
  ]

  useMount(() => {
    // 获取存储的分页信息
    const storagePage = getStorage(sub_storage_key);
    if (isValidString(storagePage)) {
      pageState.page = parseInt(storagePage);
    }

    _getAsset();
    setallassets(assetss);

    if (tradeTime && document.getElementById("subAccount") !== null) {
      setInter(INTERVAL_NUM);
    }
  });

  useUnmount(() => {
    setInter(undefined);
  });

  useInterval(() => {
    _getAsset(false);
  }, inter);
  // 获取资产和账户接口；增加首页相同的反采账户
  async function _getAsset(isLoading) {
    let params = {
      isShow: false,
      pageNum: pageState.page,
      pageSize: pageState.pageSize
    }
    if (isLoading) {
      setLoading(true);
    }
    const res = isVisitor ? await visShowAssetsAndProducts() : await getfundManagerAssets(params);
    const resFc = !isVisitor && isFcLogin ? await getfundManagerAssets({ ...params, 'isShow': false }, 'FC') : {};
    if (_.get(res, 'code', '') === '200') {
      let newAssets = {
        'results': _.concat(_.get(res, 'data.currentProductResultList', []), _.get(resFc, 'data.currentProductResultList', [])),
        'infoList': _.concat(_.get(res, 'data.pageInfo.list', []), _.get(resFc, 'data.pageInfo.list', []))
      };
      // 累计集中\反采账户，计算总资产；【tips】返回资产带",""的字符串，需转换数值进行计算
      ADD_KEYS.map(key => {
        const addNum = _.get(replaceMoney(_.get(res, `data.${key}`)), 'number');
        const addFc = _.get(replaceMoney(_.get(resFc, `data.${key}`)), 'number');
        _.set(newAssets, key, _.round(_.add(addNum, addFc), 2));
      });

      let newList = [];
      newAssets.results.map(n => {
        let findex = _.findIndex(newAssets.infoList, o => o.id === n.productId);
        if (findex !== -1) {
          let items = _.assign(n, {
            'times': moment(_.get(newAssets.infoList, `[${findex}].createTime`)).format('YYYY-MM-DD')
          });
          // 排序用的float值，从字符串资产进行处理
          RATE_KEYS.map(key => {
            _.set(items, key + '_float', _.round(parseFloat(_.get(items, key)), 2));
            _.set(items, key, _.round(parseFloat(_.get(items, key)), 2) + '%');
          });
          newList.push(items);
        }
      });
      // console.log('newList', newList)
      setallassets(newAssets);
      setFilterList(isVisitor ? newAssets.results : newList);
      setIsHomeArray(_.chain(newAssets.infoList).filter(o => o.isShow === 1).map('id').value());
      pageState.total = _.get(res, 'data.pageInfo.total', 0);
    }
    setLoading(false);
  }

  async function _restProductShow() {
    const res = await restProductShow();
    if (_.get(res, 'code', '') === '200') {
      message.success(_.get(res, 'message', '重置成功！'));
      setIsHomeArray([]);
    }
  }

  async function _updateProductShow(pms) {
    const res = await updateProductShow(pms);
    if (_.get(res, 'code', '') !== '200') {
      message.warning(_.get(res, 'message', '更新失败!'));
    }
  }

  function _goProductDetails(item) {
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    props.history.push({
      pathname: '/home/ProductDetails',
      state: { paramsData: item }
    });
  }
  // 增加或删除首页推荐显示卡
  function onCheckChange(e, pid) {
    if (_.size(isHomeArray) > (HOME_PRODUCT_NUMBER - 2) && e.target.checked === true) {
      message.info('已达到首页显示推荐大小！')
      return;
    }
    _updateProductShow({
      productId: pid,
      isShow: e.target.checked
    });
    if (e.target.checked) {
      setIsHomeArray(_.uniq([...isHomeArray, pid]));
    } else {
      let temp = _.cloneDeep(isHomeArray);
      _.pullAll(temp, [pid]);
      setIsHomeArray(temp);
    }
  }

  const renderHeaderItem = (title, vkey) => {
    return <Col {...autoCol([8])}>
      <div className='colorCardBasic colorCardFund'>
        <StatisticHeader title={title} value={_.get(allassets, vkey, '')} isColor />
      </div>
    </Col>
  };

  const resetBtn = <Button icon={<RedoOutlined />} onClick={() => {
    confirm({
      title: '重置产品显示?',
      icon: <ExclamationCircleOutlined />,
      content: '是否重置首页产品卡片的显示?',
      onOk: () => _restProductShow(),
      onCancel() { console.log('Cancel'); },
    });
  }}>
    重置产品显示
  </Button>;

  return (
    <>
      <PageHeader
        title={'全部产品'}
        onBack={() => {
          saveStorage(sub_storage_key, '1'); // 只有点击返回将分页重置为 1 ;
          props.history?.goBack();
        }}
        style={{ backgroundColor: 'white' }}
        extra={[resetBtn]}
      ></PageHeader>

      <div className="contentView" id='subAccount' style={{ padding: 12 }}>
        <Row gutter={8}>
          {renderHeaderItem('总资产', 'totalAssets')}
          {renderHeaderItem('总收益', 'totalRevenue')}
          {renderHeaderItem('总资产', 'dailyRevenue')}
        </Row>
        <br />
        <Card bodyStyle={{ padding: 12, minHeight: 588 }}>
          <Table
            rowKey="productId"
            dataSource={filterList}
            columns={columns}
            bordered
            size='small'
            scroll={{ x: colWidth(columns) }}
            loading={loading}
            pagination={{
              showSizeChanger: false,
              current: pageState.page,
              pageSize: pageState.pageSize,
              total: pageState.total,
              size: 'default',
              position: ['topLeft'],
              onChange: (e) => {
                pageState.page = e;
                saveStorage(sub_storage_key, String(e));
                _getAsset(true);
              },
            }}
          />
        </Card>
        <br />
      </div>
    </>
  )
}