import React, { useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import {
  Modal, Form, Input, InputNumber, Checkbox, Typography, message,
  Row, Col
} from 'antd';
import { INDEX_CODE_VALUE, INDEX_OPTIONS } from '@/utils/indexCode';
import { isValidObj } from '@/utils/utils2';
import _ from 'lodash';

const { Text } = Typography;
const FORM_OBJ = {
  'compare': [
    { 'type': 'input', 'key': 'name', 'label': '名称' },
  ],
  'idx': [
    { 'type': 'input', 'key': 'name', 'label': '名称' },
    { 'type': 'checkbox', 'key': 'index', 'label': '指数', 'option': INDEX_OPTIONS },
  ],
}
// 配置添加修改表单
const AddForm = ({
  pageKey = '',
  visible = false,
  name = '',
  editVal = {},

  oncancel,
  onSubmit
}) => {
  const [modalForm] = Form.useForm();
  const [formType, setFormType] = useState('new');

  useUpdateEffect(() => {
    if (visible === true && isValidObj(editVal)) {
      if (pageKey === 'idx') {
        let edit = {
          name: _.get(editVal, 'name'),
        };
        let codeArr = [];
        _.get(editVal, 'index', []).map(n => {
          codeArr.push(n.code);
          _.set(edit, n.code, n.rate);
        });
        _.set(edit, 'index', codeArr);
        // console.log('编辑', edit);
        modalForm.setFieldsValue(edit);
      };
      setFormType('edit');
    };
    if (visible === false) {
      modalForm.resetFields();
      setFormType('new');
    }
  }, [visible]);

  async function handleOk() {
    const values = await modalForm.validateFields();
    // console.log(values);
    let final = { ...values };
    let valid = true;
    if (pageKey === 'idx') {
      let indexCount = 0; let indexArray = [];
      _.get(values, 'index').map(code => {
        indexCount = indexCount + values[code];
        indexArray.push({ code, 'rate': values[code] });
      });
      if (indexCount !== 1) {
        message.info('请填写正确的比例！指数比例值累加等于1！');
        valid = false
      };
      final = { 'name': values.name, 'index': indexArray };
      if (formType === 'edit') {
        _.set(final, 'id', editVal.id);
      }
    };
    if (!valid) {
      return;
    }
    onSubmit(final, formType);
  }

  function handleCancel() {
    modalForm.resetFields();
    oncancel();
  };
  // 渲染form的组件
  function renderFormItem(item) {
    const itemType = _.get(item, 'type', '');
    if (itemType === 'input') {
      return <Input />
    } else if (itemType === 'checkbox') {
      return <Checkbox.Group>
        <Row>
          {item.option.map(itm => {
            return <Col span={8} key={itm.value}>
              <Checkbox value={itm.value}>
                {itm.label}
              </Checkbox>
            </Col>
          })}
        </Row>
      </Checkbox.Group>
    }
  }

  const newFormItem = FORM_OBJ[pageKey];

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleCancel}
      width={688}
      maskClosable={false}
      onOk={handleOk}
    >
      <div style={{ marginBottom: 24 }}>
        <Text strong style={{ fontSize: 15 }}>添加{name}</Text>
      </div>

      <Form
        form={modalForm}
        name='config_add_form'
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        autoComplete="off"
        size='small'
      >
        {newFormItem.map(item => {
          return <Form.Item
            key={item.key}
            name={item.key}
            label={item.label}
            rules={[{ required: true, message: `请填写${item.label}` }]}
          >
            {renderFormItem(item)}
          </Form.Item>
        })}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.index !== currentValues.index}
        >
          {({ getFieldValue }) =>
            _.size(getFieldValue('index')) > 0 ? getFieldValue('index').map(code => {
              return <Form.Item
                label={INDEX_CODE_VALUE[code]}
                name={code}
                key={code}
                rules={[{ required: true, message: `请输入比例` }]}
              >
                <InputNumber min={0} max={1} step={0.1}
                  placeholder="请输入比例；0.1-0.9；"
                  style={{ width: 200 }}
                />
              </Form.Item>
            }) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddForm;