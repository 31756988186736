import React, { useEffect, useState } from 'react';
import { Card, Row, Button, List, DatePicker,Select, message,Input,Form } from 'antd';
import { showTraderTrends,getAllProduct,fundManagerAnalysis } from '@/api/workbench';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import ExportJsonExcel from 'js-export-excel';
import EchartsInfo3 from './Components/EchartsInfo3';

export default withRouter(function AssetsShow() {
    const [list, setlist] = useState([]);
    const [transList, settransList] = useState([]);
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [beginData, setbeginData] = useState('');
    const [endData, setendData] = useState('');
    const [traderName, settraderName] = useState('');
    const { Search } = Input;
    const [form] = Form.useForm();
    const [chartsData1, setchartsData1] = useState([]);
    const [chartsXData1, setchartsXData1] = useState([]);
    const [chartsData2, setchartsData2] = useState([]);
    const [chartsXData2, setchartsXData2] = useState([]);
    const [chartsData3, setchartsData3] = useState([]);
    const [chartsXData3, setchartsXData3] = useState([]);
    const [chartsData4, setchartsData4] = useState([]);
    const [chartsXData4, setchartsXData4] = useState([]);
    const [chartsData5, setchartsData5] = useState([]);
    const [chartsXData5, setchartsXData5] = useState([]);

    useEffect(() => {
        async function stockAndTrader(){
            const data1 = await fundManagerAnalysis({type:2,startTime:moment().format('YYYY-MM-DD'),endTime:moment().format('YYYY-MM-DD'),productId:''});
            if(data1.code === '200'){
                let arr = [];
                let brr = [];
                const map = new Map(Object.entries(data1.data));
                let arrayObj = Array.from(map);
                arrayObj.sort(function(a,b){
                    if(a[1] === b[1]){
                        return a[0].localeCompare(b[0]);
                    }
                    return b[1]-a[1]
                });
                arrayObj.map((item,index) => {
                    if(index < 20){
                        arr.push(item[0]);
                        brr.push(item[1]);
                        return item;
                    }
                    return item;
                })
                setchartsXData1(arr);
                setchartsData1(brr);
            }
            const data2 = await fundManagerAnalysis({type:1,startTime:moment().format('YYYY-MM-DD'),endTime:moment().format('YYYY-MM-DD'),productId:null});
            if(data2.code === '200'){
                let arr = [];
                let brr = [];
                const map = new Map(Object.entries(data2.data));
                let arrayObj = Array.from(map);
                arrayObj.sort(function(a,b){
                    if(a[1] === b[1]){
                        return a[0].localeCompare(b[0]);
                    }
                    return b[1]-a[1];
                });
                arrayObj.map((item,index) => {
                    if(index < 20){
                        arr.push(item[0]);
                        brr.push(item[1]);
                        return item;
                    }
                    return item;
                })
                setchartsXData2(arr);
                setchartsData2(brr);
            }
            const data3 = await fundManagerAnalysis({type:3,designatedDate:moment().format('YYYY-MM-DD'),productId:null});
            if(data3.code === '200'){
                let arr = [];
                let brr = [];
                let result = data3.data;
                result.sort(function(a,b){
                    if(a.dailyRevenueRate === b.dailyRevenueRate){
                        return a.subAccountName.localeCompare(b.subAccountName);
                    }
                    return b.dailyRevenueRate-a.dailyRevenueRate;
                });
                result.map((item,index) => {
                    if(index < 20){
                        arr.push(item.subAccountName);
                        brr.push(item.dailyRevenueRate.toFixed(2));
                        return item;
                    }
                    return item;
                })
                setchartsXData3(arr);
                setchartsData3(brr);
            }
            const data4 = await fundManagerAnalysis({type:4,startTime:moment().format('YYYY-MM-DD'),endTime:moment().format('YYYY-MM-DD'),productId:null});
            if(data4.code === '200'){
                let arr = [];
                let brr = [];
                let result = data4.data;
                result.sort(function(a,b){
                    if(a.totalRevenueRate === b.totalRevenueRate){
                        return a.subAccountName.localeCompare(b.subAccountName);
                    }
                    return b.totalRevenueRate-a.totalRevenueRate;
                });
                result.map((item,index) => {
                    if(index < 20){
                        arr.push(item.subAccountName);
                        brr.push(item.totalRevenueRate.toFixed(2));
                        return item;
                    }
                    return item;
                })
                setchartsXData4(arr);
                setchartsData4(brr);
            }
            const data5 = await fundManagerAnalysis({type:5,startTime:moment().format('YYYY-MM-DD'),endTime:moment().format('YYYY-MM-DD'),productId:null});
            if(data5.code === '200'){
                let arr = [];
                let brr = [];
                const map = new Map(Object.entries(data5.data));
                let arrayObj = Array.from(map);
                arrayObj.sort(function(a,b){
                    if(a[1] === b[1]){
                        return a[0].localeCompare(b[0]);
                    }
                    return b[1]-a[1]
                });
                arrayObj.map((item,index) => {
                    if(index < 20){
                        arr.push(item[0]);
                        brr.push(item[1]);
                        return item;
                    }
                    return item;
                })
                setchartsXData5(arr);
                setchartsData5(brr);
            }
        }
        async function showTrends() {
            let beginTime = moment().format('YYYY-MM-DD');
            let endTime = moment().format('YYYY-MM-DD');
            setbeginData(beginTime);
            setendData(endTime);
            let params = {
                beginDate: beginTime,
                endDate: endTime,
            }
            const data = await showTraderTrends(params);
            if(data.code === '200'){
                setlist(data.data);
            }else{
                message.error(data.message);
            }
        }
        async function _gets() {
            const data = await getAllProduct();
            if (data.code === '200') {
                if(data.data !== null){
                    settransList(data.data);
                }
            }
        }
        showTrends();
        _gets();
        stockAndTrader();
    }, [])

    async function showTrends(e,dateString,tradername) {
        const values = await form.validateFields();
        if(!dateString[0]){
            let params = {
                beginDate: beginData,
                endDate: endData,
                tradeDirection: values.tradeDirection,
                productName: values.productName
            }
            if(tradername===undefined){
                params.traderName = traderName;
            }else{
                params.traderName = tradername;
            }
            const data = await showTraderTrends(params);
            if(data.code === '200'){
                setlist(data.data);
            }else{
                message.error(data.message);
            }
        }else{
            let beginTime = dateString[0];
            let endTime = dateString[1];
            setbeginData(dateString[0]);
            setendData(dateString[1]);
            let params = {
                beginDate: beginTime,
                endDate: endTime,
                tradeDirection: values.tradeDirection,
                productName: values.productName
            }
            if(tradername===undefined){
                params.traderName = traderName;
            }else{
                params.traderName = tradername;
            }
            const data = await showTraderTrends(params);
            if(data.code === '200'){
                setlist(data.data);
            }else{
                message.error(data.message);
            }
        }
    }
    function exportToExcel(){
        var data = [];
        list.map(item => {
            var a = {};
            var arr = item.split('，');
            a.name = arr[0].substr(0,arr[0].indexOf('委'));
            // a.name = arr[0].substr(0,arr[0].indexOf('买')) || arr[0].substr(0,arr[0].indexOf('卖'));
            a.direction = arr[0].slice((arr[0].indexOf('了')-2),arr[0].indexOf('了'));
            a.num = arr[0].slice(arr[0].indexOf('了')+1,arr[0].indexOf('股')+1);
            a.ming = arr[0].slice(arr[0].indexOf('股')+1,arr[0].indexOf('（'));
            a.code = arr[0].slice(arr[0].indexOf('股')+1).replace(/[^\d]/g,' ');
            a.entrustPrice = arr[1].slice(arr[1].indexOf(':')+1);
            a.clinchPrice = arr[3].slice(arr[3].indexOf(':')+1);
            a.clinchAmount = arr[4].slice(arr[4].indexOf(':')+1);
            if(arr[0].indexOf('卖出') !== -1){
                a.realRate = arr[5].substr(arr[5].indexOf('：')+1);
                a.stockRate = arr[6].substr(arr[6].indexOf('：')+1);
                a.excessRate = arr[7].substr(arr[7].indexOf('：')+1);
                a.productName = arr[8].substr(0,arr[8].indexOf('\t')+1);
                a.securitiesAccountName = arr[8].substr(arr[8].indexOf('\t')+1,arr[8].indexOf('\t'));
                a.time = arr[8].substr(arr[8].lastIndexOf('\t')+1);
            }else{
                a.realRate = null;
                a.stockRate = null;
                a.excessRate = null;
                a.productName = arr[5].substr(0,arr[5].indexOf('\t')+1);
                a.securitiesAccountName = arr[5].substr(arr[5].indexOf('\t')+1,arr[5].indexOf('\t'));
                a.time = arr[5].substr(arr[5].lastIndexOf('\t')+1);
            }
            // a.ming = arr[0].slice(arr[0].indexOf('手')+1).replace(/[0-9]/g, '');
            data.push(a);
            return item;
        })
        var option = {};
        let dataTable = [];
        if (data) {
            for (let i in data) {
              if (data) {
                let obj = {
                  '姓名': data[i].name, 
                  '方向': data[i].direction,
                  '数量': data[i].num,
                  '名称': data[i].ming,
                  '代码': data[i].code,
                  '委托价': data[i].entrustPrice,
                  '成交均价': data[i].clinchPrice,
                  '成交金额': data[i].clinchAmount,
                  '日内实际收益率': data[i].realRate,
                  '日内个股收益率': data[i].stockRate,
                  '超额收益率': data[i].excessRate,
                  '产品名称': data[i].productName,
                  '证券账户名称': data[i].securitiesAccountName,
                  '时间': data[i].time
                }
                dataTable.push(obj);
              }
            }
        }
        option.fileName = '交易员动态'  //导出的Excel文件名
        option.datas = [
            {
              sheetData: dataTable,
              sheetName: 'sheet',
              sheetFilter: ['姓名','方向','数量','名称','代码','委托价','成交均价','成交金额','日内实际收益率', '日内个股收益率', '超额收益率', '产品名称','证券账户名称','时间'],
              sheetHeader: ['姓名','方向','数量','名称','代码','委托价','成交均价','成交金额','日内实际收益率', '日内个股收益率', '超额收益率', '产品名称','证券账户名称','时间'],
            }
        ]
        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }
    function publicMethod(value1,value2){
        let arr = [];
        let brr = [];
        const map = new Map(Object.entries(value1));
        let arrayObj = Array.from(map);
        arrayObj.sort(function(a,b){
            if(a[1] === b[1]){
                return a[0].localeCompare(b[0]);
            }
            return b[1]-a[1];
        });
        arrayObj.map((item,index) => {
            if(index < 20){
                arr.push(item[0]);
                brr.push(item[1]);
                return item;
            }
            return item;
        })
        if(value2 === 1){
            setchartsXData1(arr);
            setchartsData1(brr);
        }
        if(value2 === 2){
            setchartsXData2(arr);
            setchartsData2(brr);
        }
        if(value2 === 5){
            setchartsXData5(arr);
            setchartsData5(brr);
        }
    }
    async function chartsGoBack1(value1,value2,value3){
        const data = await fundManagerAnalysis({type:2,startTime:value1,endTime:value2,productId:value3});
        if(data.code === '200'){
            publicMethod(data.data,1);
        }
    }
    async function chartsGoBack2(value1,value2,value3){
        const data = await fundManagerAnalysis({type:1,startTime:value1,endTime:value2,productId:value3});
        if(data.code === '200'){
            publicMethod(data.data,2);
        }
    }
    async function chartsGoBack3(value1,value2){
        const data = await fundManagerAnalysis({type:3,designatedDate:value1,productId:value2});
        if(data.code === '200'){
            let arr = [];
            let brr = [];
            let result = data.data;
            result.sort(function(a,b){
                if(a.dailyRevenueRate === b.dailyRevenueRate){
                    return a.subAccountName.localeCompare(b.subAccountName);
                }
                return b.dailyRevenueRate-a.dailyRevenueRate
            });
            result.map((item,index) => {
                if(index < 20){
                    arr.push(item.subAccountName);
                    brr.push(item.dailyRevenueRate.toFixed(2));
                    return item;
                }
                return item;
            })
            setchartsXData3(arr);
            setchartsData3(brr);
        }else{
            setchartsXData3([]);
            setchartsData3([]);
        }
    }
    async function chartsGoBack4(value1,value2,value3){
        const data = await fundManagerAnalysis({type:4,startTime:value1,endTime:value2,productId:value3});
        if(data.code === '200'){
            let arr = [];
            let brr = [];
            let result = data.data;
            result.sort(function(a,b){
                if(a.totalRevenueRate === b.totalRevenueRate){
                    return a.subAccountName.localeCompare(b.subAccountName);
                }
                return b.totalRevenueRate-a.totalRevenueRate
            });
            result.map((item,index) => {
                if(index < 20){
                    arr.push(item.subAccountName);
                    brr.push(item.totalRevenueRate.toFixed(2));
                    return item;
                }
                return item;
            })
            setchartsXData4(arr);
            setchartsData4(brr);
        }else{
            setchartsXData4([]);
            setchartsData4([]);
        }
    }
    async function chartsGoBack5(value1,value2,value3){
        const data = await fundManagerAnalysis({type:5,startTime:value1,endTime:value2,productId:value3});
        if(data.code === '200'){
            publicMethod(data.data,5);
        }else{
            setchartsXData5([]);
            setchartsData5([]);
        }
    }

    return (
        <div className="contentView" id="transAnalyse">
            <EchartsInfo3 
                chartsData1={chartsData1}
                chartsXData1={chartsXData1}
                chartsGoBack1={chartsGoBack1}
                chartsData2={chartsData2}
                chartsXData2={chartsXData2}
                chartsGoBack2={chartsGoBack2}
                chartsData3={chartsData3}
                chartsXData3={chartsXData3}
                chartsGoBack3={chartsGoBack3}
                chartsData4={chartsData4}
                chartsXData4={chartsXData4}
                chartsGoBack4={chartsGoBack4}
                chartsData5={chartsData5}
                chartsXData5={chartsXData5}
                chartsGoBack5={chartsGoBack5}
                transList={transList}
            />
            <br/>
            <Card
                style={{ minHeight: 800 }}
                title="交易员动态"
                extra={
                    <div>
                        <Row justify="end" align="middle">
                            <span style={{ marginRight: 10,marginLeft: 20 }}>选择品种:</span>
                            <Select
                                defaultValue="stock"
                            >
                                <Option value="stock">股票</Option>
                                <Option value="fund">基金</Option>
                                <Option value="futures">期货</Option>
                            </Select> 
                            <span style={{ marginRight: 10,marginLeft: 20 }}>选择日期:</span>
                            <RangePicker format="YYYY-MM-DD" defaultValue={[moment(moment()),moment(moment())]} onChange={showTrends}/>
                        </Row>
                        <Row justify="end" align="middle">
                            <Form form={form} name="control-hooks" layout='inline' initialValues={{productName:'',tradeDirection:''}}>
                                <Form.Item name="tradeDirection" label="选择操作：" style={{ marginRight: 10,marginTop: 10}}>
                                    <Select
                                        onChange={showTrends}
                                    >
                                        <Option value="">全部</Option>
                                        <Option value="BUY">买入</Option>
                                        <Option value="SELL">卖出</Option>
                                    </Select> 
                                </Form.Item>
                                <Form.Item name="productName" label="产品：" style={{ marginRight: 10,marginTop: 10}}>
                                    <Select
                                        onChange={showTrends}  
                                        dropdownMatchSelectWidth={false}
                                    >
                                        <Option value="">全部</Option>
                                        {
                                            transList.length !== 0 &&
                                            transList.map((item) => {
                                                return (
                                                    <Option
                                                        key={item.productId}
                                                        value={item.productName}
                                                    >
                                                        {item.productName}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                            <span style={{ marginRight: 10,marginTop:10}}>交易员:</span>
                            <Search placeholder="请输入交易员" allowClear enterButton style={{width: 200,marginTop:10}} onSearch={value=>{
                                let traderName=value;
                                let dateString = [];
                                showTrends('',dateString,traderName);
                                settraderName(value);
                            }}/>
                            <Button type='primary' style={{marginLeft:10,marginTop:10}} onClick={exportToExcel}>导出</Button>
                        </Row>
                    </div>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={list}
                    pagination={{
                        defaultPageSize: 10,
                        hideOnSinglePage:true
                    }}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={<div><h3>{item.substr(0,item.indexOf('委'))}</h3></div>}
                                // description={<div>{item}</div>}
                            />
                            {item}
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
})