import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Select, Divider } from 'antd';
import { getHighPositionLevel, showSubAccountHistory } from '@/api/fc';
import { useMount, useReactive } from 'ahooks';
import { isValidArray, createDatasTimeKey, labelValues } from '@/utils/utils2';
import { COMMON_CHART, createBaseLine, multiplication } from '@/view/common/Components/chartsInfoUtils';
import * as echarts from 'echarts';
import { NewRangePicker } from '@/view/common/widgets';
import moment from 'moment';
import _ from 'lodash';

const RANGE_TIME = [moment().subtract(7, 'd').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
const LVL_KEYS = ['levelOne', 'levelTwo', 'levelThree', 'levelFour'];
const LVL_COLOR = ['#fabe01', '#bfa074', '#00a2c4', '#bf4531'];
const LVL_NAME = ['level 1', 'level 2', 'level 3', 'level 4'];
const LEVEL_OPTIONS = {
  grid: { left: '8%', right: '6%', bottom: '10%', top: '10%' },
  xAxis: { type: 'category', name: '时间', ...COMMON_CHART.rateX },
  yAxis: { type: 'value', splitArea: { show: true }, splitLine: { show: false }, axisLine: { show: true } },
  series: [],
  tooltip: { trigger: 'axis' },
  legend: { data: [...LVL_NAME, '收益率'] }
};
const LEVEL_BAR = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  tooltip: { trigger: 'axis' },
  xAxis: { type: 'category', data: [], },
  yAxis: { name: '票数', type: 'value', splitArea: { show: false }, splitLine: { show: false }, axisLine: { show: false } },
  series: LVL_KEYS.map((k, i) => {
    return { name: LVL_NAME[i], key: k, type: 'bar', label: { show: true, }, itemStyle: { color: LVL_COLOR[i] }, data: [] }
  })
};
// 重仓分布图表
const LevelCharts = (props) => {
  const [levels, setLevels] = useState([]);
  const [rates, setRates] = useState([]);
  const [upcount, setUpcount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [option, setoption] = useState(LEVEL_OPTIONS);
  const [option2, setoption2] = useState(LEVEL_BAR);
  const levelState = useReactive({
    options: [], value: '', isSingle: false, date: RANGE_TIME
  });

  useMount(() => {
    _getHighPositionLevel();
  });

  async function _getHighPositionLevel() {
    const getId = _.get(props, 'id', 0);
    const is_reverse = _.get(props, 'isReverse');
    if (getId) {
      setLoading(true)

      let params = {
        'productId': getId,
        'beginDate': levelState.date[0],
        'endDate': levelState.date[1],
      }
      const res = await getHighPositionLevel(params, is_reverse ? 'FC' : 'NOR');
      if (_.get(res, 'code', '') === '200') {
        const getPoint = _.get(res, 'data.point', []);
        levelState.options = getPoint.map((n, i) => {
          if (i === 0) {
            levelState.value = _.get(n, 'subAccountId');
          }
          return labelValues([n.name, n.subAccountId]);
        });
        const getRate = await _showSubAccountHistory();
        // 是否未当天
        levelState.isSingle = params.beginDate === params.endDate ? true : false
        setLevels(getPoint);
        setUpcount(_.round(upcount + 0.1, 1));
      }
      setLoading(false);
    }
  };
  // 获取子账户的收益率
  async function _showSubAccountHistory(type = '') {
    if (type === 'select') {
      setLoading(true);
    }
    let ratePms = {
      'subAccountId': levelState.value,
      'beginDate': levelState.date[0],
      'endDate': levelState.date[1],
    };
    const is_reverse = _.get(props, 'isReverse');
    const res = await showSubAccountHistory(ratePms, is_reverse ? 'FC' : 'NOR');
    let success = false;
    if (_.get(res, 'code', '') === '200') {
      const getPoints = _.get(res, 'data.pointList', []);
      setRates(roundData(getPoints));
      success = true;
    }
    if (type === 'select') {
      setUpcount(_.round(upcount + 0.1, 1));
      setLoading(false);
    }
    return success;
  };
  // 重仓bar数据； 统计返回数组的大小，并时间排序
  function handleHighPos(getHigh) {
    let newHighPos = [];
    _.keys(getHigh).map(date => {
      let posSize = {};
      LVL_KEYS.map(k => {
        _.set(posSize, k, _.size(getHigh[date][k]));
      })
      newHighPos.push({ date, ...posSize });
    });
    return createDatasTimeKey(newHighPos, 'date', 'd', 'asc')
  }

  useEffect(() => {
    let myChart = props.myChart; let myChart2 = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }
    myChart = echarts.init(document.getElementById('pro_level_charts'));
    myChart2 = echarts.init(document.getElementById('pro_level_bar'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = _.cloneDeep(option); let newOption2 = _.cloneDeep(option2);
    if (isValidArray(levels)) {
      const levelItem = _.head(_.filter(levels, o => o.subAccountId === levelState.value));
      // 对数值进行累乘计算，返回收益率，需要提前除以100进行计算
      const levelLine = LVL_KEYS.map((k, i) => {
        const getItemData = _.get(levelItem, k, []).map(n => {
          const floatNum = parseFloat(n);
          return floatNum !== 0 ? _.round(floatNum / 100, 6) : 0;
        });
        return createBaseLine(
          LVL_COLOR[i], {
          'name': LVL_NAME[i],
          'data': levelState.isSingle ? roundData(_.get(levelItem, k)) : multiplication(roundData(getItemData), 4)
        });
      });
      const rateLine = createBaseLine('#923e93', { 'name': '收益率', 'data': rates });
      newOption.series = _.concat(levelLine, rateLine);
      newOption.xAxis.data = _.get(levelItem, 'timeList', []);
      // 单日highPos只有一日数据；所以上下图表内容不一样
      if (levelState.isSingle) {
        const highItem = _.head(handleHighPos(_.get(levelItem, 'highPosition', {})));
        newOption2.xAxis.data = [_.get(highItem, 'date', '')];
        LVL_KEYS.map(k => {
          let fkeyindex = _.findIndex(newOption2.series, o => o.key === k);
          const getsname = _.get(newOption2, `series[${fkeyindex}].key`);
          newOption2.series[fkeyindex].data = [_.get(highItem, getsname)];
        });
      } else {
        // 使用选中内部的highPosition字段计算子账户自己的分布情况
        newOption2.xAxis.data = _.get(levelItem, 'timeList', []);
        const itemHighPos = _.get(levelItem, 'highPosition', {});
        handleHighPos(itemHighPos).map((itm, index) => {
          LVL_KEYS.map(k => {
            let fkeyindex = _.findIndex(newOption2.series, o => o.key === k);
            newOption2.series[fkeyindex].data[index] = _.get(itm, k);
          })
        });
      }
    }
    setoption(newOption); setoption2(newOption2);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();
    myChart2.setOption(newOption2, true);
    myChart2.resize();
    echarts.connect([myChart, myChart2]);
  }, [upcount]);

  function roundData(array) {
    return isValidArray(array) ? array.map(n => _.round(parseFloat(n), 4)) : []
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Row>
        <Col span={12}>
          <Select bordered={false} style={{ width: 285 }}
            value={levelState.value}
            options={levelState.options}
            onChange={v => {
              levelState.value = v;
              _showSubAccountHistory('select');
            }}
          />
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <NewRangePicker dates={levelState.date} onSelect={(dateStrings) => {
            levelState.date = dateStrings;
            _getHighPositionLevel();
          }} />
        </Col>
      </Row>

      <Divider style={{ margin: '12px 0' }} />

      <Spin spinning={loading}>
        <div id="pro_level_charts" style={{ width: '100%', height: 405 }} />
      </Spin>

      <div id="pro_level_bar" style={{ width: '100%', height: 310 }} />
    </div>
  )
}

export default LevelCharts;