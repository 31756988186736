import _ from 'lodash';
/**
 * 基础策略信息，显示label，用key去获取到对应的数值显示
 * _.get(数据, key) = 拿到的值;
 */
const RULES_BASE = [
  { label: '一分钟成交量', key: 'totalVolume', width: 130 },
  { label: '昨日平均成交量', key: 'preAvgVolume', width: 130 },
  { label: '昨日最高成交量', key: 'preMaxVolume', width: 130 },
  { label: '前五日平均成交量的平均值', key: 'pre5DaysAvgAvgVolume', width: 210 },
  { label: '前五日平均成交量的最大值', key: 'pre5DaysMaxAvgVolume', width: 210 },
  { label: '前五日最大成交量的平均值', key: 'pre5DaysAvgMaxVolume', width: 210 },
  { label: '前五日最大成交量的最大值', key: 'pre5DaysMaxMaxVolume', width: 210 },
  { label: '一分钟涨跌幅', key: 'tempPctChange', width: 120 },
  { label: '当前涨跌幅', key: 'totalPctChange', width: 120 },
  { label: '昨日涨跌幅', key: 'prePctChg', width: 120 },
  { label: '前五日最低到最高涨跌幅', key: 'pre5DaysMaxPctChg', width: 200 },
  { label: '今日开盘涨跌幅', key: 'todayOpenPctChg', width: 130 },
  { label: '一分钟交易笔数', key: 'totalTrade', width: 130 },
  { label: '条件(1/2/3)', key: 'condition', width: 110 },
];
/**
    动态策略数据，@ 最后需要替换成 vkey 所拿到的值 ；X 需要替换 key 所拿到的值； vobj 用获取到的数值显示对应的 value
 */
const RULES_DYM = [
  [
    { label: '成交量比@倍昨日平均成交量多X', key: 'basicConditionVolumePreAvgVolume', vkey: 'basicConditionVolumePreAvgVolumeArg', width: 280 },
    { label: '成交量比@倍前五日平均成交量的最大值多X', key: 'basicConditionVolumePre5MaxAvgVolume', vkey: 'basicConditionVolumePre5MaxAvgVolumeArg', width: 310 },
    { label: '一分钟涨跌幅比基础规则一分钟涨跌幅@%少X', key: 'basicConditionTempPctChg', vkey: 'basicConditionTempPctChgArg', width: 322 },
    { label: '当前涨跌幅比基础规则当前涨跌幅@%多X', key: 'basicConditionTotalPctChg', vkey: 'basicConditionTotalPctChgArg', width: 315 },
    { label: '昨日涨跌幅比基础规则昨日涨跌幅@%少X', key: 'basicConditionPrePctChg', vkey: 'basicConditionPrePctChgArg', width: 315 },
    { label: '前五日最低到最高涨跌幅比基础规则前五日最低到最高涨跌幅@%少X', key: 'basicConditionPre5daysMaxPctChg', vkey: 'basicConditionPre5daysMaxPctChgArg', width: 468 },
    { label: '今日开盘涨跌幅比基础规则今日开盘涨跌幅@%少X', key: 'basicConditionTodayOpenPctChg', vkey: 'basicConditionTodayOpenPctChgArg', width: 375 },
    { label: '前五日是否涨停过', key: 'basicConditionLast5DaysLimited', vobj: { 0: '否', 1: '是' }, width: 180 },
    { label: '大单以上笔数@(下限X)', key: 'basicConditionBigUpTrade', vkey: 'basicConditionBigUpTradeArg', width: 190 },
    { label: '5日累积涨跌幅比基础规则@%少X', key: 'basicConditionLast5DaysUpDown', vkey: 'basicConditionLast5DaysUpDownArg', width: 245 },
  ],
  [
    { label: '交易量条件', key: 'condition1TradeCondition', vobj: { 1: '一分钟交易笔数大于15', 2: '一分钟交易笔数在10-15笔之间' }, width: 160 },
    { label: '成交量比昨日最大成交量的@倍多X', key: 'condition1TotalVolumePreCondition', vkey: 'condition1TotalVolumePreConditionArg', width: 325 },
    { label: '成交量比前五日最大成交量的最大值的@倍多X', key: 'condition1TotalVolumePre5Condition', vkey: 'condition1TotalVolumePre5ConditionArg', width: 340 },
    { label: '一分钟涨跌幅比条件1一分钟涨跌@%多X', key: 'condition1TempPctChg', vkey: 'condition1TempPctChgArg', width: 285 },
    { label: '买入笔数1档条件阈值(@倍)', key: 'condition1FirstBuy', vkey: 'condition1FirstBuyArg', width: 235 },
    { label: '买入笔数2档条件阈值(@倍)', key: 'condition1SecondBuy', vkey: 'condition1SecondBuyArg', width: 235 }
  ],
  [
    { label: '成交量比昨日平均成交量的@倍多X', key: 'condition2TotalVolumePreAvgVolumeCondition', vkey: 'condition2TotalVolumePreAvgVolumeConditionArg', width: 260 },
    { label: '成交量比昨日最大成交量的@倍多X', key: 'condition2TotalVolumePreMaxVolumeCondition', vkey: 'condition2TotalVolumePreMaxVolumeConditionArg', width: 260 },
    { label: '成交量比昨日平均成交量的最大值的@倍多X', key: 'condition2TotalVolumePre5MaxAvgVolumeCondition', vkey: 'condition2TotalVolumePre5MaxAvgVolumeConditionArg', width: 310 },
    { label: '成交量比昨日最大成交量的最大值的@倍多X', key: 'condition2TotalVolumePre5MaxMaxVolumeCondition', vkey: 'condition2TotalVolumePre5MaxMaxVolumeConditionArg', width: 310 },
    { label: '一分钟涨跌幅比条件2一分钟涨跌幅@%多X', key: 'condition2TempPctChg', vkey: 'condition2TempPctChgArg', width: 310 }
  ],
  [
    { label: '成交量比昨日平均成交量的@倍多X', key: 'condition3TotalVolumePreAvgVolumeCondition', vkey: 'condition3TotalVolumePreAvgVolumeConditionArg', width: 265 },
    { label: '成交量比昨日最大成交量的@倍多X', key: 'condition3TotalVolumePreMaxVolumeCondition', vkey: 'condition3TotalVolumePreMaxVolumeConditionArg', width: 265 },
    { label: '成交量比昨日平均成交量的最大值的@倍多X', key: 'condition3TotalVolumePre5MaxAvgVolumeCondition', vkey: 'condition3TotalVolumePre5MaxAvgVolumeConditionArg', width: 310 },
    { label: '成交量比昨日最大成交量的最大值的@倍多X', key: 'condition3TotalVolumePre5MaxMaxVolumeCondition', vkey: 'condition3TotalVolumePre5MaxMaxVolumeConditionArg', width: 322 },
    { label: '一分钟涨跌幅比条件3一分钟涨跌幅@%多X', key: 'condition3TempPctChg', vkey: 'condition3TempPctChgArg', width: 310 }
  ]
];

const LABEL_ARRAY = ['基础条件', '条件1', '条件2', '条件3']; // 与 RULES_DYM 的数组顺序相同即可, 用于统计的 title 显示;
const gets = (n = {}, key = '', t = 0) => _.get(n, key, t);

const renderDefaultSerires = (color, type = '') => {
  let config = {
    type: 'line',
    symbol: 'circle',
    data: [],
    lineStyle: { color: color },
    itemStyle: { color: color },
  }
  if (type === 'dash') {
    config.lineStyle.type = 'dashed';
    config.lineStyle.width = 1;
  }
  return config
}

const DIFF_CHART_OPTION = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  legend: { data: [], type: 'scroll' },
  xAxis: { type: 'category', data: [] },
  yAxis: {
    type: 'value',
    // splitNumber: 6,
    interval: 0.05 // 轴拆分线间隔大小
  },
  series: [],
  tooltip: { trigger: 'axis' }
};

const CONTRIBUTE_CHARTS = {
  grid: { left: '8%', right: '5%', bottom: '10%', top: '10%' },
  legend: { data: [], type: 'scroll' },
  xAxis: { type: 'category', data: [] },
  yAxis: { type: 'value' },
  series: [],
  tooltip: { trigger: 'axis' }
};

export {
  RULES_BASE,
  RULES_DYM,
  LABEL_ARRAY,
  DIFF_CHART_OPTION, CONTRIBUTE_CHARTS,
  gets,
  renderDefaultSerires
}