import React, { useState } from 'react';
import { Table, Button, Tag, Select } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { showProductSecuritiesAndSubHistory } from '@/api/details';
import { singleColumns } from '@/utils/utils';
import _ from 'lodash';

const Records = ({ options = [], goManager }) => {
  const [historyData, setHistoryData] = useState([]);

  async function handleTableChange(id) {
    if (id) {
      const data = await showProductSecuritiesAndSubHistory(
        { productId: id },
        findFcAccout(id) ? 'FC' : 'NOR'
      );
      if (_.get(data, 'code', '') === '200') {
        setHistoryData(_.get(data, 'data.securitiesAccounts', []));
      }
    }
  };
  // 查找产品是反采
  function findFcAccout(id) {
    let sindex = _.findIndex(options, o => o.value === id);
    return sindex > -1 ? _.get(options, `[${sindex}].fc`) : false;
  };

  const columns = [
    { ...singleColumns(['账户', 'customerNumber', null, 'center']) },
    {
      ...singleColumns(['总资产', 'totalAsset', null, 'center']),
      render: (text) => parseFloat(text).toFixed(2)
    },
    {
      ...singleColumns(['可用资产', 'availableBalance', null, 'center']),
      render: (text) => parseFloat(text).toFixed(2),
    },
  ]

  const expandedRowRender = (fatRecord) => {
    const columnsChildren = [
      {
        ...singleColumns(['子账户', 'name', 220]),
        render: (text) => <Tag color="blue">{text}</Tag>
      },
      {
        ...singleColumns(['资产', 'totalAsset', 100]),
        sorter: (a, b) => b.totalAsset - a.totalAsset,
        render: (text) => <Tag color="blue">{parseFloat(text).toFixed(2)}</Tag>
      },
      {
        ...singleColumns(['净值', 'netValue', 100]),
        sorter: (a, b) => b.netValue - a.netValue,
        render: (text) => <Tag color="blue">{parseFloat(text).toFixed(4)}</Tag>
      },
      {
        ...singleColumns(['收益', 'totalRevenue', 100]),
        sorter: (a, b) => b.totalRevenue - a.totalRevenue,
        render: (text) => {
          return <div><Tag color="blue">{parseFloat(text).toFixed(2)}</Tag></div>
        }
      },
      {
        ...singleColumns(['日收益', 'dailyRevenue', 100, 'center']),
        sorter: (a, b) => b.dailyRevenue - a.dailyRevenue,
        render: (text) => <Tag color="blue">{parseFloat(text).toFixed(2)}</Tag>,
      },
      {
        ...singleColumns(['可用资金', 'availableBalance', 100]),
        sorter: (a, b) => b.availableBalance - a.availableBalance,
        render: (text) => <Tag color="blue">{parseFloat(text).toFixed(2)}</Tag>,
      },
      {
        ...singleColumns(['交易员', 'traderName', 120, 'right']),
        render: (text) => <Tag color="blue">{text}</Tag>
      },
      {
        ...singleColumns(['状态', 'status', 100, 'right']),
        render: (text) => {
          let newText = ''
          if (text === 1) {
            newText = '托管中'
          } else if (text === 0) {
            newText = '停用'
          } if (text === 2) {
            newText = '已删除'
          }
          return <Tag color="blue">{newText}</Tag>
        }
      },
      {
        ...singleColumns(['操作', 'options', 110, 'right']),
        render: (text, record) => {
          return <Button
            type="link"
            icon={<RightCircleOutlined />}
            onClick={() => goManager(record)}>
            查看
          </Button>
        }
      }
    ]

    return (
      <Table
        rowKey="id"
        dataSource={_.get(fatRecord, 'subAccountList', [])}
        columns={columnsChildren}
        scroll={{ x: 1000 }}
        pagination={false}
      />
    )
  }

  return (
    <div style={{ marginBottom: 8, minHeight: 350 }}>
      <div style={{ backgroundColor: '#f5f5f5', padding: 4, borderRadius: 4, marginBottom: 8, width: 200 }}>
        <Select bordered={false} onChange={v => handleTableChange(v)} style={{ width: 200 }}>
          {options.map((n, i) => <Select.Option key={n.value + i} value={n.value}>{n.label}</Select.Option>)}
        </Select>
      </div>
      <Table
        rowKey="id"
        dataSource={historyData}
        columns={columns}
        size='small'
        bordered
        scroll={{ y: 550 }}
        expandable={{
          expandedRowRender,
        }}
        pagination={false}
      />
    </div>
  )
}

export default Records;