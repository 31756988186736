import React from 'react';
import { Table, Typography, Space, Button, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { singleColumns, colWidth } from '@/utils/utils';
import { INDEX_CODE_VALUE } from '@/utils/indexCode';
import _ from 'lodash';

const { Text } = Typography;

const ConfigTable = ({
  pageKey = '',
  loading = false,
  datas = [],

  onEdit,
  onDelete
}) => {
  const TABLE_COL = {
    'compare': [
      singleColumns(['名称', 'name']),
      singleColumns(['类别名称', 'categoryName']),
    ],
    'idx': [
      singleColumns(['名称', 'name']),
      {
        ...singleColumns(['详情', 'detail']),
        render: (text, record) => {
          const getIndex = _.get(record, 'index', []);
          return <Space size='small'>
            {getIndex.map(itm => <Text key={itm.id}>
              <Text>{INDEX_CODE_VALUE[itm.code]}</Text>
              <Text keyboard style={{ marginLeft: 3 }}>{itm.rate}</Text>
            </Text>)}
          </Space>
        }
      },
      {
        ...singleColumns(['操作', 'option']),
        render: (text, record) => {
          return <Space size='small'>
            <Button size='small' type='link' icon={<EditOutlined />}
              onClick={() => onEdit(record)} />
            <Button size='small' type='link' danger icon={<DeleteOutlined />}
              onClick={() => confirmDel(record.id)}
            />
          </Space>
        }
      },
    ],
  };

  function confirmDel(id) {
    Modal.confirm({
      title: '是否添加该记录',
      content: '',
      okText: '确认',
      cancelText: '取消',
      onOk: () => onDelete(id)
    });
  }

  const cols = TABLE_COL[pageKey];

  return (
    <div style={{ minHeight: 655 }}>
      <Table
        rowKey="newId"
        dataSource={datas}
        columns={cols}
        scroll={{ x: colWidth(cols), y: 635 }}
        pagination={false}
        bordered
        size='small'
        loading={loading}
      />
    </div>
  )
}

export default ConfigTable;