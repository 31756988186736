import React, { useState } from 'react';
import { message, Tabs, PageHeader } from 'antd';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import {
  showSubAccountData, showSubAccountHistory, getHoldingStocksInfo,
  showAllSubAccountHistory, showIndexKline, showSubAccountPlateHistory
} from '@/api/workbench';
import MainRateCharts from '@/view/common/MainRateCharts';
import ContributionCharts from './Components/ContributionCharts';
import DisplayCharts from '@/view/common/DisplayCharts';
import TransCharts from './Components/TransCharts';
import CompareCharts from './Components/CompareCharts';
import CompareChartsTwo from '@/view/common/CompareChartsTwo';
import SignalCharts from './Components/SignalCharts';
import AssetsHeader from './Components/AssetsHeader';
import PositionTable from './Components/PositionTable';
import TradeRecord from './TradeRecord';
import BtCompareCharts from './Components/BtCompareCharts';
import PlateCharts from './Components/PlateCharts';
import KRateCharts from '@/view/common/KRateCharts';
import ReviewCharts from './Components/ReviewCharts';
import { useInterval, useMount, useUnmount, useReactive } from 'ahooks';
import { DATE_TYPE, handleMainSeriesData, sliceUnmatchTimes, calCustomIndex, initDateType } from '@/view/common/Components/list_util';
import { DEFAUL_INDEX_CODE } from '@/utils/indexCode';
import { validateResponse } from '@/utils/utils';
import { isValidArray, isValidString, isMarketTrading, handleCustomIndexPms } from '@/utils/utils2';
import { RecentTabs, DetailTabsIcon } from '../ProductDetails/de_widget';
import moment from 'moment';
import _ from 'lodash';
import './list.scss';

const { TabPane } = Tabs;
const TODAY = moment().format('YYYY-MM-DD');
const INTERVAL_NUM = 60000;
const REALTIME_TAB = '1';
const MAIN_RATE = 'MAIN_RATE';
let timer = null;
/**
 * 子账户页面ListsDetails
 * 【原】6个图表所有获取数据都在该页面获取及处理；现修改为单独模块获取；
 */
export default function ListsDetails(props) {
  const mobxStore = useLocalObservable(() => store);
  const accountState = useReactive({
    info: props.location.state.subAccountInfo,// 当前子账户所用的账户数据，默认加载路由中的数据
    lastPlate: [], symbol: DEFAUL_INDEX_CODE,
  });
  const [accountCacheState] = useState(JSON.parse(JSON.stringify(mobxStore.accountCache))); // 子账户全局缓存数据
  const [atab, setAtab] = useState(String(accountState.info.subAccountId));
  const [userInfoFC] = useState(JSON.parse(JSON.stringify(mobxStore.userInfoFC)));
  const [customIndex, setCustomIndex] = useState(JSON.parse(JSON.stringify(mobxStore.customIndex)) ?? [])
  const [tradeDates, setTradeDates] = useState(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
  const initType = initDateType(tradeDates, REALTIME_TAB);
  const initTrading = isMarketTrading(initType.isTradeDay);
  const [tabActive, setTabActive] = useState(MAIN_RATE); // 图表tab
  const [assetData, setassetData] = useState({});
  const [pointDatas, setPointDatas] = useState({ timeList: [], datas: {}, newData: {} });
  const [indexList, setindexList] = useState([]);
  const [exeTimes, setexeTimes] = useState([]);
  const [validDate, setValidDate] = useState([]); // 收益率时间区间
  const [isTradeTime] = useState(initTrading); // 是否在盘中交易时间
  const [timeCount, setTimeCount] = useState(0); // 根据图表命名的 = EchartsInfo5
  const [posCount, setPosCount] = useState(0); // 当前持仓及交易记录update
  const [countMain, setCountMain] = useState(0); // 收益率更新count
  const [signalUpdate, setSignalUpdate] = useState({}); // 当前持仓及交易记录update
  const [signalCount, setSignalCount] = useState(0); // 收益率更新count
  const [inter, setInter] = useState(initTrading ? INTERVAL_NUM : undefined);
  const [newTabs, setNewTabs] = useState(initType.tab)
  const [isSubListdetail] = useState(_.get(props, 'location.state.canTrade') === 'no' ? false : true)
  const [indexValue, setIndexValue] = useState({});
  const [plateHistory, setPlateHistroy] = useState({}); // 板块平均
  const [idxParams, setIdxParams] = useState({
    'symbol': accountState.info.indexCode ?? DEFAUL_INDEX_CODE, 'dateType': initType.type, 'date': TODAY
  });
  const isVisitor = mobxStore.userInfo.role === 'visitor' ? true : false;
  const isReverse = _.get(accountState.info, 'centralizedLogin') === 2 ? true : false;
  const isFcLogin = isValidString(_.get(userInfoFC, 'token', ''));
  let isUnmont = false;

  useMount(() => {
    _initGetData();
    //交易日
    if (!isValidArray(tradeDates)) {
      mobxStore._getAllTradeDay();
      timer = setTimeout(() => {
        setTradeDates(JSON.parse(JSON.stringify(mobxStore.tradeDates)));
      }, 2000);
    };
  });

  useUnmount(() => {
    setInter(undefined);
    isUnmont = true;
    timer && clearTimeout(timer);
  })
  // 定时更新任务
  useInterval(() => {
    _cutDateType(newTabs);
    _showSubAccountData();
    if (isMarketTrading() === false) {
      setInter(undefined);
    }
  }, inter);
  // 初始加载，因增加同页面切换子账户功能，所以初始加载要统一到一个function当中
  async function _initGetData(reload) {
    accountState.info.contributionTime = [TODAY, TODAY];
    accountState.info.dateType = initType.type;
    accountState.info.transactionTime = [TODAY, TODAY];
    if (!accountState.info.subAccountId) {
      accountState.info.subAccountId = accountState.info.id;
    }

    _cutDateType(newTabs, null, 'init');
    _showAllSubAccountHistory();
    _getHoldingStocksInfo(); // 获取初始时间内的股票
    _showSubAccountData();

    if (reload) {
      setPosCount(posCount + 1); // 重载或初次加载更新，组件内部会重新获取数据
      if (tabActive !== MAIN_RATE) {
        setTabActive(MAIN_RATE);
      }
    }

    setexeTimes([TODAY, TODAY]);
    setTimeCount(timeCount + 1);
  }

  // 获取资产信息
  async function _showSubAccountData() {
    const is_reverse = _.get(accountState.info, 'centralizedLogin', 0) === 2 ? true : false;
    const data = await showSubAccountData({ 'subAccountId': accountState.info.subAccountId }, is_reverse ? 'FC' : 'NOR');
    if (validateResponse(data, isUnmont)) {
      setassetData(_.get(data, 'data', {}));
    }
  }

  // 获取子账户有收益率数据的时间区间
  async function _showAllSubAccountHistory() {
    const is_reverse = _.get(accountState.info, 'centralizedLogin', 0) === 2 ? true : false;
    if (accountState.info.subAccountId) {
      const res = await showAllSubAccountHistory(accountState.info.subAccountId, is_reverse ? 'FC' : 'NOR');
      if (validateResponse(res, isUnmont)) {
        let getDateArray = _.get(res, 'data', [])
        // 返回区间如不包含今天则添加今天，避免获取问题
        if (getDateArray.indexOf(TODAY) === -1) {
          getDateArray.push(TODAY);
        }
        setValidDate(getDateArray);
      }
    }
  }
  // 切换【1.收益率】图表时间
  function _cutDateType(e = '', date = null, init = '') {
    //e=11 点击日期点，切换到当日实时数据
    setNewTabs(e === '11' ? REALTIME_TAB : e);
    accountState.info.dateToday = e === '11' ? date : TODAY;
    const new_type = parseInt(e) > 100 ? DATE_TYPE['12'] : DATE_TYPE[e];
    accountState.info.dateType = new_type;
    // 上个接口=ALL直接切换
    if (_.get(idxParams, 'dateType') === 'ALL' && new_type === 'ALL' && init !== 'init') {
      updateMain();
    } else {
      // 实时：开启定时任务 
      setInter(e === REALTIME_TAB && isTradeTime ? INTERVAL_NUM : undefined);
      _showSubAccountHistory();
    }
  }
  // 账户收益率获取
  const unmatch_types = ['ALL', 'THREE_YEAR']; // ALL = 成立以来，数据过多或者三年数据还没有，统一同一年进行获取
  async function _showSubAccountHistory(isDate) {
    const is_reverse = _.get(accountState.info, 'centralizedLogin', 0) === 2 ? true : false;
    let params = {
      'dateType': accountState.info.dateType,
      'subAccountId': accountState.info.subAccountId,
      'date': _.get(accountState.info, 'dateToday', TODAY),
    }
    let idxPms = {
      'symbol': accountState.symbol,
      'dateType': params.dateType,
      'date': params.date,
    };
    const idxPms2 = handleCustomIndexPms(idxPms, customIndex);
    if (idxPms2.symbolInit) {
      accountState.symbol = DEFAUL_INDEX_CODE;
    };
    if (idxPms2.isCusFromStorage) {
      setCustomIndex(_.get(idxPms2, 'customs', []));
    };
    const data1 = await showSubAccountHistory(params, is_reverse ? 'FC' : 'NOR');
    // 因增加时间对比进行裁剪，两个数据需要同时获取
    const res = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
    setIdxParams(idxPms);
    if (_.get(data1, 'message', '') !== 'success') {
      message.info(_.get(data1, 'message'))
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    const validPoints = isValidArray(_.get(data1, 'data.pointList', []));
    if (validateResponse(data1, isUnmont) && validPoints) {
      let newTimeList = _.get(data1, 'data.timeList', []);
      // 指数遇到合并则进行计算
      let indexDatas = idxPms2.isBatch
        ? calCustomIndex(_.get(res, 'data', {}), idxPms2.rates, newTimeList, idxPms.dateType)
        : _.get(res, 'data', {});
      let newPoints = _.get(data1, 'data', {});
      if (_.includes(unmatch_types, params.dateType)) { // 遇到ALL和三年长数，进行裁剪
        const newRes = sliceUnmatchTimes(newPoints, indexDatas);  // 传入收益率和指数的response.data对象
        newTimeList = _.get(newRes, 'points.timeList', []);// 重新赋值时间、收益率数据
        newPoints = _.get(newRes, 'points', {});
        indexDatas = _.get(newRes, 'indexs', {});
      }
      setPointDatas({
        timeList: newTimeList,
        datas: newPoints,
        newData: handleMainSeriesData(newPoints, params.dateType)
      });
      setIndexValue(indexDatas);
    } else {
      setPointDatas({ timeList: [], datas: {}, newData: {} });
      setIndexValue({});
    }
    // 修改单日时间获取板块平均，最后统一更新图表countMain
    if (isDate) {
      _showSubAccountPlateHistory(idxPms.date)
    } else {
      updateMain();
    }
  }
  // 获取指数数据
  async function _showIndexKline(idx) {
    let params = {
      'symbol': idx ? idx : idxParams.symbol,
      'dateType': idxParams.dateType,
      'date': idxParams.date
    };
    const idxPms2 = handleCustomIndexPms(params, customIndex);
    const res = await showIndexKline(idxPms2.params, idxPms2.isBatch ? 'batch' : 'nor');
    setIdxParams(params)
    if (_.get(res, 'code', '') === '200' && !isUnmont) {
      const getData = idxPms2.isBatch
        ? calCustomIndex(_.get(res, 'data', {}), idxPms2.rates, _.get(pointDatas, 'timeList'), params.dateType)
        : _.get(res, 'data', {});
      setIndexValue(getData);
    }
    if (_.get(res, 'message', '') !== 'success') {
      message.info(_.get(res, 'message'))
    }
    updateMain();
  }
  // 板块平均收益率
  async function _showSubAccountPlateHistory(date) {
    const is_reverse = _.get(accountState.info, 'centralizedLogin', 0) === 2 ? true : false;
    let params = {
      subAccountId: accountState.info.subAccountId,
      date: date ? date : idxParams.date
    }
    // 非盘中有数据，直接更新
    if (!isTradeTime && (params.date in plateHistory)) {
      updateMain();
      return
    }

    const res = await showSubAccountPlateHistory(params, is_reverse ? 'FC' : 'NOR');
    if (_.get(res, 'code', '') === '200') {
      const getHistory = _.get(res, 'data', []);
      let tempHistroy = _.cloneDeep(plateHistory);
      let plateObj = {};
      if (isValidArray(getHistory)) {
        getHistory.map((hItem, i) => {
          let plateData = JSON.parse(_.get(hItem, 'plate', '')); // 获取板块信息，字符串，解析后为数组
          isValidArray(plateData) && plateData.map(pItem => {
            const plate_name = _.get(pItem, 'plate')
            if (!plate_name in plateObj) { // 首次创建板块，可能该板块之前没出现，所以要fill之前没出现的数据为 “-”
              plateObj[plate_name] = _.fill(Array(i + 1), '-');
            }
            // 填充对应 i 位置的数据
            _.set(plateObj, `${plate_name}[${i}]`, {
              ...pItem,
              'time': _.chain(hItem).get('time').split(':').dropRight().join(':').value() // 板块时间为15:00:00，指数时间为15:00，进行裁切处理
            });
          });
        })
      }
      // 根据日期累加/替换object的值;
      _.set(tempHistroy, params.date, plateObj);
      setPlateHistroy(tempHistroy);
      //console.log('plateObj', plateObj)
    }
    updateMain();
  }

  async function _getHoldingStocksInfo(time1, time2) {
    const is_reverse = _.get(accountState.info, 'centralizedLogin', 0) === 2 ? true : false;
    const today = moment().format('YYYY-MM-DD');
    const yestoday = moment().subtract(1, 'day').format('YYYY-MM-DD');
    const holdStockRes = await getHoldingStocksInfo({
      subAccountId: accountState.info.subAccountId,
      beginDate: time1 || yestoday,
      endDate: time2 || today
    }, is_reverse ? 'FC' : 'NOR');
    if (validateResponse(holdStockRes, isUnmont)) {
      const getData = _.get(holdStockRes, 'data', {});
      const stockNameList = Object.keys(getData);
      const newStockList = _.isObject(getData) ? stockNameList.map(keyname => {
        return { title: keyname, value: getData[keyname] }
      }) : []

      setindexList(newStockList)
    }
  }
  // 图表切换处理function
  async function callback(value) {
    if (value === MAIN_RATE) {
      _cutDateType(newTabs);
    }
    if (value !== MAIN_RATE) {//&& isTradeTime
      setInter(undefined);
    }
    setTabActive(value);
  }

  function updateMain() {
    setCountMain(_.round(countMain + 0.1, 1));
  }
  //跳转股票页面
  function _goShares() {
    props.history.push({
      pathname: '/home/StockScreening', // 直接使用Reactive的数据无法跳转，需要cloneDeep处理才可以
      state: { subAccountInfo: _.cloneDeep(accountState.info) }
    });
  }

  const main_props = {
    'pageKey': 'lists', 'role': mobxStore.userInfo.role, 'subAccountId': accountState.info.subAccountId, 'upcount': countMain,
    'isTrading': isTradeTime, 'validDateRange': validDate, 'datas': pointDatas, 'indexSymbol': accountState.symbol, 'indexValue': indexValue,
    'dailyRevenueRate': _.round(parseFloat(_.get(assetData, 'dailyRevenueRate', 0)), 2),  // 空收益率数据显示日收益率
    'currentTabs': newTabs, 'pms': idxParams, 'plateHistory': plateHistory, 'isReverse': isReverse,
    'reloadCount': posCount, 'customIndex': customIndex, 'tradeDates': tradeDates
  };
  const sub_props = {
    'indexList': indexList, 'subAccountId': accountState.info.subAccountId, 'todayFormat': TODAY, 'active': tabActive,
    'isReverse': isReverse, 'globalDate': exeTimes, 'id': accountState.info.subAccountId,
  }
  const rev_props = { 'pageKey': 'account', 'active': tabActive, 'id': accountState.info.subAccountId, 'isReverse': isReverse };
  return (
    <div className="contentView" id="listsDetails">
      <PageHeader
        title={_.get(accountState.info, 'subAccountName', '')}
        onBack={() => props.history?.goBack()}
        style={{ backgroundColor: 'white', marginBottom: 10 }}
      >
        <RecentTabs
          active={atab}
          pageKey='lists'
          items={accountCacheState}
          id={accountState.info.subAccountId}
          onItemChange={(activeKey, itemVal) => {
            setAtab(activeKey);
            accountState.info = itemVal;
            _initGetData(true);
          }}
        />
      </PageHeader>

      <AssetsHeader  // 资产信息
        role={mobxStore.userInfo.role}
        hasSubAccount={false}
        subAssest={{}}
        assetData={assetData}
        goShares={_goShares}
        isSubListdetail={isSubListdetail}
        subAccountInfo={accountState.info}
      />

      <div className='listTab'>
        <Tabs activeKey={tabActive} onChange={callback} type='card'>
          <TabPane tab={<DetailTabsIcon keys={MAIN_RATE} />} key={MAIN_RATE}>
            <MainRateCharts
              {...main_props}
              cutDateType={_cutDateType}
              checkDayRate={(key, date) => _cutDateType(key, date)}
              onPlateAvgChange={() => _showSubAccountPlateHistory()}
              indexChange={(idx) => {
                accountState.symbol = idx;
                _showIndexKline(idx);
              }}
            />
          </TabPane>
          <TabPane tab={<DetailTabsIcon keys='RATE_K' />} key='RATE_K'>
            <KRateCharts
              {...rev_props}
              subAccountId={accountState.info.subAccountId}
              newProps={props}
              indexSymbol={accountState.symbol}
            />
          </TabPane>
          {accountState.info.strategyType === 1 && <TabPane tab={<DetailTabsIcon keys='ATTRIBUTION' />} key='ATTRIBUTION'>
            <PlateCharts // 从交易记录迁移
              subAccountId={accountState.info.subAccountId}
              isReverse={isReverse}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='CONTRIBUTION2' />} key='CONTRIBUTION2'>
            <ContributionCharts
              pageKey='account'
              isReverse={isReverse}
              subAccountId={accountState.info.subAccountId || 0}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='REVIEW' />} key='REVIEW'>
            <ReviewCharts
              isReverse={isReverse}
              subAccountId={accountState.info.subAccountId || 0}
              isTrading={mobxStore.inTrading === 'trading' ? true : false}
            />
          </TabPane>}
          <TabPane tab={<DetailTabsIcon keys='DISTRIBUTION' />} key='DISTRIBUTION'>
            <DisplayCharts
              pageKey='list'
              {...rev_props}
              upTimeCount={timeCount}
            />
          </TabPane>
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='SIGNAL' />} key='SIGNAL'>
            <SignalCharts
              {...sub_props}
              param={signalUpdate}
              upcount={signalCount}
              newProps={props}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='COUNTING' />} key='COUNTING'>
            <TransCharts {...sub_props} newProps={props} />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='EARNING' />} key='EARNING'>
            <CompareCharts
              {...sub_props}
              newProps={props}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='ACC_COM' />} key='ACC_COM'>
            <CompareChartsTwo
              {...rev_props}
              isTrading={isTradeTime}
              idxPms={idxParams}
              accountInfo={{
                dateType: accountState.info.dateType,
                subAccountId: accountState.info.subAccountId,
                date: _.get(accountState.info, 'dateToday', TODAY),
              }}
            />
          </TabPane>}
          {!isVisitor && <TabPane tab={<DetailTabsIcon keys='BT_COM' />} key='BT_COM'>
            <BtCompareCharts
              {...rev_props}
              hasFc={isFcLogin}
            />
          </TabPane>}
        </Tabs>
      </div>
      <br />
      <PositionTable
        role={mobxStore.userInfo.role}
        subAccountInfo={accountState.info}
        validDateRange={validDate}
        newProps={props}
        upCount={posCount}
        isReverse={isReverse}
        isTrading={isTradeTime}
      />
      <br />
      <TradeRecord
        subAccountId={accountState.info.subAccountId}
        strategyType={accountState.info.strategyType}
        tradeStatus={isTradeTime}
        visitor={isVisitor}
        upCount={posCount}
        newProps={props}
        isReverse={isReverse}
        onTimeChange={(date) => {
          accountState.info.startAndEndTime = date;
        }}
        onSignal={(recordVal, dateArray) => {
          document.documentElement.scrollTop = document.body.scrollTop = 180;
          const getSymbol = _.get(recordVal, 'stockCode');
          const getMode = _.get(recordVal, 'tradeMode', 0);
          setSignalUpdate({
            selectStocks: getSymbol,
            traderIndex: 'all',
            startTime: dateArray[0],
            endTime: dateArray[1],
            tradeMode: getMode,
            stockName: _.get(recordVal, 'stockName', null)
          })
          setSignalCount(signalCount + 1);
          setTabActive('SIGNAL')
        }}
      />
    </div>
  )
}