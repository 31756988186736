import React, { useState, useEffect } from 'react';
import {
  Card, Col, Row, Button, Form, message, Tabs, PageHeader, InputNumber, AutoComplete, Select, Space
} from 'antd';
import { showSubAccountData } from '@/api/workbench';
import {
  buy, sell, market, showList, getCurrentStocks, cancel, getCurrentPositionStocks
} from '@/api/stock';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useLocalObservable } from 'mobx-react';
import { store } from '@/store/mobx';
import { cryptoDatas } from '@/utils/cryptoUtils';
import TradeList from './Components/TradeList';
import TradeHeader from './Components/TradeHeader';
import TradeInfo from './Components/TradeInfo';
import moment from 'moment';
import _ from 'lodash';

const { Option } = Select;

export default function Trading(props, { history }) {
  const mobxStore = useLocalObservable(() => store);
  const [subAccountInfo] = useState(props.location.state.subAccountInfo);
  const [active] = useState(props.location.state.active);
  const [record] = useState(props.location.state.record);
  const [assetData, setassetData] = useState({});
  const [listData, setlistData] = useState({});
  const [orderList, setorderList] = useState({});
  const [askPrice, setaskPrice] = useState([]);
  const [bidPrice, setbidPrice] = useState([]);
  const [optionsSell, setoptionsSell] = useState([]);
  const [isDisabled, setisDisabled] = useState(false);
  const [enDisabled, setenDisabled] = useState(false);
  const [inputDisabled, setinputDisabled] = useState(false);
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [value, setValue] = useState(0);
  const [number, setNumber] = useState(100);
  const [listParams, setListParams] = useState({});
  const [containsETF, setcontainsETF] = useState(0);
  const [clientOrderIdList, setclientOrderIdList] = useState([]);
  const isReverse = _.get(subAccountInfo, 'centralizedLogin') === 2 ? true : false;

  useEffect(() => {
    if (number <= 100) { setisDisabled(true); }
    _getlist();
    _getMarke();
    _getData();
    _getOptions();
    if (mobxStore.inTrading === 'trading') {
      _get();
    }
    // console.log('subAccountInfo', subAccountInfo)
  }, []);
  async function _get() {
    const asset = setInterval(
      async function () {
        const nextRoutePathName = window.location.pathname;
        if (!String.prototype.startsWith.call(nextRoutePathName, '/home/Trading')) {
          clearInterval(asset);
          return;
        }
        _getlist();
        _getMarkeInterval();
        _getData();
      }, 3000);
  };
  //资产收益等信息
  async function _getlist() {
    const data = await showSubAccountData({ subAccountId: subAccountInfo.subAccountId }, isReverse ? 'FC' : 'NOR');
    if (data.code === '200') {
      setassetData(data.data);
    }
  }
  async function _getMarkeInterval() {
    if (record.stockName.indexOf('ETF') !== -1 || record.stockName.indexOf('LOF') !== -1) {
      setcontainsETF(1);
    }
    let data = null;
    if (!record.tsCode) {
      data = await market({ subAccountId: subAccountInfo.subAccountId, tsCode: record.stockCode });
    } else {
      data = await market({ subAccountId: subAccountInfo.subAccountId, tsCode: record.tsCode });
    }
    if (data.code === '200') {
      if (data.data === null) {
        return message.info(data.message);
      }
      setlistData(data.data.marketData);
      setaskPrice(data.data.marketData.askPrice);
      setbidPrice(data.data.marketData.bidPrice);
    }

  }
  async function _getMarke() {
    if (record.stockName.indexOf('ETF') !== -1 || record.stockName.indexOf('LOF') !== -1) {
      setcontainsETF(1);
    }
    let data = null;
    if (!record.tsCode) {
      data = await market({ subAccountId: subAccountInfo.subAccountId, tsCode: record.stockCode });
    } else {
      data = await market({ subAccountId: subAccountInfo.subAccountId, tsCode: record.tsCode });
    }
    if (data.code === '200') {
      if (data.data === null) {
        return message.info(data.message);
      }
      setlistData(data.data.marketData);
      setaskPrice(data.data.marketData.askPrice);
      setbidPrice(data.data.marketData.bidPrice);
      if (!record.buyOrSell) {
        if (active === '1') {
          setValue(parseFloat(data.data.marketData.askPrice[0]).toFixed(3));
        } else {
          setValue(parseFloat(data.data.marketData.bidPrice[0]).toFixed(3));
        }
      } else {
        if (record.buyOrSell === '1') {
          setValue(parseFloat(data.data.marketData.askPrice[0]).toFixed(3));
        } else {
          setValue(parseFloat(data.data.marketData.bidPrice[0]).toFixed(3));
        }
      }
    }
  }
  //修改交易记录表格时间
  function selectTime(date, dateString) {
    subAccountInfo.startAndEndTime = dateString;
    _getData();
  }
  //获取买入卖出股票代码池
  async function _getOptions() {
    const data1 = await getCurrentStocks({ subAccountId: subAccountInfo.subAccountId }, isReverse ? 'FC' : 'NOR');
    const data2 = await getCurrentPositionStocks({ subAccountId: subAccountInfo.subAccountId }, isReverse ? 'FC' : 'NOR');
    if (data1.code === '200') {
      let arr = [];
      data1.data.map(item => {
        let b = { value: item.stockCode + '  ' + item.stockName };
        arr.push(b);
        return item;
      })
      //setoptions(arr)
    }
    if (data2.code === '200') {
      let brr = [];
      data2.data.map(item => {
        let b = { value: item.stockCode + '  ' + item.stockName };
        brr.push(b);
        return item;
      })
      setoptionsSell(brr)
    }
  }
  //根据时间和股票名称查询交易记录
  async function selectRecord(value) {
    subAccountInfo.content = value.replace(/(^\s*)|(\s*$)/g, "");
    _getData();
  }
  //获取交易记录
  async function _getData() {
    let params = {
      subAccountId: subAccountInfo.subAccountId,
      state: false,
      beginTime: subAccountInfo.startAndEndTime ? subAccountInfo.startAndEndTime[0] : null,
      endTime: subAccountInfo.startAndEndTime ? subAccountInfo.startAndEndTime[1] : null,
      content: subAccountInfo.content ? subAccountInfo.content : null,
    }
    setListParams(params);
    const data = await showList(params, isReverse ? 'FC' : 'NOR');
    if (data.code === '200' || data.code === '205') {
      if (data.data !== null) {
        const getList = _.get(data, 'data.result', []);
        let newList = [];
        _.size(getList) > 0 && getList.map((item, i) => {
          newList.push({ ...item, timeKey: moment().diff(moment(item.commissionTime), 'm') })
        })
        setorderList({
          list: newList,
          // rate: _.get(data, 'data.rateResult', {})
        });
      } else {
        setorderList([]);
      }
    } else {
      message.error(data.message);
    }
  }
  //下单
  async function orderBuy() {
    const values = await form.validateFields();
    values.subAccountId = subAccountInfo.subAccountId;
    values.highLimitPrice = listData.highLimited;
    values.stockName = record.stockName;
    if (!record.tsCode) {
      values.stockCode = record.stockCode.substring(0, record.stockCode.indexOf('.'));
      if (record.stockCode.substring(record.stockCode.indexOf('.') + 1) === 'SH') {
        values.market = 'SH_A';
      } else {
        values.market = 'SZ_A';
      }
    } else {
      values.stockCode = record.stockCode;
      if (record.tsCode.substring(record.tsCode.indexOf('.') + 1) === 'SH') {
        values.market = 'SH_A';
      } else {
        values.market = 'SZ_A';
      }
    }
    values.price = value;
    values.quantity = number;
    values.side = "BUY";
    values.priceType = _.get(values, 'priceType') ?? 'limit';
    if (values.price === null && values.priceType === 'limit') {
      return message.error("请输入委托价格");
    }
    if (values.quantity === null) {
      return message.error("请输入委托数量");
    }
    if (values.priceType === 'market') {
      Reflect.deleteProperty(values, 'price')
    }
    //console.log(values)
    const data = await buy(cryptoDatas(values), isReverse ? 'FC' : 'NOR');
    if (data.code === '200') {
      message.success("购买成功");
      _getData();
      setNumber(100);
    } else {
      message.error(data.message);
    }
  }
  async function orderSell() {
    const values = await form.validateFields();
    values.subAccountId = subAccountInfo.subAccountId;
    values.highLimitPrice = listData.highLimited;
    values.stockName = record.stockName;
    if (!record.tsCode) {
      values.stockCode = record.stockCode.substring(0, record.stockCode.indexOf('.'));
      if (record.stockCode.substring(record.stockCode.indexOf('.') + 1) === 'SH') {
        values.market = 'SH_A';
      } else {
        values.market = 'SZ_A';
      }
    } else {
      values.stockCode = record.stockCode;
      if (record.tsCode.substring(record.tsCode.indexOf('.') + 1) === 'SH') {
        values.market = 'SH_A';
      } else {
        values.market = 'SZ_A';
      }
    }
    values.price = value;
    values.quantity = number;
    values.side = "SELL";
    values.priceType = _.get(values, 'priceType') ?? 'limit';
    if (values.quantity === null) {
      return message.error("请输入委托数量");
    }
    if (values.priceType === 'market') {
      Reflect.deleteProperty(values, 'price')
    }
    // console.log(values)
    const data = await sell(cryptoDatas(values), isReverse ? 'FC' : 'NOR');
    if (data.code === '200') {
      message.success("卖出成功");
      _getData();
      setNumber(100);
    } else {
      message.error(data.message);
    }
  }
  function offerType(e) {
    if (e === 'market') {
      setValue(listData.match);
      setinputDisabled(true);
    } else {
      setinputDisabled(false);
    }
  }
  function onSelect(data) {
    let arr = data.split('  ');
    record.stockName = arr[1];
    record.tsCode = arr[0];
    record.stockCode = arr[0].substring(0, arr[0].indexOf('.'));
    setcontainsETF(0);
    if (arr[1].indexOf('ETF') !== -1 || arr[1].indexOf('LOF') !== -1) {
      setcontainsETF(1);
    }
  }
  function callback(key) {
    if (key === '1') {
      setValue(parseFloat(bidPrice[0]).toFixed(3));
    } else {
      setValue(parseFloat(askPrice[0]).toFixed(3));
    }
  }
  async function _cancel(record) {
    let list = clientOrderIdList;
    list.push(record.clientOrderId);
    setclientOrderIdList(list);
    let params = {
      subAccountId: subAccountInfo.subAccountId,
      state: false,
      beginTime: _.get(listParams, 'beginTime', ''),
      endTime: _.get(listParams, 'endTime', ''),
      content: _.get(listParams, 'content', ''),
    }
    const data = await showList(params, isReverse ? 'FC' : 'NOR');
    if ((data.code === '200' && data.data !== null) || data.code === '205') {
      let getResult = _.get(data, 'data.result', []);
      if (_.size(getResult) > 0) {
        getResult = getResult.map(item => {
          item.commissionTime = item.commissionTime.substring(0, item.commissionTime.indexOf('.'));
          return item;
        })
        setorderList(getResult);
        _cancel2(record);
      }
    } else {
      message.error(data.message);
    }
  }

  async function _cancel2(record) {
    let params = {
      subAccountId: subAccountInfo.subAccountId,
      relateId: record.clientOrderId
    }
    const data1 = await cancel(cryptoDatas(params), isReverse ? 'FC' : 'NOR');
    if (data1.code === '200') {
      message.success("撤单成功");
    } else {
      message.error(data1.message);
    }
  }

  function renderTradeForm(key) {
    return (
      <Row>
        <Form form={form} name="control-hooks" size='large'>
          <Form.Item label="股票名称或代码">
            <AutoComplete
              options={optionsSell}
              style={{ width: 200 }}
              onSelect={onSelect}
              placeholder="请输入股票名称或代码"
              defaultValue={record.tsCode ? record.tsCode + '  ' + record.stockName : record.stockCode + '  ' + record.stockName}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item name='priceType' label="报价类型" >
            <Select
              placeholder="请选择报价类型"
              defaultValue="limit"
              onChange={offerType}
            >
              <Option value="limit">限价单</Option>
              <Option value="market">市价单</Option>
            </Select>
          </Form.Item>
          <Form.Item name="price" label="委托价">
            <Space>
              <InputNumber disabled={inputDisabled} value={value} onChange={setValue} min={0} step={0.01} />
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => {
                  setValue(((value * 100 + 0.01 * 100) / 100).toFixed(3));
                  if (value >= 0) {
                    setenDisabled(false);
                  }
                }} />
              <Button
                type="primary"
                shape="circle"
                icon={<MinusOutlined />}
                disabled={enDisabled}
                onClick={() => {
                  setValue(((value * 100 - 0.01 * 100) / 100).toFixed(3));
                  if (Number(value) === 0.01) {
                    setenDisabled(true);
                  }
                }} />
            </Space>
          </Form.Item>
          <Form.Item name="quantity" label="委托数量">
            <Space>
              <InputNumber value={number} onChange={setNumber} min={100} step={100} />
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() => {
                  setNumber(number + 100);
                  if (number >= 100) {
                    setisDisabled(false);
                  }
                }} />
              <Button
                type="primary"
                shape="circle"
                icon={<MinusOutlined />}
                disabled={isDisabled}
                onClick={() => {
                  setNumber(number - 100);
                  if (number === 200) {
                    setisDisabled(true);
                  }
                }} />
            </Space>
          </Form.Item>
          <Form.Item label="拆单方式" rules={[{ required: true }]}>
            <Select
              placeholder="请选择拆单方式"
              defaultValue="不拆单"
            >
              <Option value="不拆单">不拆单</Option>
            </Select>
          </Form.Item>

          <Button type="primary" onClick={key === '1' ? orderBuy : orderSell} block danger={key === '1' ? true : false}>
            {key === '1' ? '买入' : '卖出'}
          </Button>
        </Form>
      </Row>
    )
  }

  return (
    <>
      <PageHeader
        title={'交易'}
        subTitle={_.get(subAccountInfo, 'subAccountName', '')}
        onBack={() => props.history?.goBack()}
        style={{ backgroundColor: 'white' }}
      ></PageHeader>

      <div className="contentView" id="trading">

        <TradeHeader assetData={assetData} />
        <br />

        <Card>
          <Row>
            <Col xxl={8} xl={8} lg={12} md={24} sm={24}>
              <Tabs defaultActiveKey={active} onChange={callback} type='card'>
                <TabPane tab="买入" key="1">
                  {renderTradeForm('1')}
                </TabPane>
                <TabPane tab="卖出" key="2">
                  {renderTradeForm('2')}
                </TabPane>
              </Tabs>
            </Col>

            <TradeInfo
              askPrice={askPrice}
              bidPrice={bidPrice}
              containsETF={containsETF}
              listData={listData}
              recordData={record}
              form={form}
            />
          </Row>
        </Card>
        <br />
        <TradeList
          datas={_.get(orderList, 'list', [])}
          clientOrderIdList={clientOrderIdList}
          onCancel={(record) => _cancel(record)}
          onTimeSelect={selectTime}
          onSelectRecord={selectRecord}
        />
      </div>
    </>
  )
}