import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { renderHugeFund } from '@/utils/utils';
import moment from 'moment';
import _ from 'lodash';
import * as echarts from 'echarts';

const COMMON_LINE_SERIES = {
  data: [],
  type: 'line',
  showSymbol: true,
  connectNulls: true,
  smooth: true,
  yAxisIndex: 1,
}
const COMMON_BAR_SERIES = {
  data: [],
  type: 'bar',
  label: {
    show: true,
    position: 'inside',
    formatter: function (param) {
      return renderHugeFund(param.value);
    }
  },
}
const COMMON_YAXIS = {
  splitNumber: 5,
  scale: true,
  splitLine: false,
  type: 'value',
  axisLabel: {
    formatter: function (value) {
      return renderHugeFund(value);
    }
  }
}

export default function FundCharts(props) {
  const getPageKey = _.get(props, 'pageKey', '')

  const [loading, setLoading] = useState(false);
  const [option, setoption] = useState({
    grid: { left: '8%', right: '8%', bottom: '10%', top: '10%' },
    xAxis: { type: 'category', data: [], },
    yAxis: getPageKey === 'Main' ? [
      {
        name: '市值',
        ...COMMON_YAXIS
      },
      {
        name: '成本',
        ...COMMON_YAXIS
      }
    ] : [{
      name: '数量',
      ...COMMON_YAXIS
    }, {
      name: '波幅(%)',
      ...COMMON_YAXIS
    }],
    series: getPageKey === 'Main' ? [
      {
        name: '外资成本',
        ...COMMON_LINE_SERIES
      },
      {
        name: '内资成本',
        ...COMMON_LINE_SERIES
      },
      {
        name: '外资市值',
        ...COMMON_BAR_SERIES
      },
      {
        name: '内资市值',
        ...COMMON_BAR_SERIES
      }
    ] : [
      {
        name: '主力',
        ...COMMON_LINE_SERIES,
        yAxisIndex: 0,
      },
      {
        name: '外资主力',
        ...COMMON_BAR_SERIES
      },
      {
        name: '内资主力',
        ...COMMON_BAR_SERIES
      },
      {
        name: '涨跌幅',
        yAxisIndex: 1,
        ...COMMON_LINE_SERIES
      },
    ],
    tooltip: { trigger: 'axis' },
  });

  useEffect(() => {
    //console.log('charts', props)
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();
    }
    // 两个tab页面用同一个charts，加载不同的key,避免冲突
    myChart = echarts.init(document.getElementById(_.get(props, 'chartKey')));
    myChart.showLoading({
      text: '数据获取中',
      effect: 'whirling'
    });

    let newOption = option;
    const getDatas = _.get(props, 'datas', []);
    if (getDatas && _.size(getDatas) > 0) {
      let foreignLine = [];
      let domesticLine = [];
      let foreignBar = [];
      let domesticBar = [];
      let xArray = [];
      let dailyk = [];
      let dailyr = [];

      if (getPageKey === 'Main') {
        getDatas.map(n => {
          foreignLine.push(n.foreignCost || '-');
          domesticLine.push(n.domesticCost || '-');
          foreignBar.push(n.foreignMarket || '-');
          domesticBar.push(n.domesticMarket || '-');

          xArray.push(moment(n.executeDate).format('YYYY-MM-DD'));
        })

        newOption.series[0].data = foreignLine;
        newOption.series[1].data = domesticLine;
        newOption.series[2].data = foreignBar;
        newOption.series[3].data = domesticBar;
      }

      if (getPageKey === 'Analysis') {
        getDatas.map(n => {
          foreignLine.push(n.mainFunds || '-');
          foreignBar.push(n.foreignFunds || '-');
          domesticBar.push(n.dvalue || '-');

          dailyk.push(_.get(n, 'closePrice'));

          xArray.push(moment(n.tradeDate).format('YYYY-MM-DD'));
        })

        newOption.series[0].data = foreignLine;
        newOption.series[1].data = foreignBar;
        newOption.series[2].data = domesticBar;

        // 计算右侧Y轴用波幅计算方法..
        const getStart = dailyk[0];
        dailyr = dailyk.map(n => _.ceil((n - getStart) / getStart, 2) * 100)

        newOption.series[3].data = dailyr;
      }

      newOption.xAxis.data = xArray;
    }
    // console.log('newOption', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

  }, [props.updateCount])

  return (
    <Spin spinning={loading}>
      <div style={{ display: 'flex' }}>
        <div
          id={_.get(props, 'chartKey')}
          style={{
            width: '100%',
            height: _.get(props, 'height') || 400
          }}
        />
      </div>
    </Spin>
  )
}