import React, { useState, useEffect } from 'react';
import { Divider, message } from 'antd';
import { showAnalysis } from '@/api/stock'; // getKLineDetail
import { kLineHistory } from '@/api/workbench';
import { useMount, useReactive, useUnmount } from 'ahooks';
import { isEmptyStringV2 } from '@/utils/utils';
import KlineChart from './Components/KlineChart';
import FundCharts from './Components/FundCharts';
import FundBars from './Components/Bars';
import FundTable from './Components/FundTable'
import moment from 'moment';
import _ from 'lodash';

const DEFAULT_RANGE = [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
// 成本分析
const MainPage = ({ tabsVal }) => {
  const [pageType, setPageType] = useState('table');
  const [mainData, setMainData] = useState({});
  const mainState = useReactive({
    info: {}, page: 1, pageSize: 20, date: DEFAULT_RANGE, searchInfo: {}, clearCount: 0, loading: false,
  });
  const klineState = useReactive({ kData: {}, info: {}, upcount: 0, cloading: false });
  const costState = useReactive({ upcount: 0, list: [], info: {} })
  let isUnmont = false;

  useMount(() => {
    _showAnalysis();
  });

  useUnmount(() => {
    isUnmont = true;
  });

  useEffect(() => {
    costState.upcount = costState.upcount + 1;
  }, [tabsVal]);
  // 主力分析接口
  async function _showAnalysis() {
    mainState.loading = true;
    let params = {
      pageNum: mainState.page,
      pageSize: 20,
      startDate: mainState.date[0],
      endDate: mainState.date[1],
      orderType: _.get(mainState, 'searchInfo.orderType', ''),
    }
    if (_.get(mainState, 'searchInfo.stockCode', null)) {
      _.set(params, 'stockCode', mainState.searchInfo.stockCode);
    }
    if (_.get(mainState, 'searchInfo.stockName', null)) {
      _.set(params, 'stockName', mainState.searchInfo.stockName);
    }
    const res = await showAnalysis(params);
    if (_.get(res, 'code', '') === '200' && !isUnmont) {
      setMainData(_.get(res, 'data', {}));
      mainState.page = _.get(res, 'data.pageNum', 0);
      mainState.info = params;
    }
    mainState.loading = false;
  }
  // 获取分钟k线数据
  async function _kLineHistory(record) {
    let params = {
      symbol: _.get(record, 'stockCode'),
      beginDate: mainState.date[0],
      endDate: mainState.date[1],
      type: 2
    }
    klineState.cloading = true;
    setPageType('chart');
    klineState.info = { ...record, ...params };

    const res = await kLineHistory(params);
    if (_.get(res, 'code', '') === '200') {
      const getPriceResult = _.get(res, 'data.priceResult', []);
      let getTimeList = getPriceResult.map(array => array[0]);
      let priceList = [];
      let newValueList = [];
      getPriceResult.map((array, index) => {
        let temp = []
        array.map((k, i) => {
          if (i === 0) {
            // temp.push(k)
          } else if (i === 5) {
            priceList.push(parseFloat(k));
            temp.push(parseFloat(k))
          } else {
            temp.push(parseFloat(k))
          }
        })
        newValueList.push(temp)
      })
      const newKData = {
        timeList: getTimeList,
        values: newValueList,
      };
      klineState.kData = newKData;
      klineState.upcount = klineState.upcount + 1;
    }
    klineState.cloading = false;
  }
  // 通用主力分析接口，不同的获取方法，需要地柜拿到所有数据
  async function getCost(code, size = 100) {
    let params = {
      pageNum: 1,
      pageSize: size,
      startDate: mainState.date[0],
      endDate: mainState.date[1],
      stockCode: code,
    }
    if (!isEmptyStringV2(params.stockCode)) {
      const res = await showAnalysis(params);
      if (_.get(res, 'code', '') === '200') {
        const getTotal = _.get(res, 'data.total', null) || 0;
        const getList = _.get(res, 'data.list', []);
        if (getTotal > size) {
          getCost(code, getTotal);
          return
        }
        let sortData = getList.map(n => {
          return {
            ...n,
            tidx: moment(n.tradeDate).diff(moment(), 'day'),
          }
        })
        sortData.sort((a, b) => a.tidx - b.tidx);
        costState.list = sortData;
        costState.upcount = costState.upcount + 1;
        costState.info = params;
      }
    }
  }

  const hanldeSearch = (val) => {
    if (val === '') {
      message.info('请输入股票代码或股票名称！')
      return
    }
    const regText = /^[0-9]*$/;
    const reg = new RegExp(regText);
    if (reg.test(val)) {
      mainState.searchInfo.stockCode = val;
    } else {
      mainState.searchInfo.stockName = val;
    }
    mainState.page = 1;
    _showAnalysis();
  }

  const handleSort = (dataIndex) => {
    if (dataIndex === 'clear') {
      _.set(mainState, 'searchInfo.orderType', '');
      mainState.page = 1;
    } else {
      _.set(mainState, 'searchInfo.orderType', dataIndex);
    }
    mainState.clearCount = mainState.clearCount + 1;
    _showAnalysis();
  }

  const handleBack = () => {
    if (_.get(mainState, 'info.startDate') !== _.get(klineState, 'info.beginDate') || _.get(mainState, 'info.endDate') !== _.get(klineState, 'info.endDate')) {
      _showAnalysis();
    }
    setPageType('table');
  }

  const isCharts = pageType === 'chart' ? true : false;
  return <>
    <FundBars
      pageKey='Analysis'
      dateArray={mainState.date}
      stockName={_.get(klineState.info, 'stockName', '')}
      orderType={_.get(mainState.searchInfo, 'orderType', null)}
      isShow={isCharts}
      onBack={() => handleBack()}
      onInputSearch={(v) => hanldeSearch(v)}
      onSortClear={() => handleSort('clear')}
      onClear={() => {
        mainState.searchInfo = {};
        mainState.page = 1;
        mainState.clearCount = mainState.clearCount + 1;
        _showAnalysis();
      }}
      onDateChange={(dateStrings) => {
        mainState.date = dateStrings;
        if (!isCharts) {
          _showAnalysis();
        } else {
          _kLineHistory(klineState.info);
          getCost(_.get(costState.info, 'stockCode', null))
        }
      }}
    />
    <Divider />
    {isCharts ? <div className='anaCharts'>
      <KlineChart
        kLineData={klineState.kData}
        isUpdate={klineState.upcount}
        loading={klineState.cloading}
        height={300}
      />
      <div style={{ height: 65 }}></div>
      <FundCharts
        pageKey='Analysis'
        chartKey='indexCharts'
        updateCount={costState.upcount}
        datas={costState.list}
        height={280}
      />
    </div> : <FundTable
      pageCurrent={mainState.page}
      mainData={mainData}
      loading={mainState.loading}
      searchValue={mainState.searchInfo}
      clearCount={mainState.clearCount}
      goDetail={(record) => {
        _kLineHistory(record);
        getCost(_.get(record, 'stockCode', null))
      }}
      onPageChange={(page, pageSize) => {
        mainState.page = page;
        _showAnalysis();
      }}
      onSort={(dataIndex) => handleSort(dataIndex)}
    />}
  </>
}

export default MainPage;