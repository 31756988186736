import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import _ from 'lodash';
import * as echarts from 'echarts';

const upColor = '#ec0000';
const upBorderColor = '#ec0000';
const downColor = '#00da3c';
const downBorderColor = '#00da3c';
const DEFAULT_OPTION = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    formatter: function (params) {
      //console.log(params)
      let maArray = [];
      let maString = '';
      let k_data = _.get(params, '[0]', {});
      if (_.size(params) > 1) {
        maArray = _.drop(params);
        maArray.map(n => {
          maString = maString + `<span style='color=${n.color}'>${n.seriesName}</span>` + ' :' + n.value + '<br>'
        })
      }

      return '开盘: ' + k_data.value[1] + '<br>' +
        '收盘: ' + k_data.value[2] + '<br>' +
        '最高: ' + k_data.value[3] + '<br>' +
        '最低: ' + k_data.value[4] + '<br>' + maString
    }
  },
  legend: {
    // data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30']
    data: ['分钟K']
  },
  grid: { left: '6%', right: '6%', bottom: '15%', top: '11%' },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLine: { onZero: false },
    splitLine: { show: false },
    min: 'dataMin',
    max: 'dataMax'
  },
  yAxis: {
    scale: true,
    splitArea: {
      show: true
    }
  },
  dataZoom: [
    { type: 'inside', start: 0, end: 100 },
    { show: true, type: 'slider', top: '91%', start: 0, end: 100, height: 12 }
  ],
  series: [
    {
      name: '分钟K',
      type: 'candlestick',
      data: [],
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      },

    },
  ]
}
// Each item: open，close，lowest，highest
const KlineChart = (props) => {
  const [option, setoption] = useState(DEFAULT_OPTION);

  useEffect(() => {
    let myChart = props.myChart;
    if (myChart !== null && myChart !== "" && myChart !== undefined) {
      myChart.dispose();//销毁
    }

    myChart = echarts.init(document.getElementById('chart_kline'));
    myChart.showLoading({ text: '数据获取中', effect: 'whirling' });

    let newOption = option;

    if (_.size(props.kLineData) > 0) {
      newOption.xAxis.data = _.get(props, 'kLineData.timeList', []);
      newOption.series[0].data = _.get(props, 'kLineData.values', []);;
    }
    //console.log('更新！', newOption)
    setoption(newOption);
    myChart.setOption(newOption, true);
    myChart.hideLoading();
    myChart.resize();

  }, [option, props.isUpdate])

  return (
    <Spin spinning={props.loading}>
      <div style={{ display: 'flex' }}>
        <div
          id="chart_kline"
          style={{
            width: '100%',
            height: _.get(props, 'height') || 480
          }}
        />
      </div>
    </Spin>

  )
}


export default KlineChart;